import React from 'react'
import {Async} from 'azlib/components/async'
import {ModalButton, showModal, PopupFrame} from './modals'
import {XLOG} from 'azlib/components/helpers'

import css from './chooser.module.css'

/** decoded value is
 * { rid: array of key values
 * 	 text: text represenation (if no, it is joined rid) 	
 *   img: image
 *   title: simple or rich tooltip
 *  }
 *  LabeledField can recognise special properies 
 * 			to read with 
 */

/**
 * simple menu-like chooser
 * 
 */
export function chooseRecordSimple({selector,text,title, readOnly,...props}) {
	return showModal(<Async value={selector.fetch_list()}
			>{rows => 
			<PopupFrame className={css.simpleChooser}>
			<div>{title}</div>
			{
				rows.length?
					(rows).map(row=><ModalButton
							disabled={readOnly}
							value={row.data}
							className={css.simpleChooserRow +" link block"}
							key={JSON.stringify(selector.getKeyValues(row.data))}
						>{
						text(row.data)
					}</ModalButton>)
				: 'Нет'
		    }</PopupFrame>
		   }</Async>, {framed:false, closeBox:true})
}


export function Chooser(props){
	return <PopupFrame className={css.simpleChooser} {...props}/>
}

function Button(props) {
	return 	<button type="button" className={css.simpleChooserRow +" link block"}
			{...props} />
}

function ChooseButton(props) {
	return 	<ModalButton type="button" className={css.simpleChooserRow +" link block"}
			{...props} />
}

Chooser.Button = Button
Chooser.ChooseButton = ChooseButton

//TODO: chooser, visualised as table (takes table)
//TODO: model guided chooser