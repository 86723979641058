import React from 'react';

import {PopupMenu, ModalButton, alert} from 'azlib/components/modals'
import {Async} from 'azlib/components/async'
import {mainDbX} from 'azlib/components/db'


console.log(window.location)

function local_addr() {
	return window.location.host === 'localhost:3000'
}

// here requests catched at devServer, so it we can use any Db module
// even plain fetch

async function do_op(op, args, silent) {
	console.log(`devops ${op} started`)
	let r = await mainDbX.fetch_get('/zone-op'
		,{op, ...args})
	console.log(await r.text())
	if(!silent) alert(`${op} done, look at dev console for results`)
}

window.DevOps = {
	logs: (service) => {
		do_op('logs',{service},true)
	}
}

export function DevOpsUI() {
	console.log(local_addr())
	return local_addr() &&
	<Async value={mainDbX.fetch_get_as_json('/zone-op')}>{zone=>
	<div
		style={{
			position:"absolute"
			, top: "1em"
			, left: 100
			, zIndex:1
			, padding: 5
			, border: "1px solid green"
			, background: "white"
		}}
	>
	<PopupMenu
		trigger={<div>DevOps<br/>{zone}</div>}
		bindX="left"
	>
		<div>devops menu</div>
		<br/>
		<ModalButton type="button" className="block"
			onClick={async e=>await do_op('dump-schemes')}
		>
			dump schemes
		</ModalButton>
		<br/>
		<ModalButton type="button" className="block"
			onClick={async e=>await do_op('diff-db:main')}
		>
			diff-db (main)
		</ModalButton>
		<br/>
		<ModalButton type="button" className="block"
			onClick={async e=>await do_op('diff-with-remote:main')}
		>
			diff-with-remote (main)
		</ModalButton>
		<br/>
		<ModalButton type="button" className="block"
			onClick={async e=>await do_op('feed:main')}
		>
			feed (main)
		</ModalButton>
		<br/>
		<ModalButton type="button" className="block"
			onClick={async e=>await do_op('replace-remote')}
		>
			replace-remote
		</ModalButton>
		<br/>
		<ModalButton type="button" className="block"
			onClick={async e=>await do_op('patch-remote')}
		>
			patch-remote
		</ModalButton>
		<br/>
		<ModalButton type="button" className="block"
			onClick={async e=>await do_op('yarn-build')}
		>
			yarn-build (for check warnings only!)
		</ModalButton>
	</PopupMenu>
	</div>
	}</Async>
}
