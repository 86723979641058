import './App.css';
import {
  BrowserRouter as Router, Routes,Route
} from "react-router-dom"


import UserInfoContext from './UserInfo.js';

import AnonApp from './AnonApp.js';
import MainApp from './MainApp.js';

import {FileUrl} from 'azlib/components/std-controls'

import {EsiaOk} from 'esia/login'

import {ConnectionMonitor, MaintenanceIndicator} from 'azlib/components/connectionMonitor'

import {DevOpsUI} from './devops-ui'

function App() {
  return (
    <Router>
    <Routes>
      <Route path="blob/:tid/:store/:key/:name" element={<FileUrl/>} />
      <Route path="esia/ok" element={<EsiaOk />} />
      <Route path="*" element={
        <div id="App">
          <DevOpsUI/>
          <ConnectionMonitor
            disconnected={
              <div id="appDisconnectedMarker" />
            }
          >
          <MaintenanceIndicator 
            on={<div id="appMaintenanceIndicator" />}
          >
          <UserInfoContext.Provider value={window.UserInfo}>
            {window?.UserInfo.login? //has user
              <MainApp/>
            :
              <AnonApp/>
            }
          </UserInfoContext.Provider>
          </MaintenanceIndicator>
          </ConnectionMonitor>
        </div>
      } />
    </Routes>
    </Router>
  );
}

export default App;
