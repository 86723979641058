import React, {useState} from 'react';
import ReactDOM from 'react-dom';

import {func_or_const} from './helpers'
/**
 * Loading shows 'standart' feedbak
*/ 
export function Loading() {
	return <div>...loading</div>;
}

/**
 * 	render component in hidden div
 */
export function Hidden({children}) {
	return ReactDOM.createPortal(<>{children}</>, document.getElementById('hidden'))
}

/**
 * toggle children rendering
 * when trigger clicked
 */ 
export function Toggle({trigger, children, ...props}) {
	const [vis, setVis] = useState(false);
	return <>{
					React.cloneElement(trigger,{...props, onClick:()=>setVis(!vis)})
					}
					{vis?func_or_const(children): null}
				</>
}

/**
 * toggle children rendering
 * with HTML5 <details>
 */ 
export function ToggleDetails({trigger, children, ...props}) {
	const [vis,setVis] = useState(false)
	return <details onToggle={(e)=>{setVis(e.target.open)}}>
					<summary>{
					React.cloneElement(trigger,{...props})
					}</summary>
					{vis?func_or_const(children):null}
				</details>
}

/**
 * toggle children visibility
 * with HTML5 <details>
 * children always rendered!
 */ 
export function Details({trigger,children, initialOpen, ...props}) {
	const [open,setOpen] = useState(initialOpen)
	if(initialOpen === undefined)
		return <details {...props}><summary children={trigger}/>
						{children}
					</details>
	return <details {...props} open={open}
				onToggle={e=>setOpen(e.target.open)} 
			><summary children={trigger}/>
				{children}
			</details>
}

