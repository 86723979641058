import React, {useContext, useRef, useState, useEffect} from 'react'

import {Async} from 'azlib/components/async'
import {PopupMenu, usePopupFrameContext} from 'azlib/components/modals'
import {ru_date_text} from 'azlib/components/locales/ru/ru_date'

import {toLocalTime} from 'azlib/components/helpers'
import {useOnce} from 'azlib/components/ui_helpers'

import {shardedDbX} from 'azlib/components/db'

//import { DbTable as Table } from 'azlib/components/super-table'
import Sorter from 'azlib/components/list_sort'

import {findViewer} from 'azlib/components/model.controls'

import {OrgInfoContext} from 'org/org'

import {ReactComponent as Scroll} from './scroll.svg'
import {ReactComponent as ScrollLight} from './scrollLight.svg'

function ScrollDep() {
	const c= usePopupFrameContext() 
	return c? <ScrollLight/> :<Scroll/>
}

function decode_hstore(v) {
	if(!v) return null
	let ret = {}
	let strings = []
	v = v.replace(/"(?:\\.|[^"])*"/g, (m)=>{
		strings.push(m.slice(1,-1).replace(/\\(.)/g,'$1'))
		return `"${strings.length-1}"`
	})
	v = v.split(/\s*,\s*/)
	for(const x of v) {
		if(x.match(/"([0-9]+)"=>"([0-9]+)"/)){
			ret[ strings[+RegExp.$1] ]
				= strings[ +RegExp.$2 ]
		}
	}
	return ret;
}

function diff_old_new(o, n) {
	o = decode_hstore(o) ?? {}	
	n = decode_hstore(n) ?? {}

	let keys = new Set()
	for(const i in o)
		keys.add(i)
	for(const i in n)
		keys.add(i)

	let ret = []
	for(const i of keys){
		if((i in n) && (i in o)) 
			{
				if(o[i] !== n[i])
					ret.push([i, o[i],n[i] ])
			}
		else
		if(i in n) ret.push([i, null, n[i] ]) 
		else
		if(i in o) ret.push([i, o[i], null ]) 
	}
	return ret
}

function model_diff(table,transform,o,n, transformContext, firstRow, role) {
	let diff = diff_old_new(o,n)
	diff = diff		
			.filter(([name])=> 
				!transform
				|| !(name in transform)
				|| transform[name]
			)
	if(role)
		diff = diff.filter(d=>d.includes(role))

	return diff.length? 
		<ShowDiff table={table} transform={transform} diff={diff}
			transformContext={transformContext} firstRow={firstRow}
		/> 
		: null
}

function ShowDiff({table, transform, diff, transformContext, firstRow}) {
	let model = window.AppBundle.model[table]
	return diff.length ? <table><tbody>{
		diff
		.map(([name,o,n])=>
			transform?.[name+'.compare']?
				transform?.[name+'.compare'](name,o,n, transformContext, firstRow)
			: {name,o,n}
		).flat()
		.map(({name,o,n})=>
			<tr key={name??''}>
			<td width="200"><b>{model[name]?.caption??name}</b></td>
			<td>{transform?.[name]? transform[name](o, transformContext, firstRow)
					:  findViewer(table, name, {})({value:o??''})
				}</td>
			<td>➔</td>
			<td>{transform?.[name]? transform[name](n, transformContext, firstRow)
					:  findViewer(table, name, {})({value:n??''})}</td>
			</tr>
		)}</tbody></table>
		:null;
}

function DefferedDiff({tid, tname, tkey, fixed, stamp, table, firstRow, transform, transformContext, ...pros}) {
	const loaded = useRef();
	const [loadedState, setLoadedState] = useState()
	return loaded.current === undefined ?
			<button type="button"
				onClick={async ()=>{
					setLoadedState(loaded.current = null)
					let DbX = shardedDbX(tid)
					let rows = await DbX.fetch_rds('logs'
							, 'stamp old_data new_data'
							, { tname: table, key: tkey, 
								...fixed, stamp 
							  }
							)
					setLoadedState(loaded.current = rows[0])
				}}
				style={{color:"black"}}
			>...</button>
			: loaded.current ? 
				model_diff(table, transform, loaded.current.old_data, loaded.current.new_data
						, transformContext, firstRow)
				?? 'служебные изменения'
			: '---???---'
		;
}

const maxCount = 20;

/*
	current rows =  state
	position = state
*/

function LogPage({table, tkey, fixed, transform, deffered, transformContext, tid, role}) {
	const orginfo = useContext(OrgInfoContext);

	let [pos,setPos] = useState(null)
	let [next,setNext] = useState(null)
	let [rows,setRows] = useState([])
	let firstRow = useRef(null);
	let guard = useRef();

	useEffect(()=>{
		if(guard.current===pos) return;
		guard.current = pos;
		shardedDbX(tid ? tid : orginfo?.tid)
		.fetch_rds('partial_logs'
			, 'stamp pname stkey decode_stkey decode_key old_data_exists new_data_exists '
			  + (deffered ? '' : 'old_data new_data')
			, { tname: table, key: tkey, ...fixed }
			, {
				limit: maxCount
				, next_offset: r => r.stamp
				, offset: pos
			  }
			)
		.then(more=>{
			setRows(rows => [...rows, 
				...more
				.map((r,i)=>i?r: (firstRow.current = r)) //save first (insert) row
				.map(r=>({...r
					, code: r.old_data_exists && r.new_data_exists ? 'Δ'
						: r.old_data_exists ? '-'
						: r.new_data_exists ? '+'
						: null
					, diff:
						deffered? <DefferedDiff 
								tname={table} key={tkey} fixed={fixed}
								stamp={r.stamp}
								tid={tid ? tid : orginfo?.tid}
								table={fixed?.stname??table}
								transform={transform}
								transformContext={transformContext}
								firstRow={firstRow.current}
							/>
						: model_diff(fixed?.stname??table, transform, r.old_data, r.new_data
							, transformContext, firstRow.current, role)
				}))
				.filter(r=>r.code!==null && r.diff)
			])
			setNext(more.next_offset)
		})
	}, [pos, setRows, setNext, orginfo])

	return <table
			className="simpleTable"
		>
		<thead><tr>
			<th>время</th>
			<th>пользователь</th>
			<th>Δ</th>
		</tr></thead>
		<tbody>
			{rows
			.map((r,i)=><tr key={i}>
			<td>{(v=>
				v && <>
				{ru_date_text(v.substr(0,10))}
				<br/>
				<small>{v.substr(11,5)}</small>
				</>
				)(toLocalTime(r.stamp))
			}</td>
			<td>{r.pname}</td>
			<td>
				{(!fixed?.stkey && r.stkey || role) &&
					<h4>
					<span style={{color:"gray"}}>{r.code}</span>
					{' '}
					{role? r.decode_key : r.decode_stkey}
					</h4>
				}
				{r.diff}
			</td>
			</tr>)
		}</tbody>
		{pos !== next && <tfoot>
		<tr>
			<td colSpan="100">
				<button type="button" onClick={()=>{setPos(next)}}>
				...
				</button>
			</td>
		</tr>
		</tfoot>}
		</table>
}
export function PopupLog({table, tkey, fixed, transform, transformContext, deffered, tid, role, ...props}) {
	return <PopupMenu 
		trigger={
			<div><ScrollDep /></div>
		}
		{...props}
		closeBox
	>
		<LogPage table={table} tkey={tkey} fixed={fixed} 
			transform={transform} transformContext={transformContext} 
			deffered={deffered} tid={tid} role={role}
			/>
	</PopupMenu> 
}
