export const monster = {
	hb:[], hm:[], hs:[], mp:[]
	, hha:[], hhd:[], hho:[], hhs:[]
}
const decoder = new Map()
const spec_to_level = new Map()

export function growUpMonster(data) {

    for(let i in monster) {
        let win_name = 'spec_'+i;
        let base = data[win_name];
        for(const md of base.orderedKeys){
            if(!md) continue;
            monster[i].push( [md, base.decode[md] ])
        }
    }

    for(const i in monster)
        for(const x of monster[i])
            decoder.set(x[0], x[1])

    for(const i in monster)
        for(const x of monster[i])
            spec_to_level.set(x[0], i)
}



export function decode(code) {
	return decoder.get(code)
}
export function decodeCombined(code) {
	return code ? code + ' ' + decoder.get(code) : code
}

export function ed_level_by_spec(spec_code) {
    return spec_to_level.get(spec_code)
}
