import { useParams } from "react-router-dom";

/**
 * if params name is capitalized, return integer
 */
export function useTypedParams() {
	const p = useParams();
	let r = {}
	for(const i in p) {
				r[ i ] = /^[A-Z]/.test(i) ?
							+p[i]
						:
							p[i]
	}
	return r;
}
