import * as yup from 'azlib/components/yup_plus'

import {XLOG} from 'azlib/components/helpers'

import {ed_level_by_spec, decode as decodeMonster} from 'config/mega_monster'

import {is_gov_doc} from 'docs/params_descr'
import React from "react";
import {region, string_model} from "azlib/components/yup_plus";


export const importIdCols = [
'ФАМИЛИЯ', 'ИМЯ', 'ОТЧЕСТВО'
, 'ДАТА РОЖДЕНИЯ', 'ГОД ОКОНЧАНИЯ'
, /^(КОД СПЕЦИАЛЬНОСТИ|КОД НАПРАВЛЕНИЯ ПОДГОТОВКИ|КОД ПРОФЕССИИ|9[/]11|НАИМЕНОВАНИЕ ПРОГРАММЫ ПРОФЕССИОНАЛЬНОГО ОБУЧЕНИЯ|НАИМЕНОВАНИЕ ПРОГРАММЫ)$/
]


export const importSchemeId = {
	last_name: yup.string().label('ФАМИЛИЯ').required()
	, first_name: yup.string().label('ИМЯ').required()
	, middle_name: yup.string().label('ОТЧЕСТВО')
	, bdate: yup.string_date().label('ДАТА РОЖДЕНИЯ').required()
	, year_completion: yup.number().label('ГОД ОКОНЧАНИЯ')
				.min(1900).max(2100)
				.required()
	, spec_code:
			yup.string().label('9/11')
			.meta({altNames: ['КОД СПЕЦИАЛЬНОСТИ', 'КОД НАПРАВЛЕНИЯ ПОДГОТОВКИ', 'КОД ПРОФЕССИИ']})
			.test('spec-code', 'invalid spec code', value=>
					value === '9' || value === '11' || value === null
					|| (ed_level_by_spec(value) && !/^\d{2}\.00\.00$/.test(value))
				)
			.default(null)
			.defined()
			.nullable()
	, spec_name: yup.string()
		.label('СПЕЦИАЛЬНОСТЬ')
		.meta({altNames: ['НАПРАВЛЕНИЕ ПОДГОТОВКИ', 'ПРОФЕССИЯ']})
	, spec_name_p: yup.string()
		.label('НАИМЕНОВАНИЕ ПРОГРАММЫ ПРОФЕССИОНАЛЬНОГО ОБУЧЕНИЯ')
	, spec_name_ap: yup.string()
		.label('НАИМЕНОВАНИЕ ПРОГРАММЫ')
}

export const importSchemeDef = { ...importSchemeId
	, doctype_kind:
			yup.string().label('ВИД ДОКУМЕНТА').meta({altNames: ['ТИП ДОКУМЕНТА']})
			.transform(value=>
				({
					'С ОТЛИЧИЕМ': '-ok-good'
					, '': '-ok'
					, 'СВИДЕТЕЛЬСТВО': '-0'
					, 'СПРАВКА': '-fail'
				})
				[value?.toUpperCase()]
			)
			.required()
	, doctype_kind_ap_p:
		yup.string_notempty().label('ТИП ДОКУМЕНТА')
			.transform(value=>
				({
					'СВИДЕТЕЛЬСТВО О ПРОФЕССИИ РАБОЧЕГО, ДОЛЖНОСТИ СЛУЖАЩЕГО': '-0'
					, 'СВИДЕТЕЛЬСТВО ОБ ОСВОЕНИИ ДОПОЛНИТЕЛЬНЫХ ПРЕДПРОФЕССИОНАЛЬНЫХ ПРОГРАММ В ОБЛАСТИ ИСКУССТВ': '-0'
					, 'ДИПЛОМ О ПРОФЕССИОНАЛЬНОЙ ПЕРЕПОДГОТОВКЕ': '-ok'
					, 'УДОСТОВЕРЕНИЕ О ПОВЫШЕНИИ КВАЛИФИКАЦИИ': '-1'
					, 'СВИДЕТЕЛЬСТВО В ОБЛАСТИ ФИЗИЧЕСКОЙ КУЛЬТУРЫ И СПОРТА': '-2'
				})
					[value?.toUpperCase()]
			)
	, snils: yup.string().label('СНИЛС')
			.matches(/^\d\d\d-\d\d\d-\d\d\d \d\d$/, { excludeEmptyString: true })

	, epgu: yup.string_bool().label('НА ЕПГУ')

	, paper: yup.string_bool().label('НА БЛАНКЕ')
	, country: yup.country().label('ГРАЖДАНСТВО')
	, contact_tel: yup.string()
	, contact_email: yup.string().label('АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ (EMAIL)')
	, gender: yup.number().label('ПОЛ')
		.nullable(true)
		.transform((_,v)=>({'М':1,'Ж':0})[v?.toUpperCase()]??null)
	, edication_reason: yup.string().label('ОСНОВАНИЕ ПРИЕМА НА ОБУЧЕНИЕ')
	, doc_reason: yup.string().label('ОСНОВАНИЕ ВЫДАЧИ ДОКУМЕНТА')
	, year_admission: yup.string().label('ГОД НАЧАЛА ОБУЧЕНИЯ').meta({altNames: ['ГОД ПОСТУПЛЕНИЯ']})
	, period_study: yup.string().label('СРОК ОБУЧЕНИЯ, ЧАСОВ').meta({altNames: ['СРОК ОБУЧЕНИЯ, ЛЕТ']})
	, form_education: yup.string_model('form_education').label('ФОРМА ОБУЧЕНИЯ')
	, place_education: yup.string_model('place_education').label('ФОРМА ПОЛУЧЕНИЯ ОБРАЗОВАНИЯ НА МОМЕНТ ПРЕКРАЩЕНИЯ ОБРАЗОВАТЕЛЬНЫХ ОТНОШЕНИЙ')
	, funding_source: yup.string_model('funding_source').label('ИСТОЧНИК ФИНАНСИРОВАНИЯ ОБУЧЕНИЯ')
	, name_program: yup.string().label('НАИМЕНОВАНИЕ ОБРАЗОВАТЕЛЬНОЙ ПРОГРАММЫ')
	, name_professional_program: yup.string_model('name_professional_program').label('ПРОГРАММА ПРОФЕССИОНАЛЬНОГО ОБУЧЕНИЯ, НАПРАВЛЕНИЕ ПОДГОТОВКИ')
	, ugs: yup.string().label('УГС')
	, area_name: yup.string_multy_model('area_name').label('НАИМЕНОВАНИЕ ОБЛАСТИ ПРОФЕССИОНАЛЬНОЙ ДЕЯТЕЛЬНОСТИ')
	, type_profession: yup.string_multy_model('type_profession').label('НАИМЕНОВАНИЕ ВИДА ПРОФЕССИОНАЛЬНОЙ ДЕЯТЕЛЬНОСТИ')
	, first_education: yup.string_bool().label('ВЫСШЕЕ ОБРАЗОВАНИЕ, ПОЛУЧАЕМОЕ ВПЕРВЫЕ')
	, additional_program: yup.string_model('additional_program').label('ДОПОЛНИТЕЛЬНАЯ ПРОФЕССИОНАЛЬНАЯ ПРОГРАММА')
	, previous_level: yup.string_model('previous_level').label('УРОВЕНЬ ОБРАЗОВАНИЯ ВО/СПО')
	, lname_previous_document: yup.string().label('ФАМИЛИЯ, УКАЗАННАЯ В ДИПЛОМЕ О ВО/СПО')
	, contract: yup.string_bool().label('НАЛИЧИЕ ДОГОВОРА О ЦЕЛЕВОМ ОБУЧЕНИИ')
	, contract_number: yup.string().label('НОМЕР ДОГОВОРА О ЦЕЛЕВОМ ОБУЧЕНИИ')
	, contract_date: yup.string_date().label('ДАТА ЗАКЛЮЧЕНИЯ ДОГОВОРА О ЦЕЛЕВОМ ОБУЧЕНИИ')
	, org_name: yup.string().label('НАИМЕНОВАНИЕ ОРГАНИЗАЦИИ, С КОТОРОЙ ЗАКЛЮЧЁН ДОГОВОР О ЦЕЛЕВОМ ОБУЧЕНИИ')
	, org_ogrn: yup.string().label('ОГРН ОРГАНИЗАЦИИ, С КОТОРОЙ ЗАКЛЮЧЁН ДОГОВОР О ЦЕЛЕВОМ ОБУЧЕНИИ')
	, org_kpp: yup.string().label('КПП ОРГАНИЗАЦИИ, С КОТОРОЙ ЗАКЛЮЧЁН ДОГОВОР О ЦЕЛЕВОМ ОБУЧЕНИИ')
	, name_employer: yup.string().label('НАИМЕНОВАНИЕ ОРГАНИЗАЦИИ РАБОТОДАТЕЛЯ')
	, ogrn_employer: yup.string().label('ОГРН ОРГАНИЗАЦИИ РАБОТОДАТЕЛЯ')
	, kpp_employer: yup.string().label('КПП ОРГАНИЗАЦИИ РАБОТОДАТЕЛЯ')
	, region_employer: yup.region().label('СУБЪЕКТ ФЕДЕРАЦИИ, В КОТОРОМ РАСПОЛОЖЕНА ОРГАНИЗАЦИЯ РАБОТОДАТЕЛЬ')
	, qualification: yup.string_notempty().label('КВАЛИФИКАЦИЯ')
	, replaced: yup.string().label('НОМЕР ЗАМЕНЯЕМОГО ДОКУМЕНТА')
					.matches(/^\d\d-\d-\d{5}-\d{6}-\d/, { excludeEmptyString: true })
	, ed_level: yup.string()
		.label('УРОВЕНЬ ОБРАЗОВАНИЯ')
		.transform(value=>
			({
				'ПРОФЕССИОНАЛЬНОЕ ОБУЧЕНИЕ': 'p'
				, 'ДОПОЛНИТЕЛЬНОЕ ПРОФЕССИОНАЛЬНОЕ ОБРАЗОВАНИЕ': 'ap'
			})
				[value?.toUpperCase()]??null
		)
	, status_message: yup.string().label('ПРИЧИНА')
					.test('need-if-replaced', 'required', (v,ctx)=>
							!ctx.parent.replaced || !!v
					)
	, name_group: yup.string().label('ГРУППИРОВАНИЕ')

	, regnumber: yup.string_notempty().label('РЕГИСТРАЦИОННЫЙ НОМЕР')

	, qualification_category: yup.string().label('ПРИСВОЕННЫЙ КВАЛИФИКАЦИОННЫЙ РАЗРЯД, КЛАСС, КАТЕГОРИЯ')

	, area_proffessional_program: yup.string().label('НАИМЕНОВАНИЕ ПРОФЕССИЙ РАБОЧИХ, ДОЛЖНОСТЕЙ СЛУЖАЩИХ')
}


export function transformImported(src, orginfo, scheme) {

	// src should be VALID
	const full_scheme = yup.object(scheme) 
	return src
		.filter(row=> row.last_name && row.first_name)
		.map(row=>
			full_scheme.validateSync(row)
		)
		.map(({spec_code,...row})=>
				({...row
				, spec_code: spec_code === '9' || spec_code === '11'
					? null : spec_code
				, ed_level: row.spec_name_p?'p':null ?? row.spec_name_ap?'ap':null ??
					({'9':'mn','11':'m'})[spec_code]
					?? ed_level_by_spec(spec_code)
				})
			)
		.map(row=>({
			...row
			, additional_info: {
				paper: row.paper
				, country: row.country || 'RU'
				, contact_tel: row.contact_tel
				, contact_email: row.contact_email
				, gender: row.gender
				, edication_reason: row.edication_reason
				, doc_reason: row.doc_reason
				, year_admission: row.year_admission
				, period_study: row.period_study
				, form_education: row.form_education
				, place_education: row.place_education
				, funding_source: row.funding_source
				, name_program: row.name_program
				, name_professional_program: row.name_professional_program
				, ugs: row.ugs
				, area_name: row.area_name? (row.ed_level === 'ap'? Object.keys(row.area_name)[0]||null : row.area_name):null
				, type_profession: row.type_profession
				, first_education: row.first_education
				, additional_program: row.additional_program
				, previous_level: row.previous_level
				, lname_previous_document: row.lname_previous_document
				, contract: row.contract
				, contract_number: row.contract_number
				, contract_date: row.contract_date
				, org_name: row.org_name
				, org_ogrn: row.org_ogrn
				, org_kpp: row.org_kpp
				, name_employer: row.name_employer
				, ogrn_employer: row.ogrn_employer
				, kpp_employer: row.kpp_employer
				, region_employer: row.region_employer
			}
			, country: row.country || 'RU'
			, doctype_kind: row.doctype_kind_ap_p || row.doctype_kind
			, spec_name: row.spec_name || row.spec_name_p || row.spec_name_ap ||
				row.spec_code && (
					!orginfo.app_skip_license
					&& orginfo.app_spec_list?.[row.ed_level]
					? orginfo.app_spec_list[row.ed_level][row.spec_code]?.[0]
					: decodeMonster(row.spec_code)
				)
			, qualification: ((row.ed_level === 'ap' || row.ed_level === 'p') && row.qualification?.toUpperCase() === 'НЕТ')? null :
				row.qualification
				||
				row.spec_code && (
					!orginfo.app_skip_license
					&& orginfo.app_spec_list?.[row.ed_level]
					&& orginfo.app_spec_list[row.ed_level][row.spec_code]?.[1]
				)
			|| null
		}))
		.map(row=>
				row.doctype_kind?
				{...row, doctype_kind: row.ed_level+row.doctype_kind}
				:row)
		.map(row=>{
			let ret = {}
			for(const i in row)
				ret[i] = row[i] === ''? null : row[i]
			return ret;
		})
		.map(row=>is_gov_doc(row, orginfo)?
			{ ...row, gov_doc: 1 }
			: row
		)
}



