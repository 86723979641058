import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from 'formik';

import {mainDbX} from 'azlib/components/db'
import {shardedDbX} from 'azlib/components/db'

import {PopupLog} from 'contingent/log'
import {diff_transform} from 'org/orginfo'

import {letIn} from 'azlib/components/helpers'

import {Link} from 'react-router-dom'

import { SearchFilter } from 'azlib/components/search_filter'

import {PersonPage} from 'persons/person'

export function SysOpOpenUser() {
  	let [user, setU] = useState(null);
	return <>
	  <h1>Просмотр юзера</h1>
     <Formik 
        initialValues={{
        	gov_regnum:''
    	}}
      >{bag=>
		  <Form className="viewOrg">
            <p></p>
             <SearchFilter
              input={
                <Field name="login" placeholder={"Найти юзера"} 
                  className="FilterInput"/>
              }
              buttonProps={{
                  onClick: async (values) =>{
                      //look for any in main db (consolidated table)
                      let personid = await mainDbX.fetch_get_as_json('/app/sysop/open_user?'
                        +'login='+bag.values.login.trim()
                      )
                      setU({personid,login: bag.values.login.trim()})
                    }
              }}
             />
             {user?.personid?
             <PersonPage user={user}/>
             : '???'
         	 }
         </Form>
 	 }</Formik>
	</>
}