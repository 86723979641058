import React, {useState} from 'react';

import {mainDbX} from 'azlib/components/db';


let npp = 1;

export function ImportIslod(){
	let [ac, setAc] = useState(null)
	let [lic,setLic] = useState(null)
	let [st, setSt] = useState(null)

	return <div
		style={{
			width: "40em"
			, margin: "5em auto 1em auto"
		}}
	>
		<button type="button" className="block"
			onClick={()=>{
				let ev = mainDbX.fetch_events('/app/sysop/lic/import-lic')
				ev.onmessage = e=>{
					setLic(lic=>{
						lic = lic ?? [];
						if(lic.length>4) lic.pop(); 
						return [ [npp++, e.data], ...lic]
					});
				}
				ev.onerror = e=>{ev.close(); setLic(
					[[npp++,'В данный момент выполнить импорт лицензий невозможно, так как он уже выполняется']]);
				}
				ev.addEventListener('done',e=> {ev.close()})
			}}
		>Импорт сначала:
		Сводный реестр лицензий на осуществление образовательной деятельности 
		</button>
		Imported: {
			lic?<div style={{height:"15em", padding:10, border:"1px solid", overflow: "scroll"}}>
			{lic.map(([npp,line])=><div key={npp}>{line}</div>)}
			</div>
			: '-'
		}

		<button type="button" className="block"
			onClick={async ()=>{
				let ev = mainDbX.fetch_events('/app/sysop/lic/import-ga')
				ev.onmessage = e=>{
					setAc(ac=>{
						ac = ac ?? [];
						if(ac.length>4) ac.pop(); 
						return [ [npp++, e.data], ...ac]
					});
				}
				ev.onerror = e=>{ev.close(); setAc(
					[[npp++,'В данный момент выполнить импорт аккредитаций невозможно, так как он уже выполняется']]);}
				ev.addEventListener('done',e=> {ev.close()})
			}}
		>Импорт потом:
		Реестр организаций, 
		осуществляющих образовательную деятельность 
		по аккредитованным образовательным программам 
		</button>
		Imported: {
			ac?<div style={{height:"15em", padding:10, border:"1px solid", overflow: "scroll"}}>
			{ac.map(([npp,line])=><div key={npp}>{line}</div>)}
			</div>
			: '-'
		}
		<hr/>
		<button type="button" className="block"
				onClick={async ()=>{
					let ev = mainDbX.fetch_events('/app/sysop/statistics_refresh')
					ev.onmessage = e=>{setSt(e.data);}
					ev.addEventListener('done',e=> {ev.close()})
				}}
		>Пересчет статистики (все организации)
		</button>
		Imported: {st??'-'}
	</div>
}
