import React, {useState, forwardRef } from 'react';

import styles from './calendar.module.css';


const firstDoW = 1; //monday; TODO: locale aware

//FIXME: const localeName = window.navigator.language || window.navigator.userLanguage

var weekDays = []

for(let i = 1; i <= 7; ++i) {
	const d = new Date(2000,0,i)
	weekDays[d.getDay()] = d.toLocaleDateString(undefined,{weekday:"short"})
}

function adjustDoW(i) {
	return (i-firstDoW+weekDays.length)%weekDays.length
}

let weekDaysAdjusted = []
for(let i = 0; i < weekDays.length; ++i)
	weekDaysAdjusted[adjustDoW(i)] = weekDays[i]

var monthNames = []
var monthNamesShort = []

for(let i = 1; i <= 12; ++i) {
	const d = new Date(2000,i-1)
	monthNames[i-1] = d.toLocaleDateString(undefined,{month:"long"})
	monthNamesShort[i-1] = d.toLocaleDateString(undefined,{month:"short"})
}

//const firstYear = 1
//const lastYear = 4000

function date(y,m,d) { 
	return new Date(y,m,d??1) 
}

function range(n) {
	let r = []
	for(let i = 0; i < n; ++i)
		r.push(i)
	return r;
}

function Hbutton({params, mode, yearOffset, modulo, month, ...props}) {
	return <span type="button"
		onClick={()=>{
			if(mode) params.setMode(mode)
			params.setStart(date(
				modulo?
						Math.floor(params.start.getFullYear()/modulo)*modulo
					:   params.start.getFullYear()+(yearOffset||0)
				, modulo? 1 : params.start.getMonth()+(month||0)
				))
		}}
		{...props}
		/>
}

function Bbutton({params, mode, year, month, ...props}) {
	return <span type="button"
		onClick={()=>{
			params.setMode(mode ?? 'days')
			params.setStart(date(
					params.start.getFullYear() + (year??0)
					, month??0
				))
		}}
	{...props} />
} 

function Xbutton({className, onChoose, dt, min, max, ...props}) {
	if(min && dt < min || max && dt > max)
		className = styles.outOfRange
	return <span className={className} {...props} 
		onClick={()=>
			(!min || dt >= min)
			&&
			(!max || dt <= max)
			&&
			onChoose(dt)
		}
	/>
}

export const Calendar = forwardRef(function({onChoose,min,max,...props}, ref) {
	const [dt,setDate] = useState(new Date()) // initial date //FIXME: it is not a state
	const [mode,setMode] = useState('days')
	const [start,setStart] = useState(
						date(dt.getFullYear(), dt.getMonth())
					)

	const params = {mode, setMode, start, setStart}

	/* eslint-disable-next-line default-case */
	switch(mode) {
		case 'days':
				return <div className={styles.container} {...props} tabIndex={1}>
							<div className={styles.header}>
								<Hbutton params={params} yearOffset={0} month={-1}>
									≪
								</Hbutton>
								<Hbutton params={params} mode="months" modulo={1}>
									{monthNames[start.getMonth()]}
								</Hbutton>
								<Hbutton params={params} mode="months" modulo={1}>
									{start.getFullYear()}
								</Hbutton>
								<Hbutton params={params} yearOffset={0} month={+1}>
									≫
								</Hbutton>
							</div>
							<div className={styles.body}>
								{
									range(7).map(r=>
										<div key={r} className={styles.days}>{
											weekDaysAdjusted.map((wd,i)=>
											r===0?// top row
												<span key={wd}>{wd}</span>
											:
												(dt=>
													<span key={wd}
													>
													<Xbutton type="button" 
														className={
															dt.getMonth() === start.getMonth()
															? styles.current
															: styles.other
														}
														onChoose={onChoose}
														dt={dt} min={min} max={max}
													>
														{dt.getDate()}
													</Xbutton>
													</span> 
												)(
													/*
													2022, may
													fDoW = 1 (Mon)
													--
													1 => Sun (0/6 (if fDow=1))
													4 => Wed (3/2 (if fDow=1)) 
													*/
													date(start.getFullYear()
													, start.getMonth()
													, (r-1)*7+i+1
													  - adjustDoW(date(start.getFullYear()
													  	, start.getMonth()
													  	, 1
														).getDay())
													)
												)
										)}
										</div>
									)
								}
							</div>
						</div>
		case 'months':
				return <div className={styles.container} {...props} tabIndex={1}>
							<div className={styles.header}>
								<Hbutton params={params} yearOffset={-1}>
									≪
								</Hbutton>
								<Hbutton params={params} mode="years" modulo={10}>
									{start.getFullYear()}
								</Hbutton>
								<Hbutton params={params} yearOffset={+1}>
									≫
								</Hbutton>
							</div>
							<div className={styles.body}>{
								range(4).map(r=>
									<div key={r}>{
										range(3).map(c=>
										<span key={c}><Bbutton className={styles.month} 
											params={params} month={r*3+c}
											>{monthNames[r*3+c]}
										</Bbutton></span>
										)
									}
									</div>
								)
							}</div>
						</div>
		case 'years':
				return <div className={styles.container} {...props} tabIndex={1}>
							<div className={styles.header}>
								<Hbutton params={params} yearOffset={-10}>
									≪
								</Hbutton>
								<Hbutton params={params} mode="decades" modulo={100}>
									{start.getFullYear()}
									-
									{start.getFullYear()+9}
								</Hbutton>
								<Hbutton params={params} yearOffset={+10}>
									≫
								</Hbutton>
							</div>
							<div className={styles.body}>{
								range(4).map(r=>
									<div key={r}>{
										range(3).map(c=><span key={c}>{
										r*3+c === 0?<Bbutton className={styles.years} 
											mode="years"
											params={params} year={-10}
											>≪</Bbutton> 
										: r*3+c === 11?<Bbutton className={styles.years} 
											mode="years"
											params={params} year={+10}
											>≫</Bbutton>
										: 
										<Bbutton className={styles.years} 
											mode="months"
											params={params} year={r*3+c-1}
											>{start.getFullYear()+r*3+c-1}
										</Bbutton>
										}</span>)
									}
									</div>
								)
							}</div>
						</div>
		case 'decades':
				return <div className={styles.container} {...props} tabIndex={1}>
							<div className={styles.header}>
								<Hbutton params={params} yearOffset={-100}>
									≪
								</Hbutton>
								<Hbutton params={params} mode="decades" modulo={100}>
									{start.getFullYear()}
									-
									{start.getFullYear()+99}
								</Hbutton>
								<Hbutton params={params} yearOffset={+100}>
									≫
								</Hbutton>
							</div>
							<div className={styles.body}>{
								range(4).map(r=>
									<div key={r}>{
										range(3).map(c=><span key={c}>{
										r*3+c === 0?<Bbutton className={styles.years} 
											mode="decades"
											params={params} year={-100}
											>≪</Bbutton> 
										: r*3+c === 11?<Bbutton className={styles.years} 
											mode="decades"
											params={params} year={+100}
											>≫</Bbutton>
										: 
										<Bbutton className={styles.decades} 
											mode="years"
											params={params} year={r*3+c-1}
											>
											<div>{start.getFullYear()+(r*3+c-1)*10}</div>
											<div>{start.getFullYear()+(r*3+c-1)*10+9}</div>
										</Bbutton>
										}</span>)
									}
									</div>
								)
							}</div>
						</div>
	}
})

export function formatLocalISO(dt) {
	// toISOString return date in GMT(!)
	// but parse ISO-like as local in local time zone!
	if(isNaN(dt)) return NaN;
	return dt.getFullYear().toString().padStart(4,'0')
			+ '-'
			+ (dt.getMonth()+1).toString().padStart(2,'0')
			+ '-'
			+ (dt.getDate()).toString().padStart(2,'0')
}

export function formatLocalISODateTime(dt) {
	// toISOString return date in GMT(!)
	// but parse ISO-like as local in local time zone!
	if(isNaN(dt)) return '';
	return dt.toISOString().replace('T', ' ')
}

export function localISOtoday() { return formatLocalISO(new Date()); }
export function localISOnow() { return formatLocalISODateTime(new Date()); }

export function parseLocalISO(dt) {
	// toISOString return date in GMT(!)
	// but parse ISO-like as local in local time zone!
	if(!dt.trim()) return new Date(NaN);
	if(dt.match(/^\s*(\d{4})-(\d{2})-(\d{2})\s*$/))
		return new Date(+RegExp.$1,+RegExp.$2-1, +RegExp.$3)
	return new Date(NaN);
}

export function formatLocalDate(dt) {
	if(!dt) return dt;
	if(Number.isNaN(dt)) return '';
	if(typeof dt === 'string') {
		let ndt = new Date(dt)
		if(isNaN(ndt)) return dt;
		dt = ndt;
	}
	return dt.toLocaleDateString(undefined, 
        {year:"numeric", month:"2-digit", day:"2-digit"}
    )
}

const localMask = formatLocalDate(new Date(3333,10,22)); // detect field order

export const parseLocalDate = 
	/.*22.+11.+3333/.test(localMask) ? // DMY
	(s) =>
		s.match(/[^\d]*(\d?\d)[^\d]+(\d?\d)[^\d]+(\d\d\d\d)/)?
			new Date(+RegExp.$3, +RegExp.$2-1, +RegExp.$1)
		: new Date(NaN)
	:
	/.*11.+22.+3333/.test(localMask) ? // MDY
	(s) => 
		s.match(/[^\d]*(\d?\d)[^\d]+(\d?\d)[^\d]+(\d\d\d\d)/)?
			new Date(+RegExp.$3, +RegExp.$1-1, +RegExp.$2)
		: new Date(NaN)
	:
	/.*3333.+22.+11/.test(localMask) ? // YDM
	(s) => 
		s.match(/[^\d]*(\d\d\d\d)[^\d]+(\d?\d)[^\d]+(\d?\d)/)?
			new Date(+RegExp.$1, +RegExp.$3-1, +RegExp.$2)
		: new Date(NaN)
	:
	/.*3333.+22.+11/.test(localMask) ? // YMD
	(s) => 
		s.match(/[^\d]*(\d\d\d\d)[^\d]+(\d?\d)[^\d]+(\d?\d)/)?
			new Date(+RegExp.$1, +RegExp.$2-1, +RegExp.$3)
		: new Date(NaN)
	:
		s => new Date(s)

export function checkDateFormat(s) {
	if(!s) return true;
	const dt = parseLocalDate(s)
	if(isNaN(dt)) return false;
	return s === formatLocalDate(s)
}
