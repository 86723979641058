export const titledName = (p, login) =>
	p?.last_name ?
	[ p?.last_name
		,
		[p?.first_name?.substr(0,1)
		, p?.middle_name?.substr(0,1)
		].filter(Boolean).map(e=>e+'.').join('')
	].join(' ')
	+ ( login? `(${p?.login})`: '')
	: p?.login

// format: И.О. Фамилия
export const titledName2 = (p, login) =>
	p?.last_name ?
	[
		[p?.first_name?.substr(0,1)
		, p?.middle_name?.substr(0,1)
		].filter(Boolean).map(e=>e+'.').join('')
	, p?.last_name
	].join(' ')
	+ ( login? `(${p?.login})`: '')
	: p?.login

export const fullPersonName = (p, login) => 
	p?.last_name ?
	[ p?.last_name
		,
		[p?.first_name
		, p?.middle_name
		].filter(Boolean).join(' ')
	].join(' ')
	+ " " +( login? `(${p?.login})`: '')
	: p?.login
