import React from 'react';

import {ReactComponent as Grey_dot} from './images/grey_dot.svg'
import {ReactComponent as Red_dot} from './images/red_dot.svg'
import {ReactComponent as Green_dot} from './images/green_dot.svg'

export function RedGrayGreen({value,alt}) {
	if(value===undefined) return;
	if(value===null) return <Grey_dot alt={alt}/>
	return value? <Green_dot alt={alt}/>
				: <Red_dot alt={alt}/>
} 
