import React, {useContext, useState, useRef, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import {XLOG, letIn} from 'azlib/components/helpers'
import {mainDbX, shardedDbX} from 'azlib/components/db';

import { later} from 'azlib/components/helpers'
import {  
	PopupMenu, showModalProcessing, alert, confirm, prompt } from 'azlib/components/modals'

import {ru_date} from 'azlib/components/locales/ru/ru_date'
import { ModelField, LabeledField, FormValues, Conditional
	, DbFormik
	, SwitchModificationState, SaveButton, IfSaved, IfModified
} from 'azlib/components/formik-fields';

import {fakeEvent} from 'azlib/components/std-controls'

import {OrgInfoContext} from 'org/org'

import UserInfoContext from 'UserInfo'

import {EdRegionEdit} from 'config/regions'

import {ArrayEditor} from 'azlib/components/std-controls'

import {PopupLog} from 'contingent/log'

import {orginfo_changed} from 'config/common'

import {ShowLocker, lockToEdit, saveIfOwned, cancelLock, alien_locked} from 'config/row_locker'
import {FlexColumnsMin} from "../sysop/create_org";
import {parse_islod, getBOSS, jsonBOSS} from "./islod_parser";



/*eslint no-mixed-operators: 0*/

/*
    , notes CLOB
    , is_org ONE

    , name_lat VARCHAR(1000)
    , sname_lat VARCHAR(500)

*/

function MultyLevels({value, name, onChange, readOnly, ...props}) {
	let parsed = value? JSON.parse(value) : {}
	const ed_levels = window.AppBundle.data.ed_levels.orderedKeys;
	const decode = (k) => window.AppBundle.data.ed_levels.decode[k];
	return <PopupMenu readOnly={readOnly}
				trigger={
						<div>
							<FlexColumnsMin className="edLevelBox">
								{
									letIn(ed_levels
									.filter(e => e in parsed)
									)(lst=>
										lst.length?
										lst.map(e=><div key={e} style={{minWidth:"15em", flexGrow:0, flexShrink:0}}>
											<div><label>{decode(e)}</label></div>
										</div>)
										: <div>...</div>
								)}
							</FlexColumnsMin>
						</div>
						}
			>
			{
				ed_levels
				.filter(Boolean)
				.map(e=><div key={e}>
					<label>
						<input type="checkbox" checked={e in parsed}
						onChange={(event)=>{
							let nv = Object.assign({},parsed);
							delete nv[e];
							if(event.target.checked) nv[e] = '';
							onChange?.(fakeEvent(name, 
									JSON.stringify(nv)
								))
						}}
						/>
						{decode(e)}
					</label>
				</div>)
			}
		</PopupMenu>
}

export function OrginfoPage({...props}) {
	const orginfo = useContext(OrgInfoContext);
	const uinfo = useContext(UserInfoContext);
	let [acc, setAcc] = useState(null)
	let [lic, setLic] = useState(null)
	let [acc2, setAcc2] = useState(null)
	let [lic2, setLic2] = useState(null)
	let [bosses, setBosses] = useState([])

	useEffect(() => {
		if(orginfo.app_all_bosses)
			setBosses(JSON.parse(orginfo.app_all_bosses))
	}, [orginfo]);

	let apiKeyRef = useRef(null)

	const readOnlyTop = (v,fc) => !fc.status.modified
			|| !orginfo.tuner || fc.values?.toplevel && fc.values?.manual
			;
	const readOnlyLocal = (v,fc) => !fc.status.modified
			|| !orginfo.tuner
			;

	const canIssue = row => row?.toplevel || row?.manual;

	const lockEdLevel = (v,fc) => !fc.status.modified || (fc.values?.manual? false:true);

	const lockSName = (v,fc) => !fc.status.modified || !orginfo.tuner || fc.values?.toplevel;

	const fc = (fc) => fc.values?.toplevel;

	return (
	<DbFormik 
		store={orginfo.DbX.store('orgtree', {})} 
		rowKey={[orginfo.tid]}
		extra="toplevel manual app_issuer is_branch app_api_key app_islod_info"
		onSaved={() => orginfo_changed(orginfo)}
	><FormValues>{(row,formik)=><>
		<FormValues>{row=>
			<div
				style={{float:"right", margin:"1em 1em 0 0"}}
			>
				<PopupLog table="orgtree" tkey={row.tid} fixed={{stname:null}}
						  transform={diff_transform} />
			</div>
		}</FormValues>
		<h1>
			<IfSaved>Данные</IfSaved>
			<IfModified fastDoNotEdit={alien_locked} superusers={uinfo.userAreas.admin || uinfo.userAreas.sysop}>Редактирование данных</IfModified> 
			{row?.app_issuer? ' выпускающей ' : ' '}
			{row?.app_issuer || row?.toplevel? 
					'организации'
				: (row?.is_branch ? 'филиала' : 'элемента структуры')}
		</h1>

		<ShowLocker show={orginfo.tuner || uinfo.userAreas.sysop || uinfo.userAreas.admin} readOnly={!orginfo.tuner}/>

		{(orginfo.tuner || uinfo.userAreas.sysop)
		 && <div
			style={{
				position: "fixed"
    		, background: "#f49f9f"
    		, zIndex: 1
    		, right: "3em"
    		, padding: "1ex"
    		, border: "1px solid red"
    		, borderRadius: "1ex"
    		, marginTop: "-3em"
			, marginRight: "3em"
			}}
		>
		<SwitchModificationState readOnly={!orginfo.tuner}
			fastDoNotEdit={alien_locked}
			superusers={uinfo.userAreas.admin || uinfo.userAreas.sysop}
			canEdit={lockToEdit}
			prepareSave={saveIfOwned}
			cancelEdit={cancelLock}
		/>
		</div>}

		{/*<Conditional when={canIssue}>
			<p></p><p></p>
			<IfModified><RGRJU readOnly={readOnlyTop} /></IfModified>
		</Conditional>*/}
		<Conditional when={canIssue}>
			<p></p><p></p>
			<IfModified fastDoNotEdit={alien_locked} superusers={uinfo.userAreas.admin || uinfo.userAreas.sysop}>
				{(orginfo.tuner || uinfo.userAreas.admin || uinfo.userAreas.sysop) &&
					<EGRUL readOnly={!orginfo.tuner} />
				}
			</IfModified>
		</Conditional>


		<div className="backgroundRounded inputForm" style={{marginTop:"1em"}}>

		<ModelField name="name" readOnly={true} />
			<div className="flexContainer">
				<div className="flexColumn">
		<ModelField name="sname" readOnly={lockSName} />
					<Conditional when={canIssue}>
						<LabeledField label="Регион" name="app_region" className="selectLikeInput"
									  as={EdRegionEdit}
									  readOnly={true}
						/>
						<ModelField name="app_place" readOnly={readOnlyLocal}
									tip="входит в состав документа об образовании, пример заполнения: г. Москва"
						/>
					</Conditional>
				</div>
				<div style={{minWidth:"20em", flexBasis:"20em", flexGrow:1, marginRight: "1ex"}}>
		<LabeledField label="Уровни образования" 
			name="app_ed_level" 
			style={{minWidth:"15em", flexGrow:0, flexShrink:0}}
			extra="app_skip_license"
			as={MultyLevels}
			readOnly={lockEdLevel}
		/>
				</div>
			</div>
		<Conditional when={canIssue}>
			{Object.keys(bosses).length > 0 ? 
			Object.entries(bosses).map(function([pos,fio]){
				return(
				<>
					<div className="flexContainer">
						<div className="flexColumn">
							<LabeledField name="fio" label="Фамилия Имя Отчество руководства (согласно ЕГРЮЛ)" value={fio} readOnly={true} />
						</div>
						<div style={{minWidth:"20em", flexBasis:"20em", flexGrow:1, marginRight: "1ex"}}>
							<LabeledField name="pos" label="Должность руководства (согласно ЕГРЮЛ)" value={pos} readOnly={true} />
						</div>
					</div>
				</>)
			})
			:
			<div className="flexContainer">
				<div className="flexColumn">
					<ModelField name="app_boss_fio" readOnly={true} />
				</div>
				<div style={{minWidth:"20em", flexBasis:"20em", flexGrow:1, marginRight: "1ex"}}>
					<ModelField name="app_boss_position" readOnly={true} />
				</div>
			</div>
			}
			<Conditional when={(v)=>v}
								 name="app_spec_list"
					><FormValues>{row=>
						<PopupMenu
							trigger={<button type="button"
											 style={{marginTop:"1.8em"}}>Специальности</button>}
							closeBox
						>
							{
								row.app_spec_list &&
								letIn(JSON.parse(row.app_spec_list))(lst=>
									Object.keys(lst)
										.filter(ed=>ed in orginfo.app_ed_level)
										.map(ed=><div key={ed}>
											<h4>{window.AppBundle.data.ed_levels.decode[ed]}</h4>
											{
												Object.keys(lst[ed])
													.map(sp=><p key={sp}>
														{sp}:
														{lst[ed][sp][0]}{lst[ed][sp][2]||''}
														{' '}
													</p>)
											}
										</div>)
								)
							}
						</PopupMenu>
					}</FormValues>
			</Conditional>
		</Conditional>

		</div>
			<div className="backgroundRounded inputForm">

		<Conditional when={canIssue}>
		<div>
			<label><b>Государственные номера</b></label>
			<div className="flexContainer">
				<div className="flexColumn">
			<ModelField name="gov_regnum" readOnly={true} />

			<ModelField name="fin_regnum" readOnly={true} />
					{row.app_skip_license || row.app_skip_acred?
						<div>
							{row.app_skip_license && <div style={{marginTop:"1em"}}>Лицензия не нужна</div>}
							{row.app_skip_acred && <div style={{marginTop:"1em"}}>Акредитация не нужна</div>}
						</div>
						:null}
				</div>
					<div style={{minWidth:"20em", flexBasis:"20em", flexGrow:1, marginRight: "1ex"}}>
			<ModelField name="fin_regnum2" readOnly={true} />
					</div></div>
		</div>
		</Conditional>
			</div>
		<div className="flexContainer">
			<div className="flexColumn inputForm backgroundRounded" style={{marginRight:"0"}}>
			<label><b>Контакты</b></label>
			<ModelField name="contact_tel" mask="(000) 000-00-00" readOnly={readOnlyLocal} />
			<ModelField name="contact_email" readOnly={readOnlyLocal} />
			<ModelField name="contact_addr" readOnly={readOnlyLocal} />
			<ModelField name="contact_person" readOnly={readOnlyLocal} />
			<ModelField name="contact_info" readOnly={readOnlyLocal} />
		</div>
			<div className="flexColumn">
		<div className="backgroundRounded inputForm" style={{marginLeft:"0"}}>
		<FormValues>{row=><>
		<label><b>Дополнительные роли подписывающих</b></label>
		<div style={{margin:"0.5em 0"}}>
			<LabeledField name="app_custom_signers"
				readOnly={readOnlyLocal}
				as={OrgSignersEditor}
			/>
		</div></>
		}</FormValues>

			<Conditional when={canIssue}>
			<label><b>Аккредитации и лицензии</b></label>
				{
					<div style={{marginTop:"1em"}}>
						<div>Аккредитации</div>
							<p>
								<button type="button" disabled={!orginfo.tuner 
																&& !orginfo.registry
																&& !orginfo.docs.operator 
																&& !orginfo.docs.validator
																&& !uinfo.userAreas.sysop
																&& !uinfo.userAreas.admin}
								onClick={async ()=>{
									let r = await mainDbX.fetch_post('/accredreestr/search/?page=1'
										, {searchby: 'organization'
											, eduOrgOgrn: row.gov_regnum
										}
									)
									if(r.ok){
										r = await r.text()
										if(r.match(/(<table\s+[^>]*>[\s\S]*<[/]table>)/)) {
											let p = new DOMParser()
											p = p.parseFromString(RegExp.$1, 'text/html')
											let a = p.getElementsByTagName('tbody')[0]
												.getElementsByTagName('tr')
											let b = []
											for(const i of a){
												if(i.children[6].textContent.trim()==="Действующее") {
													b.push({
														id: i.getAttribute('data-id')
														, name: i.children[1].textContent.trim()
														, regnumber: i.children[2].textContent.trim()
														, docnumber: i.children[3].textContent.trim()
														, issued: i.children[4].textContent.trim()
														, eol: i.children[5].textContent.trim()
													})
												}
											}
											setAcc(b)
										}
									}
								}}
								>
									Аккредитации (Сайт РОН)
								</button>
							</p>
							<div>
								{ acc &&
								acc.length?
									acc.map(a=><div key={a.id}>
											<Link
												to={`/details-acred/${a.id}`}
											><button className="intableOp">
												{a.name}
												<br />
												{`${a.docnumber} рег. ${a.regnumber}`}
												<br />
												{`${a.issued} ${a.eol}`}
											</button>
											</Link>
										</div>
									)
									: acc ? '-нет-' : ''
								}
							</div>
					</div>
				}
		</Conditional>
		</div>
				<div className="backgroundRounded inputForm" style={{marginLeft:"0"}}>
					<label><b>API</b></label>
		{(orginfo.m || orginfo.tuner || orginfo.registry || orginfo.docs.some || uinfo.userAreas.sysop || uinfo.userAreas.admin)
			&& <div style={{marginTop: "0.5em"}}>
				Ключ API:{' '}
				<input readOnly style={{margin:"0.5em 0"}}
					name="app_api_key"
					value={row.app_api_key} 
					ref={apiKeyRef}
				/>
				<div className="flexContainer">
				{apiKeyRef.current 
					&& <button type="button" disabled={!orginfo.tuner && !orginfo.registry && !orginfo.docs.operator}
					onClick={()=>{
						apiKeyRef.current.focus()
						apiKeyRef.current.select()
						document.execCommand('copy')
						alert('Ключ скопирован в буфер обмена')
					}}
					>Скопировать ключ
					</button>
				}

			<IfModified fastDoNotEdit={alien_locked} superusers={uinfo.userAreas.admin || uinfo.userAreas.sysop}>
			{(orginfo.tuner || uinfo.userAreas.sysop || uinfo.userAreas.admin) && <button type="button"
				onClick={async ()=>{
					  if(!orginfo.tuner) return;
						await confirm("Перегенировать ключ?")
						const u8 = new Uint8Array(32);
						window.crypto.getRandomValues(u8);
						let key = btoa(String.fromCharCode.apply(null, u8))
						formik.setFieldValue("app_api_key"
								, row.tid+'.'
								+key.replace(/[+]/g,'-')
									.replace(/[/]/g,'_')
									.replace(/[=]/g,'')
								)
				}}
			>Перегенерировать ключ
			</button>
			}
			</IfModified>
				</div>
		</div>
		}
		</div></div></div>
		</>}</FormValues></DbFormik>)
}
export function EGRUL({keepChoose, readOnly}) {
	let ed_level_decode = window.AppBundle.data.ed_levels.decode;
	return <FormValues>{(row,fc)=>row.gov_regnum && <div style={{margin:"0 2em"}}>

			<button type="button" disabled={readOnly}
					onClick={async ()=>{
						let data = await parse_islod(fc.values.gov_regnum)
						if (data){
							fc.setValues(values=>({
								...values
								, name: data.details.name
								, sname: data.details.sname
								, fin_regnum: data.details.fin_regnum
								, fin_regnum2: data.details.fin_regnum2
								, app_boss_fio: data.details.app_boss_fio
								, app_boss_position: data.details.app_boss_position
								, app_all_bosses: JSON.stringify(data.details.app_all_bosses)
								, app_ed_level:
									JSON.stringify(Object.fromEntries(
										Object.keys(data.merged)
											.map(e=> [e,""])
									))
								, app_spec_list: JSON.stringify(data.merged)
								, app_islod_info: JSON.stringify(data.info)
							}))	
						}					
					}}
			>Обновить данные</button>
		</div>}
	</FormValues>
}

// function getBOSS(e) {
// 	return e.match(/^[а-яё'0-9()/ -]*:?[а-яё'0-9 -]*/i)[0]
// }

export function RGRJU({keepChoose, readOnly}) {
	const [className, setClassName] = useState("hiddenEl");
	const orginfo = useContext(OrgInfoContext);
	let [imp, setImp] = useState(null)
	let [choosen, setChoosen] = useState(null)
	return <FormValues>{(row,fc)=>row.gov_regnum && <div style={{margin:"0 2em"}}>
		{!choosen && <div><button type="button" readOnly={readOnly instanceof Function?readOnly(row,fc):readOnly}
			onClick={async ()=>{ if(readOnly instanceof Function?readOnly(row,fc):readOnly) return;
			setClassName("");
			let fd = await fetchRGRJU(row.gov_regnum)
			if(fd)
				setImp(fd)
			}}>Заполнить основные данные</button><label className={className} style={{marginTop:"1em", display:"inline-block"}}>Выберете данные:</label></div>
		 || choosen && <div>
					<div>
					{choosen.n}
					</div>
					<div>
					{choosen.c}
					</div>
					<div>
					{choosen.i} / {choosen.p}
					</div>
					<div>
					{choosen.a}
					</div>
					<div>
					{getBOSS(choosen.g)}
					</div>
		 </div>
		}
		<div>
			{imp && imp.rows &&
				imp.rows
				.filter(e=>!e.e)
				.map(e=><button type="button" key={e.t} style={{marginTop:"1em"}}
					disabled={!orginfo?.tuner}
					onClick={()=>{ 
						setClassName("hiddenEl");
						fc.setValues({
							...row
							, name: e.n
							, sname: e.c
							, fin_regnum: e.i
							, fin_regnum2: e.p 
							, app_boss_fio: getBOSS(e.g).length === 1 ? (getBOSS(e.g)[0].split(':')[1]||'').trim() : ''
							, app_boss_position: getBOSS(e.g).length === 1 ? getBOSS(e.g)[0].split(':')[0].trim() : ''
							, app_all_bosses: getBOSS(e.g).length > 1 ? jsonBOSS(getBOSS(e.g)) : ''
						})
						setImp(null)
						if(keepChoose)
							setChoosen(e)
					}}
				>
					<div>
					{e.n}
					</div>
					<div>
					{e.c}
					</div>
					<div>
					{e.i} / {e.p}
					</div>
					<div>
					{e.a}
					</div>
					<div>
					{getBOSS(e.g)}
					</div>
				</button>)
			}
		</div>
		</div>}</FormValues>
}

async function fetchRGRJU(gov_regnum) {
	const max = 5;
	return showModalProcessing(
		async (hide, updateProgress, getProgress)=>{
			let current = 0; const upd_cnt = p=>({...p,cnt: current})
			do {
				updateProgress(upd_cnt)
				//KEEP IT: use nginx
				let fq = await mainDbX.fetch_post_as_json('/external/egrul/'
						,{query: gov_regnum, nameEq: 'on'})
				//KEEP IT: use php
				//let fq = await mainDbX.fetch_post_as_json('/app/user/egrul/search-egrul'
					//	,{gov_regnum})
				if(fq.t) 
				{
					let d = (new Date()).valueOf()
					await later(null,1000)
					//KEEP IT: use nginx
					let fd = await /*common*/mainDbX.fetch_get_as_json('/external/egrul/search-result/'
							+fq.t + '?r='+d + '&_='+(+d+1)	)
					//KEEP IT: use php 
					//let fd = await mainDbX.fetch_post_as_json('/app/user/egrul/egrul-info'
						//	, {t:fq.t})
					if(fd.rows) {
						return fd;
					}
				}
				await later(2000)
			} while(++current < max);
			await alert('Данные не получены')
			return null;
		}
		, props=>
		<div>
		{props.progress?.cnt+1}/{max}
		</div>
	)
}
/*
a: "454091 ЧЕЛЯБИНСКАЯ ОБЛАСТЬ ГОРОД ЧЕЛЯБИНСК УЛИЦА ЛИБКНЕХТА 2 "
c: "ООО \"ТЕХКОМПЛЕКТ-С\""
cnt: "1"
e: "21.06.2012" //end
g: "директор: Юрченко Владилен Иванович"
i: "7451213050" //inn
k: "ul"
n: "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ \"ТЕХКОМПЛЕКТ-С\""
o: "1057423502377" //ogrn
p: "745101001" //kpp
pg: "1"
r: "18.01.2005" //reg
t: "A1695B3DEA0CA32AE42F015A1B893BD08699F58377253BDF621B101199DB5B48122F821B08B09A6CDCEBEAA1F1DA89F9CDE575B9E992699AE0A1DF92977B5AF0BCB16BAC738F60D37445805AFCA321BD"
tot: "1"
*/


function pathchIslodResponse(r) {
  if(r.match(/<body>([\s\S]*)<[/]body>/)) {
  	let p = RegExp.$1
  	console.log(p)
  	p = p.replace(/<script>[\s\S]*?<[/]script>/g,'')
  	p = p.replace(/<style>[\s\S]*<[/]style>/g,'')
    return p
  }
}

export function ViewRlic() {
	const p = useParams();
	const lic = p.lic;
	let [body, setBody] = useState(undefined)
	if(body === undefined) {
		mainDbX.fetch_get(`/rlic/details/${lic}/`)
		.then(r=>r.text())
		.then(r=>{
			setBody(pathchIslodResponse(r))
		})
	}
	return <div className='isLod'
			dangerouslySetInnerHTML={{__html:body}}
			onClick={async e=>{
				let s = e.target.closest('[data-target]')
				if(!s) return
				let id = s.getAttribute('data-target')
				let t = s.closest('.isLod')
									.querySelector(`[data-id="${id}"]`)
				if(!t) return
				//console.log(t)
				let v = await mainDbX.fetch_get(`/rlic/supplement/${id}/`)
				v = await v.text()
				//console.log(v)
				t.querySelector('td').innerHTML = v
			}} 
		/>
}

export function ViewAcred() {
	const p = useParams();
	const acc = p.acc;
	let [body, setBody] = useState(undefined)
	if(body === undefined) {
		mainDbX.fetch_get(`/accredreestr/details/${acc}/1/`)
		.then(r=>r.text())
		.then(r=>{
			setBody(pathchIslodResponse(r))
		})
	}
	return <div className='isLod'
			dangerouslySetInnerHTML={{__html:body}}
			onClick={async e=>{
				let s = e.target.closest('[data-target]')
				if(!s) return
				let id = s.getAttribute('data-target')
				let t = s.closest('.isLod')
									.querySelector(`[data-name="${id}"]`)
				if(!t) return
				//console.log(t)
				let v = await mainDbX.fetch_get(`/accredreestr/supplement/${id}/`)
				v = await v.text()
				//console.log(v)
				t.querySelector('td').innerHTML = v
			}} 
		/>
}

function OrgSignersEditor({name, value, onChange, readOnly}) {
		return readOnly? (value && JSON.parse(value)||['не заданы'])
									.map(s=><div key={s}>{s}</div>)
				:
						<div>
							<ArrayEditor
								name={name}
								value={value}
								onChange={onChange}
								readOnly={readOnly}
								withBackground={true}
								editFunction={async (value)=>
									await prompt(value??'','',true)
								}
								//TODO: checkUnique
								placeholder="не заданы"

								//ItemComponent - default
								//RepositionComponent - default
								//DeleteComponent - default
								//AddComponent - default
							>{({items, add})=><>
									{items}
								<div>{add}</div>
								</>
							}
							</ArrayEditor>
						</div>
}


export const diff_transform = {
	tid: null
	, path: null
	, global_level: null
	, toplevel: null
	, app_ed_level: e=>{
		let ret = []
		e = JSON.parse(e||'{}')
		for(const i in e) {
			ret.push(window.AppBundle.data.ed_levels.decode[i]??i)
		}
		return ret.map(x=><div>{x}</div>)
	}
}