import React, {useState} from "react";
import {mainDbX} from "../azlib/components/db";
import {DbTableEdit as Table} from "../azlib/components/super-table";
import {ModelDecode, FieldInput} from "../azlib/components/std-controls";
import {decode, ed_regions} from "../config/regions";
import {alert} from "../azlib/components/modals";
import {ToggleDetails} from "../azlib/components/ui_elems";
import {Formik,Form, ErrorMessage, Field} from "formik";
import {SearchFilter} from "../azlib/components/search_filter";
import Filter from "../azlib/components/list_filter";
import {PopupLog} from "../contingent/log";
import {LabeledField} from "../azlib/components/formik-fields";
import {object, string} from "yup";

function AddElement({row, name}) {
    return <Formik initialValues={{}}
                   validationSchema={
                       object({
                           snils: string().matches(/^\/(.+)|^\d\d\d-\d\d\d-\d\d\d \d\d$/, "Формат: 111-111-111 11")
                           , ogrn:string().matches(/^\d{13}$|^\d{15}$/, "ОГРН из 13 или 15 цифр")
                       })
                   }
                   onSubmit={async (values) => {
                       await mainDbX.call_function('insert_dep_persons'
                               , {
                                   orgs: name == 'regnum' ? '{' + values.ogrn + '}' : row.orgs
                                   , persons: name == 'snils' ? '{' + values.snils + '}' : row.persons
                                   , dtype: row.dep_type
                                   , reg: row.region
                               });
                           window.location.reload()
                   }
    }>
        <Form>
            {name == 'snils' ? <div>
            <Field name="snils" required/>
            <div className="error_in_groups"><ErrorMessage name="snils" /></div>
        </div> : null}
            {name == 'regnum' ?   <div>
                <Field name="ogrn" required/>
                <div className="error_in_groups"><ErrorMessage name="ogrn" /></div>
            </div> : null}
        <div>
            <button className="add simple" type="submit"/>
        </div></Form>
    </Formik>;
}
const AddLocalElement = ({data, setter, name}) => {
    let [value, setValue] = useState('');
    return <Formik initialValues={{
                        snils: ''
                        , ogrn: ''
                    }}
                   validationSchema={
                       object({
                           snils: string().matches(/^\/(.+)|^\d\d\d-\d\d\d-\d\d\d \d\d$/, "Формат: 111-111-111 11")
                           , ogrn:string().matches(/^\d{13}$|^\d{15}$/, "ОГРН из 13 или 15 цифр")
                       })
                   }
                   onSubmit={async (values, actions) => {
                       setter([name=="snils"?values.snils:values.ogrn, ...data]);
                       actions.resetForm();
                   }
                   }>
        {formik=>
            <div className="likeTable">
                <div>
        <Form>
                {name == 'snils' ? <div>
                    <Field name="snils" value={formik.values.snils} required/>
                    <div className="error_in_groups"><ErrorMessage name="snils" /></div>
                </div> : null}
                {name == 'regnum' ?   <div>
                    <Field name="ogrn" value={formik.values.ogrn} required/>
                    <div className="error_in_groups"><ErrorMessage name="ogrn" /></div>
                </div> : null}
            <div>
                <button type="submit" className="add simple"/>
            </div>
        </Form></div>
    </div>}</Formik>
}
const ListElement = ({data, setter}) => {
    return <>{data.map(value => <div>
                <div style={{width:"100%"}}>
                    <div>{value}</div>
                </div>
                <div>
                    <button type="button" className="del"
                            onClick={async () => {
                                setter(data.filter(e => e !== value))
                            }
                            }/>
                </div>
            </div>
        )
    }</>
}
const AddGroup = ({}) => {
    let [depType, setDepType] = useState(null);
    let [region, setRegion] = useState(null);
    let [orgs, setOrgs] = useState([]);
    let [persons, setPersons] = useState([]);
    return <table className="simpleTable">
        <thead>
        <tr>
        <th colSpan="100%">Новая запись</th>
        </tr>
        <tr>
            <th>Тип ведомства</th>
            {depType==="foiv"?null:<th>Регион</th>}
            <th>ОГРН организации</th>
            <th>СНИЛС</th>
            <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>
                <select onChange={async (e)  => {
                    setDepType(e.target.value);
                    if (e.target.value==="roiv"&&region!==null) {
                        let numbers = await mainDbX.call_function('roiv_orgs'
                            , {
                                region: region
                            });
                        setOrgs(numbers.slice(1, -1).split(',').filter(v=>v!=='')); //тут меняем
                    }
                    else {
                        setOrgs([]);
                        setRegion(null);
                    }
                }}>
                    <option value="">Тип ведомства</option>
                    <option value="roiv">РОИВ</option>
                    <option value="foiv">ФОИВ</option>
                </select>
            </td>
            {depType==="foiv"?null:<td>
                <select onChange={ (e) => {
                    setRegion(e.target.value);
                    if (depType==="roiv"&&e.target.value!==null)
                        mainDbX.call_function('roiv_orgs'
                            , {
                                region: e.target.value
                            }).then(values=> setOrgs(values.slice(1, -1).split(',').filter(v => v !== '')))
                    else {
                        setOrgs([]);
                        setRegion(null);
                    }
                }
                }>
                    <option value="">Регион</option>
                    {
                        ed_regions
                            .map(e => <option key={e[1]} value={e[1]}>
                                {e[0]}
                            </option>)
                    }
                </select>
            </td>}
            <td>
                <AddLocalElement data={orgs} setter={setOrgs} name="regnum"/>
                <div className="likeTable">
                    <ListElement data={orgs} setter={setOrgs}/>
                </div>
            </td>
            <td>
                <AddLocalElement data={persons} setter={setPersons} name="snils"/>
                <div className="likeTable">
                    <ListElement data={persons} setter={setPersons}/>
                </div>
            </td>
            <td>
                <div className="tableBut">
                    <button onClick={async () => {
                        if ((orgs.length  && persons.length && region && depType==='roiv') || (orgs.length  && persons.length  && depType==='foiv')) {
                            await mainDbX.call_function('insert_dep_persons'
                                , {
                                    orgs: '{'+orgs.toString()+'}'
                                    , persons: '{'+persons.toString()+'}'
                                    , dtype: depType
                                    , reg: region
                                });
                            window.location.reload()
                        } else await alert("Не все поля заполнены")
                    }
                    }>Сохранить
                    </button>
                    <button className="danger" onClick={() =>
                        window.location.reload()
                    }>Удалить
                    </button>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
}
const diff_transform =	{
    dep_type: e=> window.AppBundle.data.dep_type.decode[e]
    , region: e=> decode(e)
}
export function Departments() {
    let [newRow, setNewRow] = useState(null);
    return <>
        <div style={{float:"right", margin:"1em 1em 0 0"}}>
            <PopupLog table="dep_persons"
                      transform={diff_transform}/>
        </div>
        <h1>РОИВ/ФОИВ</h1>
        <div>
            <Table
                className="simpleTable statistic"
                store={mainDbX.store('grouped_dep_persons', {})}
                extra="id orgs persons dep_type region"
            >
                <Table.Before>
                    <div style={{margin:"0 2em"}}>
                    <SearchFilter style={{width:"100%"}}
                                  input={
                                      <Filter.Multi className="FilterInput" placeholder={"ОГРН организации или СНИЛС пользователя "}
                                                    extract={
                                                        [
                                                            [/(\d{13})|([0-9]{3}-[0-9]{3}-[0-9]{3}\s[0-9]{2})/,
                                                                v => row => row.orgs.includes(v) || row.persons.includes(v)
                                                            ]
                                                        ]
                                                    }
                                      />
                                  }
                    />
                    <div className="flexContainer">
                        <Table.Add command={() => {
                            setNewRow(<AddGroup/>)
                        }}>
                            Добавить РОИВ/ФОИВ
                        </Table.Add>
                        <button onClick={async ()=> {
                            await mainDbX.call_function('upd_roiv_orgs');
                            window.location.reload()
                        }
                        }>Обновить</button>
                    </div>
                 </div>
                    {newRow}
                </Table.Before>
                <Table.Col label="Тип ведомства" name="dep_type">{v=>
                    <ModelDecode modelData="dep_type" value={v} />
                }</Table.Col>

                <Table.Col name="region" label="Субъект" ifDistinct={[row=>row.region,'']}>
                    {
                        (v) =>decode(v)
                    }
                </Table.Col>
                <Table.Col label="ОГРН организации" name="orgs" extra="persons dep_type region"
                >{
                    (v, row) =><div className="likeTable">
                    <div>
                        <AddElement row={row} name={'regnum'}/>
                    </div>
                    <ToggleDetails trigger={<span>Организации</span>}>{()=>
                   <> {v?.slice(1, -1).split(',')
                            .map(value=> <div>
                                <div><div>{value}</div></div>
                                    <div>
                                        <button type="button" className="del"
                                                onClick={async () => {
                                                    await mainDbX.call_function('delete_dep_persons'
                                                        , {
                                                            orgs: '{' + value + '}'
                                                            , persons: row.persons
                                                            , dtype: row.dep_type
                                                            , reg: row.region
                                                        });
                                                    window.location.reload()
                                                }
                                        }/>
                                    </div>
                                </div>
                            )
                        }
                   </>}</ToggleDetails>
                    </div>
                }
                </Table.Col>
                <Table.Col label="СНИЛС" name="persons" extra="orgs dep_type region"
                >{
                    (v, row) =><div className="likeTable">
                        <div>
                            <AddElement row={row} name={'snils'}/>
                        </div>
                        <ToggleDetails trigger={<span>Пользователи</span>}>{()=>
                            <>
                        {v?.slice(1, -1).replaceAll('"', '').split(',')
                            .map(value => <div>
                                    <div>
                                        <div>{value}</div>
                                    </div>
                                    <div>
                                        <button type="button" className="del"
                                                onClick={async () => {
                                                    await mainDbX.call_function('delete_dep_persons'
                                                        , {
                                                            orgs: row.orgs
                                                            , persons: '{' + value + '}'
                                                            , dtype: row.dep_type
                                                            , reg: row.region
                                                        });
                                                    window.location.reload()
                                                }
                                                }/>
                                    </div>
                                </div>
                            )
                        }
                        </>}</ToggleDetails>
                    </div>
                }
                </Table.Col>
                <Table.Col label="Действия" colKey="@ops" extra="id orgs persons dep_type region">
                    {
                        (v, row) => <div className="tableBut">
                            <button type="button" className="danger"
                                    onClick={async () => {
                                        await mainDbX.call_function('delete_dep_persons'
                                            , {
                                                orgs: row.orgs
                                                , persons: row.persons
                                                , dtype: row.dep_type
                                                , reg: row.region
                                            });
                                        window.location.reload()
                                    }}
                            >Удалить
                            </button>
                        </div>
                    }
                </Table.Col>
            </Table>
        </div>
    </>
}