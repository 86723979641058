import React, {useState, useRef, useEffect, useContext} from 'react';

import localDb from 'azlib/components/local-db'

import { swap_array_elements,set_array_element
	, deep_assign, Box} from 'azlib/components/struct_assign';

import {PopupMenu} from 'azlib/components/modals';

import {fakeEvent, IntInput, Textarea} from 'azlib/components/std-controls'

import styles from './doctype.module.css';

import {Canvas} from './canvas'

import Draggable from 'react-draggable'

import {blobToDataURL} from 'azlib/components/std-controls'

import {OrgInfoContext} from 'org/org'
import {useFormikContext} from "formik";

//import eye from './eye.svg'


/*
[
   items
   [
      item_name 
      , {
        x, y, w, h
        font if changed
        if table:
        cols: [ { caption, w, px, py } ]
        rowHeight, border(line width)
        table continues encoded suffix of item 
         name (#N) and contains repeatCols only
       if described 3 cols it is name, value, measure
       if 2 cols - name, value
      }
   ]
]
*/


export function FormStructureEditor({rowKey, name, value, infoset, onChange, readOnly
	, paper, margins, orginfo, ...props}) {

	const values_keep = value;
	value = JSON.parse(value||'[]');

	function paginate() {
		let currentPage = 1;
		let pageIndex = 1;
		return value =>
			value.map(
			e => e.name==='\f'? { ...e, page:++currentPage, pageIndex: pageIndex = 1 }
										: {...e, page: currentPage, pageIndex: pageIndex++ }
			)
	}

	let [definition, setDefinitionBase] = useState([]);
	useEffect(()=>{
		setDefinitionBase(paginate()(value))
	}, [values_keep])

	//console.log('d', definition)

	const saveValue = toSave => {
		onChange?.(fakeEvent(name, JSON.stringify(toSave)))
		return paginate()(toSave)
	}

	const setDefinition = (idx, e) => {
		console.log(idx, e)
		setDefinitionBase(infoset=>
			saveValue(
				set_array_element(infoset, idx, 
					e && idx !== null? 
						deep_assign(infoset[idx], e)
						: e
					)
			)
		)
	}

	const up_down = (from,to) => 
		setDefinitionBase(infoset =>
			saveValue(
				swap_array_elements(from,to,infoset)
			)
		)

	return ( 
	<div className={styles.fe_container}>
	<div className="backgroundRoundedDark templatesSearch" style={{flex:"1 0", width:"100%"}}>
		<h2>
			Состав полей
		</h2>
		<h2>
			Параметры
		</h2>
		{!!definition.length&&<table className={styles.values} style={{background:"white", width:"100%"}}>
		<thead>
		<tr><th />
			<th>Название параметра</th>
			<th>Формат вывода</th>
			<th>Свойства</th>
			<th />
		</tr>
		</thead>
		<tbody>
			{
				definition
				.map((e,i,a)=><tr key={i}>
					{e.name==='\f'?
						<td colSpan={4}><hr style={{}}/></td>
					:<>
					<td  className={styles.arrowTd} >
						<UpDown i={i} array={a} 
							up_down={up_down} 
							readOnly={readOnly}
						/>
					</td>
					<td>{e.name}
					</td>
					<td><ItemFormat readOnly={readOnly}
						idx={i} name={e.name}
						setDefinition={setDefinition}
						format={e.format}
						columns={e.columns}
					/></td>
					<td>
						<ItemView readOnly={readOnly}
							idx={i}
							setDefinition={setDefinition}
							view={e.view}
						/>
					</td>
					</>
					}
					<td>
						<button type="button" className="del" disabled={readOnly}
							onClick={()=>setDefinition(i,undefined)}
						/>
					</td>
				</tr>
				)
			}
		</tbody>
		</table>}
		<div className={styles.ops}>
		{!!definition.length &&
		<ItemsRepos 
			rowKey={rowKey}
			scan_name="scan" pdf_name="pdf"
			paper={paper} margins={margins}
			definition={definition}
			readOnly={readOnly} setDefinition={setDefinition}
			orginfo={orginfo}
			/>
		}
		</div>
	</div>
	<div className="backgroundRoundedDark templatesSearch">
		<h2>Добавить:</h2>
		<>
		{infoset ?
			<AddItem setDefinition={setDefinition} infoset={infoset} readOnly={readOnly}/>
		: 'Форма документа для параметров не выбрана'
		}
		</>
	</div>
	</div>
	);
}

function UpDown({i,array,up_down,readOnly,...props}) {
	return !readOnly && <>
		{i-1>=0?<span className={styles.arrow+ ' up'} 
			onClick={()=>up_down(i,i-1,array)}
		>↑</span>:null}
		{i+1<array.length?<span className={styles.arrow+ ' down'}
			onClick={()=>up_down(i,i+1,array)}
			>↓</span>:null}
	</>
}


export function caption_format(format, columns){
	let caption = format?.caption||'';
	if(columns?.length) {
		if(format.widths) {
			caption = (caption||'') + ' '+
			format.widths.join(' | ')
		}
		if(format?.noHeaders)
			caption += `\nбез заголовков колонок (${format?.tableMarginTop||0}мм)`
	} 
	if(format?.noTitle)
		caption += '\nбез заголовка'
	return caption
}
function ItemFormat({idx, name, readOnly, format, columns, setDefinition}) {
	const setFormat = (format) =>
		setDefinition(idx,{format:Box(format)})

	let caption = caption_format(format, columns)

	if(readOnly) return <span>{caption 
				&& caption.split('\n').map((e,i)=><div key={i}>{e}</div>)
				||'по умолчанию'}</span>

	if(/^Дата\s/.test(name)) 
		return <PopupMenu
					trigger={
						<span>{caption||'по умолчанию'}</span>
					}
					closeBox
					readOnly={readOnly}
				>
					<input type="radio" 
						style={{position:"absolute", marginTop:"8px"}}
						checked={!format.mode}
						onChange={event=>setFormat({})}
					/><div style={{margin:"0.8em 0 1ex 1.5em"}}>01.01.1901</div>

					<input type="radio" 
						style={{position:"absolute", marginTop:"8px"}}
						checked={format.mode === 'full-ggg'}
						onChange={event=>setFormat({
							caption: '01 января 1901 года'
							, mode: 'full-ggg'
						})}
					/><div style={{margin:"1.3em 0 1ex 1.5em"}}>01 января 1901 года</div>

					<input type="radio" 
						style={{position:"absolute", marginTop:"8px"}}
						checked={format.mode === 'full-gg'}
						onChange={event=>setFormat({
							caption: '01 января 1901 г.'
							, mode: 'full-gg'
						})}
					/><div style={{margin:"1.3em 0 1ex 1.5em"}}>01 января 1901 г.</div>

					<input type="radio" 
						style={{position:"absolute", marginTop:"8px"}}
						checked={format.mode === 'full-g'}
						onChange={event=>setFormat({
							caption: '01 января 1901'
							, mode: 'full-g'
						})}
					/><div style={{margin:"1.3em 0 1ex 1.5em"}}>01 января 1901</div>

					<input type="radio" 
						style={{position:"absolute", marginTop:"8px"}}
						checked={format.mode === 'short-ggg'}
						onChange={event=>setFormat({
							caption: '01.01.1901 года'
							, mode: 'short-ggg'
						})}
					/><div style={{margin:"1.3em 0 1ex 1.5em"}}>01.01.1901 г.</div>

					<input type="radio" 
						style={{position:"absolute", marginTop:"8px"}}
						checked={format.mode === 'short-gg'}
						onChange={event=>setFormat({
							caption: '01.01.1901 г.'
							, mode: 'short-gg'
						})}
					/><div style={{margin:"1.3em 0 1ex 1.5em"}}>01.01.1901 года</div>
				</PopupMenu>

	if(columns) {
		return  <PopupMenu
					trigger={
						<span>{caption||'по умолчанию'}</span>
					}
					closeBox
					readOnly={readOnly}
				>
				<div>
				Без заголовка: 
					<input type="checkbox"
						checked={format?.noTitle}
						onChange={e=>
							setFormat({...format,noTitle:e.target.checked})}
					/>
				</div>
				{!!columns.length && <div>
				Без заголовков колонок:
					<input type="checkbox"
						checked={format?.noHeaders}
						onChange={e=>
							setFormat({...format,noHeaders:e.target.checked})}
					/>
				</div>}
				{format?.noHeaders && <div>
				Отступ сверху: 
					<div><IntInput 
						value={format?.tableMarginTop||0}
						onChange={e=>
							setFormat({...format,tableMarginTop:+e.target.value})}
					/>{' '}мм
					</div>
				</div>}
				{!!columns.length && <div>
				Ширина колонок:
					{
						columns.map((e,i)=><div  key={i}>
							{i+1}:<IntInput
								value={format.widths?.[i]??0}
								onChange={e=>
									setFormat({...format,
										widths:
											set_array_element(
												format.widths??Array(columns.length).fill(0)
											, i, +e.target.value
											)
								})
							}
							/>мм
						</div>)
					}
				</div>}
				</PopupMenu>
	}

	if(name==='QR') {
		return  <PopupMenu
					trigger={
						<span>{caption||'по умолчанию'}</span>
					}
					closeBox
					readOnly={readOnly}
					style={{width:"30em"}}
				>
				<div className="flexContainerColumn" style={{marginTop:"1em"}}>Состав кода:
					<Textarea
							value={caption??''}
							onChange={e=>
								setFormat({...format,
									caption: e.target.value
									})
							}
					/></div>
				</PopupMenu>
	}
	if(name==='<Текст>') {
		return  <PopupMenu
					trigger={
						<span>{caption||'-'}</span>
					}
					closeBox
					readOnly={readOnly}
					style={{width:"30em"}}
				>
				<div className="flexContainerColumn" style={{marginTop:"1em"}}>Текст:
					<input
							value={caption??''}
							onChange={e=>
								setFormat({...format,
									caption: e.target.value
									})
							}
					/></div>
				</PopupMenu>
	}
	if(name==='<Подпись: Фамилия Имя Отчеcтво>') {
		return  <PopupMenu
					trigger={
						<span>{caption||'-'}</span>
					}
					closeBox
					readOnly={readOnly}
					style={{width:"30em"}}
				>
				<div className="flexContainerColumn" style={{marginTop:"1em"}}>Номер/должность
					<input
							value={caption??''}
							onChange={e=>
								setFormat({...format,
									caption: e.target.value
									})
							}
					/></div>
				</PopupMenu>
	}
	if(name==='<Подпись: Фамилия И.О.>') {
		return  <PopupMenu
			trigger={
				<span>{caption||'-'}</span>
			}
			closeBox
			readOnly={readOnly}
			style={{width:"30em"}}
		>
			<div className="flexContainerColumn" style={{marginTop:"1em"}}>Номер/должность
				<input
					value={caption??''}
					onChange={e=>
						setFormat({...format,
							caption: e.target.value
						})
					}
				/></div>
		</PopupMenu>
	}
	if(name==='<Подпись: И.О. Фамилия>') {
		return  <PopupMenu
					trigger={
						<span>{caption||'-'}</span>
					}
					closeBox
					readOnly={readOnly}
					style={{width:"30em"}}
				>
				<div className="flexContainerColumn" style={{marginTop:"1em"}}>Номер/должность
					<input
							value={caption??''}
							onChange={e=>
								setFormat({...format,
									caption: e.target.value
									})
							}
					/></div>
				</PopupMenu>
	}
	if(name==='<Подпись: Должность>') {
		return  <PopupMenu
					trigger={
						<span>{caption||'-'}</span>
					}
					closeBox
					readOnly={readOnly}
					style={{width:"30em"}}
				>
				<div className="flexContainerColumn" style={{marginTop:"1em"}}>Номер/должность
					<input
							value={caption??''}
							onChange={e=>
								setFormat({...format,
									caption: e.target.value
									})
							}
					/></div>
				</PopupMenu>
	}
	if(name==='<ЭЦП>') {
		return  <PopupMenu
					trigger={
						<span>{caption||'-'}</span>
					}
					closeBox
					readOnly={readOnly}
					style={{width:"30em"}}
				>
				<div className="flexContainerColumn" style={{marginTop:"1em"}}>Номер/должность:
					<input
							value={caption??''}
							onChange={e=>
								setFormat({...format,
									caption: e.target.value
									})
							}
					/></div>
				</PopupMenu>
	}
	if(name==='<Страница>') {
		return  <PopupMenu
					trigger={
						<span>{caption||'по умолчанию'}</span>
					}
					closeBox
					readOnly={readOnly}
					style={{width:"30em"}}
				>
				<div className="flexContainerColumn" style={{marginTop:"1em"}}>Текст:
					<input
							value={caption??''}
							onChange={e=>
								setFormat({...format,
									caption: e.target.value
									})
							}
					/></div>
				<div>
					Спецсимволы
					<dl>
						<dt>%P</dt>
							<dt>означает <i>Номер страницы</i></dt>
						<dt>%T</dt>
							<dt>означает <i>Число страниц</i></dt>
						<dt>%R</dt>
							<dt>означает <i>Реестровый номер</i></dt>
						<dt>%%</dt>
							<dt>означает <i>%</i></dt>
					</dl>
				</div>
				<div>
					Зеркальное выравнивание: 
					<input type="checkbox" 
						checked={!!format?.mirror_align}
						onChange={e=>setFormat({...format, mirror_align: e.target.checked})}
					 />
				</div>
				<div>
					Зеркальное размещение: 
					<input type="checkbox" 
						checked={!!format?.mirror_pos} 
						onChange={e=>setFormat({...format, mirror_pos: e.target.checked})}
					/>
				</div>
				</PopupMenu>
	}

	return  <span>{format?.caption||'по умолчанию'}</span>
}

export function caption_view(view){
	let caption = '';
	if(view?.fWeight)
		caption += '\nжирность'
	if(view?.fStyle)
		caption += '\nкурсив'
	return caption
}

function ItemView({idx, view, readOnly, setDefinition}) {
	view = view || {}
	const setStyleView = (view) =>
		setDefinition(idx,{view:Box(view)})
	let caption = caption_view(view)
	return <PopupMenu
			trigger={<span>{view?
				`(→${view.x??0}:↓${view.y??0})${view.w?'[↔'+view.w+']':''}
					${caption}
					${view.fSize?'\nразмер шрифта: '+view.fSize:''}
					${view.tAlign?'\nвыравнивание: '+view.tAlignName:''}`
				:'не выводится'}</span>}
			closeBox
			readOnly={readOnly}
		>
		<br/>
			<nobr>
			x: <IntInput value={view.x??0} 
				onChange={(e)=>{ 
					setDefinition(idx,{view:{x:e.target.value}}) 
				}}
				style={{width:"5em"}}
				/> мм
			</nobr>
			&nbsp;&nbsp;&nbsp;&nbsp; 
			<nobr>
			y: <IntInput value={view.y??0} 
				onChange={(e)=>{ 
					setDefinition(idx,{view:{y:e.target.value}}) 
				}}
				style={{width:"5em"}}
				/> мм
			</nobr>
			<p><nobr>
				Ширина: <IntInput value={view.w??''}
								  onChange={(e)=>{
					setDefinition(idx,{view:{w:e.target.value||undefined}}) 
				}}
				style={{width:"5em"}}
				/> мм (необязательно)
			</nobr></p>
			<p><nobr>
				Жирность: <input type="checkbox"
								 checked={view?.fWeight}
								 onChange={e=>
									 setStyleView({...view,fWeight:e.target.checked})}
			/>
			</nobr></p>
			<p><nobr>
				Курсив: <input type="checkbox"
							   checked={view?.fStyle}
							   onChange={e=>
								   setStyleView({...view,fStyle:e.target.checked})}
			/>
			</nobr></p>
			<p><nobr>
				Шрифт: <select value={view.fSize??''}
							   onChange={(e)=>{
								   setDefinition(idx,{view:{fSize:e.target.value||undefined}})
							   }}>
				<option value=""></option>
				<option value="6">6</option>
				<option value="7">7</option>
				<option value="8">8</option>
				<option value="9">9</option>
				<option value="10">10</option>
				<option value="11">11</option>
				<option value="12">12</option>
				<option value="14">14</option>
				<option value="16">16</option>
				<option value="18">18</option>
				<option value="20">20</option>
			</select>
			</nobr></p>
			<p><nobr>
				Выравнивание: <select value={view.tAlign??''}
									  onChange={(e)=>{
										  setStyleView({...view,tAlign:e.target.value,
											  tAlignName:e.target[e.target.selectedIndex].text})
									  }}>
				<option value=""></option>
				<option value="left">По левому краю</option>
				<option value="right">По правому краю</option>
				<option value="center">По центру</option>
				<option value="justify">По ширине</option>
			</select>
			</nobr></p>
	</PopupMenu>
}

function DraggableDiv({wh, view, format, name, pageIndex, idx, setView, setPalette, readOnly}){
	//console.log(name)
	if(!view) view = {x:0,y:0}
	const px_to_mm = px=> Math.floor(px/96*25.4);
	let dragRef = useRef(null)
	let wRef = useRef(null)
	let hRef = useRef(null)
	let coords = ({x,y}) => ({
		x: +(view?.x??0)+Math.floor( px_to_mm(x) )
		, y: +(view?.y??0)+Math.floor( px_to_mm(y) )
	})
	let [focused, setFocused] = useState(false)
	let [csize, setCSize] = useState(null)
	let [divStyle, setDivStyle] = useState({
				border: "1px dotted gray"
				, position: "absolute"
				, minWidth: "10mm"
				, minHeigh: "5mm"
				, maxWidth: (wh.w??40)+"mm"
				, maxHeigth: (wh.h??40)+"mm"
			})
	return <Draggable
		disabled={readOnly}
		nodeRef={dragRef}
		bounds="parent"
		defaultClassName={styles.draggable}
		defaultClassNameDragging={styles.dragging}
		onDrag={(e,obj)=>{
			if(!e.target.offsetParent) return;
			setPalette({name,view:{...view, ...coords(obj)}})}
		}
		onStop={(e,obj)=>{
			if(!e.target.offsetParent) return;
			//console.log(obj, coords(obj))
			setView(idx, {...view, ...coords(obj)} )
		}}
		position={{x:0,y:0}}
		cancel={styles.handle}
	>
	<div readOnly
		ref={dragRef}
		style={{...divStyle
				, width: (view?.w??30)+"mm"
				, height: (view?.h??5)+"mm"
				, left: (view?.x??0)+"mm"
				, top: (view?.y??0)+"mm"
		}}
		tabIndex={0}
		onFocus={()=>{
			setFocused(true)
			setPalette({name, view})
		}}
		onBlur={()=>setFocused(false)}
		onKeyDown={(e)=>{
			//console.log(e.key, e.shiftKey)
			let nv = view;
			/* eslint-disable-next-line default-case */
			switch(e.key) {
				case 'ArrowUp': 
					if(e.shiftKey)
						nv = {...view, h:(view.h??5)-1};
					else
						nv = {...view, y:(view.y??0)-1}; 
					break;
				case 'ArrowDown': 
					if(e.shiftKey)
						nv = {...view, h:(view.h??5)+1};
					else
						nv = {...view, y:(view.y??0)+1}; 
					break;
				case 'ArrowLeft': 
					if(e.shiftKey)
						nv = {...view, w:(view.w??30)-1};
					else
						nv = {...view, x:(view.x??0)-1}; 
					break;
				case 'ArrowRight': 
					if(e.shiftKey)
						nv = {...view, w:(view.w??30)+1};
					else
						nv = {...view, x:(view.x??0)+1}; 
					break;
				case '+': 
					nv = {...view, w:(view.w??30)+1}; break;
				case '-': 
					nv = {...view, w:(view.w??0)+1}; break;
			}
			if(nv!==view) {
				nv.x = Math.min(
						px_to_mm(dragRef?.current?.offsetParent?.offsetWidth??0)-10
						,Math.max(0,nv.x))
				nv.y = Math.min(
						px_to_mm(dragRef?.current?.offsetParent?.offsetHeight??0)-5
						,Math.max(0,nv.y))
				setView(idx, nv)
				setPalette({name,view:nv})
				e.preventDefault();
			}
		}}
	><div
		style={{
			border: csize? "1px solid": "1px transparent"
			, boxSizing: "border-box"
			, width: csize? csize.w+"px" : "100%"
			, height: csize? csize.h+"px" : "100%"
		}}
	><span style={{display: "inline-block"
				, background:"#5cf75c", minWidth: "1em"
				, textAlign:"center"
				, borderRadius: "5px"
			}}>{pageIndex}</span>
	</div>
	{focused?<Draggable 
		disabled={readOnly}
		defaultClassName={styles.handle}
		nodeRef={wRef}
		axis="x"
		bounds={{left:-dragRef?.current?.offsetWidth??0
				, right:dragRef?.current?.offsetParent?.offsetWidth??0
			}}
		onDrag={(e,obj)=>{
			if(!e.target.offsetParent) return;
			//console.log(obj.x)
			setPalette({name,view:{...view
				, w: +(view.w??30) + px_to_mm(obj.x)
			}})
			setCSize({
				w: dragRef.current.offsetWidth + obj.x
				,h: dragRef.current.offsetHeight
			})
		}}
		onStop={(e,obj)=>{
			if(!e.target.offsetParent) return;
			setPalette({name,view:{...view
				, w: +(view.w??30)+ px_to_mm(obj.x)
			}})
			setCSize(null)
			setView(idx, {...view,
				w: +(view.w??30)+ px_to_mm(obj.x)
			})
		}}
		onMouseDown={(e)=>{e.stopPropagation()}}
		position={{x:0,y:0}}
	><div 
			ref={wRef}
			style={{
			position: "absolute"
			, boxSizing: "content-box"
			, right: 0, top: "-1px"
			, height: "100%"
			, width: "16px"
			, border: "1px solid"
			, marginRight: "-18px"
			, fontSize: "14px"
			, background: "white"
			, opacity: "0.7"
			, textAlign: "center"
		}}
	><div style={{position: "absolute", top:"50%", transform: "translateY(-50%)"}}>↔</div></div></Draggable>
	:null}
	{focused?<Draggable 
		disabled={readOnly}
		defaultClassName={styles.handle}
		nodeRef={hRef}
		axis="y"
		bounds={{top:-dragRef?.current?.offsetHeight??0
				, bottom:dragRef?.current?.offsetParent?.offsetHeight??0
			}}
		onDrag={(e,obj)=>{
			if(!e.target.offsetParent) return;
			setPalette({name,view:{...view
				, h: +(view.h??5)+ px_to_mm(obj.y)
			}})
			setCSize({
				w: dragRef.current.offsetWidth 
				,h: dragRef.current.offsetHeight + obj.y
			})
		}}
		onStop={(e,obj)=>{
			if(!e.target.offsetParent) return;
			setPalette({name,view:{...view
				, h: +(view.h??5)+ px_to_mm(obj.y)
			}})
			setCSize(null)
			setView(idx, {...view,
				h: +(view.h??5)+ px_to_mm(obj.y)
			})
		}}
		onMouseDown={(e)=>{e.stopPropagation()}}
		position={{x:0,y:0}}
	><div 
			ref={hRef}
			style={{
			position: "absolute"
			, boxSizing: "content-box"
			, bottom: 0, left: "-1px"
			, width: "100%"
			, height: "16px"
			, border: "1px solid"
			, marginBottom: "-18px"
			, fontSize: "14px"
			, background: "white"
			, opacity: "0.7"
			, textAlign: "center"
		}}
	><div style={{position: "absolute", left:"50%", transform: "translateX(-50%)"}}>↕</div></div></Draggable>
	:null}
	</div>
	</Draggable>
}


function ItemsRepos({rowKey, definition, scan_name, pdf_name, paper, margins, readOnly, setDefinition, orginfo}) {

	const setView = (idx, view) =>
	setDefinition(idx,{view})

	let fc = useFormikContext()
	let Wmm = paper.w??210;
	let Hmm = paper.h??297;

	let [scan,setScan] = useState(undefined)
	let [pdf,setPdf] = useState(undefined)

	if(scan === undefined) {
		//debugger;
		setScan(null)
		localDb.get('doc_forms', rowKey) //try local first!
		  .then(d=>{
		      if(d && typeof d[scan_name] === 'string') {
		        let data_url = d[scan_name] ?? "";
		        return setScan(data_url)
		      } 
		      return orginfo.DbX.fetch_get(`/az2/server/filer?-t=doc_forms&-name=${scan_name}&`
		            +`-k[]=${rowKey[0]}`
		            ).then(e=>{
		              if(e.ok){
		                return e.blob().then(URL.createObjectURL).then(setScan)
		              }
		          })
		  })
	}
	if(pdf === undefined) {
		//debugger;
		setPdf(null)
		localDb.get('doc_forms', rowKey) //try local first!
		  .then(d=>{
		      if(d && typeof d[pdf_name] === 'string') {
		        let data_url = d[pdf_name] ?? "";
		        return setPdf(data_url)
		      } 
		      return orginfo.DbX.fetch_get(`/az2/server/filer?-t=doc_forms&-name=${pdf_name}&`
		            +`-k[]=${rowKey[0]}`
		            ).then(e=>{
		              if(e.ok){
		                return e.blob().then(blobToDataURL).then(setPdf)
		              }
		          })
		  })
	}

	let pages = definition.reduce((a,e)=>e.name === '\f'? a+1:a,1)

	return <PopupMenu
			trigger={<button type="button">Визуальное размещение</button>}
			bindX="right"
			bindY="bottom"
		>
			{ Array(pages).fill(0)
				.map((e,i)=>
					<PopupMenu key={i+1} framed={false}
						trigger={
							<button type="button">Страница {i+1}</button>
						}
						closeBox
					>
						<ReposPage scan={fc.values.scan === true ? scan : fc.values.scan ?? scan} pdf={fc.values.pdf === true ? pdf : fc.values.pdf ?? pdf} Wmm={Wmm} Hmm={Hmm} page={i+1}
							definition={definition} setView={setView}
							margins={margins} readOnly={readOnly}
						 />
					</PopupMenu>
				)
			}
	</PopupMenu>
}

function ReposPage({scan, pdf, Wmm, Hmm, 
		margins,
		definition, setView, page, readOnly}) {
	let [palette, setPalette] = useState(null)
	const p = page;

	let [wh,setWH] = useState({w:Wmm,h:Hmm})

	return <div style={{display:"grid"
		, gridTemplateColumns: "200px 1fr"
		, background: "white"
	}}>
	<div style={{heigth: "100%", 
		border:"1px solid", background:"white"
		}}>
		<div
			className={styles.palette}
			style={{
					//height: "6em"
					padding: "1ex"
				}}
		>{palette && <div>{palette.name}
			<br/>
			→:{palette.view.x??0}mm
			<br/>
			↓:{palette.view.y??0}mm
			<br/>
			↔:{palette.view.w??30}mm
		</div>}
		</div>
		<div style={{padding: "1ex"}}>
		{definition
		.filter(e=>e.page === page && e.name !== '\f')
		.map(({name,view,format,pageIndex},i)=>
			<div key={i}>
			<span style={{display: "inline-block"
				, background:"#5cf75c", minWidth: "1em"
				, textAlign:"center"
				, marginTop: "1ex"
			}}
			>{pageIndex}</span>
			{' '} {name}
			</div>
		)}
		</div>
	</div>
	<Canvas wh={wh} setWH={setWH} 
			scan={scan} pdf={pdf} pageNumber={page}>
	<div 
		style={{
			position: "absolute"
			, left: margins[0]+"mm"
			, top: margins[1]+"mm"
			, right: margins[2]+"mm"
			, bottom: margins[3]+"mm"
			, border: "1px dotted red"
		}}
	/>
	{
		definition
		.map(({name,view, format, page, pageIndex},i)=>
			page === p && name !== '\f'?
			<DraggableDiv
				key={i} pageIndex={pageIndex} idx={i}
				view={view} name={name} format={format}
				setView={setView}
				setPalette={setPalette}
				wh={wh}
				readOnly={readOnly}
			/>:null
		)
	}
	</Canvas>
	</div>
}

function AddItem({setDefinition, infoset, readOnly}) {
	function add_item(e) {
		let js = e.target.value
		if(!js) return
		js = JSON.parse(js)
		if(js.paragraph)
			setDefinition(null,{name:js.name, format:{}, columns: js.columns || [] })
		else
			setDefinition(null,{name:js.name, format:{}})
	}

	//console.log(infoset)

	return <>
		<button type="button" disabled={readOnly}
			onClick={async (e)=> {
				setDefinition(null,{name:'\f'})
			}}
		>Разрыв страницы</button>

		{
			[{name:'Фамилия Имя Отчеcтво', internal: true}
			,...JSON.parse(infoset)
			, {name:"Реестровая дата",internal:true, level:0}
			, {name:"Дата выписки",internal:true, level:0}
			, {name:"QR",internal:true, level:0}
			, {name:"<Страница>",internal:true, level:0}
			, {name:"<Текст>",internal:true, level:0}
			, {name:"<ЭЦП>",internal:true, level:0}
			, {name:"<Подпись: Фамилия Имя Отчеcтво>",internal:true, level:0}
			, {name:"<Подпись: Фамилия И.О.>",internal:true, level:0}
			, {name:"<Подпись: И.О. Фамилия>",internal:true, level:0}
			, {name:"<Подпись: Должность>",internal:true, level:0}
			]
			.filter(e=>e.internal!==false)
			.filter(e=>!e.insert)
			.reduce(({a,l},e)=>
					e.level||e.paragraph
						?	{a:[...a,{...e,l:true}],l:true}
						: 	{a:[...a,{...e,l}],l}
					,{a:[]}
					).a
			.filter(e=>e.internal || 
							e.l===undefined || e.l && e.level===1)
			.map(e=>
				<button type="button" key={e.name} style={{marginTop:"1em", width:"30em"}}
					disabled={readOnly}
					value={JSON.stringify(e)}
					onClick={add_item}
				>{e.name}</button>
			)
		}
</>
}

