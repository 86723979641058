import React, {useContext, useState} from 'react'

import * as yup from 'azlib/components/yup_plus';
import {
	ExtendFormikContext,
	Field,
	formikExtra,
	LabeledField,
	modalFormik,
	ModelField
} from 'azlib/components/formik-fields'

import {XLOG} from 'azlib/components/helpers'

import {fullPersonName} from 'azlib/components/name_funcs'
import {Async} from 'azlib/components/async'

import {alert
	, seqModalProcessing, showModal, showModalPrintTable
} from 'azlib/components/modals'

import {OrgInfoContext} from 'org/org'
import UserInfoContext from 'UserInfo'

import { DbTableEdit as Table } from 'azlib/components/super-table'

import {ModelDecode} from 'azlib/components/std-controls'

import Filter from 'azlib/components/list_filter'
import {fioExtract, fioCondition} from 'azlib/components/filter_helpers'

import { localISOtoday } from 'azlib/components/calendar'
import {DateInput} from 'azlib/components/std-controls'

import {importIdCols, transformImported, importSchemeId, importSchemeDef} from 'contingent/import_defs'
import { TableParser } from 'azlib/components/table_parser'

import { SearchFilter } from 'azlib/components/search_filter'

import css from './print.module.css'
import Sorter from "../azlib/components/list_sort";
import {statusSorter} from "../config/common";
import {object, string} from "yup";
import {ErrorMessage} from "../azlib/components/formik-fields";
import styles from "./import.module.css";
import {Link} from "react-router-dom";
import {allPrintPdf} from "../genpdf/pdf-form";
function inputDocNumbers({kind, form_type, orginfo, readOnly}) {
	let reg = window.AppBundle.data.doc_kind_regnumber.decode[
			kind
		]; 

	let forms = form_type? [form_type] 
				: window.AppBundle.data.doc_forms.decode[kind];

	let val = {
				docdate: window.localStorage['default-docdate'] 
						?? localISOtoday()
				, regnumber: ''
			}
	let scheme = {
				  docdate: yup.date().required('Обязательное поле')
				  , regnumber: yup.string()
				}	

	for(const f of forms){
		val[f] = '';
		scheme[f] = yup.string().max(30);
	}

	scheme = yup.object(scheme)


	return modalFormik({
			initialValues:val
			, validationSchema: scheme
			, initialStatus:{}
			, output: v=>{
				window.localStorage['default-docdate'] = v.docdate
				let common = {
								docdate:v.docdate, 
								regnumber: v.regnumber,
								numbers: {},
								first_type: forms[0]
							}
				for(const f of forms) {
					if(v[f])
						common.numbers[f] = v[f]
				}
				return (common)
			}
		},
		<ExtendFormikContext value={{[formikExtra]: { storeWithFields: {store:'prints'} }}}>
			<label>Дата выдачи</label>
			<div><Field name="docdate" as={DateInput} /></div>

			<div>
			{forms.length>1 && <label>Номера</label>}
			{
				forms.map(f=>
					<div key={f}>
						<label>{f}</label>
						<div><Field name={f}  autoComplete="off"/></div>
						<div className="error_in_groups"><ErrorMessage name={f} /></div>
					</div>
				)
			}
			</div>

			{reg && <>
			<div><LabeledField name="regnumber" label="Регистрационный номер" max_length={30}
							   autoComplete="off"/></div>
			</>}
			<div className="flexContainer">
				<button disabled={readOnly} type="submit">ОК</button>
			</div>
		</ExtendFormikContext>
	, {width:"30em", closeBox:true})
}
async function printState(kind, form_type, orginfo, readOnly) {
	let dlg = await inputDocNumbers({kind,form_type,orginfo,readOnly})

	let numbers = {}

	for(let f in dlg.numbers) {
		let number = dlg.numbers[f]
		let current_number = number.match(/(\d+)$/)
		if(!current_number) {
			alert('нет цифр в конце номера')
			return
		}
		current_number = current_number[1]
		let base_number = number.replace(/(\d+)$/,'');
		numbers[f] = {base_number, current_number}
	}

	let regnumber = dlg.regnumber
	let current_regnumber = null;
	if(regnumber) {
  		current_regnumber = regnumber.match(/(\d+)$/)
		if(!current_regnumber) {
			alert('нет цифр в конце номера')
			return
		}
		current_regnumber = + current_regnumber[1];
	}
	let base_regnumber = regnumber.replace(/(\d+)$/,'');

	return {
		first_type: dlg.first_type
		, docdate: dlg.docdate
		, numbers
		, base_regnumber, current_regnumber
		, orginfo
	}
}

async function printStep(item, ops, updateProgress, state, form) {
	let key = item

	if(updateProgress) {
		let row = await ops.source.get(JSON.stringify(key))
		if(!form) {
			updateProgress(p=>({...p
				, key
				, row: row.data
				, number: state.numbers[state.first_type].base_number
							+state.numbers[state.first_type].current_number
			}))
		} else {
		   	let {printPdf} = await import('genpdf/pdf-form')
		    await printPdf(form.form,row.data.docid,state.orginfo)
			await new Promise((resolve,reject)=>{
				updateProgress(p=>({...p
					, key
					, row: row.data
					, number: state.numbers[state.first_type].base_number
								+state.numbers[state.first_type].current_number
					, next: resolve
					, stop: reject
				}))
			})
		}
	}

	for(const f in state.numbers)
		await state.orginfo.DbX.upsert_direct('prints', null
			,{docid: key[0]
			, docdate: state.docdate
			, form_type: f
			, docnumber:
				state.numbers[f].base_number
				+ state.numbers[f].current_number
			, regnumber: 
				state.current_regnumber === null? ''
				: state.base_regnumber + state.current_regnumber
			})
	
	
	for(const f in state.numbers)
		++state.numbers[f].current_number;
	if(state.current_regnumber!== null) ++state.current_regnumber;
	ops.remove(key)
}

export async function confirmPrint(printDocs, obj_num, state, ops) {
	for (const doc in printDocs) {
		if (printDocs[doc]) {
			for(const f in state.numbers)
				await state.orginfo.DbX.upsert_direct('prints', null
					,{docid: doc
						, docdate: state.docdate
						, form_type: f
						, docnumber:
							state.numbers[f].base_number
							+ obj_num[doc].num
						, regnumber:
							state.current_regnumber === null? ''
								: state.base_regnumber + state.current_regnumber
					})
			if(state.current_regnumber!== null) ++state.current_regnumber;

			ops.remove([parseInt(doc)])
		}
	}
}
export function PrintList({form, setForm}) {
	const orginfo = useContext(OrgInfoContext);
	const uinfo = useContext(UserInfoContext);
	const terms = orginfo.terms
	let grp_fix = null;
	return <>
		<Table  
			className="simpleTable"
			store={orginfo.DbX.store("issued", { tid: orginfo.tid, ...grp_fix } )}
			storeFilter={row=>
				row.doctype_kind === form.kind
				&& row.status === 'issued-ok'
				&& (!row.cached_numbers ||
					!JSON.parse(row.cached_numbers)[form.form_type])
				&& row.year_completion == (new Date()).getFullYear()
			}
			extra="doctype_kind status cached_numbers year_completion"
			handleSave={Table.directSave}
			>
			<Table.Before>
				<div className="templatesSearch backgroundAngular">
					<SearchFilter
						input={
						<Filter.Multi className="FilterInput" 
							extract={
								[
									[fioExtract, fioCondition(
										row=>[row.last_name, row.first_name, row.middle_name]
										)
									]
								]
							}
						/>
						}
					/>

					<div className="flexContainer">
						<button onClick={()=>{setForm(undefined)}}>
							вернуться к выбору бланка
						</button>
						<Sorter.Select
							slot="combo"
							style={{marginLeft:"auto"}}
						>
							<option comparator={(a,b)=>
								Sorter.cmp(
									`${a.last_name} ${a.first_name} ${a.middle_name}`
									,  `${b.last_name} ${b.first_name} ${b.middle_name}`
								)
							}>По ФИО ↗</option>
							<option comparator={(a,b)=>
								-Sorter.cmp(
									`${a.last_name} ${a.first_name} ${a.middle_name}`
									,  `${b.last_name} ${b.first_name} ${b.middle_name}`
								)
							}>По ФИО ↘</option>
						</Sorter.Select>
					{(orginfo.docs.printer || uinfo.userAreas.sysop || uinfo.userAreas.admin) &&
						<Table.ForSelection
							command={async (selected,ops)=>{
								if (selected.length > 20) {
									await alert('Выберите не более 20 документов для печати.');
									return
								}
								let state = await printState(form.kind, form.form_type, orginfo, !orginfo.docs.printer)
								let sel = selected.map(([k])=>
									ops.source.get(JSON.stringify(k)).data
								)
								await allPrintPdf(sel, form, state)

								let cur_numbers = {};
								let numStr = state.numbers[state.first_type].current_number;
								let num = parseInt(numStr);
								selected.forEach(([k]) => {
									const docid = ops.source.get(JSON.stringify(k)).data.docid;
									let formattedNum = numStr;
									cur_numbers[docid] = { num: formattedNum };
									num++;
									numStr = num.toString().padStart(formattedNum.length, '0'); // for numbers in the format: 0001
								});
								await showModalPrintTable(orginfo, selected, state, ops, cur_numbers)

							}}
						>Печать</Table.ForSelection>
					}
				</div>
				</div>
			</Table.Before>
			<Table.OperationChecks style={{width:"4em"}}/>
			<Table.Col label="ФИО" name="docid"
				extra="last_name first_name middle_name bdate snils"
			>
				{(value,row) =>
					fullPersonName(row) || '--???--'
				}
			</Table.Col>
			<Table.Col label={terms.group} name="gcode" extra="gcode{group_name}"
				ifDistinct={row=>row.gcode}
			>{
				(v, row) => row.gcode$?.group_name
			}
			</Table.Col>
			<Table.ModelCol name="our_number" />
			<Table.Empty>Печать доступна только для документов, выпущенных в текущий год.
				Для документов, выпущенных в год, отличный от текущего, воспользуйтесь печатью в разделе "Реестр".
			</Table.Empty>
		</Table>
	</>
}

export function PrintDocs() {
	const orginfo = useContext(OrgInfoContext);

	let [kind,setKind] = useState(undefined)
	let [form,setForm] = useState(undefined)
	const [active, setActive] = useState("");
	const handleClick = (kind) => {
		setActive(kind);
	}
	let [tableImport, setTable] = useState();

	let scheme = importSchemeDef
	let setFields = (fields) => {
		let header = ['ФИО', 'Статус импорта']
		setTable({
			cols : header
			, import : fields.filter(e=>e.metka)
			, invalid : fields.filter(e=>!e.metka)
		})
	}
	return <>
		<h1>Печать</h1>
		{form===undefined?

			<div className={css.printPage}>
				<div className="backgroundRounded">
				<h2>Выбор типа документа</h2>
				<Async value={
					orginfo.DbX.fetch_rds('doc_print_kinds', 'doctype_kind', {tid:orginfo.tid})
					}
				>{value=>
					value.map(e=>
						<div key={e.doctype_kind} active={active === e.doctype_kind ? "true" : ""} style={{display:"flex", justifyContent:"center"}}>
							<button type="button" className="block"

								onClick={()=>{
									setKind(e.doctype_kind)
									handleClick(e.doctype_kind)
								}}
							>
								<ModelDecode modelData="doc_kinds" 
									value={e.doctype_kind} />
							</button>
					</div>)
				}</Async>
				</div>
				<div className="backgroundRounded">
				<h2>Выбор бланка</h2>
				{
					orginfo.orgtree_docforms
					.filter(f=>f.kind===kind)
					.map(f=>
						<div key={f.form}>
							<button type="button" className="block"

								onClick={()=>{
									setForm(f)
								}}
							>
							<ModelDecode modelData="doc_kinds" value={f.kind} />
							({f.form_type})
							:	{f.formname}
							</button>
						</div>
					)
				}
				<div>
					{kind?	<button type="button" className="block"
							style={{width:"15em", background:"rgba(70, 120, 144, 0.9)"}}
						onClick={async ()=>{
							await docNumbersImport(kind, orginfo, setFields)
						}}
					>
					Импорт номеров
					</button>:null}
				</div>
			</div>
				{!tableImport?.import? '':
					<table className={styles.table} style={{margin:"0em 2em 1em 2em"}}>
						<thead>
						<tr>
							{tableImport.cols.map(c=><th key = {c}>{c}</th>)}
						</tr>
						</thead>
						<tbody>
						{tableImport.import.map(c =>
							<tr key = {c.docid}>
								<td>{<Link to={`/org/${orginfo.tid}/docs/${c.docid}`}>{c.fio}</Link>}</td>
								<td>{c.metka == null ? 'Обновление данных невозможно' :
									(c.metka ? 'Добавлен' : 'Изменен')}</td>
							</tr>)}
						{tableImport.invalid.map(c =>
							<tr key = {c}>
								<td>{c.fio}</td>
								<td>Данные не импортированы</td>
							</tr>)}
						</tbody>
					</table>}
			</div>
		: <>
			<h2 style={{marginLeft:"2em"}}>
				Документы для печати:{' '}
				<ModelDecode modelData="doc_kinds" value={form.kind} />
				: {form.form_type}
				{' '}
				{form.formname?`(${form.formname})`:''}
			</h2>
			<PrintList form={form} setForm={setForm}/>
		</>
		}
	</>
}


async function docNumbersImport(kind, orginfo, setFields) {

	let reg = window.AppBundle.data.doc_kind_regnumber.decode[
			kind
		]; 

	let forms = window.AppBundle.data.doc_forms.decode[kind];

	let columns =	[
		...importIdCols
		, 'ДАТА ВЫДАЧИ'
	]

	if(reg) 
		columns.push('РЕГИСТРАЦИОННЫЙ НОМЕР')

	let ext = {}
	if(forms)
		for(const f of forms) {
			columns.push(f.toUpperCase())
			if (RegExp("^p|^ap").test(kind) && f === "Приложение") ext[f] = yup.string().label(f.toUpperCase())
			else ext[f] = yup.string().label(f.toUpperCase()).min(1)
		}

	let scheme = {...importSchemeId, 
		docdate: yup.string_date().label('ДАТА ВЫДАЧИ')
		, ...ext}

	let table = await showModal(({hide})=>
			<TableParser
				columns={columns}
				scheme={scheme}

				Ok={
					({table,reset}) => 
					<button type="button" className="modalButWithBackground" 
					disabled={!orginfo.docs.printer}
						onClick={()=>{
							console.log(table)
							hide(table)
						}}
						
					>Импортировать</button>
				}
			/>
	, {closeBox:true})

		//get first line
	let caption = table.shift()

	table = table.filter(x=>x.last_name && x.first_name)
	let valid = table.filter(x=>x.valid).map(({valid,...x})=>x)

	valid = transformImported(valid, orginfo, scheme)

	//valid = valid.filter(x=>x.valid!==false).map(({valid,...x})=>x)

	valid = valid.map(row=>({...row, tid: orginfo.tid, doctype_kind:kind})) //set tid for all docs

	console.log('after tid', valid)

	setFields(await orginfo.DbX.call_function('import_prints', {
		s: valid, forms: forms
	}))
}

