import React from 'react';
import { useFormikContext } from 'formik'
import {XLOG} from 'azlib/components/helpers'

import {IntInput, FieldInput, FieldSelect} from 'azlib/components/std-controls'
import {fakeEvent} from 'azlib/components/std-controls'
import {htmlBool, later} from 'azlib/components/helpers'

import {prompt} from 'azlib/components/modals'

import {calculate_internal, predefinedUnitsMap} from './params_descr'
import {ru_number_take_word} from 'azlib/components/locales/ru/ru_number'

import {defaultTemplate} from 'config/doctype_typepros'

import css from './infoset_edit.module.css'

function make_columns(e) {
	return e.internal !== undefined ? [''] 
		: e.domains.map(domain=>
			domain.domain.dummy ?? domain.placeholder ?? ''
		) 
}



// template use level as th/group marker
// also we use insertion point as end of current tree element
// so, element just aftre insert point has the same level as ip 
/*
	R 1
		p 0 -> 2  (parent=R)
		ip 1 (reset to 0!) //TODO: we need insertion point that reset level to itself
		G 2       (parent=R) (R is reopened)
		  p 0 -> 3 (parent=G)
		  p 0 -> 3 (parent=G)
		ip 2 (reset 1)
		p 0 -> 2  (parent=R)
		KR 2
			k 0 -> 3  (parent=KR)
		ip 2 (reset 1)
		p 0 -> 2  (parent=R)
*/

function template_to_tree(a) {
	let ret = []
	let levels = [ret]
	let level = 0
	for(let i of a) {
		if(i.insert) {
			level = i.level||level; // reset level to ip level 
			//ignore wrong insert points!
			levels[level]?.push(i)
			continue
		} 
		if(i.paragraph) {
			// this is a paragrapth element
			i = { ...i, columns: i.columns??[], level: i.level??1 }
		} else if(i.level) {
			//this is a group element
			i = { ...i, group: true}
		}
		if(i.internal || i.domains) {
			//generate empty values
			i = {...i, values: make_columns(i)} 
		}

		if(i.level && i.level > level+1) continue; //ignore wrongly leveled items

		if(i.level) { //item is a level start element
			level = i.level
			levels[level-1]?.push({...i, children: levels[level]=[] })
		}
		else if (i.separate) levels[0]?.push(i)
		else {
			levels[level]?.push(i)
		}
		if(i.subname) {
			// has subname
			levels[level]?.push({name:i.subname, subname:true, values: []})
		}
	}
	return ret;
}


/*

template is a tree
but infoset is a list(!)

so, we has current position in stream (name+props)
+ current stack in template (indexed by names)

+ we can check after-insert-point name

also, we can always store level 

of this is an item with no level (it is OLD item)
we put in into current ip OR one level up after insert point

*/

/*
	p1        
	p2
	R1 1
		G1 2
		IP-2
		p3 
		p4
here, p3,p4 goes to G1 in infoset
so, if p3,p4 has no level they can be attached to any here and up!
(if not merged already)

 r
 	p1
 	pr
 		p1
 	kr
 		p1

*/

export function parse_infoset_value(i,v) {
	if(typeof v === 'string') {
		//read plain value
		v = {
			columns: [v]
		}
	}
	if(Array.isArray(v)) {
		v = { columns: v }
	}
	if(v?.th) { //paragraph
		v = {name:i, paragraph: true
			, columns: v.columns
			, level: v.level || 1 //oldstyle value
			, children: []
		}
	} else if(v?.level || v?.group) { //group
		v = {name:i, group: true
			, values: v.columns ?? []
			, level: v.level  //oldstyle value
			, children: []
		}
	} else { //plain item
		v = {name:i
			, values: v && v.columns || null
		}
	}
	return v;
}

function index_level_item(item) {
	let idx = new Map()
	let first_ip = [] //current insert point accumulator
	let ip = first_ip;
	for(const t of item) {
		if(t.insert) {
			t.added = ip //save current ip and reset
			ip = []
		} else {
			if(idx.has(t.name)) {
				// a same named element
				const last = (idx.get(t.name).last||0)+1
				idx.set(t.name, {
					...idx.get(t.name)
					, npp: 0
					, last // keep next last index	
				})
				idx.set(t.name+'###'+last,{t, ip, npp:last})
			} else {
				// first encounter
				idx.set(t.name, {t, ip}) // link element and insert point to name
			} 
		}
	}
	item.push({added:ip, rest: true}) // if no ip found
	//  at the end of item children we place array placeholder
	return {idx, ip: first_ip}
}

function fill_template(template, infoset) {
	//console.log('merge',template,infoset) 
	//return

	let levels = []
	let def_level = 0
	levels[0] = index_level_item(template) 
	//console.log(levels[0].idx)
	//each level has 1) template names on this level 2) current ip of this level 

	for(let [name, value, level] of infoset) {
		value = parse_infoset_value(name,value)
		{
			// NEW format: exact infoset tree!
			let fnd = levels[level]?.idx?.get(name);
			if(fnd) {
				//consume found item from index
				const next = levels[level].idx.get(name+'###'+(fnd.npp+1))
				if(next) {
					levels[level].idx.set(name,next)
				} else {
					levels[level].idx.delete(name)
				}
				let t = fnd.t
				//prdefined elem found
				if(t.paragraph) {
					//do nothing
				} else {
					t.values = value.values
					if(t.values && t.domains)
						t.domains.forEach((d,i)=>{
							if(d.domain?.dummy !== undefined)
								t.values[i] = d.domain.dummy //replace with dummy
						})
				}
				if(t.children) {
					// new level item touched
					levels[level+1] = index_level_item(t.children)
				}
				levels[level].ip = fnd.ip
			} else {
				// adhoc value!
				levels[level].ip.push(value)
				if(value.paragraph || value.group) {
					// adhoc group value
					// append all rest values
					value.children = [] //adhoc ip
					levels[level+1] = {
						idx: new Map() //adhoc has no known names!
						, ip: value.children //and append point at the end
					}
				}
			}
		}
	}
	return template
}

function flatten_ip(arr) {
	let ret = 
		// process ip adhocs
		arr
		.map(({added,...t})=>
			[ ...(added??[])
					.map(c=>({...c,domains:t.domains, adhoc:true}))
				, t
			]
			).flat().filter(x=>!x.rest)
	return ret;
}


function childrenAsArray(level, {children,...t}) {
	if(!children) return [{...t, level}]
	return [{...t,level}
		, ...flatten_ip(children)
				.map(childrenAsArray.bind(null,level+1))]
}


function merge_lists(template, infoset) {
	template = template_to_tree(template)
	//console.log(template)

	//try {
		let filled = fill_template(template,infoset)
		//console.log(filled)

		let ret = flatten_ip(filled)
								.map(childrenAsArray.bind(null,0))
								.flat(100)

		let k = 0;
		return ret.map(e=>({...e, autokey: ++k}))
	//} catch(e) {
	//	let filled = fill_template(template,[])
	//	//console.log(filled)
	//
	//	let ret = flatten_ip(filled)
	//							.map(childrenAsArray.bind(null,0))
	//							.flat(100)
	//
	//	let k = 0;
	//	return ret.map(e=>({...e, autokey: ++k}))
	//}
}

//const measureSeperator = ' '; //this is thin space

export function parseInfosetValue(doc_kind, template, value) {
	template = defaultTemplate(doc_kind, template)
	let infoset = value
	if(!infoset || typeof infoset === 'string') 
		infoset = JSON.parse(infoset||"[]");
	return merge_lists(template, infoset)
}

function stripUnits(v, units) {
	if(v && units) {
		//FIXME: check unit's words exactly
		return v.replace(/^(.+)\s+\S+$/, '$1'); //strip last word
	}
	return v
}


function iset_from_merged(merged) {
	return JSON.stringify(merged
			.filter(t=>!t.insert)
			.filter(t=>t.paragraph || t.group || t.internal===undefined)
			.map(({name, values, columns, paragraph, group, level})=>
			[name,
				paragraph?
						{columns, th: true}
				:
				  values?.length === 1 && !group ? values[0]
				  : !group ? values
				  : { columns: values, group: true }
			,level]))
}


export function prefillExactValues(doc_kind, template, value) {
	let merged = parseInfosetValue(doc_kind, template, value)
	return iset_from_merged(merged.map(({domains,values,...e})=>
		e.insert ? { domains, ...e}
		: !domains ? {...e, values}
		: !values  ? {...e, domains, values}
		: {...e, domains,
			values: domains.map((d,i)=>
						d.domain?.strings && d.domain.strings.length===1 ?
						 d.domain.strings[0]
							: values[i]
						) 
		  }
		)
		)	
}


export function InfosetEditor({value, name
		, onChange, onBlur 
		, doc_kind, template, readOnly, refFieldValidate
		, ...props}) {
	let fc = useFormikContext()
	//console.log('vvvv', fc.values)

	let merged = parseInfosetValue(doc_kind, template, value)

	const errors = new Map(); //fill later, but used in validate

	merged = merged.map(({domains,values,...e})=>
		e.insert ? { domains, ...e}
			: !domains ? {...e, values}
				: !values  ? {...e, domains, values}
					: {...e, domains,
						values: domains.map((d,i)=>
							values[i]
						)
					}
	)

	if(refFieldValidate)
	refFieldValidate.current = (infoset) => {
		errors.clear()
		let merged = parseInfosetValue(doc_kind, template, infoset)

		for(const {domains, values, units, autokey} 
			of merged) {
			if(!domains) continue; // adhoc too!

			if(values===undefined) continue;
			if(values===null) continue; //skipped row

			domains.forEach(({domain},i)=>{
				if(!domain) return
				if(domain.dummy!==undefined) return
				let key = autokey+":"+i
				let value = values[i]
				//console.log(autokey,i,value,domain)
				if(!value){
					errors.set(key, 'нужно значение')
					//console.log('req',value)
					return;
				}
				value = stripUnits(value, units)
				if(domain.range &&
					!/^[0-9]*$/.test(value)
				){
					errors.set(key, 'нужно число')
					//console.log('num')
					return;
				}
				if(domain.range &&
					(domain.range.min !== undefined 
						&& domain.range.min > +value
					||
					domain.range.max !== undefined
						&& domain.range.max < +value
					)
				){
					errors.set(key, 'вне диапазона')
					//console.log('range')
					return;
				}
			})
		}
		//console.log(errors)
		return	errors.size > 0 ? 
				'Есть ошибки в заполнении сведений' 
				: undefined
	}
	refFieldValidate?.current(value)


	/* 
			L1
				L2
					p1 (!l)
					p2 (!l)
				p3 (in L1) => (l=-1)
	*/

	let templateMap = new Map()
	for(const t of merged) templateMap.set(t.autokey,t)

	const change = e => {
		const t = templateMap.get(+e.target.name)
		const col = +e.target.getAttribute('col') //FIXME: react way
		const value = e.target.value
		t.values[col] = 
				t.domains?.[col].units && value?
					value 
					+ ' ' 
					+ ru_number_take_word(value, 
						predefinedUnitsMap[t.domains[col].units]??t.domains[col].units)
				: value
			;
		onChange?.(fakeEvent(name,
					iset_from_merged(merged)
				))
		later().then(()=>fc.setFieldTouched(name)) 
	}
	const hideParam = (e) => {
		const t = templateMap.get(+e.target.name)
		t.values = null;
		onChange?.(fakeEvent(name,
					iset_from_merged(merged)
				))
		later().then(()=>fc.setFieldTouched(name)) 
	}
	const showParam = (e) => {
		const t = templateMap.get(+e.target.name)
		t.values = t.domains? make_columns(t) : [''];
		onChange?.(fakeEvent(name,
					iset_from_merged(merged)
				))
		later().then(()=>fc.setFieldTouched(name)) 
	}

	const addParam = async (e) => {
		const t = templateMap.get(+e.target.name)
		if (!t.phrases) t.phrases = [];
		const arrayPhrases = t.phrases;
		let initial = arrayPhrases.length>1?'':arrayPhrases[0];
		const vname = await prompt('Название', initial, {required:true, arrayPhrases:arrayPhrases})
		merged = merged.map(c=>
				c.autokey === +e.target.name
				? [
				{name: vname
				, domains: c.domains
				, values: 
					c.domains? make_columns(c)
					: []
				, level: c.level
				}				
				, c]
			: c
			).flat()

		console.log(merged)

		onChange?.(fakeEvent(name,
					iset_from_merged(merged)
				))
	}
	const delParam = async (e) => {
		merged = merged.filter(c=>c.autokey !== +e.target.name)
		onChange?.(fakeEvent(name,
					iset_from_merged(merged)
				))
	}

	//console.log(doc_kind, merged)

	return 	<table className={css.InfosetEditor}>
		<tbody>
			{
			merged
			.filter(e=>e.internal!==false)
			.map( ({name, paragraph, group, insert, 
					internal, domains, adhoc, subname,
					values, columns, autokey}) =>
				<React.Fragment key={autokey}>
					{insert? 
						!readOnly &&
						//show add button
						<tr>
						<td style={{maxWidth:"10em"}}>
							<button type="button" className="add"
								name={autokey}
								onClick={addParam}
							>{name}</button>
						</td>
						</tr>
					:
					paragraph 
					?//show columns
					<>
					<tr><td colSpan={100}><b>{name}</b></td></tr>
					{columns && <tr>
						{columns.map((c,i)=>
							<td key={i}><i>{c}</i></td>
						)}
					</tr>}
					</>
					: //show plain parameter
					internal ? ((v)=> v !== null &&
						<tr>
							<td style={{maxWidth:"10em"}}>{name}</td>
							<td>{v}</td>
						</tr>)((calculate_internal[name]??(()=>null)) (fc.values))
					:
					values === null || autokey === 0?
					<tr param-hidden="">
						<td style={{maxWidth:"10em"}}>{name}</td>
						<td>
						{!!autokey && !readOnly && <button type="button" className="del"
								name={autokey}
								onClick={showParam}
							>↷</button>}
						</td>
					</tr>
					:
					<tr>
						<td style={{maxWidth:"10em"}}>{name}</td>
						{
							domains && domains.map(({domain,units},i)=>
								<td key={i} units={units}>
								{
								domain?.dummy !== undefined? domain.dummy
								: domain?.strings?
									<FieldSelect className={css.DocFormCtrl} 
										name={autokey} col={i}
										value={stripUnits(values[i]??'', units)}
										onChange={change}
										infoset-error={htmlBool(errors.has(autokey+':'+i))}
										readOnly={readOnly}
									>{
										['', ...domain.strings].map(s=><option key={s} value={s}>{s}</option>)
									}
									</FieldSelect>
								: domain?.range ?
								    <IntInput className={css.DocFormCtrl}
											name={autokey} col={i}
											value={stripUnits(values[i]??'', units)}
											onChange={change}
											infoset-error={htmlBool(errors.has(autokey+':'+i))}
											readOnly={readOnly}
											range={domain.range}
									/>
								:
									<FieldInput className={css.DocFormCtrl} 
												name={autokey} col={i}
												value={stripUnits(values[i]??'', units)}
												onChange={change}
												infoset-error={htmlBool(errors.has(autokey+':'+i))}
												readOnly={readOnly}
									/>
								}
								{
									units &&
									ru_number_take_word(stripUnits(values[i], units)??0
									, predefinedUnitsMap[units]??units)
								}
								</td>
							)
						}
						{!domains //adhoc values
							&& values.map((v,i)=>
								<td key={i}>
									<FieldInput className={css.DocFormCtrl} 
												name={autokey} col={i} 
												value={v}
												onChange={change}
												readOnly={readOnly}
									/>
								</td>
							)
						}
						<td colSpan="100%">{!readOnly && !(paragraph || group) && !subname &&
							<button type="button" className="del" style={{width:"2em",margin:"0 0 0 auto"}}
								name={autokey}
								onClick={domains && !adhoc?hideParam:delParam}

							/>
							}
						</td>
					</tr>
					}
				</React.Fragment>
				)
			}
		</tbody>
		</table>
}
