import React, {useContext, useState} from 'react'
import { Formik, Form, Field } from 'formik'

import {Link} from "react-router-dom";

import {DateView} from 'azlib/components/std-controls'
import {wrapNotEmpty} from 'azlib/components/helpers'
import {fullPersonName} from 'azlib/components/name_funcs'
import {mainDbX, shardedDbX} from 'azlib/components/db'

import {OrgInfoContext} from 'org/org'

import { DbTable as Table } from 'azlib/components/super-table'

import { SearchFilter } from 'azlib/components/search_filter'

/*
			validationSchema={
				object({
				  number: string()
				  , last_name: string()
				  , first_name: string()
				  , middle_name: string()
				  , year_completion: number()
				})
			}

*/

export function IssuedSearchPage() {
	const orginfo = useContext(OrgInfoContext);
	const rds = orginfo ? 
		'search_issued_user'
		: 'search_issued_sysop';

	let [mode, setMode] = useState('number') //or fio
	let [flt, setFlt] = useState(null)
	let [instance, setInstance] = useState(0)

	const searchDbX = shardedDbX(orginfo?.tid
		|| (
			mode === 'number' && 
				flt?.our_number?.match(/^\d\d-\d-(\d)\d{4}-\d{6}-\d$/)
			? RegExp.$1
			: undefined
		)
	)

	const isStatusStampEmpty = (row) => {
		return row.status === 'issued-ok' && row.replaced === ''
	};

	return <>
		<h1>Поиск документа</h1>
		<Formik initialValues={
			{
				number: ''
				, last_name: ''
				, first_name: ''
				, middle_name: ''
				, year_completion: (new Date()).getFullYear()
				, ed_level: ''
				, spec_code: ''
			}}
			onSubmit={async v=>{
				let flt = {}
				if(orginfo)
					flt.tid = orginfo.tid
				if(mode === 'number') {
					if(v.number.match(/^\s*(\d\d-\d-(\d{5})-\d{6}-\d)\s*$/)) {
						flt.our_number = RegExp.$1
					} else
					if(v.number.trim()) {
						flt.docnumber = v.number.trim()
					} else {
						flt.our_number = '-';
					}
				}
				if(mode === 'fio') {
					flt.last_name = v.last_name || '-';
					flt.first_name = v.first_name || '-';
					flt.middle_name = v.middle_name || null;
					flt.year_completion = v.year_completion || (new Date()).getFullYear();
				}
				setInstance(i=>i+1);
				setFlt(flt);
			}}
		>{
		<Form className="searchForm flexContainerColumn"
			style={{
				display: "flex",
				margin: "0 2em",
				flexWrap:" wrap"
			}}
		>
			<div>
				<p>Поиск по реестровому номеру документа</p>
	            <SearchFilter
	              input={
					<Field name="number" placeholder={"Поиск по реестровому номеру документа"}
						className="FilterInput"/>
	              }
	              buttonProps={{ type:"submit"
	              	 , onClick: ()=>setMode('number')
	              	}}
	            />
			</div>
			<div className="backgroundRounded" style={{padding:"1em"}}>
				<span><b>Поиск по реквизитам обучающегося</b></span>
				<div className="flexContainer">
						<div className="flexColumn">
							<p>Фамилия</p>
							<Field name="last_name" />
						</div>
					<div className="flexColumn">
								<p>Отчество (при наличии)</p>
								<Field name="middle_name" />
				</div>
					<div className="flexColumn">
							<p>Имя</p>
							<Field name="first_name" />
			</div>
					<div className="flexColumn">
							<p>Год выпуска</p>
							<Field name="year_completion" />
		</div>
			</div>
				<button type="submit" onClick={()=>setMode('fio')}
				style={{marginTop:"1em"}}>
					Найти
				</button>
		</div>
		</Form>
		}</Formik>



		{flt && <div>
			<Table className="simpleTable" key={instance}
				store={searchDbX.store(rds, flt )}
			>
			<Table.Col label="ФИО" name="docid"
				extra="last_name first_name middle_name bdate tid"
			>
				{(value,row) =>
					<Link to={`/org/${row.tid}/issued/${value}`} >
					{fullPersonName(row) || '--???--'}
					</Link>
				}
			</Table.Col>
			<Table.ModelCol name="doctype_name">{
				(View, v, row) =>
					<div>
						<Link to={`/org/${row.tid}/issued/${row.docid}`} >
						{View}
						</Link>
					</div>
			}
			</Table.ModelCol>

			<Table.ModelCol name="our_number" />

			<Table.ModelCol name="status" />

			<Table.Col name="status_stamp"
				extra="status_message replaced{our_number} replaced_with{our_number}"
				ifDistinct={[isStatusStampEmpty, true]}
				>{(stamp, row) =><>
					{(row.status === 'issued-rejected'
					||
					row.status === 'issued-replaced')
					&& <div><DateView value={stamp.substr(0,10)} /></div> //trim to time!
					}
					{
					wrapNotEmpty(row.replaced$?.our_number,
						v=><div>`Взамен: ${v}`</div>)
					}
					{
					wrapNotEmpty(row.replaced_with$?.our_number,
					  v=><div>{`Заменен на: ${v}`}</div>)
					}
				</>}
			</Table.Col>

			<Table.Empty>Документ не найден</Table.Empty>

			</Table>
		</div>}
	</>
}