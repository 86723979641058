import React, {useContext} from 'react';

import {htmlBool} from './helpers' 
import {Hidden} from './ui_elems' 
import {confirm as confirmDlg, showModalProcessing} from './modals' 

import {useRowContext
	, SuperTableContext
	, ops as ops_st
	, default as SuperTable
} from './super-table-int'

import {
	OperationCol
	, OperationChecks, SelectionMode, selectionMode
	, ModelCol
} from './super-table-std-cols'

import { handleSaveChangedLocal, handleSaveChangedDirect } from './formik-fields'


import css from './super-table.module.css'


/**
 * 
 * 
 * <SuperTable>
 * 	<SuperTable.Before/>
 * 	<SuperTable.Col/>
 * 	<SuperTable.Col/>
 * 	<SuperTable.Col>
 * 		<SuperTable.Col/>
 * 		<SuperTable.Col/>
 *  </SuperTable.Col>
 *  <SuperTable.After/>
 * </SuperTable.SuperTable> 
 * 
 * 
 */

export function FormSwitch({trigger, children, global, ...props}) {
	const ctx = useRowContext();
	return React.cloneElement(trigger, 
			{...props,
				onClick: () => {
					ctx.toggleForm(children, global)
				}
			}, 
			ctx.key===null?<Hidden>{children}</Hidden>:null) //key is null when collecting
}

function FormSwitchButton({onClick,...props}) {
	const ctx = useRowContext();
	return <button type="button"
			{...props} onClick={onClick} 
			open={
				(ctx.globalForm === null || ctx.globalForm === ctx.stringKey)
				&& !!ctx.form} className={css.FormSwitchButton} />;	
}

FormSwitch.Button = FormSwitchButton;

export const TableShape = {
	container: <table />
	, header: <thead />
	, body: <tbody />
	, hrow: <tr />
	, row: <tr />
	, rowProps: ()=>{}
	, th: <th />
	, td: <td />
	, empty: React.createElement(
						({children})=><tr className={css.SuperTableEmpty}><td colSpan={1000}>{children}</td></tr>
					)
	, form: React.createElement(
						({children})=><tr className={css.SuperTableForm}><td colSpan={1000}>{children}</td></tr>
					)
}

export {useRowContext} from './super-table-int';
export {default as SuperTable} from './super-table-int';


export function Add({command,small,readOnly,className,...props}) {
	const ops = useContext(SuperTableContext)
	return !readOnly &&
			<button type="button" 
				className={`${className} add ${small?'small':''}`} 
				onClick={()=>command(ops)}
				{...props}
			/>
				
}

export function Fetch({command,readOnly,...props}) {
	const ops = useContext(SuperTableContext)
	return !readOnly &&
		<button type="button"
				onClick={()=>command(ops)}
				{...props}
		/>

}

export function Del({command, confirm,small, readOnly, ...props}) {
	const ctx = useRowContext();
	const ops = ctx.tableContext;
	return <button type="button" disabled={readOnly} className={`del ${small?'small':''}`} onClick={async ()=>{
					let cf = true;
					if(confirm)  
						cf = await confirmDlg(confirm === true? 'Удалить?'
									: confirm instanceof Function
										? confirm(ctx.row)
										: confirm
									)
					if(cf) 
						if(command) command(ctx.row,ops)
						else ops.remove(ctx.row)

				}}
				{...props}
			/>
				
}

export function ForSelection({command,readOnly,...props}) {
	const tableContext = useContext(SuperTableContext)
	return !readOnly 
		&& !!tableContext?.sharedState?.multiRowOp?.selected.size
		&&
		<button type="button" {...props} 
			onClick={async ()=>{
				let mrs = tableContext.sharedState.multiRowOp
					console.log(mrs)
				try{
				let arr = Array.from(mrs.selected.entries())
									.map(([k,f])=>[JSON.parse(k),f])
				if(mrs.op.UI)
					await showModalProcessing(mrs.op.operation(arr, tableContext), mrs.op.UI)
				else
					await (command??mrs.op)(arr, tableContext)
				} 
				finally {
					//selectionMode(tableContext, null)
				}
			}}
		/>
}


//<tr modified={} />

export const TableBoldShape = {
	...TableShape
	, rowProps: (row,status)=>({modified: htmlBool(status?.modified)})
}


export function DbTable(props) {
	return <SuperTable shape={TableBoldShape} {...props} />
}

export function DbTableEdit(props) {
	return <SuperTable shape={TableBoldShape} handleSave={handleSaveChangedLocal} {...props} />
}

DbTableEdit.localSave = handleSaveChangedLocal
DbTableEdit.directSave = handleSaveChangedDirect

const ops = { ...ops_st
	, OperationCol, OperationChecks, ForSelection, SelectionMode
	, ModelCol, Fetch
	, FormSwitch, Add, Del 
}

Object.assign(SuperTable, ops)
Object.assign(DbTable, ops)
Object.assign(DbTableEdit, ops)

//TODO
// 3. accumulate-labes (take label from controls in column)
// 4. filter-assigned (inline filter on col!)