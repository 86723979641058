import {isPlainObject} from './helpers'

const Value = Symbol('value-for-key') 
const Boxed = Symbol('packed-value') 

function compare_keys(obj, key)
{
	for(const i in key)
		if(i!==Value)
			if(obj[i] !== key[i]) return false;
	return true;
}

/**
 * to array/object to assgin value
 * this function deep traverse <to>-object
 * and compare properies
 * if property in <from>
 *  - is absent in <to>, it's added
 *  - present in <to>, but undefiend in <from>, it's deleted
 *  - present in both => deeply replace 
 * 
 * if <to> is an array
 * from should be in the form:
 *    { field-name: field-value ...., [Value]: value}
 * this object compared with each array element
 * (each property compared with corresponding in array)
 * and, if matched (equal), deep assign Value to array element
 * 
 * assigning to primitive values simply returns <from>
 * 
 * 
*/
export function deep_assign(to, from) {
	//console.log('DA', to, from, '/DA')
	if(Boxed in Object(from)) return from[Boxed];
	if(Array.isArray(to)) {
		if(!(Value in Object(from)))
			return from; //replace whole array
		let v = from[Value]
		let found = false;
		let ret = to.map(a=>
			compare_keys(a, from) ? (found=true, v === undefined? v : deep_assign(a, v)) : a
		).filter(a=>a!==undefined);//handle delete
		if(found) return ret;
		//append
		if(v!==undefined)
			return [...ret, v];
		return to; //try delete already missing key
	}
	// and we have object to assign to
	if(isPlainObject(to)) {
		let ret = Object.assign({}, to) //clone
		for(const i in from) {
			if(from[i]===undefined)
				delete ret[i];
			else
				ret[i] = deep_assign(to[i], from[i])
		}
		return ret;
	}
	// it's not an array and not an object case
	return from;
}

deep_assign.Val = Value;
deep_assign.Box = Boxed;

export function Keyed(key,value) {
	//console.log('KEY', key, value)
	return {...key, [Value]: value === undefined? value : {...key, ...value} }
}

export function Box(value) {
	return {[Boxed] : value}
}

export function swap_array_elements(index,swap,array) {
	return array.map( (s,i,a) =>
				i===index? a[swap] :
				i===swap? a[index] :
				s
			)
}

export function set_array_element(array, index, elem) {
	return index === null? [...array, elem]
				: elem === undefined? array.filter((e,i)=> i !== index)
				: array.map((e,i)=> i === index? elem : e)
}
