import React from 'react';

import {fullPersonName} from 'azlib/components/name_funcs'
import {prompt, ModalButton} from 'azlib/components/modals'

import { DbTableEdit as Table } from 'azlib/components/super-table'

import {mainDbX} from 'azlib/components/db'
	// all users are in MAIN db initially

import {PopupLog} from 'contingent/log'
import {
	Field,
	modalFormik,
	formikExtra,
	ExtendFormikContext
} from 'azlib/components/formik-fields'
import {ErrorMessage} from "formik";
import {object, string} from "yup";

function setSysrole(){
	return modalFormik({
		initialValues: {login:''}
		, initialStatus:{}
		, validationSchema:
			object({
				login:string()
					.required('Обязательное поле')
					.matches(/^\/(.+)|^\d\d\d-\d\d\d-\d\d\d \d\d$/, "Формат: 111-111-111 11")
			})
		}
		,
		<ExtendFormikContext value={{[formikExtra]: { storeWithFields: {store:'persons'} }}}>
			<div style={{marginBottom:"1em"}}>СНИЛС пользователя</div>
			<Field name="login" style={{width:"100%"}} />
			<p className="error" ><ErrorMessage name="login"/></p>
			<div className="flexContainer" style={{marginTop:"1em"}}>
				<button type="submit" className="modalButton">Добавить</button>
				<ModalButton>Отмена</ModalButton>
			</div>
		</ExtendFormikContext>
	)
}

export function SysUsers({sysrole, dropRole, title}) {
	let btnRole;
	if (sysrole === "admin") btnRole="админа"
	else btnRole="оператора";
	return <>
	<div style={{float:"right", margin:"1em 1em 0 0"}}>
		<PopupLog table="persons"
			transform={diff_transform}
			role={sysrole} />
	</div>
	  <h1>{title}</h1>

	  <Table key={sysrole}
			className="simpleTable"
			store={mainDbX.store("persons", { sysrole } )}
	  >
	  	<Table.Col label="Фамилия Имя Отчество" colKey="@fio"
	  		extra="login first_name last_name middle_name"
	  	>{(_,row)=>fullPersonName(row)
	  	}</Table.Col>
		 <Table.Col label="СНИЛС" name="login"/>
	  	<Table.Col name="person" className="del-paddings-del-button">
			{window.UserInfo.userAreas.admin &&
	  		<Table.Del confirm command={
	  			async (row,ops) => {
	  				await mainDbX.call_function('set_sysrole'
	  					, {login: row.login, sysrole:dropRole})
	  				ops.remove([row.person])
					window.document.location.reload()
	  			}
	  		} />
	  		}
	  	</Table.Col>
	  <Table.After>
	    {window.UserInfo.userAreas.admin &&
	  	<Table.Add command={async (ops)=>{
	  		let vals = await setSysrole()
			let login = vals.login
			if(login[0] === '/') login = login.substr(1)
	  		let person = await mainDbX.call_function('set_sysrole'
	  			, {login, sysrole})
			ops.add_to_list([person])
		}}>Добавить {btnRole}</Table.Add>
	  }
	  </Table.After>
	  </Table>
	</>
} 

const diff_transform = {
	person: null
	, contact_email: null
	, contact_tel: null
	, contact_info: null
	, ext_filled: null
}