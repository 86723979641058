import React from 'react'

let XLSX = require("xlsx");

function parseInfoset(data, row, row_index, index, array, header, current_header, mark){
    if (row[1] && row[2] === 0 && (row[1].th)) {
        header[row[2]].col.add((row[1].columns && row[1].columns !== '') ? row[0] + " [ " + row[1].columns.join(" | ") + " ]" : row[0]);
        return;
    }
    if (row[1] && row[2] === 0 && (row[1].group)) {
        if(data[row_index][current_header.join("//")] !== undefined) row[0]=row[0]+" "  //FiXME the uniqueness of the names of repeating columns at the same level is ensured by adding spaces to the end
        header[row[2]].col.add(row[0])
        data[row_index][current_header.join("//")] = row[1].columns ? row[1].columns.join(" | ") : ""
        if(array[index+1][2]>row[2] && data[row_index][current_header.join("//")]!=="") array[index+1][0] = "+" + array[index+1][0] //----------------
        return;
    }
    if (row[1] && row[2] !== 0 && row[1].group) {
        if(data[row_index][current_header.join("//")] !== undefined) row[0]=row[0]+" "   //FiXME
        header[row[2]][current_header[row[2] - 1]].add(row[0])
        data[row_index][current_header.join("//")] = row[1].columns ? row[1].columns.join(" | ") : ""
        if(array[index+1][2]>row[2] && data[row_index][current_header.join("//")]!=="") array[index+1][0] = "+" + array[index+1][0] //--------------------
        return;
    }
    if (row[2] === 0) {
        if(data[row_index][row[0]] !== undefined) row[0]=row[0]+" "   //FiXME
        header[row[2]].colWithoutChild.add(row[0])
        data[row_index][row[0]] = row[1]
        return;
    }
    if (row[2] !== 0 && row[1] === "") {
        if(data[row_index][current_header.join("//")+"//" + "-"+row[0]] !== undefined) row[0]=row[0]+" "   //FiXME
        header[row[2]][current_header.join("//")].add("-"+row[0])
        data[row_index][current_header.join("//") + "//" + "-"+row[0]] = row[0]
        return;
    }
    if (row[1] && row[2] !== 0 && (row[1].th )) {
        if(data[row_index][current_header.join("//")+"//"+row[0]] !== undefined) row[0]=row[0]+" "    //FiXME
        header[row[2]][current_header[row[2] - 1]].add(row[0])
        data[row_index][current_header.join("//")+"//"+row[0]] = row[1].columns ? row[1].columns.join(" | ") : ""
        return;
    }
    if (row[2] !== 0) {
        if(data[row_index][current_header.join("//")+"//"+row[0]] !== undefined) row[0]=row[0]+" "   //FiXME
        header[row[2]][current_header.join("//")].add(row[0])
        if (row[1] && row[1].length > 0) data[row_index][current_header.join("//")+"//"+row[0]] = Array.isArray(row[1]) ? row[1].join(" | ") : row[1];
        if (row[1] && (row[1].length === 0) && mark==="th") data[row_index][current_header.join("//")+"//"+row[0]] = "+";
        return;
    }
}
function exportScheme(values, constHeader){
    let exportSchemeDef = {
        'ФАМИЛИЯ': values.last_name
        , 'ИМЯ': values.first_name
        , 'ОТЧЕСТВО': values.middle_name
        , 'ДАТА РОЖДЕНИЯ':values.bdate.split("-").reverse().join(".")
        , 'РЕЕСТРОВЫЙ НОМЕР': values.our_number
        , 'ГОД ОКОНЧАНИЯ':values.year_completion
        , 'СПЕЦИАЛЬНОСТЬ': values.spec_code
        , 'КВАЛИФИКАЦИЯ': values.qualification
        , 'ПОЛ': ({'1':'М','0':'Ж'})[JSON.parse(values.additional_info||"{}").gender]
        , 'СНИЛС': values.snils
        , 'НА ЕПГУ': ({true:'да'})[values.epgu]
        , 'ГРАЖДАНСТВО': JSON.parse(values.additional_info||"{}").country
        , 'ВИД ДОКУМЕНТА':({
            '-ok-good': 'С ОТЛИЧИЕМ'
            , '-ok': ''
            , '-0': 'СВИДЕТЕЛЬСТВО'
            , '-fail': 'СПРАВКА'
        })[values.doctype_kind.match(/-(.)+/g)]
        , 'НА БЛАНКЕ': ({true:'да'})[JSON.parse(values.additional_info||"{}").paper]
        , 'ОСНОВАНИЕ ВЫДАЧИ ДОКУМЕНТА': JSON.parse(values.additional_info||"{}").doc_reason
        , 'ОСНОВАНИЕ ОБУЧЕНИЯ':JSON.parse(values.additional_info||"{}").edication_reason
        , 'НОМЕР ЗАМЕНЯЕМОГО ДОКУМЕНТА':values.replaced
        , 'ПРИЧИНА':values.status_message
        , 'ГРУППИРОВАНИЕ':values.name_group
        , 'РЕГИСТРАЦИОННЫЙ НОМЕР': values.regnumber
    }
    Object.keys(exportSchemeDef).forEach((k) => (exportSchemeDef[k] === "" || exportSchemeDef[k] === undefined) && delete exportSchemeDef[k] || constHeader.add(k));
    return exportSchemeDef
}
export function exportInfosets(values){
    let current_lv=0;
    let current_header=[];
    let header = []
    let data =[]
    let mark;
    let constHeader = new Set()
    let signerRoles = new Set();
    values.forEach((rows, row_index)=>{
        data[row_index] = {}
        data[row_index] = exportScheme(rows, constHeader)
        if (rows.infoset !== "") {
            let infoset = JSON.parse(rows.infoset);
            Object.keys(rows).forEach(key => {
                if (key === "infoset") infoset.forEach((row, index, array) => {
                    if (typeof (header[row[2]]) == 'undefined') header[row[2]] = {}
                    if (current_lv >= row[2]) {
                        for (let i = 0; i < current_lv - row[2] + 1; i++) current_header.pop();
                        current_lv = row[2] - 1;
                        mark = "";
                    }
                    if (row[1] && (row[1].th || row[1].group)) {
                        if (row[1].th) {
                            mark = "th";
                            current_header.push((row[1].columns && row[1].columns !== '') ? row[0] + " [ " + row[1].columns.join(" | ") + " ]" : row[0])
                        } else {
                            mark = "group";
                            current_header.push(row[0]);
                        }
                        current_lv = row[2];
                    }
                    if (row[2] === 0 && typeof (header[row[2]].col) == 'undefined') {
                        header[row[2]].col = new Set()
                        header[row[2]].colWithoutChild = new Set()
                    }
                    if (row[2] === 1 && typeof (header[row[2]][current_header[row[2] - 1]]) == 'undefined') {
                        header[row[2]][current_header[row[2] - 1]] = new Set();
                    }
                    if (row[2] > 1 && typeof (header[row[2]][current_header.join("//")]) == 'undefined') {
                        header[row[2]][current_header.join("//")] = new Set();
                    }
                    parseInfoset(data, row, row_index, index, array, header, current_header, mark)
                })
                if (key === "signers" && rows.signers !== ""){
                    let signers = JSON.parse(rows.signers);
                    signers.forEach((row) => {
                        if (row.r !== undefined) {
                            signerRoles.add(row.r);
                            data[row_index]["Подписывающий//" + row.r] = row.i[1] + " " + row.i[2] + " " + row.i[3];
                        }
                    })
                }
            });
        }
    })
    header[0].col.add("Подписывающий")
    header[1]["Подписывающий"] = signerRoles;
    header = mergeHeaders(header)
    let headerJson = []
    let head = makeHeaders(header,headerJson)
    createXlsx(headerJson, head, data, constHeader)
}
function makeHeaders(header, headerJson) {
    let head = []
    for (let i = 0; i < header.length; ++i) head[i] = []
    header.forEach((rows, index)=>{
        Object.values(rows).forEach(value => {
            value.forEach(val=>{
                val.forEach(el=>{
                    if(index === 0) makeHeaderJson(el, el, header, 1, headerJson, head, [])
                })
            })
        })
    })
    return head
}
function makeHeaderJson(element, key, header, index, headerJson, head, path) {
    let len = 0;
    if (typeof(header[index]) != 'undefined' && Object.keys(header[index]).find(k => k === key)){
        len = header[index][key].length-1
        header[index][key].forEach((val, key)=>{
            path = path.slice(0, index-1)
            path.push(element)
            val.forEach(value=> {
                len = len + makeHeaderJson(value,path.join("//")+"//"+value, header, index+1, headerJson, head, path, element)
            })
            insertHeaders(element, header, index, head, len)
        })
        return len
    }
    else {
        insertHeaders(element, header, index, head, 1)
        for (let i = index; i < header.length; i++) head[i].push("")
        if(element[0]==='+') headerJson.push(path.join("//"))
        else {
            path.push(element)
            headerJson.push(key)
            path.pop()
        }
        return 1
    }
}
function insertHeaders(element, header, index, head, len){
    for (let i = 0; i < len; i++) {
        if (element[0] !== '-') head[index-1].push(element)
        else head[index-1].push("-")
    }
}
function mergeHeaders(header) {
    header.forEach((rows,index)=>{
        Object.keys(rows).forEach(key => {
            rows[key]= new Array(rows[key])
        })
        if (index===0) {
            rows.col = [].concat(rows.colWithoutChild, rows.col)
            delete rows.colWithoutChild;
        }
    })
    return header
}
function mergeColumns(worksheet, head, constLength) {
    let end
    let count = 0
    if(!worksheet["!merges"]) worksheet["!merges"] = [];
    head.forEach((rows, rowIndex) => {
        rows.forEach((element, index, row) => {
            if (element !== "" && element === row[index+1]) {
                end = index+1
                count += 1
            }
            else {
                if(count)
                    worksheet["!merges"].push({
                        s: { r: rowIndex, c: constLength+end-count },
                        e: { r: rowIndex, c: constLength+end }
                    });
                count = 0
            }
        })
    })
}
function createXlsx(headerJson, head, data, constHeader){
    let workbook = XLSX.utils.book_new();
    constHeader = [...constHeader]
    let header = constHeader.concat(headerJson)
    let constLength = constHeader.length
    let worksheet = XLSX.utils.aoa_to_sheet(new Array(constHeader),{header: header, skipHeader: true})
    XLSX.utils.sheet_add_aoa(worksheet, head,{origin: {r:0, c:constLength}})
    mergeColumns(worksheet, head, constLength)
    XLSX.utils.sheet_add_json(worksheet, data,{header: header, origin: {r:3, c:0}, skipHeader: true})
    worksheet["!cols"] = []
    worksheet["!rows"] = []
    for(let i = 0; i < header.length; i++) worksheet["!cols"].push({ wch: 20 })
    for(let i = 0; i < data.length+3; i++) worksheet["!rows"].push({ hpt: 40 })
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "docs.xlsx")
}