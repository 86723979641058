import {later} from 'azlib/components/helpers'
import {alert, showModalProcessing} from 'azlib/components/modals'
import {mainDbX} from 'azlib/components/db'

const ed_levels = {
	"mn": /основное общее/i
	, "m": /среднее общее/i
	, "mp": /среднее профессиональное/i
	, "hb": /бакалавр/i
	, "hs": /специалитет/i
	, "hm": /магистр/i
	, "hha": /аспирант/i
	, "hhd": /адъюнкт/i
	, "hho": /ординат/i
	, "hhs": /ассистент/i
	, "ap": /дополнительное профессиональное/i
	, "p": /профессиональное обучение/i
};

function getDomTable(text, selector, attr) {
	let found;
    let p = new DOMParser()

   	p = p.parseFromString(text, 'text/html')
   	const obj = p.querySelector(selector)

   	if(!obj) return [];

    let ret = [] 
    for(const i of obj.getElementsByTagName('tbody')[0]
            				.getElementsByTagName('tr'))
    {
    	let r = []
   		r.push(attr?i.getAttribute(attr):'')
    	for(const j of i.getElementsByTagName('th'))
    		r.push(j.textContent.trim())
    	for(const j of i.getElementsByTagName('td'))
    		r.push(j.textContent.trim())
    	ret.push(r)
    }
    return ret;
}

function parseSpecRows(text) {
    let p = new DOMParser()

   	p = p.parseFromString(text, 'text/html')

   	// tables with headers
   	let tables = p.querySelectorAll('TABLE > THEAD > TR > TH:first-child');
   	let spec_rows_tables = []
   	for(const i of tables)
   		if(i.textContent.trim().toUpperCase()==="№ п/п".toUpperCase())
   			spec_rows_tables.push(i.parentNode.parentNode.parentNode) // take <table>

   	let ret = []
   	for(const i of spec_rows_tables) {
   		for(const j of i.querySelectorAll('TBODY > TR')) {
   			if(j.firstElementChild.textContent.match(/^\s*\d+\s*$/)) {
   				ret.push([ null // id
   					, j.children[0].textContent //npp
   					, j.children[1]?.textContent || ''  // spec code or level for scholls
   					, j.children[2]?.textContent || ''  // spec name
   					, j.children[3]?.textContent || ''  // level
   					, j.children[4]?.textContent || ''  // qual
   					])
   			}
   		}
   	}
	//Профессиональное обучение
	for(const i of p.querySelectorAll('TABLE > TBODY > TR > TH:first-child')) {
			if (i.textContent.match(/профессиональное обучение/i)) {
				ret.push([null // id
					, '' //npp
					, i.textContent  // spec code or level for scholls
					, ''  // spec name
					, ''  // level
					, ''  // qual
				])
		}
	}
   	return ret
} 

export async function parse_islod(ogrn) {
    let ret = { details: {}, merged: {}}

	const egrul = await fetchEGRUL(ogrn)
	if(!egrul) throw 'not an org';

	ret.details = {
		gov_regnum: ogrn
		, name: egrul.n.charAt(0).toUpperCase() + egrul.n.slice(1)
		, sname: egrul.c ?? ''
		, fin_regnum: egrul.i
		, fin_regnum2: egrul.p ?? ''
		, app_boss_fio: getBOSS(egrul.g).length === 1 ? (getBOSS(egrul.g)[0].split(':')[1]||'').trim() : ''
		, app_boss_position: getBOSS(egrul.g).length === 1 ? getBOSS(egrul.g)[0].split(':')[0].trim() : ''
		, app_all_bosses: getBOSS(egrul.g).length > 1 ? jsonBOSS(getBOSS(egrul.g)) : ''
	}
	ret.info = {}

	return await showModalProcessing(async (hide,updateProgress,getProgress) => {
		updateProgress('Лицензии: старт')
		let r = await mainDbX.fetch_post('/rlic/search/?page=1'
						, {
						eoOgrn: ogrn
						, licenseStateId: 1 // maybe, we sholuld filter
						}
					)
		if(!r.ok) throw r.status + ':' + await r.text();
		r = await r.text()
		await later(500);

	  let b = []
		for(const i of getDomTable(r,'table.search-result','DATA-GUID')) {
	      b.push({
	        id: i[0]
	        , name: i[1]
	        , regnumber: i[2]
	        , status: i[3]
	        , eol: i[4]
	      })
	    }
	    for(const e of b) {
			updateProgress(`Лицензии: читаем: ${e.name}`)
	    e.name =
					e.name.replace(/^\s*Индивидуальный\s+предприниматель\s*/i,'').replace(/\s+/g, ' ')
	    	let r = await mainDbX.fetch_get(`/rlic/details/${e.id}/`)
			if(!r.ok) {
				e.error = r.status + ':' + await r.text();
			} else {
				r = await r.text()
				await later(500);
				// common info
				let p = {}
				for(const i of getDomTable(r,'table'))
					p[ i[1] ] = i[2];
				const status = p['Текущий статус лицензии']
				if(status !== "Действует") continue;
				e.details = {
					status
					, gov_regnum: p['ОГРН']
					, name: p['Полное наименование организации (ФИО индивидуального предпринимателя)']
					, sname: p['Сокращенное наименование организации']
					, fin_regnum: p['ИНН']
					, fin_regnum2: p['КПП']
				}

				e.details.name =
					e.details.name.replace(/^\s*Индивидуальный\s+предприниматель\s*/i,'') 
				e.details.sname =
					e.details.sname.replace(/^\s*Индивидуальный\s+предприниматель\s*/i,'')
				e.details.name =
					e.details.name.charAt(0).toUpperCase() + e.details.name.slice(1)
				e.info = [];

				for(const i of getDomTable(r,'table.table-filled','data-target')) { //second table - supplements
					if(i[2] !== 'Действует') continue;
					let item = {name:i[1].charAt(0).toUpperCase() + i[1].slice(1), sp: {} }
					item.name = item.name.replace(/^\s*Индивидуальный\s+предприниматель\s*/i,'').replace(/\s+/g, ' ')
					if(item.name === e.name) {
						item.head = true; //head org
					}
					console.log(item.name, e.name)
					updateProgress(`Лицензии: читаем: ${i[1]}`)
					let r = await mainDbX.fetch_get(`/rlic/supplement/${i[0]}/`)
					if(!r.ok) {
						item.error = r.status + ':' + await r.text();
					} else {
						r = await r.text()
						await later(500);
						// first table - org/branch info
						let p = {}

						for(const i of getDomTable(r,'table'))
							p[ i[1] ] = i[2];
						item.sname = p['Сокращенное наименование'];
						item.sname = item.sname.replace(/^\s*Индивидуальный\s+предприниматель\s*/i,'') 

						let spec_rows = parseSpecRows(r)
						for(const i of spec_rows) {
							let sp = {
								spec_code: i[2]
								, spec_name: i[3]
								, level: i[4]
								, qual: i[5]
							}
							console.log(sp);
							let ed = null;
							if(sp.spec_code.match(/^\d\d[.](\d\d)[.]\d\d$/)) {
								//  есть нормальный код специальности!
								ed = {
									'00': '' /// ??? what is it
									, '01': 'mp'
									, '02': 'mp'
									, '03': 'hb'
									, '04': 'hm'
									, '05': 'hs'
									, '06': 'hha'
									, '07': 'hhd'
									, '08': 'hho'
									, '09': 'hhs'
								}[ RegExp.$1 ];
								if(!ed) {
									console.log(`!!!!!!!!!!!!!!!!!-----${sp.spec_code}------`);
								}
							}
							else if(sp.spec_code.match(/^\d+[.]\d+[.]\d+$/)) {
								// ваковский код специальности, кажется, это всегда аспирантура?
								ed = 'hha'
							} else {
								// возможно, работает для школ
								for(const l in ed_levels)
									if(sp.spec_code.match(ed_levels[l])) {
										ed = l;
										sp.spec_code = '';
										sp.spec_name = '';
										sp.qual = '';
										break;
									}
							}
							if(ed) {
								//console.log(ed,item.sp)
								(item.sp[ed] ?? (item.sp[ed] = {}))
									[sp.spec_code] = [ sp.spec_name, sp.qual ];
							}							
						}
						e.info.push(item)
					}
					let merged = {}
					console.log(e.info)
					for(const i of e.info) {
						for(const ed in i.sp) {
							merged[ed] = { ...merged[ed], ...i.sp[ed]}
						}
					}
					e.merged = merged;
				}
			}
	    }
	    // собираем, если более одной лицензии
	    for(const e of b) {
	    	ret.details = {...e.details, ...ret.details}
				for(const ed in e.merged) {
					ret.merged[ed] = { ...ret.merged[ed], ...e.merged[ed]}
				}
				for(const i of e.info) {
					ret.info[i.name] = i; //FIXME: этот год заменяет филиал таким же 
				}
	    }
		updateProgress(`Лицензии: сделано`)
		await later(500)

		//---------- accred ------------------

		updateProgress('Аккредитации: старт')
        r = await mainDbX.fetch_post('/accredreestr/search/?page=1'
          , {searchby: 'organization'
            , eduOrgOgrn: ogrn
            }
          )
		if(!r.ok) throw r.status + ':' + await r.text();
		r = await r.text()
		await later(500);

	    b = []
	    //console.log(r)
		for(const i of getDomTable(r,'table.search-result','data-id')) {
		  if(i[7] !== 'Действующее') continue
	      b.push({
	        id: i[0]
	        , name: i[2]
	        , regnumber: i[4]
	        , status: i[7]
	        , eol: i[6]
	      })
	    }
	    for(const e of b) {
			updateProgress(`Аккредитации: читаем: ${e.name}`)
	    	let r = await mainDbX.fetch_get(`/accredreestr/details/${e.id}/1/`)
			if(!r.ok) {
				e.error = r.status + ':' + await r.text();
			} else {
				r = await r.text()
				await later(500);
				let p = {}
				for(const i of getDomTable(r,'table'))
					p[ i[1] ] = i[2];
				const status = p['Текущий статус свидетельства']
				if(status !== "Действующее") continue;
				e.details = {
					status
					, gov_regnum: p['ОГРН']
				}

				e.info = [];

				//if(!$node->Supplements || !$node->Supplements->Supplement) {
					// это странные школы без приложений
				//}

				for(const i of getDomTable(r,'table.table-filled:not(.hidden)','data-target')) { //second table - supplements
					if(!i[0]) continue
					if(i[4] !== 'Действующее') continue;
					let item = {name:i[2], sp: {} } 
					updateProgress(`Аккредитации: читаем: ${i[2]}`)
					let r = await mainDbX.fetch_get(`/accredreestr/supplement/${i[0]}/`)
					if(!r.ok) {
						item.error = r.status + ':' + await r.text();
					} else {
						r = await r.text()
						await later(500);

						//console.log(r);

						for(const i of getDomTable(r,'[data-supplement-id]')) { //FIXME: find by selector or check attribute
							let sp = {
								spec_code: i[3]
								, spec_name: i[4]
								, level: i[5]
								, ugs: i[1]
							}
							if(sp.spec_code === '-' || sp.spec_code === '--')
								sp.spec_code = ''

							let ed = null;
							if(sp.spec_code.match(/^\d\d[.](\d\d)[.]\d\d$/)) {
								//  есть нормальный код специальности!
								ed = {
									'00': '' /// ??? what is it
									, '01': 'mp'
									, '02': 'mp'
									, '03': 'hb'
									, '04': 'hm'
									, '05': 'hs'
									, '06': 'hha'
									, '07': 'hhd'
									, '08': 'hho'
									, '09': 'hhs'
								}[ RegExp.$1 ];
								if(!ed) {
									console.log(`!!!!!!!!!!!!!!!!!-----${sp.spec_code}------`);
								}
							}
							if(!sp.spec_code && sp.ugs) {
								// не нашли КОД, но есть УГС
								// вероятно, это такое глючное СПО
								sp.spec_code = sp.ugs.substr(0,2); //проверять будем по лицензии
								ed = 'mp';
							}
							if(!ed) {
								// сюда мы попадем со школой
								for(const l in ed_levels)
									if(l[0] === 'm'
										&& sp.level.match(ed_levels[l])) {
										ed = l;
										break;
									}
							}
							if(ed) {
								(item.sp[ed] ?? (item.sp[ed] = {}))
									[sp.spec_code] = true;
							}
						}
						e.info.push(item)
					}
					let merged = {}
					for(const i of e.info) {
						for(const ed in i.sp) {
							merged[ed] = { ...merged[ed], ...i.sp[ed]}
						}
					}
					e.merged = merged;
				}
			}
	    }
	    // собираем, если более одной аккредитации
	    let acc = {}
	    for(const e of b) {
				for(const ed in e.merged) {
					acc[ed] = { ...acc[ed], ...e.merged[ed]}
				}
	    }
		updateProgress(`Аккредитации: сделано`)
		await later(500)
		//проставляем аккредатации в лицезнии

		for(const ed in acc)
			for(const sp in acc[ed]) {
				if(ret.merged[ed]?.[sp]) {
					ret.merged[ed][sp][2] = '+';
				} else if(sp.length===2) {//UGS
					if(ret.merged[ed])
					for(const i in ret.merged[ed])
						if(i.startsWith(sp))
							ret.merged[ed][i][2] = '+';
				}
			}

			// перенесем аккредитации в филиалы. это не верно, но сойдет

			for(const f in ret.info)
				for(const ed in ret.info[f].sp)
					for(const i in ret.info[f].sp[ed])
						ret.info[f].sp[ed][i][2] = ret.merged[ed][i][2];
	    return ret
	}
	,
	({progress}) =>
	<div>
		{progress}
	</div>
	)
}

async function fetchEGRUL(ogrn) {
	const max = 5;
	return showModalProcessing(
		async (hide, updateProgress, getProgress)=>{
			let current = 0; 
			const upd_cnt = p=>({...p,cnt: current})

			do {
				updateProgress(upd_cnt)
				//KEEP IT: use nginx
				let fq = await mainDbX.fetch_post_as_json('/external/egrul/'
						,{query: ogrn, nameEq: 'on'})
				await later(500)
				//KEEP IT: use php
				//let fq = await mainDbX.fetch_post_as_json('/app/user/egrul/search-egrul'
					//	,{ogrn})
				if(fq.t) 
				{
					let d = (new Date()).valueOf()
					await later(500)
					//KEEP IT: use nginx
					let fd = await mainDbX.fetch_get_as_json('/external/egrul/search-result/'
							+fq.t + '?r='+d + '&_='+(+d+1)	)
					//KEEP IT: use php 
					//let fd = await mainDbX.fetch_post_as_json('/app/user/egrul/egrul-info'
						//	, {t:fq.t})
					if(fd.rows) {
						let rows = fd.rows.filter(e=>!e.e)
						if(!rows.length) {
							await alert('Данные странные')
							return null
						}
						if(rows.length === 1) {
							return rows[0]
						}
						return await new Promise((resolve,reject)=>{
							updateProgress({egrul:{
								choose: (item) => resolve(item)
								, items: rows
							}})
						})
					}
				}
				await later(2000)
			} while(++current < max);
			await alert('Данные не получены')
			return null;
		}
		, ({progress}) =>
		<div>
		{progress?.egrul?
			<div>
			{
				progress.egrul.items.map(e=>
				<button type="button" key={e.t}
					onClick={()=>progress.egrul.choose(e)}
				>
					<div>
					{e.n}
					</div>
					<div>
					{e.c}
					</div>
					<div>
					{e.i} / {e.p}
					</div>
					<div>
					{e.a}
					</div>
					<div>
					{getBOSS(e.g)}
					</div>
				</button>
				)
			}
			</div>
		: <span>ЕГРЮЛ: {progress?.cnt+1}/{max}</span>
		}
		</div>
	)
}

/*
a: "454091 ЧЕЛЯБИНСКАЯ ОБЛАСТЬ ГОРОД ЧЕЛЯБИНСК УЛИЦА ЛИБКНЕХТА 2 "
c: "ООО \"ТЕХКОМПЛЕКТ-С\""
cnt: "1"
e: "21.06.2012" //end
g: "директор: Юрченко Владилен Иванович" or "АДМИНИСТРАТИВНЫЙ ДИРЕКТОР: Закс Иосиф Абрамович, РЕКТОР: Закс Лев Абрамович"
i: "7451213050" //inn
k: "ul"
n: "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ \"ТЕХКОМПЛЕКТ-С\""
o: "1057423502377" //ogrn
p: "745101001" //kpp
pg: "1"
r: "18.01.2005" //reg
t: "A1695B3DEA0CA32AE42F015A1B893BD08699F58377253BDF621B101199DB5B48122F821B08B09A6CDCEBEAA1F1DA89F9CDE575B9E992699AE0A1DF92977B5AF0BCB16BAC738F60D37445805AFCA321BD"
tot: "1"
*/

export function getBOSS(e) {
	if(!e) return ''
	let mas = e.split(',')
	let out = mas.map(el => el.match(/^[а-яё'"0-9()/ .-]*:?[а-яё'"0-9 -]*/i)[0].trim())
	return out;
}

export function jsonBOSS(e) {
	if(!e) return ''
	const outJson = {}

	for (const elem of e) {
		const [position, name] = elem.split(":")
		outJson[position.trim()] = name.trim()
	}
	return JSON.stringify(outJson)
}