import React, {useContext, useState, useEffect} from 'react'
import {Link, useLocation} from "react-router-dom";

import {FormikProvider} from 'formik'
import { Field, modalFormik } from 'azlib/components/formik-fields'

import {useDebouncedEffect} from 'azlib/components/ui_helpers'

import {fullPersonName} from 'azlib/components/name_funcs'
import {showModal, PopupMenu, ModalButton, prompt, showModalProcessing}
	from 'azlib/components/modals'

import {DateView,IntInput,ModelDecode} from 'azlib/components/std-controls'

import {OrgInfoContext} from 'org/org'

import { DbTableEdit as Table } from 'azlib/components/super-table'

import Filter from 'azlib/components/list_filter'
import {fioExtract, fioCondition} from 'azlib/components/filter_helpers'

import {ru_date, ru_date_text} from 'azlib/components/locales/ru/ru_date'

import UserInfoContext from 'UserInfo'

import {FeedbackButton, NumberCol, ShowPrintedDocs, StatusCol, Header} from './issued_op'

import {Loading} from "../azlib/components/ui_elems";
import {exportInfosets} from "../contingent/export_issued";

import { SearchFilter } from 'azlib/components/search_filter'

import css from './issued.module.css'
import {YearCtrl} from "../contingent/groups";
import {group_ed_level_options, hasSpecialities, params_options} from "../config/ed_levels_map";
import {ReactComponent as Question} from './../question.svg'
import {YearInput} from "./doclist";

export function formatDocNumbers(kind,dn, docid, orginfo) {
	if(!dn) return null;

	let forms = window.AppBundle.data.doc_forms.decode[kind];

	let d1 = dn[ forms[0] ]?.date;
	let rn = dn[ forms[0] ]?.reg;

	if(!forms) {
		debugger;
	}

	const used = forms.filter(f=>dn[f]?.number)
	const diff = used.some(
			f=>dn[f]?.number !== d1 || dn[f].reg !== rn 
		)

	return <button type="button" className="block link"
				   onClick={async ()=>{
					   await showModal(<ShowPrintedDocs kind={kind} docid={docid} orginfo={orginfo}/>
						   ,{closeBox:true})
				   }}>
		{
			!used.length? 'нет' :
			diff?
			used.map(f=>
					<div key={f}>
						{forms.length>1?<nobr>{f}:</nobr>:''}
						<i><nobr>{dn[f].number}</nobr></i>
						<i><nobr> от {ru_date(dn[f].date)}</nobr></i>
						{!!dn[f].reg && <i><nobr> рег № {dn[f].reg}</nobr></i>}
					</div>
				)
			:
			<>
				<div>Дата: <i><nobr>{ru_date_text(d1)}</nobr></i></div>
				{!!rn && <div><nobr>Рег №: <i>{rn}</i></nobr></div>}
				{used.map(f=> <div key={f}>
					{forms.length>1?<nobr>{f}:</nobr>:''}
					<i><nobr>{dn[f].number}</nobr></i>
					</div>)
				}
			</>
		}
	</button>
}

const thisYear = (new Date()).getFullYear()

async function exportFilter({orginfo}) {
	let rows = await showModalProcessing(async () =>
			await orginfo.DbX.fetch_rds("issued","ed_level spec_code year_completion", {tid:orginfo.tid})
			, props=><Loading/>
		)
	let params = await modalFormik({ initialValues: { year: '', spec: ''} }
		,
		formik=> <>
			<div className="flexContainer">
				<label style={{width:"8em"}}>Уровень</label>
				<Field name="ed_level" as="select">
					<option value="">Выберите значение</option>
					{
						group_ed_level_options([...new Set(rows.map(e => e.ed_level))].sort().reduce((obj, item)=>Object.assign(obj, { [item]: "" }), {}), false)
					}
				</Field>
			</div>
			{formik.values.ed_level &&<>
			<div className="flexContainer">
			<label style={{width:"8em"}}>Год</label>
			<Field name="year" as="select">
				<option value="">Выберите значение</option>
				{
					params_options(rows, "year_completion", formik.values.ed_level)
				}
			</Field>
			</div></>}
			{hasSpecialities(formik.values.ed_level) &&<>
			<div className="flexContainer">
			<label style={{width:"8em"}}>Специальность</label>
			<Field name="spec" as="select">
				<option value="">Выберите значение</option>
				{
					params_options(rows, "spec_code", formik.values.ed_level)
				}
			</Field>
			</div> </>}
			<button type="submit" style={{margin:"1em auto 0 auto"}}>ОК</button>
		</>
	, {closeBox:true})

	//FIXME: show modal loading here!
	let selector = await orginfo.DbX.fetch_rds("issued"
		,"infoset doctype_kind our_number signers"
		+" additional_info snils epgu"
		+" last_name first_name middle_name bdate "
		+" year_completion spec_code qualification"
		,{tid:orginfo.tid, ed_level: checkParams(params.ed_level), spec_code: checkParams(params.spec), year_completion: checkParams(params.year)})

	if (selector.length === 0) alert('Нет данных для экспорта')
	else exportInfosets(selector)
}
function checkParams(params){
	return params ==="" ? undefined: params
}

export function IssuedList({status,...props}) {
	const location = useLocation();
	let filter_status = new URLSearchParams(location.search).get("status")
	let filter_doctype_kind = new URLSearchParams(location.search).get("doctype_kind")
	let filter_year_completion = new URLSearchParams(location.search).get("year_completion")
	const orginfo = useContext(OrgInfoContext);

	const uinfo = useContext(UserInfoContext);

	const [fltDoc, setFltDoc] = useState(null)
	const [fltFIO, setFltFIO] = useState(null)
	const [fltYear, setFltYear] = useState(filter_year_completion || thisYear)

	//console.log('r', {doc:fltDoc, fio:fltFIO})

	let [debFlt, setDebFlt] = useState({})
	useDebouncedEffect(()=>{
			setDebFlt({doc:fltDoc, fio:fltFIO})
			//console.log('d', {doc:fltDoc, fio:fltFIO})
		}
		, [fltDoc, fltFIO], 1000)

	/*
        restriction rules
        if there is docnumber filter, use all years
        , disable status/kind filters
        also if there is fio filter with exact search
            (fioExtract -> ln_e + fn or fn_e)
    */

	const actualYear = filter_year_completion ? filter_year_completion
		: fltDoc || debFlt?.fio?.ln_e ? null
									: fltYear

	const kfio = debFlt?.fio?.ln_e && debFlt?.fio?.ln || ''

	const key = `${filter_doctype_kind}:${filter_status}:${actualYear}:${fltDoc}:${kfio}`

	const clFilter = !fltDoc && !debFlt?.fio?.ln_e

	const isStatusStampEmpty = (row) => {
		return row.status === 'issued-ok' && row.replaced === ''
	};

	return <>
		<h1>Реестр документов</h1>

		<Table className="simpleTable"
			   fetchKey={key}
			   store={orginfo.DbX.store("issued", { "path*": orginfo.path
				   , status: filter_status || undefined
				   , doctype_kind: filter_doctype_kind || undefined
				   , year_completion: actualYear || undefined 
				   , our_number: fltDoc || undefined
				   , last_name: debFlt?.fio?.ln_e && debFlt?.fio?.ln || undefined
				})}
		>
			<Table.Before className={css.FilterTable}>
				<div className="templatesSearch backgroundAngular">
					{(orginfo.registry || orginfo.docs.printer || uinfo.userAreas.sysop || uinfo.userAreas.admin) &&
					<div className="flexContainer">
					<button type="button"  style={{float:"right"}}
							onClick={async ()=>{
								await exportFilter({orginfo})
							}}
					>Экспорт</button>
					</div>}
					<SearchFilter
						input={
						<Filter.Multi placeholder={"Найти документ"} className="FilterInput" minlength="3"
									  extract={
										  [
										  	 [
										  	 	/(\d\d-\d-\d{5}-\d{6}-\d)/
										  	 	, n => row => row.our_number === n
										  	 	, n => setFltDoc(n)
										  	 ]
											  ,
											  [fioExtract, fioCondition(
												  row=>[row.last_name, row.first_name, row.middle_name]
											  	)
												, n => JSON.stringify(n) !== JSON.stringify(fltFIO) 
													&& setFltFIO(n)
											  ]
										  ]
									  }
									  saveAs="multi"
						/>
						}
					/>

					<div className="flexContainer">
				{clFilter && <button className="checkBoxBut">Без вложенных:
				<Filter.Check value={orginfo.tid}
							  condition={v => row => !clFilter || row.tid === v}
							  saveAs="org_check"
				/>
				<Question title="скрывает все документы, выпущенные элементами структуры" style={{height:"1em"}}/>
				</button>}
				{filter_status? 
					<ModelDecode value={filter_status} modelData="doc_status"/>
					:
					clFilter && <Filter Element={<select style={{marginLeft:"auto"}}>
							<option value="">Все статусы</option>
							<option value="issued-ok">действительные</option>
							<option value="issued-replacing">в процессе замены</option>
							<option value="issued-replaced">замененные</option>
							<option value="issued-rejected">отмененные</option>
							</select>}
						condition={v => row => !clFilter || row.status === v}
						saveAs="statuses"
					/>
				}
				{' '}
				{filter_doctype_kind?
					<>Документ: {' '} <ModelDecode value={filter_doctype_kind} modelData="doc_kinds"/></>
					:
					clFilter && <Filter Element={<select>
							<option value="">Уровень:</option>
							{
								window.AppBundle.data.ed_levels.orderedKeys
									.filter(e=>!!e)
									.filter(e=>e in orginfo.app_ed_level)
									.map(e=><option key={e} value={e}>
										{window.AppBundle.data.ed_levels.decode[e]}
									</option>)
							}
							</select>}
						 condition={v => row =>  !clFilter || row.ed_level === v}
						 saveAs="ed_level"
					/>
				}
				{' '}
				{filter_year_completion? 
					<>Год: {' '} {actualYear}</>
				: <YearInput full strict
						value={fltYear} 
						onChange={e=>{
							setFltYear(e.target.value)
						}}
					/>
				}
					</div>
				</div>
			</Table.Before>
			<Table.Col label="ФИО" name="docid"
					   extra="last_name first_name middle_name bdate tid ed_level"
			>
				{(value,row) =>
					<Link to={`/org/${row.tid}/issued/${value}`} >
						{fullPersonName(row) || '--???--'}
					</Link>
				}
			</Table.Col>
			<Table.ModelCol name="doctype_name">{
				(View, v, row) =>
					<div>
						<Link to={`/org/${row.tid}/issued/${row.docid}`} >
							{View}
						</Link>
					</div>
			}
			</Table.ModelCol>

			<Table.ModelCol name="our_number" />
			<Table.ModelCol name="our_date" />
			<Table.Col label="Номера" className="intableOp" name="cached_numbers" extra="doctype_kind"
				ifDistinct={[row=>row.cached_numbers,'']}
				>
				{(dn,row) => dn && formatDocNumbers(row.doctype_kind, JSON.parse(dn), row.docid, orginfo)
			}</Table.Col>

			<Table.ModelCol name="status" />

			<Table.Col name="status_stamp" 
					extra="status_message replaced{. our_number} replaced_with{. our_number}"
					ifDistinct={[isStatusStampEmpty, true]}
			>
				{
					(stamp, row) =><>
						{row.status === 'issued-rejected'
						||
						row.status === 'issued-replaced'
							? <div><DateView value={stamp.substr(0,10)} /></div> //trim to time!
							:null
						}
						{row.replaced &&
							<div>Взамен:{' '}
								<Link to={`/org/${row.tid}/issued/${row.replaced}`} >
								 	{row.replaced$.our_number}
								</Link>
							</div>
						}
						{row.replaced_with &&
							<div>Заменен на:{' '}
								<Link to={`/org/${row.tid}/issued/${row.replaced_with}`} >
									{row.replaced_with$.our_number}
								</Link>
							</div>
						}
					</>
				}
			</Table.Col>

			<Table.Col colKey="@feedbacks" name="docid">{(docid,row)=><div className="intableOp">
				{(orginfo.registry || uinfo.userAreas.sysop) && row.status === 'issued-ok' &&
					<StatusCol docid={row.docid} snils={row.snils} orginfo={orginfo} readOnly={!orginfo.registry} header={<Header stud={row}/>}/> }
				{(orginfo.docs.printer || uinfo.userAreas.sysop) && row.status === 'issued-ok' &&
					<NumberCol docid={row.docid} doctype_kind={row.doctype_kind} readOnly={!orginfo.docs.printer} orginfo={orginfo} header={<Header stud={row} regnumb/>}
					/>}
				{(orginfo.registry || uinfo.userAreas.sysop) &&
					<FeedbackButton orginfo={orginfo} docid={docid} header={<Header stud={row} regnumb/>}
					/>}</div>}</Table.Col>
		</Table>

	</>
}


