import React, {useContext, useState} from 'react'

import {fullPersonName} from 'azlib/components/name_funcs'
import {ToggleDetails} from 'azlib/components/ui_elems'
import {PopupMenu, useModalContext, ModalButton, PopupFrame} from 'azlib/components/modals'

import {XLOG, letIn, later} from 'azlib/components/helpers'

import {Async} from 'azlib/components/async'

import {OrgInfoContext} from 'org/org'

import { DbTableEdit as Table } from 'azlib/components/super-table'
import { SuperTableContext } from 'azlib/components/super-table-int';

import {
	LabeledField,
	modalFormik,
	Field,
	formikExtra,
	ExtendFormikContext,
	ErrorMessage,
	ModelField
} from 'azlib/components/formik-fields'
import { Formik, Form } from 'formik'
import { boolean, mixed, object, string } from 'yup';

import { FieldAsPrompt, PopupEdit } from 'azlib/components/std-controls'
import {fakeEvent} from 'azlib/components/std-controls'

import { chooseRecordSimple } from 'azlib/components/chooser'

import {PopupLog} from 'contingent/log'

import {alert,confirm} from 'azlib/components/modals'
import {Link, json} from "react-router-dom";
import {ReactComponent as Img_to_element} from "./org-img/to_element.svg";
import UserInfoContext from "../UserInfo";
import Sorter from 'azlib/components/list_sort'
import Filter from 'azlib/components/list_filter'

function MultyLevelsSub({name, value, ed_levels, onChange, onBlur, readOnly, ...props}) {
	let parsed = value? JSON.parse(value) : {}
	const a = 
		window.AppBundle.data.ed_levels.orderedKeys
		.filter(e=>e in ed_levels);
	const decode = (k) => window.AppBundle.data.ed_levels.decode[k];
	return <div>
		{
			a.filter(Boolean).map(e=>
				<div key={e}>
				<label>
					<input type="checkbox" checked={e in parsed} disabled={readOnly}
					onChange={(event)=>{
						let nv = Object.assign({},parsed);
						delete nv[e];
						if(event.target.checked) nv[e] = '';
						onChange?.(fakeEvent(name, 
								JSON.stringify(nv)
							))
					}}
					/>
					{decode(e)}
				</label>
				</div>
			)
		}
	</div>
}
 

function orgCreate({orginfo}) {
	return modalFormik({
			initialValues: {name:'', app_ed_level:'{}'}
			, initialStatus:{}
			, validationSchema:
				object({
				  name: string().required('Обязательное поле'),
				  app_ed_level: string()
						.test('atLeastOneLevel', 'Необходимо выбрать уровни образования', function (value) {
						return Object.keys(JSON.parse(value)).length > 0;
						}),
				}).required()
			}
		,<ExtendFormikContext value={{[formikExtra]: { storeWithFields: {store:'orgtree'} }}}>
			<label><b>Наименование</b></label>
			<div style={{marginTop:"0.5em"}}><ModelField name="name" label=""
				style={{width:"100%", marginBottom:"1em"}}
			/></div>
			<label><b>Уровни образования:</b></label>
			<div style={{marginTop:"0.5em"}}><Field name="app_ed_level"
			 ed_levels={orginfo.app_ed_level}
			 as={MultyLevelsSub} /></div>
			 <div className="error_in_groups"><ErrorMessage name="app_ed_level"/></div>
			<div style={{marginTop:"0.5em"}}>
				<button type="submit" style={{margin:"auto"}}>Создать</button>
			</div>
		</ExtendFormikContext>
	, {style:{padding:"1em"}, closeBox:true}
	)
}

function LevelsEdit({app_ed_level, orginfo, readOnly}) {
	const {hide} = useModalContext()
	return <Formik initialValues={{app_ed_level}}
			validationSchema={
				object({
				app_ed_level: string()
						.test('atLeastOneLevel', 'Необходимо выбрать уровни образования', function (value) {
						return Object.keys(JSON.parse(value)).length > 0;
						}),
				}).required()
			}
		onSubmit={values=>hide(values.app_ed_level)}
		>
		<PopupFrame style={{width:"30em", padding:"1em"}}>
		<Form>
			<div>
				<Field name="app_ed_level" readOnly={readOnly}
			 		ed_levels={orginfo.app_ed_level}
			 		as={MultyLevelsSub} />
					<p className="error" ><ErrorMessage name="app_ed_level"/></p>
			</div>
			<div>
				<button style={{margin:"auto"}} type="submit" disabled={readOnly}>Изменить</button>
			</div>
		</Form>
		</PopupFrame>
	</Formik>
}


export function OrgstructPage({...props}) {
	const orginfo = useContext(OrgInfoContext);
	let uinfo = useContext(UserInfoContext);
	let [changed,setChanged] = useState(null)
	return <Async value={orginfo.DbX.fetch_column('orgtree','app_islod_info',orginfo.tid)}
		>{islod_info =>
		<>
		<div
			style={{float:"right", margin:"1em 1em 0 0"}}
		>
			<PopupLog table="orgtree" tkey={orginfo.tid}
					  fixed={{
						  stname:'orgtree'}}
					  transform={diff_transform} />
		</div>
		<h1>Структура организации</h1>
			{changed &&
				<PopupFrame
					style={{
						position:"fixed"
						, fontSize: "16pt"
						, top: "1em"
						, right: "1em"
						, color: "red"
						, zIndex: 10
					}}
				>
					Для активации изменений ОБНОВИТЕ страницу
				</PopupFrame>
			}
		<Table className="simpleTable" layout="fixed"
			store={orginfo.DbX.store("subtree", { }, { vars:{path: orginfo.path} }) }
			storeFilter={row=>
				row.path && row.path.length === orginfo.path.length+3
				&&
				row.path.startsWith(orginfo.path)
				// db/localDb return all locally changed rows in orgtree
				// and storeFilter keeps only related to this subtree level
			}
			extra="path is_branch is_struct"
			handleSave={Table.directSave}
			defaultSorter={(a,b)=>Sorter.cmp(a.name, b.name)}
			>
			<Table.Before>
				<div className="templatesSearch backgroundAngular">
					<div className="flexContainer">
					<Filter Element={<select>
									<option value="">Элементы структуры и филиалы</option>
									<option value="struct">Только элементы структруры</option>
									<option value="branch">Только филиалы</option>
								</select>}
							condition={v => v === 'struct' ?
									row => row.is_struct === true :
									v === 'branch' ?
									row => row.is_branch === true:
									row => true
							   }
							saveAs="org_kind"
						/>
					</div>
				</div>
			</Table.Before>
			<Table.Col label="Название элемента структуры" colKey="name" >
				{(value,row)=><div className="flexContainer notWrap"><LabeledField name="name" placeholder="---???---"
					required as={FieldAsPrompt}
					readOnly={!orginfo.tuner}
				/>
					{(uinfo.userAreas.sysop || uinfo.userAreas.admin)&&<Link to={`/org/${row.tid}`}><Img_to_element alt="перейти в элемент"/></Link>}
				</div>}
			</Table.Col>
			<Table.Col label="Персонал" name="tid">{tid=>
				<ToggleDetails trigger={<span>Ответственные за элемент структуры</span>}>{()=>
					<Table
						className="simpleTable subTable"
						store={orginfo.DbX.store("orgtree_managers", { tid }) }
						handleSave={Table.directSave}
					>
						<Table.Col colKey="person"
							extra="person{login last_name first_name middle_name}"
						>
						{(v,row)=>fullPersonName(row.person$)}
						</Table.Col>
						<Table.Col colKey="@ops" style={{width: "6em"}}>
							<Table.Del disabled={!orginfo.tuner} command={async (row, ops) => {
								ops.remove(row)
								if(row.person === uinfo.personid) setChanged(true)
							}}/>
						</Table.Col>
						<Table.After>
							<Table.Add className="simple" command={async (ops) => {
								let choosen = await chooseRecordSimple({
									selector:
									orginfo.DbX.store('persons2tree', {tid:orginfo.tid})
											.withFields("tid person{. first_name last_name middle_name login}")
									, text: row => fullPersonName(row.person$, true)
									, title: 'Выбор пользователя'
									, readOnly: !orginfo.tuner
								})
								await orginfo.DbX.add_direct('orgtree_managers',null
									, {tid} , {person: choosen.person}
									)
								ops.add_to_list([tid,choosen.person])
								if(choosen.person === uinfo.personid) setChanged(true)
							}}
							/>
						</Table.After>
					</Table>
				}</ToggleDetails>
			}</Table.Col>
			<Table.Col name="app_ed_level" label="Уровень образования">{v=>
				<LabeledField name="app_ed_level"
							  placeholder="..."
							  trigger={(v, ph, def)=>
								  v && letIn(Object.keys(JSON.parse(v)))(keys=>
									  keys.length?<div>{
											  keys.map(ed=><div key={ed} >
													  [{window.AppBundle.data.ed_levels.decode[ed]}]
												  </div>
											  )
										  }</div>
										  : def
								  ) || def}
							  ed_levels={orginfo.app_ed_level}
							  as={PopupEdit}
				>
					<LevelsEdit app_ed_level={v} orginfo={orginfo} readOnly={!orginfo.tuner} />
				</LabeledField>
			}</Table.Col>
			<Table.Col colKey="@ops"  className = 'del-paddings-del-button'>
				<Table.Del readOnly={!orginfo.tuner} command={async (row,ops)=>{
					let docs = await orginfo.DbX.fetch_rds("docs_preparing","docid", {tid:row.tid});
					let issued = await orginfo.DbX.fetch_rds("issued","docid", {tid:row.tid});
					let suborgs = await orginfo.DbX.fetch_rds("orgtree", "path", { "path*": row.path + '___%'});
					if(docs.length) await alert("Невозможно удалить, переместите документы/обучающихся в другую организацию!");
					else if(suborgs.length) await alert("Ошибка! Необходимо предварительно удалить вложенные элементы структруры!");
					else if(issued.length) {
						await confirm("После удаления реестр элемента структуры будет перенесен в основную организацию. Удалить?");
						await ops.remove(row);
					}
					else {
						await confirm("Удалить?");
						await ops.remove(row);
						setChanged(true)
						await orginfo.DbX.call_function("update_orginfo_cache_by_tid", {
							tid: orginfo.tid
						})
					}
				}}/>
			</Table.Col>
			<Table.After>
				<PopupMenu trigger={
						<button type="button">Создать элемент</button>
					}
					bindX="left"
				>{({hide})=><>
				<div>
				<Table.Add command={async (ops) => {
						hide()
						let vals = await orgCreate({orginfo})
						let tid = await orginfo.DbX.fetch_post_as_json('/app/user/create-substruct'
						, {path: orginfo.path, is_struct: 1, ...vals })
						ops.add_to_list([tid])
						setChanged(true)
						await orginfo.DbX.call_function("update_orginfo_cache_by_tid", {
							tid: orginfo.tid
						})
						}}
				 	className="block"
					disabled={!orginfo.tuner}
				>
					Элемент структуры
				</Table.Add>
				</div>
				<div>
				{/*
				<Table.Add command={async (ops) => {
						hide()
						let vals = await orgCreate({orginfo})
						let tid = await orginfo.DbX.fetch_post_as_json('/app/user/create-substruct'
						, {path: orginfo.path, is_branch: 1, ...vals })
						ops.add_to_list([tid])
						}}
				 	className="block"
				>
					Филиал
				</Table.Add>
				*/}
				{islod_info&& <SuperTableContext.Consumer>{ops=>
				<PopupMenu trigger={<button className="block add">Филиал</button>}
					bindX="left"
					style={{maxWidth:"80vw"}}
					onHide={async f=>{
						hide()
						if (![...ops.source.values()].some(obj => obj.data.name === f.name)){
							let tid = await orginfo.DbX.fetch_post_as_json('/app/user/create-substruct'
							, {path: orginfo.path, is_branch: 1
							, name: f.name
							, app_ed_level: JSON.stringify(
									Object.fromEntries(
										Object.entries(f.sp)
										.map(([e,v])=>[e,""])
									))
							, app_spec_list: JSON.stringify(f.sp)
							})
							ops.add_to_list([tid])
							setChanged(true)
							await orginfo.DbX.call_function("update_orginfo_cache_by_tid", {
								tid: orginfo.tid
							})
						}else await alert('Данный филиал уже создан!');
					}}
				>
					{Object.values(JSON.parse(islod_info))
					.filter(f=>!f.head)
					.map(f=><ModalButton className="block" key={f.name} disabled={!orginfo.tuner}
							value={f}
						>
						{f.name}
						</ModalButton>
					)}
				</PopupMenu>
				}</SuperTableContext.Consumer>
				}
				</div>
				{/*<div>*/}
				{/*<Table.Add command={async (ops) => {*/}
				{/*		hide()*/}
				{/*		let vals = await orgCreate({orginfo})*/}
				{/*		let tid = await orginfo.DbX.fetch_post_as_json('/app/user/create-substruct'*/}
				{/*		, {path: orginfo.path, app_issuer: 1, ...vals })*/}
				{/*		ops.add_to_list([tid])*/}
				{/*		}}*/}
				{/* 	className="block"*/}
				{/*	disabled={!orginfo.tuner}*/}
				{/*>*/}
				{/*	Выпускающая организация*/}
				{/*</Table.Add>*/}
				{/*</div>*/}
				</>}</PopupMenu>
			</Table.After>
		</Table>
	</>
	}</Async>
}

const diff_transform = {
	"tid.compare": e=>' '
}

/*
	person management
	for each orgstruct we need a 'manager' person
	and every persons who manage upper level are managers of this level
	so, we can go deeper in the struct edit
	--
	i.e.
	user have 
	- a list of struct items he assigned directly (it is a 'choose org')
	- a list of struct items under each directly assigned items (it is 'structure')
	and from the structure it can manage 'managers'
	(+manager set manager flag -manager remove manager flag)
	if no more flags, item is removed
	maybe it's a view with insert/delete trigger
	tid + person
*/

