import React, {useState, useRef, useEffect} from 'react'

import {htmlBool, XLOG} from './helpers'
import { ModalButton, showModal} from './modals'

import styles from './table_parser.module.css'; 

import readXlsxFile from 'read-excel-file'
import {insertColspan, mergeHeaderRow} from "../../contingent/import_adv";

import {ReactComponent as Green_dot} from 'azlib/components/images/green_dot.svg'
import {ReactComponent as Red_dot} from 'azlib/components/images/red_dot.svg'

function takeText(e) {
	if(e.nodeType === 3 || e.nodeType === 4)
		// text or cdata 
		return e.nodeValue
	if(e.nodeType === 1) {
		//element
		if(e.tagName === "BR")
			return "\n"
		let r = []
		for(let c = e.firstChild; c; c = c.nextSibling){
			r.push(takeText(c))
		}
		return r.join('')
	}
	return ''
}

function CSVToArray( strData, strDelimiter ){
        // Check to see if the delimiter is defined. If not,
        // then default to comma.
        strDelimiter = (strDelimiter || ",");

        // Create a regular expression to parse the CSV values.
        var objPattern = new RegExp(
            (
                // Delimiters.
                "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

                // Quoted fields.
                "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

                // Standard fields.
                "([^\"\\" + strDelimiter + "\\r\\n]*))"
            ),
            "gi"
            );


        // Create an array to hold our data. Give the array
        // a default empty first row.
        var arrData = [[]];

        // Create an array to hold our individual pattern
        // matching groups.
        var arrMatches = null;


        // Keep looping over the regular expression matches
        // until we can no longer find a match.
        while ((arrMatches = objPattern.exec( strData ))) {

            // Get the delimiter that was found.
            var strMatchedDelimiter = arrMatches[ 1 ];

            // Check to see if the given delimiter has a length
            // (is not the start of string) and if it matches
            // field delimiter. If id does not, then we know
            // that this delimiter is a row delimiter.
            if (
                strMatchedDelimiter.length &&
                strMatchedDelimiter !== strDelimiter
                ){

                // Since we have reached a new row of data,
                // add an empty row to our data array.
                arrData.push( [] );

            }

            var strMatchedValue;

            // Now that we have our delimiter out of the way,
            // let's check to see which kind of value we
            // captured (quoted or unquoted).
            if (arrMatches[ 2 ]){

                // We found a quoted value. When we capture
                // this value, unescape any double quotes.
                strMatchedValue = arrMatches[ 2 ].replace(
                    new RegExp( "\"\"", "g" ),
                    "\""
                    );

            } else {

                // We found a non-quoted value.
                strMatchedValue = arrMatches[ 3 ];

            }


            // Now that we have our value string, let's add
            // it to the data array.
            arrData[ arrData.length - 1 ].push( strMatchedValue );
        }

        // Return the parsed data.
        return( arrData );
    }


function oneToTwoDim(arr, columns) {
	let res = []
	for(let i = 0; i < arr.length; i+= columns)
		res.push(arr.slice(i, i+ columns))
	return res;
}

export function TableSkipped({invalid, skipped, terms, ...props}) {
	let columns =
		{last_name: 'Фамилия'
		, first_name: 'Имя'
		, middle_name: 'Отчество'
		, bdate: 'Дата рождения'
		, spec_code: terms.group}

	return <div>
		<h2>Данные не импортированы</h2>
		{
			invalid && invalid.length !== 0?
				<>
					<h3>Данные {terms.students_gen} содержат ошибки
						<hr/></h3>
					<table className={styles.parsed}>
					<thead>
					<tr>
						<th> </th>
						{
							Object.keys(columns)
								.map((c, i)=>
									invalid.find(s=>s[c])?<th key={i}>{columns[c]}</th>
								:null)
						}
					</tr>
					</thead>
					<tbody>{
						invalid
							.map((r,i)=><tr key={i}><th>-</th>{
								Object.keys(columns)
									.map((c, i)=><td key={i}
										valid={htmlBool(r?.valid??true)}>{r[c]}</td>)
							}
							</tr>)
					}</tbody>
				</table>
				</>
				:null
		}{
			skipped && skipped.length !== 0?
				<>
				<h3>Данные {terms.students_gen} не найдены
					<hr/></h3>
				<table className={styles.parsed}>
					<thead>
					<tr>
						<th> </th>
						{
							Object.keys(columns)
								.map((c, i)=>
									skipped.find(s=>s[c])?<th key={i}>{columns[c]}</th>
										:null)
						}
					</tr>
					</thead>
					<tbody>{
						skipped
							.map((r,i)=><tr key={i}><th>-</th>{
								Object.keys(columns)
									.map((c, i)=><td key={i}
													 valid={htmlBool(r?.valid??true)}>{r[c]}</td>)
							}
							</tr>)
					}</tbody>
				</table>
			</>: null
		}
		<br/>
		<ModalButton
			style={{marginTop:"1em", float:"right"}}>OK</ModalButton>
	</div>
}

function normaliseColHeader(name) {
	return (name||'').toUpperCase().replace(/\s+/g,' ');
}

function validateScheme(scheme, value) {
	try {
		if(scheme) {
			if (scheme.len) {
				if (value.length !== scheme.len && value.length > 0 && !(value.length === 1 && value[0] === "")) return false;
			}
			else scheme.validateSync(value)
		}
	} catch(err) {
		console.log(err)
		return false
	}
	return true;
}

function colunmsCount(value, index, columnLengths){
	if(value.match(/^(.*?)\[(.*)\]$/)) columnLengths[index] = {len: RegExp.$2.trim().split('|').length}
	else if (!value) columnLengths[index] = columnLengths[index-1]? columnLengths[index-1] : undefined;
}

export function TableParser({columns, scheme, revalidate, Ok, ...props}) {
	let [value,setValue] = useState('')

	let [table, setTable] = useState(null)
	let [badTable, setBadTable] = useState(false)

	let refCols = useRef(null)

	useEffect(()=>{
		if(!value) {
			setTable(null)
			return;
		}
		let table = value.map(r=>r.map(c=> c? (typeof c === 'string'?
									c.trim().replace(/\s+/g,' ')
									: c.toString().trim().replace(/\s+/g,' ')) : ''
								  )
						);
		console.log(table);

		let rlen = table.reduce((a,r)=>Math.max(a,r.length),0)
		table = table.map(r=>Object.assign(Array(rlen).fill(''),r))


		let importSchemeMapper = {}
		for(const i in scheme) {
			const def = scheme[i].describe()
			importSchemeMapper[def.label||i] = i
			if(def.meta?.altNames)
			for(const l of def.meta?.altNames)
				importSchemeMapper[l] = i
		}

		if(!table.length) {
			// do nothing!
			return;
		}

		let cols = table[0].map(normaliseColHeader)
		if(cols.length < columns.length 
			||
			!columns.every((h,i)=>
					h instanceof RegExp? h.test(cols[i])
					: h === cols[i]
			)
		){
			console.log(cols.length, columns.length, columns)
			setBadTable(true);
			return;
		}


		let colHeaders = []
			while(table[colHeaders.length+1] && table[colHeaders.length+1][0]==="" && table[colHeaders.length+1][1]==="") {
			colHeaders.push(table[colHeaders.length+1])
		}

		let columnLengths = [];
		refCols.current = cols.map((el, index)=>importSchemeMapper[el]? importSchemeMapper[el] : colunmsCount(el, index, columnLengths))

		columnLengths = columnLengths.map((el, index)=> el? {len: el.len - colHeaders.some(val=>val[index]!=="" && val[index]!=="-")} : el)

		table = table.map((r,i)=>r.map((value,j)=>({
			value
			, valid: i===0 || (!r[0][0] && !r[1][0])
					||
				(refCols.current[j]?
					validateScheme(scheme[refCols.current[j]], value) : validateScheme(columnLengths[j], value.split('|'))
				)
		})))

		if(revalidate) {
			table = revalidate(table)
			if(!table) {
				setBadTable(true)
				return;
			}
		}
		table.forEach((el,idx)=> el.unshift({status: true}) && el.forEach((val,i,arr)=>{
			if(idx <= colHeaders.length) arr[0]={status: null};
			else if(val.valid===false) arr[0]={status: false};
		}))
		setBadTable(false)
		setTable(table)
		console.log('tabletab', table)
	}
	,[value])

	return <div style={{minHeight:"11em", marginTop:"1em", overflow:"auto"}}>
		{!value?<>
			<span>
				<b>Вставьте</b> (Ctrl-v или right-click -> вставить)
				<br />
				<b>табличные</b> данные, <b>скопированные</b> из 
				<i>MsExcel, Libre/OpenOffice Calc, MyOffice,
				Google Tables, MsWord, Libre/OpenOffice Writer, Google Docs и т.п.</i> 
				в поле ниже
			</span>
		<br/>
		<div className="FilterInput" data-placeholder="Место для импорта" style={{margin: "1em 0 2em 0"}}
			tabIndex={1}
			contentEditable
			onPaste={event=>{ 
				setTimeout(function(){
					let target = event.target 
					let table = target.querySelector('TABLE')
					if(!table) { 
						setValue(null)
						target.innerHTML = ''
					}
					let arr = []
					let i = 0;
					for(const tr of table.querySelectorAll('TR')) {
						if(!arr[i]) arr[i] = [];
						let j = 0; 
						for(const td of tr.querySelectorAll('TD')) {
							const cspan = +td.getAttribute('colspan')||1
							const rspan = +td.getAttribute('rowsapn')||1
							while(arr[i][j]!==undefined) ++j;
							for(let ii = 0; ii < rspan; ++ii )
								for(let jj = 0; jj < cspan; ++jj ){
									if(!arr[i+ii]) arr[i+ii] = []
									arr[i+ii][j+jj] = '';
								}
							arr[i][j] = takeText(td)
						}
						++i;
					}
					setValue(arr); target.innerHTML = '';
				},100)
			}}
			onKeyDown={event=>{
				//console.log(event); 
				//polyfill if needed https://github.com/inexorabletash/polyfill/blob/master/keyboard.md
				if(event.ctrlKey && event.code==='KeyV'
					||
				   event.shiftKey && event.key==='Insert'
					||
				   event.key==='F5'
				    ||
				   event.ctrlKey && event.code==='KeyR'
				) return;
				event.preventDefault()
			}} 
			onCut={event=>{event.preventDefault()}}
		/>
			<label className="buttonBackground">
			Загрузить xlsx
			<input type="file" 
				style={{width:0, height:0, overflow:"hidden"}}
				onChange={async e => {
					let f = await readXlsxFile(e.target.files[0])
					let t =
						f.map(r=>r.map(c=>
									typeof c ==='string'?
										c.replace(/\s+/g,' ').replace(/_x000D_/g, "\n")
									: c instanceof Date?
										c.toISOString().substr(0,10)
									: c
									)
							)
					setValue(t)
					//console.log(f)				
				}}
			/>
			</label>
			<label className="buttonBackground">
			Загрузить csv
			<input type="file" 
				style={{width:0, height:0, overflow:"hidden"}}
				onChange={async e => {
					let f = await e.target.files[0].text()
					let div = await showModal(<div
							style={{width:"30em"}}
						>
						<ModalButton className="block" value=';'>разделитель ;</ModalButton>
						<ModalButton className="block" value=','>разделитель ,</ModalButton>
						<ModalButton className="block" value='|'>разделитель |</ModalButton>
						<ModalButton className="block" value='[TAB]'>разделитель [TAB]</ModalButton>
					</div>)
					if(div==='[TAB]') div = '\t'
					f = CSVToArray(f,div)
					setValue(f)
					//console.log(div, f)				
				}}
			/>
			</label>

		</>
		: table? <>
				<fieldset style={{display:"grid"}}>
			<legend style={{fontSize: "18px"}}>Данные для импорта</legend>
			<div style={{overflow:"auto",maxHeight:"80vh"}}><table className={styles.parsed+" simpleTable"} style={{padding:"0"}}>
			<thead>
				<tr>
					<th key={0}>Статус</th>
				{
					mergeHeaderRow(table[0])
					.map((c,j)=>j!=0&&<th key={j} colSpan={c.colspan}>{c.value}</th>)
				}
				</tr>
			</thead>
			<tbody>{
			table
			.slice(1)
				.map((r,i)=><tr key={i}><th key={0}>{r[0].status ? <Green_dot/> : r[0].status === false ? <Red_dot/> : null}</th>{
				r.map((c,j)=>
						j!=0&&<td key={j} colSpan={c.colspan}
						valid={htmlBool(c.valid??true)}
						>{c.value}</td>
				)
			}
			</tr>)
			}</tbody>
			</table></div>
			{table.some(r=>r.some((c,i)=>!c.valid && i!=0))?
			<i style={{color:'#f54D00', marginTop:"1em"}}>строки, содержащие ошибки, будут проигнорированы!</i>
			:null}
			<br/>
				<div className="flexContainer" style={{justifyContent:"flex-start"}}>
			<Ok
				table={
					table.map(r=>
						r.filter((row, idx)=>idx!=0).reduce((x,c,i)=>(
						{
							...x, 
								[refCols.current?.[i] || '$']:
									refCols.current?.[i]? c.value
									: [...(x.$ ?? []), c.value ]
							, valid: x.valid && c.valid
						}), {valid: true})
					)
				} 
				reset={()=>setValue('')}
			/>
			<button type="button" style={{marginLeft:"1em"}} className="modalButWithBackground"
				onClick={()=>setValue('')}
			>Отменить импорт</button>
				</div>
				</fieldset>
			</>
		: badTable?<> 	
			<i style={{color:'#f54D00', marginTop:"1em"}}>Не та строка заголовка!</i>
			<br/>
			<button type="button" className="modalButWithBackground"
					onClick={()=>{setValue(''); setBadTable(false);}}
			>Отменить импорт</button>
			</>
		: <div><h2>Загрузка</h2></div>
		}
	</div>
}

//TODO: https://docs.sheetjs.com/
