import {FieldInput
		, Checkbox2, InlineSelect, ModelSelect
		, Textarea, MaskedInput, DateInput, FileInput, IntInput
	, Viewer, ModelDecode, DateView, CLOBView, BoolView
	} 
	from 'azlib/components/std-controls';

window.globalControlRegistryEditors = {}; 
window.globalControlRegistryViewers = {}; 

export function registerControlsEditors(...controls) {
	Object.assign(window.globalControlRegistryEditors, 
		...controls);
}
export function registerControlsViewers(...controls) {
	Object.assign(window.globalControlRegistryViewers, 
		...controls);
}

registerControlsEditors({
	VARCHAR: FieldInput
	, INTEGER: IntInput
	, BIGINT: IntInput
	, DECIMAL: (props) => <FieldInput pattern='[0-9]*(?:[.][0-9]*)?' {...props} />
	, CLOB: Textarea
	, ONE: Checkbox2
	, BOOL: (props) => <InlineSelect modelData='BOOL' {...props} />
	, SELECT: ModelSelect
	, MASK: MaskedInput
	, DATE: DateInput
	, BLOB: FileInput
})

registerControlsViewers({
	'': Viewer
	, CLOB: CLOBView
	, DECODE: ModelDecode
	, DATE: DateView
	, ONE: BoolView
})