import React, {useState} from 'react';

import {
  Routes,
  Route
} from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from 'formik';

import * as yup from 'yup';

import {GlobalPageAreas, PageTitle} from 'azlib/components/page-header'

import {mainDbX} from 'azlib/components/db';

import {defer, later} from 'azlib/components/helpers'
import {Async} from 'azlib/components/async'

import {esiaLogin} from 'esia/login'


const validationSchema = yup.object({
  login: yup
    .string('Введите ваш логин')
    .required('Необходим логин'),
  pwd: yup
    .string('Введите ваш пароль')
    //.min(8, 'Password should be of minimum 8 characters length')
    .required('Необходим пароль'),
});

function LoginPage() {
  let [pass, setPass] = useState(false)
  return (<>
      <PageTitle title={<span>Вход в систему</span>} doctitle="Вход в систему" />
      {pass?
      <Formik
        initialValues={{login:'', pwd: ''}}
        validationSchema={validationSchema}
        onSubmit={values =>
          mainDbX.fetch_post("/login",values)
          //.then((ret)=>ret.text())
          //.then((ret)=>console.log(ret))
          .then(() => later(1*1000)) //wait one second to update user cache!
          .then(() => window.open("/","_self"))
        }
      >
          <Form className="loginForm"
          >
            <p></p>
            <p><Field name="login" type="text" placeholder="логин" /></p>
            <p className="error" ><ErrorMessage name="login"/></p>
            <p><Field name="pwd" type="password"  placeholder="пароль" /></p>
            <p className="error" ><ErrorMessage name="pwd"/></p>
            <p><button type="submit">Войти</button></p>
            {/*<p><Link to="/">Home</Link></p>*/}
          </Form>
      </Formik>
      : <div style={{
          width:"20em"
          , margin: "5em auto 5em auto"
        }}
          className="loginSwitch"
        >
        <button type="button"
          className="block"
          onClick={()=>{
            esiaLogin(true)
          }}
        >Вход через ЕСИА</button>
        {
          (window.location.hostname === 'localhost' 
          || window.location.hostname === '10.20.16.11'
          || window.location.hostname.startsWith('172.16.87.')
          )
          &&
          <button type="button"
            className="block"
            onClick={()=>{
              setPass(true)
            }}
          >Вход по паролю</button>
        }
        </div>
      }
    </>);
}

function AnotherPage() {
    return "..."
}

const AnonPage = () => {
  let [t,setT] = useState(null);
  let [m,setM] = useState(null);
  //console.log(uinfo)
  return (<>
<GlobalPageAreas title={t} menu={m}>
<header id="App-header">
  <span id="TitlePrefix">ЕР ЦДО</span> <span id="PageTitle" ref={setT}/>
</header>
<nav id="App-menu" ref={setM}/>
<main id="App-main">
  <Async value={defer(null)}>{()=>
  <Routes>
    <Route path="/page" element={<AnotherPage />}/>
    <Route path="/" element={<LoginPage />}/>
  </Routes>
  }</Async>
</main>
</GlobalPageAreas>
</>)
};

export default AnonPage;
