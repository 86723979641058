import React, {useContext} from 'react'
import {Link} from "react-router-dom";

import {XLOG} from 'azlib/components/helpers'

import { object, string, number } from 'yup';

import {
	modalFormik,
	ModelField,
	Field,
	ErrorMessage,
	formikExtra,
	ExtendFormikContext
} from 'azlib/components/formik-fields'
import {alert, PopupMenu} from 'azlib/components/modals'

import {OrgInfoContext} from 'org/org'
import UserInfoContext from 'UserInfo'

import { DbTableEdit as Table } from 'azlib/components/super-table'
import Filter from 'azlib/components/list_filter'

import { LabeledField } from 'azlib/components/formik-fields'

import {FieldAsPrompt, ModalEdit, ModelDecode, FieldSelect, Textarea} from 'azlib/components/std-controls'

import {group_ed_level_options, has_spec_code, hasSpecialities, MegaMonsterEdit} from 'config/ed_levels_map'

import {DoctypeModalSelector, ConfirmDoctypeModalSelector} from 'config/doctype_selector'

import {PopupLog} from 'contingent/log'

import { SearchFilter } from 'azlib/components/search_filter'

import {orginfo_changed} from 'config/common'
import {ReactComponent as EditSVG} from 'contingent/edit.svg'
import {YearInput} from "../docs/doclist";
const thisYear = (new Date()).getFullYear()

export function YearCtrl({value,strict,full,...props}) {
	return <FieldSelect {...props} value={value?+value:''} value-reflect={value}>
		{!strict && <option value="">Год:</option>}
		<option value={thisYear-5}>{thisYear-5}</option>
		<option value={thisYear-4}>{thisYear-4}</option>
		<option value={thisYear-3}>{thisYear-3}</option>
		<option value={thisYear-2}>{thisYear-2}</option>
		<option value={thisYear-1}>{thisYear-1}</option>
		<option value={thisYear}>{thisYear}</option>
		<option value={thisYear+1}>{thisYear+1}</option>
	</FieldSelect>
}

function groupCreate({orginfo, year_completion, ed_level, name }) {
	const app_ed_level = orginfo.app_ed_level
	return modalFormik({
			initialValues:{
				group_name: name || ''
				, ed_level: ed_level || ''
				, year_completion: year_completion || thisYear
				, spec_code: '', spec_name: ''
				, qualification: ''
				, qualification_category: ''
				, area_proffessional_program: ''
			}
			, initialStatus:{}
			, validationSchema:
				object({
					group_name: string().required('Обязательное поле')
					, ed_level: string().required('Обязательное поле')
					, year_completion: number().required('Обязательное поле')
					, spec_name: string().max(500)
					, spec_code: string().when("ed_level", (ed_level) =>
						has_spec_code(ed_level) && !(ed_level!=='p' || ed_level!=='ap')?
							string().required('Обязательное поле') : string()
					)
					, qualification: string().when("ed_level", (ed_level) =>
						has_spec_code(ed_level) && !(ed_level!=='p' || ed_level!=='ap')?
							string().required('Обязательное поле') : string()
					)
					, qualification_category: string().max(100)
					, area_proffessional_program: string().max(100)
				})
		},
		formik=>
			<ExtendFormikContext value={{[formikExtra]: { storeWithFields: {store:'holder_groups'} }}}>
				<div className="flexContainer"><label>Наименование</label><ModelField name="group_name" label="" style={{width:"24em"}}/>
				</div>
				<div className="flexContainer"><label>Уровень</label><div>
						<Field name="ed_level" as="select" className="customArrow" style={{width:"24em"}}
							   onChange={e=>{
								   formik.setFieldValue(e.target.name, e.target.value)
								   formik.setFieldValue('spec_code', '')
							   }}
						>{
							group_ed_level_options(app_ed_level, true)
						}</Field><div className="error_in_groups"><ErrorMessage name="ed_level" /></div></div></div>
				{(formik.values.ed_level === "p" || formik.values.ed_level === "ap") &&
					<>
					<div className="flexContainer"><label>{formik.values.ed_level === "p" ?
						"Наименование программы профессионального обучения" : "Наименование программы"}</label>
						<ModelField style={{width:"24em"}}
							label=""
							name="spec_name" as={Textarea}
							required
						/>
					</div>
					<div className="flexContainer"><label>Квалификация</label>
							<ModelField name="qualification" label="" style={{width:"24em"}}
										as={Textarea}
										max_length={200}
							/></div>
						{formik.values.ed_level === "p"&&
							<><div className="flexContainer"><label>Присвоенный квалификационный разряд, класс, категория</label>
								<ModelField name="qualification_category" label="" style={{width: "24em"}}
										as={Textarea}
							/></div>
								<div className="flexContainer"><label>Наименование профессий рабочих, должностей служащих</label>
									<ModelField name="area_proffessional_program" label="" style={{width:"24em"}}
							as={Textarea}
						/></div></>}
							</>
						}
							{hasSpecialities(formik.values.ed_level) && <>
								{formik.values.spec_code &&
									<div className="flexContainer PopupLabel" style={{alignItems: "center"}}><label>Наименование специальности</label><div style={{width:"22.5em"}}><Field style={{padding:"0.3em 1em", margin:0}}
																																											  name="spec_name" as={Textarea}
																																											  readOnly
																																											  required
						/></div>
							<Field undecorated name="spec_name" style={{background:"transparent", margin:"0", minHeight:"min-content", padding:"0"}}
								   prompt="Редактировать наименование специальности"
								   as={FieldAsPrompt}
							>
								<EditSVG tabIndex={0} alt="Редактировать" title="Редактировать"
										 style={{height:"1em"
											 , verticalAlign:"bottom"
											 , display: "inline-block"
										 }}
								/>
							</Field>

						<div className="error_in_groups"><ErrorMessage name="spec_name" /></div></div>}
					<div className="flexContainer"><label>Специальность</label><div>
						<Field name="spec_code" name2="spec_name" name3="qualification" style={{ padding:"0.3em 1em", marginBottom:"1em"}}
							   ed_level={formik.values.ed_level}
							   as={MegaMonsterEdit}
							   orginfo={orginfo}
							   placeholder="--.--.--"
						/><div className="error_in_groups"><ErrorMessage name="spec_code" /></div></div>
					</div>
				</>
				}
				{hasSpecialities(formik.values.ed_level) &&<>
					<div className="flexContainer"><label>Квалификация</label>
						<ModelField name="qualification" label=""
							   ed_level={formik.values.ed_level}
							   placeholder="не задана"
							   style={{paddingRight: 30}}
						/>
						<PopupMenu
							trigger={
								<span
									className="customArrow"
									style={{border:"none", margin:"0 0 auto -4em", padding:"1em 0", zIndex:100}}
								></span>
							}
							bindX="right"
							onHide={(res)=>{
								if(res) {
									formik.setFieldValue('qualification', res)
								}
							}}
						>{({hide})=>
							<div onClick={e=>{
								hide(e.target.textContent)
							}}
							>
								{
									window.AppBundle.data.known_qualifications.decode.all
										.map(e=>
											<button type="button" className="block link" key={e}>{e}</button>
										)
								}
							</div>}
						</PopupMenu>
					</div>
				</>}
				<div className="flexContainer"><label>Год выпуска</label><div><Field name="year_completion"
																				as={YearCtrl}
																				className="customArrow"
				/><div className="error_in_groups"><ErrorMessage name="year_completion" /></div></div>
				</div>
				<div>
					<button type="submit" className="blueModalButton" disabled={!orginfo.docs.operator && !orginfo.docs.validator}>Создать</button>
				</div>
			</ExtendFormikContext>
		,
		{style:{width:"38em"}, closeBox:true}
	)
}

export function GroupsPage({prefix}) {
	const orginfo = useContext(OrgInfoContext);
	const uinfo = useContext(UserInfoContext);
	const tid = orginfo.tid;
	const terms = orginfo.terms
	return <>
		<div
			style={{float:"right", margin:"1em 1em 0 0"}}
		>
			<PopupLog table="holder_groups" tkey={tid}
					  fixed={{
						  tname: 'orgtree'
						  ,stname:'holder_groups'}}
					  transform={diff_transform}
					  transformContext={orginfo}
			/>
		</div>
		<h1>{terms.groups}:</h1>
		<Table className="simpleTable withHiddenCols tableGroups"
			   store={orginfo.DbX.store("holder_groups", {tid}) }
			   uniquekey = {{'название класса/группы': 'group_name'}}
			   handleSave={Table.directSave}
		>
			<Table.Before>
				<div className="templatesSearch backgroundAngular">
					<SearchFilter
						input={
						<Filter.Multi className="FilterInput"  placeholder={"Найти класс/группу"}
									  extract={
										  [
											  [/(.+)/, txt =>	row => row.group_name
												  && row.group_name.toUpperCase().includes(txt.toUpperCase())
											  ]
										  ]
									  }
									  saveAs="multi"
						/>
						}
					/>
					<div className="flexContainer">
						{(uinfo.userAreas.sysop || orginfo.docs.operator) &&
							<Table.Add disabled={!orginfo.docs.operator} command={async (ops) => {
								let flt = ops.filterLeafs()
								let ret = await groupCreate({orginfo
										, year_completion: flt.year_completion
										, ed_level: flt.ed_level
										, name: flt.multi
									}
								)
								let res = ops.checkUnique(ret)
								if(!res){
									await ops.add_to_list(
										await ops.append_direct(ret)
									)
									orginfo_changed(orginfo)
								}
								else {
									// console.log(res + ' должно быть уникальным')
									await alert(res + ' должно быть уникальным')
								}
							}}>
								Создать {terms.group_acc}
							</Table.Add>
						}
						<Filter Element={<select style={{marginLeft:"auto"}}>
							<option value="">Уровень:</option>
							{
								window.AppBundle.data.ed_levels.orderedKeys
									.filter(e=>!!e)
									.filter(e=>e in orginfo.app_ed_level)
									.map(e=><option key={e} value={e}>
										{window.AppBundle.data.ed_levels.decode[e]}
									</option>)
							}
						</select>}
								condition={v => row => row.ed_level === v}
								saveAs="ed_level"
						/>
						<Filter
							Element={<YearInput value={thisYear}/>}
							condition={v => row => +row.year_completion === +v}
							saveAs="year_completion"
						/>
					</div>
				</div>
			</Table.Before>
			<Table.Col name="group_name" label="Название" style={{width:"15em"}}>
				<LabeledField name="group_name" prompt={`Название ${terms.group_gen}`} style={{textAlign:"left"}}
							  required as={FieldAsPrompt}
							  readOnly={!orginfo.docs.operator}
				/>
			</Table.Col>

			<Table.Col className="colLevel" label="Уровень образования" name="ed_level">{v=>
				<ModelDecode modelData="ed_levels" value={v} />
			}</Table.Col>

			<Table.Col label="Год" name="year_completion" style={{width:"4em"}}>
				{v=>v}
			</Table.Col>

			<Table.Col label="Специальность" name="spec_code"
					   ifDistinct={[row=>row.spec_code,'']}
			>{(v,r)=>
				v
			}</Table.Col>
			<Table.Col label="" name="spec_name" style={{display:"none"}}
			ifDistinct={[row=>row.spec_name,'']}
			>{(v,r)=>
			v
			}</Table.Col>
			<Table.Col label="Квалификация" name="qualification"
					   ifDistinct={[row=>row.qualification,'']}
			>{(v,r)=>
				v
			}</Table.Col>
			<Table.Col label="Категория" name="qualification_category"
					   ifDistinct={[row=>row.qualification_category,'']}
			>{(v,r)=>
				v
			}</Table.Col>
			<Table.Col label="Наименование профессий" name="area_proffessional_program"
					   ifDistinct={[row=>row.area_proffessional_program,'']}
			>{(v,r)=>
				v
			}</Table.Col>

			<Table.Col label="Форма документа" className="autowidthTd" colKey="Template" extra="doctype{. typename}">
				{(_, row)=>
					<LabeledField className="intableOp" label="" as={ModalEdit}
								  name="doctype"
								  placeholder="-----"
								  text={row.doctype$?.typename}
								  dialog={
									  <DoctypeModalSelector
										  doctypes={orginfo.orgtree_doctypes}
										  ed_level={row.ed_level}
										  spec_code={row.spec_code}
										  withEmpty="--- без формы документа ---"
										  forGroups={true}
									  />}
								  closeBox
								  readOnly={!orginfo.docs.operator}
					/>
				}</Table.Col>
			<Table.Col label="Список обучающихся" colKey="@link">
				{(v, row)=><Link className="intableOp" to={`${prefix||''}../${row.gcode}/cards/`}>список {terms.students_gen}</Link>}
			</Table.Col>

			{(uinfo.userAreas.sysop || orginfo.docs.operator) && <Table.Col colKey="@menu" className="del-paddings-del-button">
				<Table.Del confirm={(row)=>`Удалить ${row.group_name}`}
						   command={async (row,ops)=>{
							   await ops.remove(row);
							   orginfo_changed(orginfo);
						   }}
						   disabled={!orginfo.docs.operator}></Table.Del>
			</Table.Col>}
			<Table.Empty>Нет {terms.groups_gen}</Table.Empty>
		</Table>
	</>
}

const diff_transform = {
	gcode: null
	, tid: null
	, doctype: (e,orginfo)=>
		(new Map(
				orginfo.orgtree_doctypes?.map(e=>[e.doctype,e]) ?? []
			)
		).get(+e)?.typename ??
		e ? `[id: ${e}]` : ''
}