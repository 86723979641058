import React, {useState, useMemo, useContext} from 'react'

import {ModalButton, showModal, PopupFrame} from 'azlib/components/modals'

import {ModalEdit, fakeEvent} from 'azlib/components/std-controls'

import { monster,  decode as decodeMonster } from 'config/mega_monster'

import {Chooser} from 'azlib/components/chooser'

import Sorter from 'azlib/components/list_sort'
import css from 'azlib/components/chooser.module.css'
import {OrgInfoContext} from "../org/org";
export function has_spec_code(ed_level) {
	return ed_level !== 'mn' && ed_level !== 'm';
}

export function get_doc_kinds_by_ed_level(ed_level, fail) {
	return [
		...window.AppBundle.data.doc_kinds.orderedKeys
		.filter(k=>k.startsWith(ed_level+'-'))
		, fail? 'fail' : '' 
	].filter(Boolean);
}

export function group_ed_level_options(app_ed_level, with_empty) {
	let a = []
	for(const i in app_ed_level)
		a.push(i)
	const decode =
		window.AppBundle.data.ed_levels.decode
	return (with_empty? ['',...a]:a).map(k=>
        <option key={k} value={k}>{decode[k]}</option>
	)
}
export function params_options(rows, params, ed_level) {
	return [...new Set(rows.filter(e => e.ed_level === ed_level).map(e => e[params]))].sort().map(e=><option key={e} value={e}>	{e}
	</option>)
}

function groupToMap(array, key) {
	let map = new Map()
	for(const a of array){
		const k = key(a)
		map.set(k, [... (map.get(k)??[]), a])
	}
	return map
}

export function docKindModalSelector(global, ed_level, base_doctypes, tbl) {
	let ed_levels = 
				typeof ed_level === 'string'? [ed_level]
				:
				window.AppBundle.data.ed_levels.orderedKeys
				.filter(e=>e in ed_level)
	//console.log(base_doctypes)
	const decode_levels =
		window.AppBundle.data.ed_levels.decode
	const decode_kinds =
		window.AppBundle.data.doc_kinds.decode
	const Body = () => {
		const [level,setLevel] = useState(
							typeof ed_level === 'string'? ed_level: null
							)
		const [kind,setKind] = useState(null)

		const grouped = useMemo(()=>new Map(
					[ [kind, base_doctypes
						.filter(dt=>dt.kind === kind)]
					, ...groupToMap(base_doctypes
								.filter(dt=>dt.kind.startsWith(level+'-'))
								.filter(dt=>dt.kind !== kind)
						, dt=>dt.kind)
						.entries()
					]
				), [level, kind, base_doctypes])
		const [currentBasetype, setBt] = useState(kind)
		const [copyTemplates, setCopy] = useState(undefined)
		//console.log(base_doctypes, filter_doctypes, kind)

		return <Chooser>
		<div style={{textAlign:"center"}}>{copyTemplates?tbl==='form'?
			'Копирование бланка'
			:'Копирование формы документа':level?'Тип документа':'Уровень образования'}</div>
		{
			!level?
				ed_levels.filter(Boolean).map(level=>
					<Chooser.Button key={level}
					onClick={()=>setLevel(level)}>
					{decode_levels[level]}
					</Chooser.Button>
				)
			:
				!kind?
				get_doc_kinds_by_ed_level(level, false).map(kind=>
					<Chooser.Button key={kind}
						onClick={()=>{setKind(kind); setBt(kind)}}
					>{decode_kinds[kind]}
					</Chooser.Button>
				) :!copyTemplates?<>
						<hr />
					<div style={{textAlign:"center"}}>{decode_kinds[kind]}</div>
						<hr />
					{global || tbl==='form'? <Chooser.ChooseButton key={kind} value={{kind}}>
						{tbl==='form'?
							'Создать новый бланк'
						:'Создать новую форму документа'
						}
					</Chooser.ChooseButton> : null}
					{grouped.size?<button type="button" className={css.simpleChooserRow+" block"} onClick={()=>setCopy(true)}
					>{tbl==='form'?
						'Скопировать существующий бланк'
						:'Скопировать существующую форму документа'
					}</button>:null}
					</>
					:<>
					{
						[...grouped]
						.map(([k,bt])=>
							<React.Fragment key={k}>
								<hr/>
							<button type="button" className="block link" style={{textAlign:"center", minHeight:"0"}}
								onClick={()=>setBt(k)}
							>{currentBasetype === k? '▾' : '▸' }
							 <span>{decode_kinds[k]}</span>
							</button>
								<hr/>
								{bt.length?null:<div style={{marginBottom:"1ex", textAlign:"center"}}>{tbl==='form'?
									'Нет бланков'
									:'Нет форм документов'
								}</div>}
							{	currentBasetype === k &&
								bt.map(dt=>
									<Chooser.ChooseButton
										key={dt.doctype ?? dt.form}
										value={{kind,parent:dt.doctype ?? dt.form}}
									>{dt.typename ?? dt.formname}</Chooser.ChooseButton>						
									)
							}
							</React.Fragment>
						)
					}
				</>
		}
	    </Chooser>
	}
	return showModal(<Body/>, {closeBox:true})
}

export function smart_ed_level(v) {
	return v && v.match(/^([^-]+)/)[1]; //extract ed_level from kind
}
export function hasSpecialities(ed_level) {
	if(!ed_level) return false;
	if(typeof ed_level === 'string')
	    return smart_ed_level(ed_level) in monster;
	for(const i in ed_level)
		if( i in monster )
			return true
    return false;
}

export function MegaMonsterSelect({ed_level, src, ...props}) {
	let [filter, setFilter] = useState({})
	src.sort(([ka,va],[kb,vb])=>Sorter.cmp(ka,kb))

	return <PopupFrame
			style={{display:"flex column"}}
		>
		<div><input onChange={(e)=>{
			let val = e.target.value.trim()
			if(!val) setFilter({})
			if(/^[0-9]/.test(val))
				setFilter({k:val})
			else
				setFilter({v:val.toUpperCase()})
		}} 
			style={{width:"90%", margin:"0 auto 1em auto", display:"block"}}
			  autoComplete="off"
		/>
		</div>
		<div style={{height:"80vh", width:"30em", overflow:"auto", position:"relative"}}>
				<ModalButton
					value=""
					className="link block"
				>
					<div style={{width:"5em", position:"absolute"}}>--.--.--</div>
					<div style={{marginLeft:"6em"}}>&nbsp;</div>
				</ModalButton>
		{
			src
			.filter(([k,v])=>
				filter.k? k.startsWith(filter.k)
				: filter.v? v.toUpperCase().indexOf(filter.v) >= 0
				: true
			)
			.map(([k,v])=>
				<ModalButton key={k} 
					value={k}
					className="link block"
					disabled={/^\d{2}\.00\.00$/.test(k)}
				>
					<div style={{width:"5em", position:"absolute"}}>{k}</div>
					<div style={{marginLeft:"6em"}}>{v}</div>
				</ModalButton>
			)
		}
		</div>
	</PopupFrame>
}

export function MegaMonsterEdit({ed_level, orginfo, ...props}) {
	const el = smart_ed_level(ed_level)
	const custom = 
		!orginfo.app_skip_license && orginfo.app_spec_list
		&& orginfo.app_spec_list[el]

	let src = custom ? Object.keys(custom)
						.map(k=>[k, custom[k][0] ])
				: monster[el];

	return <ModalEdit {...props}
		onChange={e=>{
			props.onChange(e)
			const code = e.target.value;
			if(props.name2) {
				props.onChange(fakeEvent(props.name2, 
							code ? custom? custom[code][0]
									:
									decodeMonster(code)
							: '' 
						))
			}
			if(props.name3 && custom) {
				props.onChange(fakeEvent(props.name3, code ? custom[code][1] : ''))
			}			
		}}
		dialog={<MegaMonsterSelect src={src} />} closeBox
		text={(code,placeholder)=>
			<span className="customArrow">{code||placeholder}</span>
		}
	/>
}

const exp = {has_spec_code, get_doc_kinds_by_ed_level, group_ed_level_options}

export default exp;

