import React, {useRef, useMemo} from 'react'

import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

export function Canvas({wh, setWH, scan, pdf, pageNumber, children}) {
	let refPage = useRef(null);
	let pdfUrl = useMemo(()=>({url:pdf}),[pdf])
	//console.log(wh)
	if(pdf) {
		//console.log(pdf)
		return <div>
				<div className="draggabeCanvas"
					style={{
						position:"absolute"
						, width: wh.w + "mm"
						, height: wh.h + "mm"
						, zIndex: 1
					}}
				>
					{children}
				</div>
				<Document
					file={pdfUrl}
				>
				<Page pageNumber={pageNumber}
					canvasRef={refPage}
					scale={96/72}
					onLoadSuccess={(page)=>{
						console.log(page.width, refPage.current.offsetWidth)
						setWH({
								w:refPage.current.offsetWidth * 25.4 / 96
							,	h:refPage.current.offsetHeight  * 25.4 / 96
							})
					}}
				/>
				</Document>
			</div>
	}
	if(scan)
		return <div className="draggabeCanvas"
		style={{
			backgroundImage: `url(${scan??''})`
			, backgroundRepeat: "no-repeat"
			, backgroundSize: "100% 100%"
  			, backgroundPosition: "top left"			
  			, width: wh.w+"mm"
			, height: wh.h+"mm"
			, position: "relative"
		}}
	>{children}</div>
}