import React from 'react';

export function isFixedPosition(e) {
  while(e) {
    if(e === window.document.body)
      return false;
    e = e.offsetParent
  }
  return true;
}

export function getXY(elem) {
	var box = elem.getBoundingClientRect();

  var fixed = isFixedPosition(elem);

	var body = document.body;
	var docElem = document.documentElement;

	var scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
	var scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft;

	var clientTop = docElem.clientTop || body.clientTop || 0;
	var clientLeft = docElem.clientLeft || body.clientLeft || 0;

	var top  = fixed? box.top : box.top +  scrollTop - clientTop;
	var left = fixed? box.left : box.left + scrollLeft - clientLeft;

	return { x: Math.round(left), y: Math.round(top) 
			, w: Math.round(box.right) - Math.round(box.left)
			, h: Math.round(box.bottom) - Math.round(box.top)
      , fixed
		}
}



function resizeTextArea (e, paddingTop) {
    var sx = window.scrollX;
    var sy = window.scrollY;
    let r = {}
    e.style.paddingTop = '0px';
    e.style.overflow = 'hidden';
    e.style.width = '100%';
    r.w = e.style.width = e.offsetWidth+'px';
    e.style.height = 'auto';
    r.h = e.style.height = e.scrollHeight+'px'
    e.style.width = '100%';
    e.style.overflowX = 'auto';
    e.style.overflowY = 'hidden';
    if (paddingTop && e.value.length < 53){
      e.style.paddingTop = parseInt(e.style.height) / 2-10 + 'px';
    }
    window.scrollTo(sx,sy);
    //console.log('R', r);
    return r;
  }


export class AutosizeTextarea extends React.Component {
  constructor(props) {
    super(props);
    this.paddingTop = props.addpadding;
    this.textarea = React.createRef();
  }
  componentDidMount() {
    //console.log(this.textarea)
    resizeTextArea(this.textarea.current, this.paddingTop)
  }
  onInput = (event) => {
    this.props.onChange?.(event)
    resizeTextArea(event.target, this.paddingTop)
  }
  render() {
    if(this.textarea.current && this.textarea.current.value !== this.props.value) {
      console.log(this.textarea.current , this.textarea.current.value, this.props.value)
      this.textarea.current.value = this.props.value
      resizeTextArea(this.textarea.current, this.paddingTop)
    }
    return <textarea ref={this.textarea}
        {...this.props} 
        onChange={this.onInput}
      />
  }
}

export const useDebouncedEffect = (effect, deps, delay) => {
  React.useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
}

/**
 * call ref initialization once
 */
export const useOnce = init => {
  let ret = React.useRef();
  if(ret.current === undefined) {
    ret.current = init()
  }
  return ret.current;
}

/**
 * call async ref initialization once
 * initially ref is undefined
 * when init started, it is replaced with null
 * and when it is done, with returned value
 * 
 */
export const useOnceAsync = init => {
  let ret = React.useRef();
  if(ret.current === undefined) {
    ret.current = null;
    init().then( v=> ret.current = v)
  }
  return ret.current;
}
