import React from 'react'
import raw from "raw.macro";
import Markdown from 'markdown-to-jsx'

const md = raw('./faq.md')

function toggle(e) {}

function QGroup(props) {
	return <h2
			onClick={(e)=>toggle(e.target)}
		>{props.children}</h2>
}

export function FaqPage() {
	return <Markdown className="FAQ"
		options={{
			overrides: {
				h2: QGroup
			}
		}}
	>{md}</Markdown>
}
