import {ru_date_text} from 'azlib/components/locales/ru/ru_date'
import {fullPersonName} from 'azlib/components/name_funcs'

function names(ed_level,spec_code) {
	switch(ed_level) {
	case 'hs': case 'hho': case 'hhs': return 's';
	case 'hb': case 'hm': case 'hha': case 'hhd': return 'd';
	case 'mp': return spec_code.match(/^..[.]01[.]..$/) ? 'p'
					: spec_code.match(/^..[.]02[.]..$/) ? 's'
					: null;
	}
	return null;
}

export const calculate_internal = {
	 "Фамилия": row => row.last_name || null
	, "Имя": row => row.first_name || null
	, "Отчество": row => row.middle_name || null
	, "Дата рождения": row => ru_date_text(row.bdate) || null

	, "Наименование организации": row=>
			JSON.parse(row.infoset_orginfo||'{}').name //keep orginal
			|| null
	, "ОГРН": row=>
			JSON.parse(row.infoset_orginfo||'{}').gov_regnum //keep orginal
			|| null
	, "Место нахождения организации": row =>
			JSON.parse(row.infoset_orginfo||'{}').app_place //keep orginal
			|| null

	, "Год выпуска": row => String(row.year_completion) || null

	, "Специальность": row => names(row.ed_level, row.spec_code) === 's' && 
								row.spec_name || null
	, "Код специальности": row => names(row.ed_level, row.spec_code) === 's' &&
								row.spec_code || null

	, "Направление подготовки": row => names(row.ed_level, row.spec_code) === 'd' &&
								row.spec_name || null
	, "Код направления подготовки": row => names(row.ed_level, row.spec_code) === 'd' &&
								row.spec_code || null

	, "Профессия": row => names(row.ed_level, row.spec_code) === 'p' &&
								row.spec_name || null
	, "Код профессии": row => names(row.ed_level, row.spec_code) === 'p' &&
								row.spec_code || null

	, "Квалификация": row => row.qualification || null

	, "Присвоенный квалификационный разряд, класс, категория": row => row.qualification_category || null

	, "Наименование профессий рабочих, должностей служащих": row => row.area_proffessional_program || null

	, "Реестровый номер": row => row.our_number || null

	, "Аккредитованная программа": row => row.gov_doc? 'Да' : null
}

export const internal_fields = [
	'docid', 'ed_level', 'doctype_kind'
	, 'doctype_used', 'doctype_infoset', 'infoset'
	, 'last_name', 'first_name', 'middle_name'
	, 'bdate', 'replaced'
	, "year_completion", "spec_code", "spec_name", "qualification"
	, "qualification_category", "area_proffessional_program"
	, "our_date"
	, "our_number"
	, "signers"
	, "infoset_orginfo"
	, 'tid'
	, "gov_doc"
]

export function is_gov_doc(row, orginfo) {
	return orginfo.app_spec_list[row.ed_level]
	 	   ?.[row.spec_code||'']?.[2]
}

/*
cases:
1) no value
	name => null
2) empty value
	name => ''
3) has value
	name => string
4) value with unit
	name => string + unit
	* unit value we take from template(!)
	* and attach to value
5) value + value2
	name => string + '|' + string
	we can split by '|'
5) value + unit + value2
	name => string + unit + '|' + string

import:
	when imported
	we parse ONE string according to split rules
	we do not edit imported values
	so infoset can hold anythyng
	parseInfosetValue+adjust_infoset 
		can parse it (now they understand name-value pairs)
	
*/


export const predefinedUnitsMap = {
	'баллы' : ['балл','балла','баллов']
	, "часы" : ['час','часа','часов']
	, "дни" : ['день','дня','дней']
	, "недели" : ['неделя','недели','недель']
	, "месяцы" : ['месяц','месяца','месяцев']
	, "годы" : ['год','года','лет']
	, "рубли" : ['рубль','рубля','рублей']
	, "штуки" : ['штука','штуки','штук']
	, "люди" : ['человек','человека','человек']
}

/*

props

on the server we can build from signers

or put signers out of signed text

in print parse signers and extend infoset with it's items

*/