import React, {useContext, useEffect, useState, useMemo} from 'react'
import {useDebouncedEffect} from 'azlib/components/ui_helpers'
import {OrgInfoContext} from 'org/org'
import {statusSorter, kindSorter, combineSorters} from 'config/common'
import {ModelDecode} from 'azlib/components/std-controls'
import {Link} from "react-router-dom";
import styles from './statistics.module.css'
import {ReactComponent as QuestionGrey} from './../question_grey.svg'

import img_card from './stat-img/card.png'
import img_scroll from './stat-img/scroll.png'
import img_cap from './stat-img/cap.png'
import img_sigma1 from './stat-img/sigma1.png'
import img_sigma2 from './stat-img/sigma2.png'

const img_info = {
	"":  { img: img_card, op: 50 }
	,"st-check":  { img: img_card, op: 60 } 
	//,"st-verification":  { img: img_card, op: 80 }
	,"doc-ready":  { img: img_scroll, op: 40 }
	,"doc-fill":  { img: img_scroll, op: 50 }
	,"doc-check":  { img: img_scroll, op: 60 }
	,"doc-print":  { img: img_scroll, op: 70 }
	,"doc-sign":  { img: img_scroll, op: 80 }
	,"doc-signed":  { img: img_scroll, op: 90 }
	,"issued-ok":  { img: img_cap, op: 100 }
	,"issued-replaced":  { img: img_cap, op: 70 }
	,"issued-replacing":  { img: img_cap, op: 70 }
	,"issued-rejected":  { img: img_cap, op: 50 }
}

function yearSorter(a,b) {
	return (
		a.year-b.year
	)
}


const fields = ["doctype_kind", "year", "count", "tid", "status", "path"]
export function StatisticsList({status, gcode, ...props}) {
    const orginfo = useContext(OrgInfoContext)
	const date = new Date();
	let [orgCheck, setOrgCheck] = useState(false);
	let [table, setTable] = useState()
	let [issued, setIssued] = useState()
	let [year, setYear] = useState(Number(date.getFullYear())-8)
	let [year_till, setYearTill] = useState(Number(date.getFullYear()))

	let setFieldMass = (array) => {
		if (issued) { array.sort(kindSorter) }
		let year_doc = []
		let doctype_kind = []
		let rds = []
		for (const row of array){
			if(issued){
				if (!year_doc.includes(row.year) && row.status===issued) {year_doc.push (row.year); }
				if (row.status===issued)rds.push(row)
			}else{
				if (!year_doc.includes(row.status) && row.status.substring(0,3)==='doc') {year_doc.push (row.status); }
				rds.push(row)
			}
			if (!doctype_kind.includes(row.doctype_kind)) {doctype_kind.push (row.doctype_kind); }
		}

		function CreateTable ( ){
			let arr = [
				...doctype_kind.map(k=>[])
			];
			const keyYorS = (issued? 'year' : 'status');
			let groupRds = rds.reduce((acc, obj) => {
				let index = acc.findIndex((elm) => {return elm.doctype_kind == obj.doctype_kind && elm.year_status == obj[keyYorS];})
				if (index == -1) {
					acc.push({doctype_kind: obj.doctype_kind, year_status: obj[keyYorS], 'count': obj.count});
				}
				else { acc[index].count += obj.count; }
				return acc;
			}, []);

			groupRds.forEach((elm) => {
				let dk = doctype_kind.indexOf(elm.doctype_kind);
				let ys = year_doc.indexOf(elm.year_status);
				arr[dk][ys] = elm.count;
			})

			return arr
		}

		let table = CreateTable()

		setTable({
			cols: year_doc
			, rows: doctype_kind
			, cells: table
			, issued : issued
		})
	}

	let server_table = useMemo( ()=>
			orginfo.DbX.fetch_rds("doc_statistics", fields, { "path*": orginfo.path})
		,  [orginfo] )

	let [debYears, setDebYears] = useState(0)
	useDebouncedEffect(()=>
		setDebYears({year, year_till} )
	, [year, year_till], 1000)

	useEffect(()=>{
		(server_table?? Promise.resolve([]))
		.then(rows=>rows.map(r=>({...r
				, year:r.year??''
				, doctype_kind:r.doctype_kind??''
			}))
			.filter(r=> issued? debYears.year <= r.year && r.year <= debYears.year_till:true)
			.filter(r=>(orgCheck) ? r.tid === orginfo.tid:true)
		)
		.then(rows => {
			setFieldMass(rows.sort(
					combineSorters([statusSorter, yearSorter, kindSorter])
				));
		})
	}, [server_table, issued, debYears, orgCheck])

	//console.log('C', table)
	
    return <>
    <h1>Статистика</h1>
		{!table? <div className="loading">Загрузка...</div>:
		<>
			<div className="templatesSearch backgroundAngular flexContainer" style={{position:"sticky", top:0, zIndex:9, background:"rgba(196,209,226,1)"}}>
			<select value={issued} onChange={(e) => setIssued(e.target.value)}>
				<option value="">Документы в работе</option>
				<option value="issued-ok">Выпущенные документы</option>
				<option value="issued-rejected">Отмененные документы</option>
				<option value="issued-replacing">В процессе замены</option>
				<option value="issued-replaced">Замененные документы</option>
			</select>
			{table.issued &&
				<><span style={{marginInline:"1em", margin:"auto 1em"}}>c</span>
					<input value={year} className="FilterInput"
						   style={{width:"5em"}}
						   onChange={e=>setYear(e.target.value)}
						   autoComplete="off"
					/>
					<span style={{marginInline:"1em", margin:"auto 1em"}}>по</span>
					<input value={year_till} className="FilterInput"
						   style={{width:"5em"}}
						   onChange={e=>setYearTill(e.target.value)}
						   autoComplete="off"
					/>
				</>
			}
			{
				<div style={{margin:"auto 0 auto auto"}}>
					<span>Без вложенных:{' '}</span>
					<input type="checkbox" value = {orgCheck} checked={orgCheck} onChange={() => setOrgCheck(!orgCheck)} />
					<QuestionGrey title="скрывает все документы, выпущенные элементами структуры" style={{height:"1em"}}/>
				</div>
			}
			</div>
			<table className={styles.statTable}>
			<thead>
			<tr>
				<td className={styles.nun}  />
				{table.cols.map(c=><th className={styles.header} key={c}>
					{table.issued?
						c:
						<ModelDecode value={c} modelData="doc_status" />}
				</th>)}
				<th className={styles.header}>∑</th>
			</tr>
			</thead>
			<tbody>
			{
				table.rows.map((r,i)=><tr key={r}>
					<th className={styles.doctype}>
					{r === 'no_doctype'? ' '
					:	<ModelDecode value={r} modelData="doc_kinds" />
					}
					</th>
					{
						table.cols.map((c,j)=>
							<td className={styles.count} key={c}>
							{table.cells?.[i]?.[j] &&
								<Link to={table.issued?
									`/org/${orginfo.tid}/issued?status=${issued}&year_completion=${c}&doctype_kind=${
										r === 'no_doctype'? '' : r
									}`
									:
									`/org/${orginfo.tid}/docs?path=${orginfo.path}&status=${c}&doctype_kind=${ //
										r === 'no_doctype'? '' : r
									}`
								}>
									<img src={img_info[c]?img_info[c].img:img_info['issued-ok'].img}
										style={{opacity:img_info[c]?img_info[c].op+'%':img_info['issued-ok'].op+'%'}}
										alt=""
									/>
									<span>
										{table.cells?.[i]?.[j]??''}
									</span>
								</Link>
							}
							</td>
						)
					}
					<td className={styles.count + ' ' + styles.count_sum}>
						<Link to={table.issued?
							`/org/${orginfo.tid}/issued?status=${issued}&year_completion[]=${table.cols}&doctype_kind=${
								r === 'no_doctype'? '' : r
							}`
							:
							`/org/${orginfo.tid}/docs?path=${orginfo.path}&doctype_kind=${
								r === 'no_doctype'? '' : r
							}`
						}>
							<img src={img_sigma1}
								 style={{opacity:'90%'}}
								alt=""
							/>
							<span>
							{table.cols.reduce((a,c,j)=> a+(table.cells?.[i]?.[j]??0), 0)}
							</span>
						</Link>
					</td>
					<th className={styles.doctype}>
					{r === 'no_doctype'? ' '
					:	<ModelDecode value={r} modelData="doc_kinds" />
					}
					</th>
				</tr>)
			}
			<tr>
				<th className={styles.doctype}>∑</th>
				{
					table.cols.map((c,j)=>
						<td className={styles.count + ' ' + styles.count_sum} key={c}>
							<Link to={table.issued?`/org/${orginfo.tid}/issued?status=${issued}&year_completion=${c}`
								:`/org/${orginfo.tid}/docs?path=${orginfo.path}&status=${c}`}>
								<img src={img_info[c]?img_info[c].img:img_info['issued-ok'].img}
									 style={{opacity:img_info[c]?img_info[c].op+'%':img_info['issued-ok'].op+'%'}}
										alt=""
								/>
								<span>
								{
									table.rows.reduce((a,c,i)=>a+
											(table.cells?.[i]?.[j]??0)
										,0)
								}
							</span>
							</Link>
						</td>
					)
				}
				<td className={styles.count + ' ' + styles.count_sum}>
					<Link to={table.issued?`/org/${orginfo.tid}/issued?status=${issued}`:`/org/${orginfo.tid}/docs?path=${orginfo.path}&`}>
						<img src={img_sigma2}
							 style={{opacity:'90%'}}
										alt=""
						/>
						<span>
						{table.cols.reduce((a,c,j)=>a+
								table.rows.reduce((a,c,i)=>a+(table.cells?.[i]?.[j]??0),0)
							, 0)
						}
						</span>
					</Link>
				</td>
				<th className={styles.doctype}>∑</th>
			</tr>
		</tbody>
		<tfoot>
		<tr>
			<td className={styles.nun}  />
				{table.cols.map(c=><th className={styles.header} key={c}>
					{table.issued?c:<ModelDecode value={c} modelData="doc_status" />}</th>)}
			<th className={styles.header}>∑</th>
		</tr>
		</tfoot>
	</table>
	</>
   }
   </>
}