import React, {useRef, useState} from 'react'
import {Conditional, Calculate, formikExtra, useFormContext, cancelLocalEdit} from 'azlib/components/formik-fields'
import {seat_code} from 'azlib/components/local_storage'

import {later, toLocalTime} from 'azlib/components/helpers'

import {alert} from 'azlib/components/modals'

import {shardedDbX} from 'azlib/components/db'
import localDb from 'azlib/components/local-db'
import {askPeer, replyPeer} from 'azlib/components/icc_post'
import {registerThisAsICCmodule} from 'azlib/components/icc_post'

import {fullPersonName} from 'azlib/components/name_funcs'

import localDB  from 'azlib/components/local-db'
import {useFormikContext} from "formik";

import {ReactComponent as Grey_dot} from 'azlib/components/images/grey_dot.svg'
import {ReactComponent as Red_dot} from 'azlib/components/images/red_dot.svg'
import {ReactComponent as Green_dot} from 'azlib/components/images/green_dot.svg'
import {ru_date_text} from "../azlib/components/locales/ru/ru_date";

export function alien_locked(row) {
	return row.row_locker && 
		(row.row_lock_seat !== seat_code() 
		 || row.row_locker !== window.UserInfo.personid);
}

async function lock_row(table,key, DbX) {
	table = localDB.baseStore(table) //FIXME: crul url too
	const r = await DbX.fetch_post_as_json('/app/user/row_locker'
		, {"-t":table, "-k": key, seat: seat_code()} )
	return r && r.row_lock_seat === seat_code() 
			&& r.row_locker === window.UserInfo.personid  
			? r.row_lock_stamp
			: null
}

async function unlock_row(table,key, DbX) {
	table = localDB.baseStore(table) //FIXME: crul url too
	const r = await DbX.fetch_post_as_json('/app/user/row_locker'
		, {"-t":table, "-k": key} )
}

async function not_locked_here(store,key) {
	const local = await localDb.get(store, key)
	return !local;
}

let iccModule = registerThisAsICCmodule('row_locker')
let ask_is_locked= iccModule.entryPoint('is_locked'
	, r => not_locked_here(r.store,r.key)
)

async function check_locked_status(user,peer, store, key) {
	try {
		return await ask_is_locked(user,peer, {store, key})
	} catch(e){
		return null;
	}
}

function LockerState() {
	let [state,setState] = useState(null)
	let [cnt, Next] = useState(0)
	let latch = useRef();

	const fc = useFormContext()
	if(!alien_locked(fc.values)) return; // not locked at all
	if(!iccModule.connected()) {
		later(5*1000)
		.then(()=>Next(c=>c+1));
		return "<wait>";
	}

	if(!latch.current) {
		latch.current = true; // block repeated call
		//  true -> not locked
		//  false -> locked
		//  null -> unknown (some errors in request)
		const store = fc[formikExtra].storeWithFields.store
		const key = fc[formikExtra].rowKey
		check_locked_status(
			fc.values.row_locker
			, fc.values.row_lock_seat
			, store, key
		)
		.then(async unlocked=>{
			setState(unlocked)
			if(unlocked) {
				// row unlocked, so clear our local flags
				await fc.setFormikState(prev=>
				({...prev
					, status: {...prev.status, skipLocalSave: true}
					, values: {...prev.values, 
									row_locker: ''
									, row_lock_seat: ''
									, row_lock_stamp: ''
									, row_locker$: {
										last_name: ''
										, first_name: ''
										, middle_name: ''
									}
					} 
				}))	
			} else {
				later(5*1000)
				.then(()=>{
					latch.current = false; 
					// rerender!
					Next(c=>c+1)
				})
			}
		})
	}
	return state === false? <Green_dot title="Пользователь сейчас на сайте"/>
			: state === null ? <Grey_dot title="Пользователь сейчас не активен"/>
			: <Red_dot title="Запись разблокирована"/>
}


export function ShowLocker({show, readOnly}){
	const fc = useFormikContext();
	return 	<Conditional name="row_locker" when={l=>l}>
		<Calculate extra="row_locker{last_name first_name middle_name} row_lock_seat row_lock_stamp"
		>{row=>
			row && <div style={{marginLeft:"2em"}}><h3>
				{row.row_locker === window.UserInfo.personid &&
				row.row_lock_seat === seat_code()
					?'Редактируете Вы':<>Начиная с {toLocalTime(row.row_lock_stamp).substr(11,5)} {ru_date_text(toLocalTime(row.row_lock_stamp).substr(0,10))} редактирует {fullPersonName(row.row_locker$)}<LockerState/>{show?<button disabled={readOnly} style={{margin:"0.5em 0"}} onClick={async()=>{
						await cancelLocalEdit(fc,cancelLock)
						if(await lockToEdit(fc)) {
							await fc.setStatus({...fc.status, modified: true})
						}
					}}>Забрать себе</button>:null}</>}
			</h3>
			</div>
		}</Calculate>
	</Conditional>
}

export async function lockToEdit(fc) {
	const store = fc[formikExtra].storeWithFields.store
	const key = fc[formikExtra].rowKey

	const stamp = await lock_row(store,key, shardedDbX(fc.values.tid))
	if(stamp) {
		//locked!
		const lock = {
			row_locker: window.UserInfo.personid
			, row_lock_seat: seat_code()
			, row_lock_stamp: stamp
			, row_locker$: {
				last_name: window.UserInfo.last_name
				, first_name: window.UserInfo.first_name
				, middle_name: window.UserInfo.middle_name
			}
		}
		await fc.setFormikState(prev=>
		({...prev
			, status: {...prev.status, skipLocalSave: true}
			, values: {...prev.values, ...lock} 
		}))
		return lock;
	} else {
		const lock = await
			shardedDbX(fc.values.tid)
				.fetch_row(store,"row_lock_seat row_lock_stamp row_locker{. last_name first_name middle_name}", key)
		await fc.setFormikState(prev=>
		({...prev
			, status: {...prev.status, skipLocalSave: true}
			, values: {...prev.values, ...lock} 
		}))
		alert("Редактируется другим пользователем или на другом рабочем месте")
		return null;
	}
}

export async function saveIfOwned(fc) {
	const store = fc[formikExtra].storeWithFields.store
	const key = fc[formikExtra].rowKey
	const lock = await
		shardedDbX(fc.values.tid)
			.fetch_row(store,"row_lock_seat row_lock_stamp row_locker{. last_name first_name middle_name}", key)
	if(lock.row_lock_seat === seat_code()
		&& lock.row_locker === window.UserInfo.personid
		&& lock.row_lock_stamp === fc.values.row_lock_stamp) {
		//we are lock owner! so, save and clear lock
		await fc.setFormikState(prev=>
		({...prev
			, status: {...prev.status, skipLocalSave: true}
			, values: {...prev.values, 
							row_locker: ''
							, row_lock_seat: ''
							, row_lock_stamp: ''
							, row_locker$: {
								last_name: ''
								, first_name: ''
								, middle_name: ''
							}
			} 
		}))
		await later(100)	
		return true;
	}
	cancelLocalEdit(fc)
	alert("Изменено на другом рабочем месте!")
	return false;
}
export async function saveIfOwnedOrFree(fc) {
	const store = fc[formikExtra].storeWithFields.store
	const key = fc[formikExtra].rowKey
	const lock = await
		shardedDbX(fc.values.tid)
			.fetch_row(store,"status row_lock_seat row_lock_stamp row_locker{. last_name first_name middle_name}", key)
	if(lock.row_lock_seat === '' && lock.row_locker === '' && lock.status === fc.values.status) {
		await later(100)
		return true;
	}
	else if(lock.row_lock_seat === seat_code()
		&& lock.row_locker === window.UserInfo.personid
		&& lock.row_lock_stamp === fc.values.row_lock_stamp) {
		//we are lock owner! so, save and clear lock
		await fc.setFormikState(prev=>
			({...prev
				, status: {...prev.status, skipLocalSave: true}
				, values: {...prev.values,
					row_locker: ''
					, row_lock_seat: ''
					, row_lock_stamp: ''
					, row_locker$: {
						last_name: ''
						, first_name: ''
						, middle_name: ''
					}
				}
			}))
		await later(100)
		return true;
	}
	// another one edited our record
	cancelLocalEdit(fc)
	alert("Изменено на другом рабочем месте!")
	await cancelLocalEdit(fc);
	return false;
}

export function clearLockerFields(values) {
	return {
		...values
		, row_locker: ''
		, row_lock_seat: ''
		, row_lock_stamp: ''
		, row_locker$: {
			last_name: ''
			, first_name: ''
			, middle_name: ''
		}
	}
}

export async function cancelLock(fc) {
	const store = fc[formikExtra].storeWithFields.store
	const key = fc[formikExtra].rowKey
	await unlock_row(store,key, shardedDbX(fc.values.tid))
	await fc.setFormikState(prev=>
	({...prev
		, status: {...prev.status, skipLocalSave: true}
		, values: clearLockerFields(prev.values) 
	}))	
	return true
}