import React, {useContext, useEffect, useState} from 'react';

import {
  Link,
  useNavigate
} from "react-router-dom";

import {
	DbFormik
	, LabeledField, FormValues
	, ModelField, Calculate
	, SwitchModificationState, IfSaved, IfModified
	, StatusDriver
} from 'azlib/components/formik-fields'

import {DoctypeModalSelector} from 'config/doctype_selector'


import { DbTable as Table } from 'azlib/components/super-table'
import Filter from 'azlib/components/list_filter'

import {useTypedParams} from 'azlib/components/router_helpers'

import {combine_arrays, object_struct_diff} from 'azlib/components/diff_helpers'

import {prompt, alert} from 'azlib/components/modals'

import {ModelDecode, ModalEdit, FieldSelect} from 'azlib/components/std-controls'

import {OrgInfoContext} from 'org/org'
import UserInfoContext from 'UserInfo'

import {smart_ed_level, docKindModalSelector} from 'config/ed_levels_map'

import {FormStructureEditor, caption_format} from 'config/docform_structure'

import {PopupLog} from 'contingent/log'

import {ShowLocker, lockToEdit, saveIfOwned, cancelLock, alien_locked} from 'config/row_locker'

import { SearchFilter } from 'azlib/components/search_filter'

import {ReactComponent as Left} from './arrows/left.svg'
import {ReactComponent as Right} from './arrows/right.svg'
import {ReactComponent as Up} from './arrows/up.svg'
import {ReactComponent as Down} from './arrows/down.svg'
import {ReactComponent as Hline} from './arrows/hline.svg'
import {ReactComponent as Vline} from './arrows/vline.svg'
import {ReactComponent as QuestionGrey} from './../question_grey.svg'
import css from './docforms.module.css'
import {mainDbX} from "../azlib/components/db";

import {orginfo_changed} from 'config/common'

export function DocPapers() {
	const orginfo = useContext(OrgInfoContext);
	const navigate = useNavigate();

	const [docformsCount, setDocformsCount] = useState(0);
	const [selectedLevel, setSelectedLevel] = useState('');
	
	useEffect(() => {		  
		orginfo.DbX.fetch_rds("doc_forms", 'form kind', { tid: orginfo.tid })
			.then((docformsData) => {
				let data = selectedLevel !== '' ? docformsData.filter(docforms => docforms.kind.split("-")[0] === selectedLevel): docformsData
				setDocformsCount(data.length);
			})
	}, [orginfo, selectedLevel]);

	function handleFilterChange(filterValue) {
		setSelectedLevel(filterValue)
	}

	return <>
		<div
			style={{float:"right", margin:"1em 1em 0 0"}}
		>
			<PopupLog table="doc_forms" tkey={orginfo.tid}
					  fixed={{
						  tname: 'orgtree'
						  ,stname:'doc_forms'}}
					  transform={diff_transform}
					  transformContext={orginfo}
					  deffered={true}
			/>
		</div>
		<h1>Бланки документов</h1>
		<Table className="simpleTable"
			store={orginfo.DbX.store("doc_forms", { tid: orginfo.tid } )}
			extra="parent"
			>
			<Table.Before>
				<div className="templatesSearch backgroundAngular">
					<SearchFilter
						input={
						<Filter.Multi placeholder={"Найти документ"} className="FilterInput"
						extract={
							[
								[/(.+)/, txt =>	row => row.formname 
										&& row.formname.toUpperCase().includes(txt.toUpperCase())
								]
							]
						}
						saveAs="multi"
						/>
						}
					/>
					<div className="flexContainer">
						<Table.Add command={async (ops) => {
							let name = ops.filterLeafs().multi
							let lvl = ops.filterLeafs().level

							let choosen = await docKindModalSelector(orginfo.tid % 10 === 0,
								lvl && lvl in orginfo.app_ed_level && lvl
								|| orginfo.app_ed_level
								, orginfo.orgtree_docforms, 'form')
							if(choosen.parent) {
								let r =
									await orginfo.DbX.fetch_row('doc_forms',
										'form_type definition'
										//+' scan pdf'
										+' paper_width paper_height brochure'
										+' margin_left margin_top margin_right margin_bottom'
										+' font_size line_height'
										+' fields_source'
										+' '
										, [choosen.parent])
								choosen = { ...choosen
									, ...r
								}
							}
							choosen.formname = await prompt("Название", name, true)
							choosen.tid = orginfo.tid
							choosen.form = -1000000000 //hack: impossible generated key
							console.log(choosen)
							if(orginfo.tuner) {
								let key = await orginfo.DbX.save_form('doc_forms', null, choosen)
								navigate(`/org/${orginfo.tid}/forms/${key[0]}`)
							}
							else
								alert("Недостаточно прав!")
						}}>
							Создать новый бланк
						</Table.Add>
					<Filter Element={<select style={{marginLeft:"auto"}}>
						<option value="">Уровень:</option>
						{
							window.AppBundle.data.ed_levels.orderedKeys
							.filter(e=>!!e)
							.filter(e=>e in orginfo.app_ed_level)
							.map(e=><option key={e} value={e}>
									{window.AppBundle.data.ed_levels.decode[e]}
								</option>)
						}
					</select>}
					condition={v => row => row.kind.startsWith(v+'-')}
					saveAs="level"
					changeFilter={handleFilterChange}
				/>
				<div className="backgroundRounded" style={{textAlign:"center", padding:"1em 1em", color:"#3C5773", lineHeight:"1em"}}>
					<label>Количество бланков: {docformsCount}</label>
				</div>
				</div>
				</div>
			</Table.Before>
			<Table.ModelCol name="kind">
				{(view,value,row) =>
					<Link to={`/org/${orginfo.tid}/forms/${row.form}`}>
						{view}
					</Link>
				}
			</Table.ModelCol>
			<Table.Col label="Название" name="formname">
				{(value,row) =>
					<Link to={`/org/${orginfo.tid}/forms/${row.form}`}>
						{value || '--???--'}
					</Link>
				}
			</Table.Col>
			<Table.ModelCol name="form_type">
				{(_,value,row) =>
					<Link to={`/org/${orginfo.tid}/forms/${row.form}`}>
						{value}
					</Link>
				}
			</Table.ModelCol>
			<Table.Col label="Статус" name="active">
				{(value, row) =>
					value? 'активирован': 'не активирован'
				}
			</Table.Col>
			<Table.Col label="" colKey="@del" className="del-paddings-del-button">
			<Table.Del confirm disabled={!orginfo.tuner}
					command={async (row,ops)=>{
						await ops.remove(row);
						orginfo_changed(orginfo);
					}}
				/>
			</Table.Col>
		</Table>
		</>
}

export function DocPaper(props) {
	const orginfo = useContext(OrgInfoContext);
	const uinfo = useContext(UserInfoContext);
	const params = useTypedParams();
	const navigate = useNavigate();
	const store = 'doc_forms';
	const savedState = (v,fc) => !fc.status.modified || !orginfo.tuner;
	return (
	<DbFormik
		store={orginfo.DbX.store(store, { tid: orginfo.tid })} 
		rowKey={[params.Form]}
		onKeyChanged={key =>
			navigate(`/org/${orginfo.tid}/forms/${key[0]}`, { replace: true })
		}
	>
		<Calculate name="kind">{(kind,row)=><>
			<div
				style={{float:"right", margin:"1em 1em 0 0"}}
			>
				<PopupLog table="orgtree" tkey={orginfo.tid}
						  fixed={{stname:"doc_forms", stkey:row.form}}
						  transform={diff_transform}
						  transformContext={row}
				/>
			</div>
		<h1>
				<ModelDecode modelData="doc_kinds" value={kind} /> : бланк
		</h1>
		</>}</Calculate>

		<ShowLocker show={orginfo.tuner || uinfo.userAreas.sysop || uinfo.userAreas.admin} readOnly={!orginfo.tuner}/>
		<div className="templatesSearch">
		<div className="backgroundRounded templatesSearch">
			<div className="flexContainer">
		<SwitchModificationState readOnly={!orginfo.tuner}
			fastDoNotEdit={alien_locked}
			superusers={uinfo.userAreas.admin || uinfo.userAreas.sysop}
			canEdit={lockToEdit}
			prepareSave={saveIfOwned}
			cancelEdit={cancelLock}
		/>
		<IfModified fastDoNotEdit={alien_locked} superusers={uinfo.userAreas.admin || uinfo.userAreas.sysop}>
			<StatusDriver name="active" readOnly={!orginfo.tuner}
				prepareSave={saveIfOwned}
				map={{
					'': [{to: true, text:'Активировать'}]
					, true: [{to:'', text:'Деактивировать'}]
				}}
			/>
		</IfModified>
			</div>
			<div className="gridContainer" style={{gridTemplateColumns:"40% 40% 10% 10%"}}>
		<ModelField name="formname" readOnly={savedState}/>
				<Calculate name="form_type">{(ft,row)=>
			<LabeledField label="Тип бланка" name="form_type" readOnly={savedState}
				as={FieldSelect}>
				<option value=""></option>
				{
					window.AppBundle.data.doc_forms.decode[row.kind]
					.map(t=><option key={t} value={t}>{t}</option>)
				}
			</LabeledField>
				}</Calculate>
		{ (orginfo.tid % 10 === 0) && // if org is in main shard
			<ModelField name="global_template" readOnly={savedState} />
		}
			<div className="flexContainer" style={{flexWrap:"nowrap"}}><ModelField name="local" readOnly={savedState} /><QuestionGrey className="question"
			title="Если флаг не установлен - организациям ниже по структуре (элементам структуры) будет доступен данный бланк"/></div>
			</div>
			<div className="gridContainer" style={{gridTemplateColumns:"40% 40% 10% 10%"}}>
		<Calculate name="kind" extra="fields_source{. typename infoset}">{(kind,row)=>
				<LabeledField label="Форма документов" as={ModalEdit} style={{height:"2.5em"}}
					name="fields_source"
				  	tip={"Выбор формы документов для отображения набора параметров"}
					placeholder="-----"
					text={row.fields_source$?.typename}
					narrowValue={async (v) =>
						{
							let infoset = await orginfo.DbX.fetch_column('doc_types','infoset',v.$)
							return {...v,infoset}
						}
					}
					dialog={
						<DoctypeModalSelector
							doctypes={orginfo.orgtree_doctypes}
							ed_level={smart_ed_level(kind)}
						/>}
					closeBox
					readOnly={savedState}
				/>
		}</Calculate>
			</div>
		</div>
		<div className={css.margins_edit + " backgroundRounded templatesSearch"}>
			<div className="flexContainer flexGrow">
				<div>
					<h2>Страница (размер и поля)</h2>
					<div style={{background:"white", margin: "1em auto", width:"550px"}}>
					<div style={{textAlign:"center"
						, margin:"0 1em",  width: "400px"
						, color: "#ccc"
					}}>ширина страницы</div>
					<div style={{margin:"0 1em"
								, width: "400px"
							}}
					>
						<div
							style={{width:"5em", margin:"0 auto"}}
						>
						<ModelField name="paper_width" label="" undecorated readOnly={savedState}/>
						</div>
						<div
							style={{position:"absolute", marginTop:"-13px"}}
						>
								<Left style={{height:14}}/>
								<Hline style={{height:14, width:371}}/>
								<Right style={{height:14}}/>
						</div>
						<small>мм</small>
					</div>
		<div style={{display:"flex", alignItems: "center", paddingBottom:"1em"}}>
				<div
						style={{border:"1px solid #ccc"
							, margin:"0 1em"
							, width: "400px"
						}}>
					<div
						style={{padding:"0 3em"}}
						><ModelField name="margin_top" undecorated="filled" label="" readOnly={savedState}/>
									<small>мм</small>
					</div>

					<div style={{display:"flex", alignItems: "center"}}>
						<div style={{width:"3em"}}
							><ModelField name="margin_left" undecorated="filled" label="" readOnly={savedState}/>
										<small>мм</small>
						</div>
					
						<div style={{border:"1px solid"
							, width: "300px"
							, height: "200px"
							, background: "#eee"
							, textAlign: "center"
							, position: "relative"
							}}>
							<div style={{position: "absolute",
								left: "50%", top: "50%", transform: "translate(-50%, -50%)"
								}}
							>зона печати</div>
						</div>
						
						<div style={{width:"3em"}}
							><ModelField name="margin_right" undecorated="filled" label="" readOnly={savedState}/>
										<small>мм</small>
						</div>
					</div>

					<div 
							style={{padding:"0 3em"}}
						><ModelField name="margin_bottom" undecorated="filled" label="" readOnly={savedState}/>
									<small>мм</small>
					</div>

				</div>

				<div style={{width:"14px", lineHeight:"14px"}}>
					<Up style={{width:14}}/>
					<Vline style={{width:14, height:270}}/>
					<Down style={{width:14}}/>
				</div>
				<div style={{minWidth:"3em"}}>
					<ModelField name="paper_height" style={{padding:"0"}} label="" undecorated readOnly={savedState} />
						<small>мм</small>
				</div>
				<div style={{textAlign:"center"
					, color: "#ccc"
					 , transform: "translateX(-50%) translateX(-1ex) rotate(-90deg)"
					 , transformOrigin: "center"
					 , marginLeft: "1em"
				}}>высота страницы</div>
		</div>
		</div>
		</div>
		<div className="backgroundRoundedDark templatesSearch flexContainerColumn" style={{flexGrow:0}}>
		<ModelField name="brochure" readOnly={savedState} />

			<ModelField name="font_size" readOnly={savedState} />
			<ModelField name="line_height" readOnly={savedState} />

			<ModelField name="scan" readOnly={savedState}
					fileHref={{
						prefix: `/${orginfo.tid}`,
						store, key: [params.Form]
					}}
					accept="image/*"
				/>
			<ModelField name="pdf" readOnly={savedState} 
					fileHref={{
						prefix: `/${orginfo.tid}`,
						store, key: [params.Form]
					}}
					accept="application/pdf"
				/>
		</div>
			</div>
		</div>
		<div className={css.margins_edit + " backgroundRounded"} >
		<FormValues>{row=>
		<LabeledField name="definition"
			rowKey={[params.Form]}
			infoset={row.fields_source$?.infoset}
			as={FormStructureEditor}
			readOnly={savedState}
			paper={{w:row.paper_width, h:row.paper_height}}
			margins={[row.margin_left??20, row.margin_top??20,
						row.margin_right??20, row.margin_bottom??20
					]}
			orginfo={orginfo}
		/>
		}</FormValues>
		</div>
		</div>
	</DbFormik>)
}

function stripName({name,format,view,page,columns,...e}){
	return <div>
		 {view &&<div>x:{view.x} y:{view.y}</div>}
		 {format &&<div>{caption_format(format,columns)}</div>}
		 {page &&<div>стр {page}</div>}
		</div>
}

const diff_transform = {
		tid: null
		, form: null
		, path: null
		, fields_source: null
		, row_locker: null
		, row_lock_seat: null
		, row_lock_stamp: null
		, pdf: e=>'<pdf file>'
		, scan: e=>'<image file>'
		, form_type: (e,ctx,first)=> e
		, "definition.compare":
			(name,o,n) => {
				//console.log(o,n)
				o = o? JSON.parse(o): []
				n = n? JSON.parse(n): []
				let comb = combine_arrays(o,n,(e=>e?.name))
				comb = comb
					.filter(([name,eo,en])=>
							JSON.stringify(eo) !== JSON.stringify(en)
						)
				return comb
					.map( ([name,eo,en]) => 
							({
								name: '['+name+']'
								, o: eo ? stripName(object_struct_diff(eo,en)||{}) : null
								, n: en ? stripName(object_struct_diff(en,eo)||{}) : null
							})
						)
			}
	}
