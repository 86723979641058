import React, {useContext, useState} from 'react'

import {getTimezone, letIn} from 'azlib/components/helpers'
import {combine_arrays} from 'azlib/components/diff_helpers'
import {fullPersonName} from 'azlib/components/name_funcs'
import {PopupMenu} from 'azlib/components/modals'
import {ru_date_text} from 'azlib/components/locales/ru/ru_date'

import {toLocalTime} from 'azlib/components/helpers'

import { DbTable as Table } from 'azlib/components/super-table'
import Sorter from 'azlib/components/list_sort'

import {OrgInfoContext} from 'org/org'

import {PopupLog} from './log'

	
import {ReactComponent as HistorySVG} from './history.svg'
import {mainDbX} from "../azlib/components/db";

function stripName(e) {
	let v = e[1];
	console.log(v)
	if(typeof v === "string") return v;
	if(Array.isArray(v)) return v.join(" | ")
	if(v && v.columns && v.th)
		return `[${v.columns.join(' | ')}]`
	if(v && v.columns )
		return v.columns.join(' | ')
	//return v && JSON.parse(v);
}

export const diff_transform =	{
	tid: null
	, docid: null
	, student: null
	, status_stamp: null
	, status_prev: null
	, doctype_infoset: null
	, signer: null
	, signers: e=> !e? '':
		JSON.parse(e).map(s=>
				`${s.i[0]}: ${s.i[1]} ${s.i[2]} ${s.i[3]}`
			)
	, gcode: (e,orginfo) =>
		(new Map(
			orginfo?.orgtree_groups?
			orginfo.orgtree_groups.map(e=>[+e.gcode,e])
			: []
			)
		).get(+e)?.group_name ?? e ? `[id: ${e}]` : ''
	, signed: null
	, infoset_orginfo: (e) =>
		letIn(e && JSON.parse(e))(e=>e&& <div>
			<div>{e.name}</div>
			<div>{e.gov_regnum}</div>
			<div>{e.app_place}</div>
			<div>{e.app_boss_fio}</div>
			<div>{e.app_boss_position}</div>
		</div>)
	, "infoset.compare": (name,o,n) => {
		o = o? JSON.parse(o): []
		n = n? JSON.parse(n): []
		let comb = combine_arrays(o,n,(e=>e?.[0]+"\n"+(e?.[2]??0)))
		comb = comb
			.filter(([key,eo,en])=> key && key !== "\n" &&
					JSON.stringify(eo) !== JSON.stringify(en)
				)
		return comb
			.map( ([key,eo,en]) => 
					({
						name: '['+key.split("\n")[0]+']'
						, o: eo ? stripName(eo) : null
						, n: en ? stripName(en) : null
					})
				)
	}
}


export function StatusHistory({docid, ...props}) {
	const orginfo = useContext(OrgInfoContext)
	let [rowsLen, setRowsLen] = useState()
	if(rowsLen === undefined) {
		orginfo.DbX.fetch_rds("docs_trace" ,"docid" , {docid:docid})
			.then(a=>setRowsLen(a.length))
	}
	//console.log(orginfo)
	return rowsLen?<PopupMenu
		trigger={
			<HistorySVG style={{height: '45px'}} alt="Показать лог" />
		}
		style={{maxWidth:"90vw", overflow:"auto"}}
		{...props}
	>
		<div className="flexContainer" style={{alignItems: "center"}}
		><PopupLog table="orgtree" tkey={orginfo.tid}
			fixed={{stname:"docs", stkey:docid}}
			transform={diff_transform}
			transformContext={orginfo}
			style={{float:"right"}}
		/>
		<b>Время указано по {getTimezone()}</b></div>
		<Table className="withHiddenCols"
			store={orginfo.DbX.store("docs_trace", { docid } )}
			defaultSorter={(a,b)=>Sorter.cmp(b.stamp, a.stamp)}
		>
			<Table.Col name="stamp">{gmt=> (v=>
				v && 
				ru_date_text(v.substr(0,10))
				)(toLocalTime(gmt))
			}</Table.Col>
			<Table.Col colKey="@stamp" name="stamp">{gmt=> (v=>
				v && 
				v.substr(11,5)
				)(toLocalTime(gmt))
			}</Table.Col>
			<Table.ModelCol name="status"/>
			<Table.ModelCol name="subcode" 
				ifDistinct={[row=>row.subcode, ""]}
			/>
			<Table.ModelCol name="message" 
				ifDistinct={[row=>row.message, ""]}
			/>
			<Table.Col label="ФИО" name="autor"
				extra="autor{last_name first_name middle_name}"
				ifDistinct={[row=>row.autor, ""]}
			>
				{(value,row) =>
						fullPersonName(row.autor$, '')
				}
			</Table.Col>

		</Table>
	</PopupMenu>:null
}
