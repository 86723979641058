import React, {useEffect, useState} from 'react';

import {registerSSEListener, unregisterSSEListener, registerSSEListenerFocused} from './icc'
import {alert} from 'azlib/components/modals'
import {defer} from 'azlib/components/helpers'

export const ConnectionMonitorContext = React.createContext()

export function ConnectionMonitor({connected, disconnected, unknown, children}) {
	const [cc,setCC] = useState()
	useEffect(()=>{
		registerSSEListener('connectionChanged', setCC)
		return ()=>unregisterSSEListener('connectionChanged', setCC)
	}, [setCC])
	return <ConnectionMonitorContext.Provider value={cc}>
				{cc === undefined? unknown
				: cc? connected : disconnected}
				{children}
			</ConnectionMonitorContext.Provider>
}

export const MaintenanceIndicatorContext = React.createContext()

export function MaintenanceIndicator({on,off,children}) {
  const matches = document.cookie.match(/(?:^|; )enable_maintenance=([^;]*)/);

  const cc = matches?.[1] === 'yes';

  return <ConnectionMonitorContext.Provider value={cc}>
				{ cc? on : off}
				{children}
			</ConnectionMonitorContext.Provider>
}

let alerts = defer()
registerSSEListenerFocused('alert', e=>{
		alerts = alerts.then(()=>{
				console.log(e.data)
				return alert(e.data)
		});
})

registerSSEListenerFocused('uinfo-changed', e=>{
	window.localStorage['cachedUinfo'] = ''
})