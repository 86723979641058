import React, {useEffect, useState} from 'react'

import {ModalButton, PopupFrame} from 'azlib/components/modals'

import {FieldSelect, fakeEvent,  ModalEdit} from 'azlib/components/std-controls'


export const ed_regions = [
["Республика Адыгея (Адыгея)","1"]
,["Республика Башкортостан","2"]
,["Республика Бурятия","3"]
,["Республика Алтай","4"]
,["Республика Дагестан","5"]
,["Республика Ингушетия","6"]
,["Кабардино-Балкарская Республика","7"]
,["Республика Калмыкия","8"]
,["Карачаево-Черкесская Республика","9"]
,["Республика Карелия","10"]
,["Республика Коми","11"]
,["Республика Марий Эл","12"]
,["Республика Мордовия","13"]
,["Республика Саха (Якутия)","14"]
,["Республика Северная Осетия - Алания","15"]
,["Республика Татарстан (Татарстан)","16"]
,["Республика Тыва","17"]
,["Удмуртская Республика","18"]
,["Республика Хакасия","19"]
,["Чеченская Республика","20"]
,["Чувашская Республика - Чувашия","21"]
,["Алтайский край","22"]
,["Краснодарский край","23"]
,["Красноярский край","24"]
,["Приморский край","25"]
,["Ставропольский край","26"]
,["Хабаровский край","27"]
,["Амурская область","28"]
,["Архангельская область","29"]
,["Астраханская область","30"]
,["Белгородская область","31"]
,["Брянская область","32"]
,["Владимирская область","33"]
,["Волгоградская область","34"]
,["Вологодская область","35"]
,["Воронежская область","36"]
,["Ивановская область","37"]
,["Иркутская область","38"]
,["Калининградская область","39"]
,["Калужская область","40"]
,["Камчатский край","41"]
,["Кемеровская область - Кузбасс","42"]
,["Кировская область","43"]
,["Костромская область","44"]
,["Курганская область","45"]
,["Курская область","46"]
,["Ленинградская область","47"]
,["Липецкая область","48"]
,["Магаданская область","49"]
,["Московская область","50"]
,["Мурманская область","51"]
,["Нижегородская область","52"]
,["Новгородская область","53"]
,["Новосибирская область","54"]
,["Омская область","55"]
,["Оренбургская область","56"]
,["Орловская область","57"]
,["Пензенская область","58"]
,["Пермский край","59"]
,["Псковская область","60"]
,["Ростовская область","61"]
,["Рязанская область","62"]
,["Самарская область","63"]
,["Саратовская область","64"]
,["Сахалинская область","65"]
,["Свердловская область","66"]
,["Смоленская область","67"]
,["Тамбовская область","68"]
,["Тверская область","69"]
,["Томская область","70"]
,["Тульская область","71"]
,["Тюменская область","72"]
,["Ульяновская область","73"]
,["Челябинская область","74"]
,["Забайкальский край","75"]
,["Ярославская область","76"]
,["Москва","77"]
,["Санкт-Петербург","78"]
,["Еврейская автономная область","79"]
,["Ненецкий автономный округ","83"]
,["Ханты-Мансийский автономный округ - Югра","86"]
,["Чукотский автономный округ","87"]
,["Ямало-Ненецкий автономный округ","89"]
,["Запорожская область","90"]
,["Республика Крым","91"]
,["Севастополь","92"]
,["Донецкая Народная Республика","93"]
,["Луганская Народная Республика","94"]
,["Херсонская область","95"]
,["Иностранное государство","99"]
];

const decoder = new Map()

for(const x of ed_regions)
	decoder.set(x[1], x[0])

export function decode(code) {
	return decoder.get(code)
}

export function RegionSelect(props) {
	return <FieldSelect {...props}>
		<option value="">Регион:</option>
		{ed_regions
			.map(e => <option key={e[1]} value={e[1]}>
				{e[0]}
			</option>)
		}
	</FieldSelect>
}

export function edRegionsObj() {
	let obj = {};
	obj[""] = "";
	ed_regions.forEach((v) => {
		let key = v[0];
		let value = v[1];
		obj[key] = value;
	});
	return obj
}
export function decodeCombined(code) {
	return code ? code + ' ' + decoder.get(code) : code
}

export function EdRegionSelect({...props}) {
	let [filter, setFilter] = useState({})
	return <PopupFrame
			style={{display:"flex column"}}
		>
		<div><input onChange={(e)=>{
			let val = e.target.value.trim()
			if(!val) setFilter({})
			if(/^[0-9]/.test(val))
				setFilter({k:val})
			else
				setFilter({v:val.toUpperCase()})
		}} 
			style={{width:"90%", margin:"0 auto 1em auto", display:"block"}}
			  autoComplete="off"
		/>
		</div>
		<div style={{height:"80vh", width:"30em", overflow:"auto", position:"relative"}}>
				<ModalButton
					value=""
					className="link block"
				>
					<div style={{width:"5em", position:"absolute"}}>---</div>
					<div style={{marginLeft:"6em"}}>&nbsp;</div>
				</ModalButton>
		{
			ed_regions
			.filter(([v,k])=>
				filter.k? k.startsWith(filter.k)
				: filter.v? v.toUpperCase().indexOf(filter.v) >= 0
				: true
			)
			.map(([v,k])=>
				<ModalButton key={k} 
					value={k}
					className="link block"
				>
					<div style={{width:"5em", position:"absolute"}}>{k}</div>
					<div style={{marginLeft:"6em"}}>{v}</div>
				</ModalButton>
			)
		}
		</div>
	</PopupFrame>
}

export function EdRegionEdit(props) {
	return <ModalEdit {...props}
		dialog={<EdRegionSelect />} closeBox
		text={decodeCombined}
	/>
}

export const ed_regions_from_ogrn = (ogrn) => {
	let regionCode = ogrn.substring(3, 5);
	if (regionCode.startsWith('0')) {
		regionCode = regionCode.substring(1);
	}
	const region = ed_regions.find(item => item[1] === regionCode);
	return region ? region[1] : '';
};

export function EdRegionEditInRegOrg(props) {
	const [value, setValue] = useState(props.value || '');

	useEffect(() => {
		setValue(props.value);
		props.fc.setFieldValue("app_region", props.value)
	}, [props.value]);

	return <ModalEdit {...props}
		value={value}
		onChange={e=>{
			props.onChange(e)
			props.onChange(fakeEvent('app_region', e.target.value))
			setValue(e.target.value)
		}}
		dialog={<EdRegionSelect />} closeBox
		text={decodeCombined}
	/>
}