import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {defer, XLOG} from 'azlib/components/helpers'

import 'azlib/components/model.controls.reg'
import {initStores} from 'azlib/components/local-db'
import {growUpMonster} from 'config/mega_monster'
import {updateUa} from 'config/common'

import {seat_code} from 'azlib/components/local_storage'
import {createChannel} from 'azlib/components/icc'

import data from 'app-cfg/model.data.ini'

//console.log('!!!!!!!!', data);


if(window.location.pathname === '/' && window.location.search) {
  console.log('need redirect')
  console.log(window.location)
  window.history.replaceState(null,'', 
    window.location.search.substr(1));
}

function getUinfoCookie() {
    const matches = document.cookie.match(/(?:^|; )uinfo=([^;]*)/);
    return matches?.[1];
}

function getPeerCookie() {
    const matches = document.cookie.match(/(?:^|; )peer_code=([^;]*)/);
    return matches?.[1];
}

function hasAZstate() {
    const matches = document.cookie.match(/(?:^|; )AZstate=([^;]*)/);
    return !!matches?.[1];
}

async function uinfo_cached() {
  if(!hasAZstate()) return {}
  const u = getUinfoCookie()
  if(!u) return {}
  let c = window.localStorage['cachedUinfo']
  c = JSON.parse(c||'{}')
  let s=Date.now()
  if(c.personid !== +u
    || c.stamp < Date.now() - 1*60*1000
  ) {
    //reload
    let r = await fetch('/app/user/user-info?s='+s, {headers: {"Cache-Control": "no-cache"}})
    //let r = await fetch('/app/user/user-info', {headers: {"Cache-Control": "no-cache"}})
    const ts = (new Date(r.headers.get('date'))).valueOf()
    c = await r.json()
    c.shards = c.shards.split(/,\s*/).sort()
    c.stamp = Date.now()
    window.localStorage['cachedUinfo'] = JSON.stringify(c)
    c.ts = ts

  } else {
    //cached
    let r = await fetch('/ping?s='+s, {headers: {"Cache-Control": "no-cache"}})
    //let r = await fetch('/ping', {headers: {"Cache-Control": "no-cache"}})
    c.ts = (new Date(r.headers.get('date'))).valueOf()
  }
  c.peer = decodeURIComponent(getPeerCookie())
  c.mt = Date.now()
  return c
}

uinfo_cached()
.then( updateUa )
.then(()=>
  fetch('/app-bundle?'+(window.UserInfo?.app_bundle_stamp??''))
)
.then( r => r.json() )
.then( json => {
  json.data = data;
  window.AppBundle = json;
  initStores(window.AppBundle.model);
  growUpMonster(window.AppBundle.data);
  console.log('bundle loaded')
})
.then(
  () => {
    ;
    createChannel(`/icc/sub/${window.UserInfo.personid??0}/${seat_code()}`)
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
