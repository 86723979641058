import React, {useState} from 'react'

import {Async} from 'azlib/components/async'

import {in_local_cache} from 'azlib/components/db'

import {Chooser} from 'azlib/components/chooser'

import {confirm} from 'azlib/components/modals'

export function DoctypeModalSelector({doctypes, ed_level, spec_code, withEmpty, header, forGroups}) {
	const aviable = doctypes.filter(e => 
			(!spec_code || !e.spec_code || e.spec_code === spec_code)
			&& e.kind.startsWith(ed_level+'-') 
		)

	const usedKinds = new Set(aviable.map(e=>e.kind))

	const orderedKinds = 
			window.AppBundle.data.doc_kinds.orderedKeys
			.filter(e => usedKinds.has(e) )
			.map(k => ({ k, types: aviable.filter(e=>e.kind === k) }))

	const decode_kinds = (k) =>
		window.AppBundle.data.doc_kinds.decode[k]

	if(forGroups)
		confirm('Новая форма документа будет применена для документов в статусе «подготовлен». Продолжить?')

	const [currentBasetype, setBt] = useState(null)

	return <Chooser>
			<div style={{textAlign:"center"}}>{header}</div>
		<div style={{textAlign:"center"}}>Выбор формы документа</div>
		{
			orderedKinds.map(({k,types}) =>
				<React.Fragment key={k}>
					<hr/>
					<button type="button" className="block link" style={{textAlign: "center", minHeight: "0"}}
							onClick={() => setBt(k)}
					><div style={{textAlign: "center"}}>{currentBasetype === k ? '▾' : '▸'}
						{decode_kinds(k)}</div>
					</button>
					<hr/>
					{currentBasetype === k &&
						<div>
							<Async value={
								Promise.all(
									types
										.map(async e => {
												const local = await in_local_cache('doc_types', [e.doctype])
												return {...e, local}
											}
										)
								)
							} fallback="">
								{types =>
									<>
										{types
											.filter(t => !t.local)
											.map(({doctype, typename, kind}) =>
												<Chooser.ChooseButton key={doctype}
																	  value={{$: doctype, typename, kind}}
												>
													{typename}
												</Chooser.ChooseButton>
											)
										}
										{types.some(t => t.local)
											&& <>
												Редактируются вами:
												{types.filter(t => t.local).map(t =>
													<div className="error">{t.typename}</div>
												)}
											</>
										}
									</>
								}
							</Async>
						</div>
					}
				</React.Fragment>
			)
		}
		{withEmpty?
		<Chooser.ChooseButton value={{$:'',typename:'', kind:''}}>
			{withEmpty === true ? '--- без формы документа ---' : withEmpty}
		</Chooser.ChooseButton>
		:null}
	    </Chooser>
}
