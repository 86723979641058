import React from 'react';

export const OrgInfoContext = React.createContext(null)

export const terminology = {
	def: {
		student: "обучающийся"
		, student_gen: "обучающегося"
		, student_acc: "обучающегося"
		, students: "Обучающиеся"
		, students_gen: "обучающихся"
		, group: "группа"
		, group_gen: "группы"
		, group_acc: "группу"
		, groups: "Группы"
		, groups_gen: "групп"
	}
	, school: {
		student: "обучающийся"
		, student_gen: "обучающегося"
		, student_acc: "обучающегося"
		, students: "Обучающиеся"
		, students_gen: "обучающихся"
		, group: "класс"
		, group_gen: "класса"
		, group_acc: "класс"
		, groups: "Классы"
		, groups_gen: "классов"
	}
	, both: {
		student: "бучающийся"
		, student_gen: "обучающегося"
		, student_acc: "обучающегося"
		, students: "Обучающиеся"
		, students_gen: "обучающихся"
		, group: "Класс/группа"
		, group_gen: "класса/группы"
		, group_acc: "класс/группу"
		, groups: "Классы/ Группы"
		, groups_gen: "классов/групп"
	}
}

function terms_by_level(ed_level) {
	return !ed_level? terminology.both
		: ed_level === 'mn' || ed_level === 'm'? terminology.school
		: terminology.def
}
