import React, {useContext, useEffect, useMemo, useState} from "react";
import {SearchFilter} from "../azlib/components/search_filter";
import {mainDbX} from "../azlib/components/db";
import { DbTableEdit as Table } from 'azlib/components/super-table'
import {emptyOrgFields} from "../contingent/cards";
import Filter from "../azlib/components/list_filter";
import {ed_regions} from "../config/regions";
import {Link} from "react-router-dom";
import {ReactComponent as Img_to_element} from "../org/org-img/to_element.svg";
import {download, exportScheme} from "../org/export";
import {alert} from 'azlib/components/modals'
import {statusSorter} from "../config/common";
import Sorter from "../azlib/components/list_sort";
import {exportAsTable, exportAsXLSX} from "../monitoring";
import XLSX from "xlsx";
export function ExportStatistics({}) {
    async function statistic_file(ops) {
        let filters = ops.filterLeafs()
        let orgs = await mainDbX.fetch_rds("orgtree_statistic"
            , "tid parent_ogrn app_ed_level path pos suborgs act sign action managers\n" +
            "                gov_regnum name contact_person contact_email contact_addr\n" +
            "                app_toplevel_region app_toplevel_place person\n" +
            "                app_region app_place contact_tel is_branch is_struct toplevel"
            , {})
        let numbers = filters.multyOgrn?.match(/(\d{13}(\s\d{13})*)/)
        let lvl = window.AppBundle.data.ed_levels.orderedKeys
            .filter(e => !!e)
            .map(e =>filters[e]?e:null)
            .filter(e => e)

        orgs = orgs.filter(row=>(numbers?filters.multyOgrn.split(' ').filter(e => e).includes(row.gov_regnum) || filters.multyOgrn.split(' ').filter(e => e).includes(row.parent_ogrn):true)
        &&(filters.region?row.app_toplevel_region === filters.region:true)
        &&(filters.allLevel || filters.toplevel&&row.toplevel || filters.is_branch&&row.is_branch ||filters.is_struct&&row.is_struct ||
                !(filters.allLevel||filters.toplevel||filters.is_branch||filters.is_struct))
        &&(!lvl.length||lvl.some(el =>(Object.keys(JSON.parse(row.app_ed_level || '{}'))).includes(el))))
        let data = [];
        orgs.forEach((row) => {
            data.push({
                "ОГРН": row.gov_regnum ? row.gov_regnum : row.parent_ogrn
                , "Наименование": row.name
                , "Заполнение данных организации": emptyOrgFields(row, true)
                , "Пользователи": orgMembers(row, true)
                , "Элементы структуры": orgStruct(row)
            })
        })
        if(data.length) {
            let date = new Date();
            let name = "Статистика_" + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
            exportAsTable(name,data,"csv")
        }
        else await alert("Организации не выбраны")
    }
    return <>
        <h1>Экспорт статистики</h1>
        <div>
            <Table
                className="simpleTable statistic"
                store={mainDbX.store('orgtree_statistic', {})}
                extra="tid parent_ogrn app_ed_level path pos suborgs act sign action managers
                gov_regnum name contact_person contact_email contact_addr
                app_toplevel_region app_toplevel_place person
                app_region app_place contact_tel is_branch is_struct toplevel"
                defaultSorter={(a,b)=>Sorter.cmp(a.path,b.path)}
            >
                <Table.Before>
                    <div className="flexContainer orgFilter">
                        <Filter.And>
                            <SearchFilter style={{width:"100%"}}
                                input={
                                    <Filter.Multi className="FilterInput" placeholder={"ОГРН организации"} saveAs="multyOgrn"
                                                  extract={
                                                      [
                                                          [/(\d{13}(\s\d{13})*)/,
                                                              v => row => v.split(' ').filter(e => e).includes(row.gov_regnum) || v.split(' ').filter(e => e).includes(row.parent_ogrn)
                                                          ]
                                                      ]
                                                  }
                                    />
                                }
                            />


                            <Filter.And>
                        <button className="checkBoxBut"
                        >
                            <div><Filter.Or>
                                <div><Filter.Check
                                    condition={v => row => true}
                                    saveAs="allLevel"
                                /><span>Основные, элементы и филиалы</span></div>
                                <div><Filter.Check
                                    condition={v => v ? row => row.toplevel : row => true}
                                    saveAs="toplevel"
                                /><span>Только основные</span></div>
                                <div><Filter.Check
                                    condition={v => v ? row => row.is_branch : row => true}
                                    saveAs="is_branch"
                                /><span>Только филиалы</span></div>
                                <div><Filter.Check
                                    condition={v => v ? row => row.is_struct : row => true}
                                    saveAs="is_struct"
                                /><span>Только элементы структуры</span></div>
                            </Filter.Or>
                            </div>

                        </button>
                                <button className="checkBoxBut edLevelFilter"><Filter.Or>{
                                    window.AppBundle.data.ed_levels.orderedKeys
                                .filter(e => !!e)
                                .map(e =>
                                    <div><Filter.Check
                                        condition={v => v ? row => JSON.parse(row.app_ed_level || '{}')[e] === "" : row => true}
                                        saveAs={e}
                                    /><span>{window.AppBundle.data.ed_levels.decode[e]}</span></div>
                                )

                        }</Filter.Or></button>
                        <Filter Element={<select>
                            <option value="">Регион</option>
                            {
                                ed_regions
                                    .map(e => <option key={e[1]} value={e[1]}>
                                        {e[0]}
                                    </option>)
                            }
                        </select>
                        }
                                condition={v => row => row.app_toplevel_region === v}
                                saveAs="region"
                        />
                            </Filter.And>
                            </Filter.And>
                        <Table.Fetch command={statistic_file}>
                            Выгрузить файл
                        </Table.Fetch>
                    </div>
                </Table.Before>
                <Table.Col label="ОГРН" name="gov_regnum" extra="tid parent_ogrn"
                >{
                    (v, row) =><div className="flexAlignCenter notWrap">{v?<div className="mainOrg">{v}</div>:row.parent_ogrn}
                        <Link to={`/org/${row.tid}`}><Img_to_element alt="перейти в организацию"/></Link></div>
                }
                </Table.Col>
                <Table.Col name="name" label="Наименование"/>
                <Table.Col label="Заполнение данных организации" name="contact_person"
                           extra="contact_email contact_addr app_region app_place contact_tel
                            app_toplevel_region app_toplevel_place is_branch toplevel"
                >{
                    (v, row) => emptyOrgFields(row)
                }
                </Table.Col>
                <Table.Col label="Пользователи" extra="person pos act sign action"
                >{
                    (v, row) =>  orgMembers(row)
                }
                </Table.Col>
                <Table.Col label="Элементы структуры" extra="managers suborgs"
                >{
                    (v, row) =>orgStruct(row)
                }
                </Table.Col>

                <Table.Empty>Документ не найден</Table.Empty>

            </Table>
        </div>


    </>
}

function orgMembers(row, likeTxt){
    return row.person? (!(row.action || row.sign || row.pos || row.act) ? "Да" : (likeTxt?
        ("Есть ошибки: "
            + (row.action ?"- нет минимального набора функций ":"")
            + (row.sign ? "- нет ролей у подписывающего ":"")
            + (row.pos ? "- не заполнены должности ":"")
            + (row.act ? "- подписывающий не вошел в систему":""))
        :<>
        Есть ошибки:
        {row.action ? <><br/>Нет минимального набора функций</>:"" }
        {row.sign ? <><br/>Нет ролей у подписывающего</>:""}
        {row.pos ? <><br/>Не заполнены должности</>:""}
        {row.act ? <><br/>Подписывающий не вошел в систему</>:""}
    </>)): "Нет пользователей"
}
function orgStruct(row){
    return row.suborgs ? (row.managers ? "Да" : "Нет") : null
}