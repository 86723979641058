import React, {useState, useContext} from 'react';

import {htmlBool, parse_fields, func_or_const, applyPlaceholder} from './helpers' 

import {Loading, Hidden} from './ui_elems' 

import { DbFormikInt, DbFieldCollector } from './formik-fields'

import Filter, {loadFilterSavedState} from './list_filter'
import Sorter, {loadSortSavedState} from './list_sort'

const makeStringKey = (ops, data) => JSON.stringify(ops.getKeyValues(data))

function makeUniqueKey (rule, row) {
	return rule instanceof Function? rule(row)
			: Array.isArray(rule)?
				rule.map(f=>row[f].replace(/\s+/g, ' ').trim())
			: row[rule].replace(/\s+/g, ' ').trim()	
}


function ColGroup() {}
function Before() {}
function After() {}
function Empty() {}

//simple Col:
//children is a function in this case call it
//or element in this case: use as is
function Col({value, rowObj, status, placeholder, children}) {
	return children?
			children instanceof Function 
			? children(value,rowObj, children)
			: children
		:
			applyPlaceholder(value, placeholder) 
		;
}


/*
colspan = sum of childs colspans
rowspan =
	only for cols (not colgroups)
	it's max of all siblings level - our level
*/
function set_rowspans(subcols) {
	const deep = subcols.reduce((p,e)=>Math.max(p,e.deep??1), 0)
	subcols.forEach(e=>{
		//console.log(e.label, e.level, deep)
		if(e.rowSpan) //aready has rowSpan => simple col
			e.rowSpan = deep - e.level + 1
	})
	return deep
}


function mapColTree(children, level, ops) {
	let ret = []
	React.Children.forEach(children, (e) =>{
		if(!e) return;
		if(e.type === ColGroup) {
			let {label, colKey, children, ...props} = e.props
			let x = {
				level //level in tree
				, label: <>{label===true?'':label}</>
				, props
				, subcols: // subcolumns
					mapColTree(children, level+1, ops)
			}
			x.colSpan = x.subcols.reduce((p,e)=>p+e.colSpan, 0);
			x.deep = set_rowspans(x.subcols)
			x.colKey= x.subcols.map(e=>e.colKey).join(':')
			ret.push(x)
			return;
		}
		if(e.type === Before || e.type === After || e.type === Empty)
			return;

		//cols!		
		let patched_props = 
			e.type.columnProps?.(e.props, ops) || e.props;
		let {colKey, name, extra, label, children, calculatedProps
					, ifDistinct, hiddenIf
					, ...props} 
						= patched_props

		if(label === true)
			label = <></>
		else if(typeof label === 'string') 
			label = <>{label}</>

		ret.push({
			level //level in tree
			, colKey: colKey ?? name
			, colSpan: 1
			, rowSpan: 1
			, element: e
			, ifDistinct //(row) => checked value
										// [ (row) => checked value, empty value ]
			, hiddenIf
			, name, extra, label, calculatedProps, props
		})
	})
	//console.log('tree', ret)
	return ret;
}

function toColTree(colsTree, ret, cols) {
	for(const e of colsTree) {
		if(ret.length <= e.level)
			ret.push([])
		ret[e.level].push(e)
		if(e.subcols)
			toColTree(e.subcols, ret, cols)
		else
			cols.push(e)
	}
}


const SuperRowContext = React.createContext(null) 

export function useRowContext(name) {
	return useContext(SuperRowContext);
}

function rowInt(SuperTableShape, tableContext, cols, rowObj, status) {
	return React.cloneElement(SuperTableShape.row
			, SuperTableShape.rowProps(rowObj, status), 
			cols.map(c=>
			React.cloneElement(SuperTableShape.td,
				{ ...c.props
					, ...(c.calculatedProps?.(rowObj, status))
					, "hidden-col": htmlBool( 
									tableContext.hiddenCol?.has(c.colKey)
								)
					, key:c.colKey
				},
				tableContext.hiddenCol?.has(c.colKey)
				? <div style={{display:"none"}}>
					{React.cloneElement(c.element
					, { value:rowObj[c.name], rowObj, status }
					)}
					</div>
				:
					React.cloneElement(c.element
					, { value:rowObj[c.name], rowObj, status }
					)
			)))
}

/**
 * 	rowKey is null when collecting
 * */
function StRow({rawRow, cols, tableContext, globalForm}) {
	let [form, setForm] = useState(null)
	let nTableContext = useContext(SuperTableContext)

	const stringKey = makeStringKey(tableContext, rawRow.data);
	
	let row_context = { 
	 tableContext

	 , key: tableContext.getKeyValues(rawRow.data)
	 , stringKey

	 , setForm
	 , toggleForm : (p, global) => {
	 				if(!global) setForm(form?null:p)
	 				else {
	 					if(globalForm === stringKey)
	 							setForm(form?null:p)
	 					else
	 							setForm(p)
	 					tableContext.globalForm(stringKey)
	 				}
	 			}
	 , form
	 , globalForm
	}

	const rowWithForm = (row, status) => 		
		<SuperRowContext.Provider value={{...row_context, row, status}}>
					{rowInt(tableContext.shape, 
							tableContext, cols, row, status)}
					{(globalForm === null || globalForm === stringKey)
						&& form?
							React.cloneElement(tableContext.shape.form,{},
								func_or_const(form, row, status)
						):null
					}
		</SuperRowContext.Provider>	
 
	const row = tableContext.handleSave?
		<DbFormikInt key={stringKey}
					storeWithFields={tableContext}
    			data={rawRow.data}
    			modified={rawRow.modified}
    			rowKey={row_context.key} 
    			handleSave={tableContext.handleSave}
    			dependencies={tableContext.dependencies}
    			children={formik=>rowWithForm(formik.values,formik.status)}
					onChangeData = {(values, status)=>{
					//console.log('value', values)
					//console.log('status: ', status)
					nTableContext.setSource(values, status)
				}}
		/>
		: rowWithForm(rawRow.data, {modified: rawRow.modified })

	return  row
}

export const SuperTableContext = React.createContext(null) 

export default class SuperTable extends React.Component {
  constructor(props) {
    super(props)

   //very pereliminary tableContext
	this.ops = {
			shape: props.shape
			, store: props.store.store
			, dependencies: props.dependencies
			, handleSave: props.handleSave
		}

	let colsTree = mapColTree(props.children,0, this.ops)
	set_rowspans(colsTree);

	this.headers = []
	this.cols = []
	toColTree(colsTree, this.headers, this.cols);

	const fields = props.store.fieldsCollector(props.extra)
	this.fields = fields;


		for(const e of this.cols) {
			if(e.name) this.fields.add(e.name)
		    if(e.extra)
		        for(const f of parse_fields(e.extra))
		        	this.fields.add(f)
		}
		

	// start with one dummy record
	this.state = {
        	globalForm: null 
        , collector: (name) => fields.add(name)
        , sharedState: {}
    }
  }

	//generate NEW state with row list where 
	// one row replaced (or added) with updated version
	updateRowInSource(row) {
			let key = makeStringKey(this.ops, row.data)
			this.setState(({source})=>{
				let nsource = new Map(source)
				nsource.set(key, {
					data: row.data
					, modified: false
					, version: (nsource.get(key)?.version??0)+1
				})
				return {source:nsource}
			})
	}

	getStoreWithFields() {
		let storeWithFields = this.props.store.withFields(Array.from(this.fields))
		return { ...storeWithFields

			// add (or replace!) row in database AND in the list
			, add_to_list: async (key) => {
					const nv = await storeWithFields.read(key)

					this.setState(({source})=>
						({
							source: new Map([
										[makeStringKey(this.ops, nv.data), nv]
										, ...source
									])
						})
					)
					return key;
				}
			, remove: async (keyOrValues) => {
					if(!Array.isArray(keyOrValues)) {
						await storeWithFields.remove(keyOrValues)
						keyOrValues = makeStringKey(this.ops, keyOrValues); 
					} else
						keyOrValues = JSON.stringify(keyOrValues);
					this.setState(({source})=>{
							let nset = new Map(source);
							let found = nset.delete(keyOrValues) 
							return found ? {source:nset} : {source}
						})
				}
		}
	}
  
  componentDidMount() {
		this.ops = { ...this.ops
			, globalForm: (rowKey) => this.setState({globalForm:rowKey})

			, checkUnique: (row) => {
					for(const ruleName in this.props.uniquekey) {
						let akey = JSON.stringify(makeUniqueKey(
										this.props.uniquekey[ruleName], row))
						for(const [k,ur] of this.state.source)
							if(akey === 
								JSON.stringify(makeUniqueKey(
										this.props.uniquekey[ruleName], ur.data))
							)
								return ruleName
					}
				}
			}

			/* to set formik values
			1. track version of data and include it in the row key
				in this case row will recreated when outer data changed
				it is enought when whole data change
			2. expose internal state and change it incrementally
				skipping autosave
			*/

		if(!this.inited) {
			this.inited = true;
			this.saveConditionOps = loadFilterSavedState(this.props.store)
			this.saveSorterOps = loadSortSavedState(this.props.store)

			this.setState({
				source: new Map() //start from empty list
				, collector: null //disable field connector
			})

			this.asyncInit()
		}
  }

  componentDidUpdate(prevProps, prevState) {
  	if(this.props.fetchKey !== prevProps.fetchKey) {
  		this.refetch()
  	}
  }

  async asyncInit() {
			let restoreFilter = new Promise(resolve=>{
				this.conditionInit = (condition) => {
							this.conditionInit = null
							resolve(condition)
						}		
			})

			let restoreSorter = new Promise(resolve=>{
				this.sorterInit = (sorter) => {
							this.sorterInit = null
							resolve(sorter)
						}		
			})

			let condition = await restoreFilter
			this.setState({condition})

			let sorter = await restoreSorter
			this.setState({sorter})

			await this.refetch()
  }

  async refetch() {

		this.ops = { ...this.ops, ...this.getStoreWithFields() }

		let rows = await this.ops.fetch_list()

		if(this.props.storeFilter)
			rows = rows.filter(r=>this.props.storeFilter(r.data, r))

		//console.log('read!!', rows)
		this.setState({
			source: new Map((rows??[]).map(r=>[makeStringKey(this.ops, r.data), r]))
		})
  }

  render() {
  	const {store, shape, handleSave, extra, fetchKey, storeFilter, defaultSorter, ...props} = this.props
		  
 		const SuperTableShape = this.ops.shape 
		//console.log('rendersupertable')
 		if( this.state.collector ) { 
			return <>
					<Loading/>
					<Hidden>
					<SuperTableContext.Provider value={{
						store: store.store
					}}>
						<DbFieldCollector	key={null}
								collector={this.state.collector}
						><SuperRowContext.Provider value={{}}>
							<table><tbody>{rowInt(SuperTableShape, {}, this.cols, {})}</tbody></table>
						 </SuperRowContext.Provider>
						</DbFieldCollector>
					</SuperTableContext.Provider>
				  </Hidden>
				</>
		}

	 	const sorter = this.state.sorter??
	 		(defaultSorter? (a,b) => defaultSorter(a.data,b.data): (()=>0))
	 	let filtred = [...this.state.source.values()]
	 								.filter(raw=>!this.state.condition 
	 													|| this.state.condition(raw.data))
	 								.sort(sorter)

		// console.log('filtred',filtred)
	 		const colDistinctScan =
	 				this.cols.filter(c=>c.ifDistinct)
	 				.map(c=>Array.isArray(c.ifDistinct)? 
	 							{key: c.colKey, func: c.ifDistinct[0], state: c.ifDistinct[1]} 
	 						:	{key: c.colKey, func: c.ifDistinct} 
					)

	 		if(colDistinctScan.length) {
	 			for(const r of filtred){
	 				for(let c of colDistinctScan) {
	 					if(c.distinct) continue; //already distinct
	 					const v = c.func(r.data)
	 					if('state' in c) c.distinct = c.state !== v;
	 					else c.state = v;
	 				}
	 			}
	 		}

	 		let hiddenCol = new Set(
	 				colDistinctScan.filter(c=>!c.distinct)
	 				.map(c=>c.key)
	 			)

	 		for(const col of this.cols) {
	 			if(col.hiddenIf?.()) {
	 				hiddenCol.add(col.colKey)
	 			}
	 		}

	let ops_ext = {
 		sharedState: this.state.sharedState ?? {}
 		, setSharedState: (reducer) =>{ 
 			this.setState(current=>({
 				...current,
 				sharedState: reducer(current.sharedState)
 			}))}
 		, hiddenCol
		, source: this.state.source
		, setSource: (values, status)=>{
			let key = makeStringKey(this.ops, values)
//			console.log('values: ', values)
//			console.log('status modif: ', status.modified)
			this.setState(({source})=>{
				let nsource = new Map(source)
				nsource.set(key, {
					data: values
					, modified: status.modified
				})
				return {source:nsource}
			})
		}
		, filterLeafs: this.saveConditionOps.currentState
 	}

	let before = null
	let after = null
	let empty = null
		React.Children.forEach(props.children, (e) =>{
			if(!e) return;
			if(e.type === Before) {
				before = e.props.children
				return;
			}
			if(e.type === After) {
				after = e.props.children
				return;
			}
			if(e.type === Empty) {
				empty = e.props.children
				return;
			}
		})

	let ctx = {...this.ops, ...ops_ext}

	  const table = 
	  	<SuperTableContext.Provider value={ctx}>
	  	<Filter.Root 
	  		onConditionInit={this.conditionInit}
	  		onConditionChange={condition=>this.setState({condition})}
	  		leafSaver={this.saveConditionOps}
	  	>
	  	<Sorter
	  		onSortInit={this.sorterInit} 
	  		onSortChange={(sorter)=>this.setState({sorter})}
	  		leafSaver={this.saveSorterOps}
	  	>
			{ before }
			<div className="tableOverflow">{React.cloneElement(SuperTableShape.container, props,<>
			{React.cloneElement(SuperTableShape.header,{},
				this.headers.map((r,j)=>
					React.cloneElement(SuperTableShape.hrow, {key:j}, 
						r.map(e => React.cloneElement(SuperTableShape.th, 
							{...e.props
								, key:e.colKey, colSpan: e.colSpan, rowSpan: e.rowSpan
								, "hidden-col": htmlBool(ops_ext.hiddenCol?.has(e.colKey) ?? false) 
							}
							, 
								e.label &&
								React.cloneElement(e.label, {tableContext:ctx})
							)
						)
					)
				)
			)}
			{React.cloneElement(SuperTableShape.body,{}, 
				filtred.length === 0 ? 
					empty ?
						React.cloneElement(SuperTableShape.empty,{},empty)
						: null
				:
					filtred.map(r => <StRow 
								   key={
								   	(r.version??0)+':'+ //track row verions, if it is repaced, remount controls
								   	makeStringKey(ctx, r.data)
								   } 
								   cols={this.cols} 
								   rawRow={ r } 
								   globalForm={ this.state.globalForm }
								   tableContext={ctx}
							/>)
			)}
			</>)}</div>
			{ after }
		</Sorter>
		</Filter.Root>
		</SuperTableContext.Provider>
  	return table
  }
}


export const ops = { Col, ColGroup
	, Before, After, Empty
	, Consumer: SuperTableContext.Consumer
}
