import React, {useContext, useState, useRef} from 'react'
import {Link, useNavigate} from "react-router-dom";

import {letIn} from 'azlib/components/helpers'
import {useTypedParams} from 'azlib/components/router_helpers'
import {later} from 'azlib/components/helpers'
import {fullPersonName} from 'azlib/components/name_funcs'
import {PopupMenu, ModalButton, seqModalProcessing, PopupFrame} from 'azlib/components/modals'

import {OrgInfoContext} from 'org/org'
import UserInfoContext from 'UserInfo'
import {ReactComponent as QuestionGrey} from './../question_grey.svg'
import { DbTableEdit as Table } from 'azlib/components/super-table'

import {
	ModelField, LabeledField, Calculate, LabeledOutput, FormValues
	, Conditional, LabeledFieldLabel, JsonField
	, DbFormik, useFormContext
	, StatusDriver, statusMessageBig
	, cancelLocalEdit
	, IfModified
	, IfExistingRecord, IfNewRecord, SaveButton, Label, fieldValidate, CollectFromProps
} from 'azlib/components/formik-fields';

import {
	ModelDecode,
	MaskedInput,
	FieldAsPrompt,
	Textarea,
	fakeEvent,
	Checkbox2,
	ModelSelect, FieldSelect, DateInput, ModalEdit
} from 'azlib/components/std-controls'

import { hasSpecialities, MegaMonsterEdit} from 'config/ed_levels_map'
import {spec_name_by_kind} from 'config/doctype_typepros'

import {YearCtrl} from 'contingent/groups'

import Filter from 'azlib/components/list_filter'
import {fioExtract, fioCondition} from 'azlib/components/filter_helpers'
import Sorter from 'azlib/components/list_sort'

import {statusSorter} from 'config/common'

import {diff_transform, StatusHistory} from 'contingent/status_history'

import { SearchFilter } from 'azlib/components/search_filter'
import {FieldInput} from 'azlib/components/std-controls'
import {
	ShowLocker,
	lockToEdit,
	cancelLock,
	clearLockerFields,
	alien_locked,
	saveIfOwnedOrFree
} from 'config/row_locker'

import {ReactComponent as EditSVG} from './edit.svg'

import {OKSM_Edit} from 'config/oksm'
import {PopupLog} from "./log";
import {EdRegionEdit, EdRegionSelect} from "../config/regions";

export function CardList() {
	const navigate = useNavigate();
	let params = useTypedParams();
	const gcode = params.Grp;
	const orginfo = useContext(OrgInfoContext);
	const uinfo = useContext(UserInfoContext);
	let terms = orginfo.terms
	let grpName = ''
	let grp_fix = null;
	let ginfo = null
	const [isVisible, setIsVisible] = useState(true)
	if(gcode) {
		ginfo = orginfo.group_info(gcode)
		terms = orginfo.terms_by_level(ginfo?.ed_level)
		grpName = ginfo?.group_name
		grp_fix = {gcode}
	}
	async function add_student(ops) {
			let [fio] = fioExtract(ops.filterLeafs().multi, 3)
			let init = {}
			if(fio) {
				if(fio.ln)
					init.last_name = fio.ln
				if(fio.fn)
					init.first_name = fio.fn
				if(fio.mn)
					init.middle_name = fio.mn
			}
			init.gcode = gcode||'';
			init.additional_info = JSON.stringify({country:"RU"});
			init.gcode$ = {}
			init.gcode$.group_name = grpName;
			init.year_completion = (new Date()).getFullYear()
			if(ginfo) {
				init.doctype_used = ginfo.doctype
				init.ed_level = ginfo.ed_level
				init.year_completion =
					ginfo.year_completion
					?? (new Date()).getFullYear()
				init.spec_code = ginfo.spec_code
				init.spec_name = ginfo.spec_name
				init.qualification = ginfo.qualification
				init.qualification_category = ginfo.qualification_category
				init.area_proffessional_program = ginfo.area_proffessional_program
			}
			let key = await ops.add_to_list(
						await ops.append_local(init)
					)
			navigate(`/org/${orginfo.tid}/contingent/cards/${key[0]}`)
		}
	return <>
		<div
			style={{float:"right", margin:"1em 1em 0 0"}}
		>
			<PopupLog table="docs" tkey={orginfo.tid}
					  fixed={{
						  tname: 'orgtree'
						  ,stname:'docs'}}
					  transform={diff_transform}
					  transformContext={orginfo}
					  deffered={true}
			/>
		</div>
		<h1>Список {terms.students_gen} {grpName && `${terms.group_gen} ${grpName}` }</h1>
		{gcode && !grpName ? <div className="error">Нет такой группы</div>
			: orginfo.contact_tel
			&& orginfo.contact_email
			&& (orginfo.app_region || orginfo.toplevel && orginfo.is_branch || !orginfo.toplevel)
			&& (orginfo.app_place || orginfo.toplevel && orginfo.is_branch || !orginfo.toplevel)
			&& (orginfo.app_toplevel_region || orginfo.toplevel)
			&& (orginfo.app_toplevel_place || orginfo.toplevel)
			&& orginfo.contact_addr
			&& orginfo.contact_person ?
			<Table key={gcode}
				   className="simpleTable"
				   store={orginfo.DbX.store('docs_preparing', { tid: orginfo.tid, ...grp_fix } )}
				   extra="tid year_completion spec_code spec_name ed_level"
				   defaultSorter={statusSorter}
			>
				<Table.Before>
					<div className="templatesSearch backgroundAngular">
						<SearchFilter
							input={
							<Filter.Multi className="FilterInput" placeholder={"Найти обучающегося"} saveAs="multi" minlength="3"
										  extract={
											  [
												  [fioExtract, fioCondition(
													  row=>[row.last_name, row.first_name, row.middle_name]
												  )
												  ]
											  ]
										  }
							/>
							}
						/>

						<div className="flexContainer">
							{(orginfo.docs.operator || uinfo.userAreas.sysop) &&
								<Table.Add disabled={!orginfo.docs.operator} command={add_student}>
									Добавить {terms.student_acc}
								</Table.Add>
							}
							{(orginfo.docs.operator || orginfo.docs.validator || uinfo.userAreas.sysop) && isVisible &&
								<Table.SelectionMode
							>{setMode=>
								<PopupMenu
									trigger={<button type="button">Переместить обучающегося</button>}
									bindX="left"
								>{({hide})=><>
									{
										letIn(!ginfo ? orginfo.orgtree_groups
											: orginfo.orgtree_groups
												.filter(g => g.ed_level === ginfo.ed_level)
										)(grp_list =>
											!!grp_list.length &&
											<PopupMenu
												trigger={<button className="block"
												>Перенести в {terms.group_acc}</button>}
												bindX="right"
												style={{transform: "translate(100%,-1.2em)"}}
												onHide={() => {
													hide()
												}}
											>
												{
													grp_list.map(grp =>
														<ModalButton key={grp.gcode} className="block" disabled={!orginfo.docs.operator && !orginfo.docs.validator}
																	 onClick={() => setMode({
																		 processor:
																			 async (selected, ctx) => {
																				 seqModalProcessing(
																					 async ([item, methods]) => {
																						 //console.log(item)
																						 let data = await orginfo.DbX.fetch_rds("docs", "status", {docid:item[0]})
																						 let status = data[0].status
																						 await orginfo.DbX.save_form('docs_preparing'
																							 , null
																							 , {
																								 docid: item[0]
																								 , gcode: grp.gcode
																								 , status: status === 'doc-ready' ? 'doc-fill' : status
																							 })
																						 methods.setFormikState(st => ({
																							 ...st
																							 ,
																							 values: {
																								 ...st.values
																								 , gcode: grp.gcode
																								 , gcode$: {
																									 group_name: grp.group_name
																								 }
																								 , status: status === 'doc-ready' ? 'doc-fill' : status
																							 }
																							 ,
																							 status: {
																								 ...st.status,
																								 skipLocalSave: true
																							 }
																						 }))
																					 }
																					 , selected
																				 )
																			 }
																		 ,
																		 guard: values => values.spec_code?(values.ed_level === grp.ed_level
																						&& values.spec_code === grp.spec_code
																						&& values.spec_name === grp.spec_name
																						)
																						:
																						(values.ed_level === grp.ed_level)
																	 }, setIsVisible(!isVisible))}
														>{grp.group_name}
														</ModalButton>
													)
												}
											</PopupMenu>
										)}
									{
										letIn(!!orginfo.orgtree_ancestors.length
											? orginfo.orgtree_ancestors[0] : null)
										(orgtree_ancestor =>
											letIn(orginfo.orgtree_childs)
											(orgtree_childs =>
												(orgtree_ancestor || !!orgtree_childs.length)
												&&
												<PopupMenu
													trigger={<button className="block"
													>Перенести в ...</button>}
													bindX="right"
													style={{transform: "translate(100%,-1.2em)"}}
													onHide={() => {
														hide()
													}}
												>
													{orgtree_ancestor &&
														<ModalButton className="block"
															disabled={!orginfo.docs.operator && !orginfo.docs.validator}
																		onClick={() => {
																			setMode(
																				async (selected, ctx) => {
																					seqModalProcessing(
																						async ([item, methods]) => {
																							let key = item
																							await orginfo.DbX.call_function('move_doc'
																							, {doc: key[0]
																								, tid: orgtree_ancestor.ancestor_tid
																							})
																							ctx.remove(key)
																						}
																						, selected
																					)
																				}, setIsVisible(!isVisible))
																		}}>{orgtree_ancestor.name}
														</ModalButton>
													}
													{
														orgtree_childs.map(sub =>
															<ModalButton className="block"
																disabled={!orginfo.docs.operator && !orginfo.docs.validator}
																			key={sub.tid}
																			onClick={() => {
																				setMode(
																					async (selected, ctx) => {
																						seqModalProcessing(
																							async ([item, methods]) => {
																								let key = item
																								await orginfo.DbX.call_function('move_doc'
																								, {doc: key[0]
																									, tid: sub.tid
																									})
																								ctx.remove(key)
																							}
																							, selected
																						)
																					}, setIsVisible(!isVisible))
																			}}
															>{sub.name}</ModalButton>
														)
													}
												</PopupMenu>
											))}</>}
								</PopupMenu>
							}
							</Table.SelectionMode>
							}
							<button className="checkBoxBut">
								На проверке анкеты:
								<Filter.Check value="st-check"
											  condition={v => row=>row.status===v}
											  saveAs="stCheck"
								/>
							</button>
							<Sorter.Select
								slot="combo"
								style={{marginLeft:"auto"}}
							>
								<option comparator={(a,b)=>
									Sorter.cmp(
										`${a.last_name} ${a.first_name} ${a.middle_name}`
										,  `${b.last_name} ${b.first_name} ${b.middle_name}`
									)
								}>По ФИО ↗</option>
								<option comparator={(a,b)=>
									-Sorter.cmp(
										`${a.last_name} ${a.first_name} ${a.middle_name}`
										,  `${b.last_name} ${b.first_name} ${b.middle_name}`
									)
								}>По ФИО ↘</option>
								<option comparator={(a,b)=>statusSorter(a,b)}
								>По статусу ↗</option>
								<option comparator={(a,b)=>statusSorter(b,a)}
								>По статусу ↘</option>
							</Sorter.Select>
						</div>
					</div>
				</Table.Before>
				<Table.OperationCol label={setMode=>{setIsVisible(isVisible)}} style={{width:"3em"}}/>
				<Table.Col label="Фамилия Имя Отчество" name="docid"
						   extra="last_name first_name middle_name bdate"
				>
					{(value,row) =>
						<Link to={`/org/${orginfo.tid}/contingent/cards/${value}`}
						>{fullPersonName(row) || '--???--'}
						</Link>
					}
				</Table.Col>
				<Table.Col label={terms.group} name="gcode"
						   extra="gcode{group_name} doctype_used"
						   ifDistinct={row=>row.gcode}
				>{
					(v, row) => row.gcode$?.group_name
				}
				</Table.Col>
				<Table.ModelCol name="status" label="Статус документа"/>
				<Table.After>
					{(orginfo.docs.operator || uinfo.userAreas.sysop) &&
						<Table.Add disabled={!orginfo.docs.operator} command={add_student}>
							Добавить {terms.student_acc}
						</Table.Add>
					}
				</Table.After>
			</Table> 
			: <h1 style={{textAlign:"center", color:"red"}}>
				{emptyOrgFields(orginfo)}
				</h1>
		}
	</>
}
export function emptyOrgFields(orginfo, likeTxt){
	return orginfo.contact_tel
	&& orginfo.contact_email
	&& (orginfo.app_region || orginfo.toplevel && orginfo.is_branch || !orginfo.toplevel)
	&& (orginfo.app_place || orginfo.toplevel && orginfo.is_branch || !orginfo.toplevel)
	&& (orginfo.app_toplevel_region || orginfo.toplevel)
	&& (orginfo.app_toplevel_place || orginfo.toplevel)
	&& orginfo.contact_addr
	&& orginfo.contact_person ? "Да" : (likeTxt?
		("В данных организации не заполнены поля: "
			+ (orginfo.contact_person ? '':"- контактное лицо ")
			+ (orginfo.contact_email ? '':"- электронная почта ")
			+ (orginfo.contact_addr ? '':"- почтовый адрес ")
			+ (orginfo.contact_tel ? '':"- телефон ")
			+ ((orginfo.app_region || orginfo.toplevel && orginfo.is_branch || !orginfo.toplevel) ? '':"- регион ")
			+ ((orginfo.app_place || orginfo.toplevel && orginfo.is_branch || !orginfo.toplevel) ? '':"- место нахождения ")
			+ ((orginfo.app_toplevel_region || orginfo.toplevel) ? '':"- регион основной организации ")
			+ ((orginfo.app_toplevel_place || orginfo.toplevel) ? '':"- место нахождения основной организации ")
		)
		:<>
	В данных организации не заполнены поля:
	{orginfo.contact_person ? '':<><br/>контактное лицо</>}
	{orginfo.contact_email ? '':<><br/>электронная почта</>}
	{orginfo.contact_addr ? '':<><br/>почтовый адрес</>}{orginfo.contact_tel ? '':<><br/>телефон</>}
	{(orginfo.app_region || orginfo.toplevel && orginfo.is_branch || !orginfo.toplevel) ? '':<><br/>регион</>}
	{(orginfo.app_place || orginfo.toplevel && orginfo.is_branch || !orginfo.toplevel) ? '':<><br/>место нахождения</>}
	{(orginfo.app_toplevel_region || orginfo.toplevel) ? '':<><br/>регион основной организации</>}
	{(orginfo.app_toplevel_place || orginfo.toplevel) ? '':<><br/>место нахождения основной организации</>}
	</>)
}

export function CardPage() {
	const navigate = useNavigate();
	let params = useTypedParams();
	const docid = params.Docid;
	const orginfo = useContext(OrgInfoContext);
	const uinfo = useContext(UserInfoContext);

	const lockInfoFieldGroup = (row) => row.status !== '' 
								|| !orginfo.docs.operator
								|| alien_locked(row);
	const lockEducationFieldGroup = lockInfoFieldGroup;
	const inGroup = (row) => row.gcode;
	const lockIfInGroupFieldGroup = row => lockEducationFieldGroup(row) || row.gcode;

	const statusMap =  row => ({
		... (orginfo.docs.operator || uinfo.userAreas.sysop || uinfo.userAreas.admin?
			{'': [ { to: 'st-check'
					, prepareSave: docid > 0 ? saveIfOwnedOrFree:null
					, text: "На проверку"
					, readOnly: !orginfo.docs.operator
				}
				]}
			: null)
		, ...(orginfo.docs.validator || uinfo.userAreas.sysop || uinfo.userAreas.admin?
			{'st-check': [
					{to:''
						, text: 'Вернуть'
						, prepare: () => statusMessageBig(
							'Комментарий'
						)
						, readOnly: !orginfo.docs.validator
					},
					// {to:'st-verification'
					//   , text: 'На верификацию'
					// }
					{to: row.doctype_used? 'doc-fill':'doc-ready'
						, text: 'Подготовлен'
						, readOnly: !orginfo.docs.validator
					}
					, ...(uinfo.userAreas.admin?
							[
								{to:'st-verification'
									, text: 'На верификацию (админ)'
								}
							]
							: []
					)
				]
			}
			:null)
	})
	return <>
		<DbFormik
			store={orginfo.DbX.store('docs_preparing')}
			rowKey={[docid]}
			extra="tid ed_level doctype_used"
			dependencies={{
				ed_level: ['doctype_used', 'spec_code', 'spec_name', 'qualification']
			}}
			handleSave={async (values,changed,fc, ...rest)=>{
				if(fc.status.skipLocalSave) {
					// not a real data editing here
					DbFormik.localSave(values,changed,fc, ...rest)
					return;
				}
				if(alien_locked(values)) {
					// ignore if alien_locked
					return;
				}
				if(changed) {
					console.log('try start edit')
					let locker = values.row_locker 
								|| fc.status.local
								? true
								:  await lockToEdit(fc)
					if(locker){
						// locked
						await DbFormik.localSave(
							{ ...values, ...locker} 
							,changed,fc, ...rest
						)
					} else {
						cancelLocalEdit(fc)
					}
				}
			}}
			onSaving={(values)=>{
				console.log('saving')
				if(values.row_locker) {
					values = clearLockerFields(values)
				}
				return values
			}}
			onKeyChanged={async key => {
				await later(10)
				navigate(`/org/${orginfo.tid}/contingent/cards/${key[0]}`, { replace: true })
			}}
		><FormValues>{(row,fc)=>
			letIn(alien_locked(row))(fastDoNotEdit=> 
			<>
			<Calculate name="ed_level">{lvl=>
				<>
					<h1>Анкета {orginfo.terms_by_level(lvl).student_gen}</h1>
				</>
			}</Calculate>
			<Calculate name="doctype_kind"
					   extra="infoset doctype_infoset signed doctype_name doctype_used{. typename signer_roles} our_date our_number"
			>{(kind, row)=>
				<>{}
					</>}
				</Calculate>
			<IfExistingRecord>
			<ShowLocker show={orginfo.docs.operator || uinfo.userAreas.sysop || uinfo.userAreas.admin} readOnly={!orginfo.docs.operator}/>
			</IfExistingRecord>
			<IfNewRecord>
				<div style={{marginLeft:"2em"}}><h3>Создаваемая запись</h3></div>

				<IfModified fastDoNotEdit={alien_locked} superusers={uinfo.userAreas.admin || uinfo.userAreas.sysop}>
					<button type="button" style={{margin:"0 0 1em 2em"}}
							onClick={async ()=>{
								if(!orginfo.docs.operator) return;
								await cancelLocalEdit(fc)
								window.document.location.reload()
							}}
					>Отменить создание</button>
				</IfModified>
			</IfNewRecord>

				<div style={{margin: "0 2em 1em 2em"}}>
					<div className="backgroundRounded" style={{padding: "1em", marginBottom: "1em", display: "flex"}}>
						<div>
							<Calculate name="status" extra="status_message">{(st, row) => <>
								{row.status && row.status.startsWith('doc-') &&
									<button><Link to={`/org/${orginfo.tid}/docs/${row.docid}`}
									>Документ</Link></button>}
								{row.status && row.status.startsWith('issued-') &&
									<button><Link to={`/org/${orginfo.tid}/issued/${row.docid}`}
									>Документ</Link></button>}
								<h3>
									{'Статус:  '}
									<ModelDecode modelData="doc_status" value={st}/>
								</h3>
								{row.status_message?.trim() ?
									<h3>{'Комментарий:  '}{row.status_message}
									</h3>
									: null
								}
							</>}</Calculate>
						</div>
						<div
							style={{marginLeft: "auto"}}
						>
							<StatusHistory docid={docid}
										   bindX="right"
							/>
						</div>
					</div>

					<div className="backgroundRounded">
						<div className="flexWrap">
						<fieldset>
							<legend>Сведения</legend>
							<ModelField name="last_name" readOnly={lockInfoFieldGroup}/>
							<ModelField name="first_name" readOnly={lockInfoFieldGroup}/>
							<ModelField name="middle_name" readOnly={lockInfoFieldGroup}
										tip="при наличии"
							/>
							<div className="flexContainer">
								<ModelField name="bdate" readOnly={lockInfoFieldGroup}/>
							</div>
						</fieldset>

						<fieldset>
							<legend>Данные процесса выпуска документа</legend>

							<Calculate name="gcode" extra="gcode{group_name} doctype_used">{(gcode, row) =>
								gcode &&
								<LabeledOutput label="Группа">{row.gcode$.group_name}</LabeledOutput>
							}</Calculate>

							<ModelField name="ed_level"
										readOnly={lockIfInGroupFieldGroup}
										filter_data={
											(value) =>
												!value ||
												value in orginfo.app_ed_level
										}
							/>

							<Calculate name="year_completion" extra="gcode">{(y, row) => <>
								{row.gcode && <LabeledField name="year_completion" label="Год выпуска"
															required tip='Изменить, если отличается от класса/группы'
															as={YearCtrl} readOnly={lockEducationFieldGroup}
								/>}
								{!row.gcode && <LabeledField name="year_completion" label="Год выпуска"
															 required
															 as={YearCtrl} readOnly={lockEducationFieldGroup}
								/>}</>}</Calculate>
							<Conditional when={r => r.ed_level === "p" || r.ed_level === "ap"}
										 extra="spec_name qualification qualification_category area_proffessional_program">
								<div>
									<LabeledField
										label={row.ed_level === "p" ? "Наименование программы профессионального обучения" : "Наименование программы"}
										name="spec_name" as={Textarea} readOnly={lockInfoFieldGroup}
										required
									/>
								</div>
								<div className="flexContainer">
									<div style={{width: "100%"}}><LabeledField label="Квалификация" name="qualification"
																			   readOnly={lockEducationFieldGroup}
																			   as={Textarea}
									/></div>
								</div>
								{row.ed_level === "p" && <>
									<div className="flexContainer">
										<div style={{width: "100%"}}><LabeledField
											label="Присвоенный квалификационный разряд, класс, категория"
											name="qualification_category"
											readOnly={lockEducationFieldGroup}
											as={Textarea}
											max_length={100}
										/></div>
									</div>
									<div className="flexContainer">
										<div style={{width: "100%"}}><LabeledField
											label="Наименование профессий рабочих, должностей служащих"
											name="area_proffessional_program"
											readOnly={lockEducationFieldGroup}
											as={Textarea}
											max_length={100}
										/></div>
									</div>
								</>}
							</Conditional>
							<Conditional when={r => hasSpecialities(r.ed_level)}
										 extra="spec_code spec_name qualification">
								<LabeledFieldLabel
									label={spec_name_by_kind(row.ed_level)}
									readOnly={lockEducationFieldGroup}
									required
								/>
								<div className="flexContainer ">
									<div style={{width: "100%"}}>
										<LabeledField
											name="spec_code" name2="spec_name" name3="qualification"
											ed_level={row?.ed_level}
											as={MegaMonsterEdit}
											orginfo={orginfo}
											placeholder="--.--.--"
											readOnly={lockEducationFieldGroup}
											required
										/>
									</div>
									<div style={{
										width: "calc(100% - 4em)"
									}}>
										<LabeledField
											name="spec_name" as={Textarea}
											readOnly
											required
											addpadding="true"
										/>
									</div>
									<div>
										<LabeledField undecorated name="spec_name"
													  readOnly={lockEducationFieldGroup}
													  prompt="Редактировать наименование специальности"
													  as={FieldAsPrompt}
										>
											<EditSVG tabIndex={0} alt="Редактировать" title="Редактировать"
													 style={{
														 height: "1em"
														 , verticalAlign: "bottom"
														 , display: "inline-block"
													 }}
											/>
										</LabeledField>
									</div>
								</div>
								<div className="flexContainer">
									<div
										style={{width: "calc(100% - 4em)", display: "inline-block"}}
									><LabeledField label="Квалификация" name="qualification"
												   readOnly={lockEducationFieldGroup} as={Textarea}
												   required
												   max_length={200}
												   style={{paddingRight: 30}}
												   addpadding="true"
												   tip="Введите значение или выберите его из списка"
									/></div>
									{!lockEducationFieldGroup(row)
										&& <PopupMenu
											trigger={
												<span
													className="customArrow"
													style={{
														border: "none",
														height: "1em",
														display: "inline-block",
														margin: "3em 0.8em 0 0"
													}}
												></span>
											}
											bindX="right"
											onHide={(res) => {
												if (res) {
													fc.setFieldValue('qualification', res)
												}
											}}
										>{({hide}) =>
											<div onClick={e => {
												hide(e.target.textContent)
											}}
											>
												{
													window.AppBundle.data.known_qualifications.decode.all
														.map(e =>
															<button type="button" className="block link"
																	key={e}>{e}</button>
														)
												}
											</div>}
										</PopupMenu>
									}</div>
							</Conditional>
							<LabeledField
								name="snils"
								label="СНИЛС" pattern='[0-9]{3}-[0-9]{3}-[0-9]{3} [0-9]{2}'
								mask="000-000-000 00"
								required={row.epgu}
								as={EditSnils}
								readOnly={lockEducationFieldGroup}
								tip="Необходим для передачи на ЕПГУ"
							/>
							<div className="flexContainer">
								<div className="flexContainer"><ModelField name="epgu" readOnly={lockEducationFieldGroup} /><QuestionGrey className="question"
																																		  title="флаг необходим для передачи выпущенного документа на ЕПГУ"/></div>
							</div>
						</fieldset>
						</div>
						<div className="flexContainer" style={{marginLeft: "1em"}}>
							<Calculate extra="doctype_kind">{(row) =>
								<StatusDriver name="status" map={statusMap(row)}/>
							}</Calculate>
							<IfExistingRecord>
								<IfModified fastDoNotEdit={alien_locked}
											superusers={uinfo.userAreas.admin || uinfo.userAreas.sysop}>
									<div className="flexContainer">
										<button type="button"
												onClick={async () => {
													if (!orginfo.docs.operator) return;
													await cancelLocalEdit(fc, cancelLock)
												}}
										>Отменить редактирование
										</button>
										<SaveButton type="button" class="danger"
										>Сохранить
										</SaveButton>
									</div>
								</IfModified>
								<Calculate>{row =>
									!row.status
									&&
									(orginfo.docs.operator || uinfo.userAreas.sysop)
									&&
									<button type="button" className="danger" disabled={!orginfo.docs.operator}
											onClick={async () => {
												await orginfo.DbX.remove_form('docs_preparing', {docid: row.docid})
												window.document.location.reload()
											}}
									>Удалить</button>
								}
								</Calculate>
							</IfExistingRecord>
							<Calculate>{row =>
								uinfo.userAreas.admin
								&&
								!row.status?.startsWith('issued-')
								&&
								<button type="button" className="statusDriver danger"
										onClick={async () => {
											await orginfo.DbX.remove_form('docs_preparing', {docid: row.docid})
											window.document.location.reload()
										}}
								>Удалить!!!!</button>
							}
							</Calculate>
						</div>
						{(orginfo.docs.operator||uinfo.userAreas.sysop||uinfo.userAreas.admin||orginfo.docs.validator||orginfo.registry||orginfo.docs.printer)&&
							<LabeledField name="additional_info" readOnly={!orginfo.docs.operator
								|| alien_locked(row)}
															   as={InformationParser}
															   level={row.ed_level}
						/>}
					</div>
				</div>
			</>)
		}</FormValues></DbFormik>
	</>
}

function InformationParser({name, value, level, onChange, readOnly, ...props}) {
	let parsed = value ? JSON.parse(value) : {}
	return(
		<div className="flexWrap">
			<fieldset>
				<legend>Дополнительная информация</legend>
				<div className="flexContainer" style={{justifyContent:"flex-start"}}>
					<JsonField label="На бланке" field="paper" readOnly={readOnly} json={parsed} name={name} onChange={onChange} question="флаг необходим для возможности печати документа на бланке"
							   as={Checkbox2}/></div>
				<JsonField field="contact_email" label="Адрес электронной почты (email) обладателя документа" readOnly={readOnly} json={parsed} name={name} onChange={onChange}/>
				<JsonField label="Пол" as={ModelSelect} modelData="gender" field="gender" readOnly={readOnly} onChange={onChange} name={name} json={parsed}/>
				<JsonField label="Гражданство" field="country" readOnly={readOnly} onChange={onChange} json={parsed} name={name}
							  as={OKSM_Edit}
							  tip="По умолчанию - гражданство РФ"
				/>
				<JsonField field="doc_reason" label="Основание выдачи документа" readOnly={readOnly} json={parsed} name={name} onChange={onChange}
							  tip="наименование, дата издания, номер (при наличии) локального акта"
				/>
				<JsonField field="edication_reason" label="Основание приема на обучение"  readOnly={readOnly}json={parsed} name={name} onChange={onChange}/>
				<JsonField as={MaskedInput} readOnly={readOnly} mask="[1|2]000" label={level ==="ap"||level ==="p"?"Год начала обучения":"Год поступления"} field="year_admission" json={parsed} name={name} onChange={onChange}/>
				<JsonField label={level ==="ap"||level ==="p"?"Срок обучения, часов":"Срок обучения, лет"} readOnly={readOnly} field="period_study" json={parsed} name={name} onChange={onChange}/>
				<JsonField label="Форма обучения" field="form_education" as={ModelSelect} modelData="form_education" readOnly={readOnly} json={parsed} name={name}
						   question="Форма обучения лица, получившего документ об образовании, на момент прекращения образовательных отношений (очная, очно-заочная или заочная) в случае получения образования
						   в организациях, осуществляющих образовательную деятельность" onChange={onChange}/>
				<JsonField label="Форма получения образования на момент прекращения образовательных отношений" readOnly={readOnly} field="place_education"
						   as={ModelSelect} modelData="place_education" json={parsed} readOnly={readOnly} name={name} onChange={onChange}/>
				<JsonField label="Источник финансирования обучения" field="funding_source" readOnly={readOnly} as={ModelSelect} modelData="funding_source" json={parsed} name={name}
						   question="Источник финансирования обучения лица, получившего документ об образовании, на момент прекращения образовательных отношений" onChange={onChange}/>
				{level === "mp"&&<>
					<JsonField label="Наименование образовательной программы" readOnly={readOnly} question="Наименование образовательной программы заполняется в соответствии со ст.12 ч.5 273 ФЗ,
					а также локальным нормативным актом образовательной организации, которым оно было утверждено"
							   field="name_program" json={parsed} name={name} onChange={onChange}/>
				</>}
				{RegExp("^h").test(level)&&<>
					<JsonField label="Наименование образовательной программы" readOnly={readOnly} question="Наименование образовательной программы заполняется в соответствии со ст.12 ч.5 273 ФЗ,
					а также локальным нормативным актом образовательной организации, которым оно было утверждено"
							   field="name_program" json={parsed} name={name} onChange={onChange}/>
					<JsonField label="УГС" field="ugs" json={parsed} name={name} readOnly={readOnly} onChange={onChange}/>
					<JsonField label="Наименование области профессиональной деятельности" field="area_name" as={MultyModelData} modelData="area_name" readOnly={readOnly} json={parsed} name={name} onChange={onChange}/>
					<JsonField label="Наименование вида профессиональной деятельности" field="type_profession" modelData="type_profession" as={MultyModelData} readOnly={readOnly} json={parsed} name={name} onChange={onChange}/>
					<JsonField label="Высшее образование, получаемое впервые" as={Checkbox2} field="first_education" readOnly={readOnly} json={parsed} name={name} onChange={onChange}/>
				</>}
				{level === "ap"&&<>
					<JsonField label="Дополнительная профессиональная программа" field="additional_program" readOnly={readOnly} json={parsed}
							   as={ModelSelect} modelData="additional_program" name={name} readOnly={readOnly} onChange={onChange}/>
					<JsonField label="Наименование области профессиональной деятельности" readOnly={readOnly} field="area_name" as={ModelDataList} modelData="area_name" json={parsed}
							   name={name} onChange={onChange}/>
					<JsonField label="УГС" field="ugs" json={parsed} name={name} readOnly={readOnly} onChange={onChange}/>
					<JsonField label="Уровень образования ВО/СПО" field="previous_level" readOnly={readOnly} json={parsed}
							   name={name} onChange={onChange} as={ModelSelect} readOnly={readOnly} modelData="previous_level"/>
					<JsonField label="Фамилия, указанная в дипломе о ВО/СПО" readOnly={readOnly} field="lname_previous_document" json={parsed} name={name} onChange={onChange}/>
				</>}
				{level === "p"&&<>
					<JsonField label="Программа профессионального обучения, направление подготовки" readOnly={readOnly} field="name_professional_program" json={parsed}
							   as={ModelSelect} modelData="name_professional_program" name={name} onChange={onChange}/>
				</>}
			</fieldset>
			{(RegExp("^h").test(level) || level === "mp")&&<fieldset>
				<legend>Целевое обучение</legend>
				<JsonField label="Наличие договора о целевом обучении" field="contract" name={name} readOnly={readOnly} json={parsed} as={Checkbox2} onChange={onChange}/>
				<JsonField label="Номер договора о целевом обучении" field="contract_number" json={parsed} readOnly={readOnly} name={name} onChange={onChange}/>
				<JsonField label="Дата заключения договора о целевом обучении" as={DateInput} field="contract_date" json={parsed} readOnly={readOnly} name={name} onChange={onChange}/>
				<JsonField label="Наименование организации, с которой заключён договор о целевом обучении" field="org_name" readOnly={readOnly} json={parsed} name={name} onChange={onChange}/>
				<JsonField label="ОГРН организации, с которой заключён договор о целевом обучении" field="org_ogrn" readOnly={readOnly} json={parsed} name={name} onChange={onChange}/>
				<JsonField label="КПП организации, с которой заключён договор о целевом обучении" field="org_kpp" readOnly={readOnly} json={parsed} name={name} onChange={onChange}/>
				<JsonField label="Наименование организации-работодателя" field="name_employer" json={parsed} readOnly={readOnly} name={name} onChange={onChange}/>
				<JsonField label="ОГРН организации-работодателя" field="ogrn_employer" json={parsed} readOnly={readOnly} name={name} onChange={onChange}/>
				<JsonField label="КПП организации-работодателя" field="kpp_employer" json={parsed} readOnly={readOnly} name={name} onChange={onChange}/>
				<JsonField label="Субъект федерации, в котором расположена организация-работодатель" readOnly={readOnly} as={EdRegionEdit} field="region_employer" json={parsed} name={name} onChange={onChange}/>
			</fieldset>}
		</div>
	)
}

function EditSnils({onBlur, name, readOnly, ...props}) {
	const orginfo = useContext(OrgInfoContext);
	const uinfo = useContext(UserInfoContext);
	let ctrl = useRef(null)
	let img = useRef(null)
	const [unlockedSnils, setUnlockedSnils] = useState(false)
	const [old, setOld] = useState('')
	let fc = useFormContext()
	//if(status === '')
	return <>
		<MaskedInput
					ref={ctrl}
					name={name}
					{...props}
					readOnly={readOnly}
					style={{paddingRight:"3em",
					}}
				/>
			</>
	// else return <>
	// 	{
	// 		(orginfo.docs.operator || uinfo.userAreas.sysop) &&
	// 		(unlockedSnils?
	// 				<SaveSVG tabIndex={0} ref={img} alt="Сохранить" title="Сохранить"
	// 						 style={{height:"1em"
	// 							 , verticalAlign:"base-line"
	// 							 , display: "inline-block"
	// 							 , position: "absolute"
	// 							 , right: "1ex"
	// 						 }}
	// 						 onClick={()=>{
	// 							if(!orginfo.docs.operator || row_locked) return;
	// 							 fc.submitForm()
	// 								 .then(()=>{
	// 									 setUnlockedSnils(false)
	// 									 ctrl.current.focus()
	// 								 })
	// 						 }}
	// 				/>
	// 				:
	// 				<EditSVG tabIndex={0} ref={img} alt="Редактировать" title="Редактировать"
	// 						 style={{height:"1em"
	// 							 , verticalAlign:"base-line"
	// 							 , display: "inline-block"
	// 							 , position: "absolute"
	// 							 , right: "1ex"
	// 						 }}
	// 						 onClick={()=>{
	// 							if(!orginfo.docs.operator || row_locked) return;
	// 							 setOld(fc.values[name])
	// 							 setUnlockedSnils(true)
	// 							 ctrl.current.focus()
	// 						 }}
	// 				/>
	// 		)
	// 	}
	// 	<MaskedInput
	// 		ref={ctrl}
	// 		name={name}
	// 		{...props}
	// 		readOnly={!unlockedSnils || row_locked}
	// 		style={{paddingRight:"3em",
	// 		}}
	// 		onBlur={(e)=>{
	// 			//console.log(e)
	// 			if(unlockedSnils) {
	// 				later().then(()=>{
	// 					if(document.activeElement !== img.current) {
	// 						setUnlockedSnils(false)
	// 						fc.setFieldValue(name, old)
	// 					}
	// 				})
	// 				return
	// 			}
	// 			onBlur?.(e)
	// 		}}
	// 	/>
	// </>
}

function MultyModelData({value, name, onChange, readOnly, modelData, ...props}) {
	let [filter, setFilter] = useState('')
	let parsed;
	if (value && typeof value === "object") parsed = value;
	else parsed = {};
	const keys = window.AppBundle.data[modelData].orderedKeys;
	const decode = (k) => window.AppBundle.data[modelData].decode[k];
	return <PopupMenu readOnly={readOnly}
					  trigger={
						  <div>
							  <Textarea className="selectLikeInput" value={
								  letIn(keys
									  .filter(e => e in parsed)
								  )(lst=>
									  lst.length?
										  lst.join("\n")
										  : ""
								  )}/>
						  </div>
					  }
	>
		<div><input style={{width:"90%", margin:"0 auto 1em auto", display:"block"}}
					autoComplete="off"
					onChange={(e)=> {
						let val = e.target.value.trim()
						setFilter(val.toUpperCase())
					}}
		/>
		</div>
		{
			keys
				.filter(e=> e?
					filter? e.toUpperCase().indexOf(filter) >= 0
						: true
					: false)
				.map(e=><div key={e}>
					<label>
						<input type="checkbox" checked={e in parsed}
							   onChange={(event)=>{
								   let nv = Object.assign({},parsed);
								   delete nv[e];
								   if(event.target.checked) nv[e] = '';
								   onChange?.(fakeEvent(name, nv))
							   }}
						/>
						{decode(e)}
					</label>
				</div>)
		}
	</PopupMenu>
}
function ModelDataList(props) {
	return <ModalEdit {...props}
					  dialog={<ModelList modelData={props.modelData}/>} closeBox
	/>
}
function ModelList({modelData, ...props}) {
	let [filter, setFilter] = useState('')
	return <PopupFrame
		style={{display:"flex column"}}
	>
		<div><input style={{width:"90%", margin:"0 auto 1em auto", display:"block"}}
					autoComplete="off"
					onChange={(e)=> {
						let val = e.target.value.trim()
						setFilter(val.toUpperCase())
					}}
		/>
		</div>
		<div style={{height:"80vh", width:"30em", overflow:"auto", position:"relative"}}>
			<ModalButton
				value=""
				className="link block"
			>
				<div style={{width:"5em", position:"absolute"}}>---</div>
				<div style={{marginLeft:"6em"}}>&nbsp;</div>
			</ModalButton>
			{
				window.AppBundle.data[modelData].orderedKeys
					.filter(e=> e?
						filter? e.toUpperCase().indexOf(filter) >= 0
							: true
						: false)
					.map(e=>
						<ModalButton key={e}
									 value={e}
									 className="link block"
						>
							<div>{e}</div>
						</ModalButton>
					)
			}
		</div>
	</PopupFrame>
}