import React, {useContext, useState} from 'react'
import {OrgInfoContext} from 'org/org'
import { revalidateSigners, importInfosets } from 'contingent/import_adv'
import { ModelDecode } from 'azlib/components/std-controls'
import { Link } from "react-router-dom";
import { TableParser } from 'azlib/components/table_parser'
import { importIdCols, importSchemeDef } from 'contingent/import_defs'

import styles from './import.module.css'
import {emptyOrgFields} from "../contingent/cards";


function ImportButton ({ process, table, reset, readOnly, ...props}) {
	return (
	  <button type="button" disabled={readOnly}
	  	onClick={async() => {
			await process(table)
			reset()
	  	}}
	  	{...props}
	  /> 
	);
};

export function Import(){
	const orginfo = useContext(OrgInfoContext);
	let [tableImport, setTable] = useState();

	let scheme = importSchemeDef

	let setFields = (fields) => {
		let header = ['ФИО', 'Статус импорта', 'Статус документа']
	 	
	 	setTable({
	 		cols : header
	 		, import : fields?.import
			, invalid : fields?.invalid
		})
	}

    return <>
		<h1>
			Импорт документов
		</h1>
		{orginfo.contact_tel
			&& orginfo.contact_email
			&& (orginfo.app_region || orginfo.toplevel && orginfo.is_branch || !orginfo.toplevel)
			&& (orginfo.app_place || orginfo.toplevel && orginfo.is_branch || !orginfo.toplevel)
			&& (orginfo.app_toplevel_region || orginfo.toplevel)
			&& (orginfo.app_toplevel_place || orginfo.toplevel)
			&& orginfo.contact_addr
			&& orginfo.contact_person ?
		<div className="templatesSearch backgroundAngular">
		<div>
			<TableParser
				columns={importIdCols}
				scheme={scheme}
				revalidate={revalidateSigners(orginfo)}

				Ok={
					(props) => <ImportButton readOnly={!orginfo.docs.operator}
						process={table =>
							importInfosets(orginfo, setFields, table, scheme)
						}
						{...props}
					>Импортировать</ImportButton>
				}
			/>
		</div>
		{!tableImport?.import? '':
 		<table className={styles.table}>
			<thead>
			<tr><th colSpan="100%">
					"Обновление данных невозможно" для {tableImport.import.filter(c=>c.metka == null).length} документов из {tableImport.import.length} "загруженных"
				</th></tr>
				<tr>
					{tableImport.cols.map(c=><th key = {c}>{c}</th>)}
				</tr>
			</thead>
			<tbody>
				{tableImport.import.map(c => 
				<tr key = {c.docid}>
					<td>{<Link to={`/org/${orginfo.tid}/contingent/cards/${c.docid}`}>{c.fio}</Link>}</td>
					<td>{c.metka == null ? 'Обновление данных невозможно' : 
						(c.metka ? 'Добавлен' : 'Изменен')}</td>
					<td>{<ModelDecode value={c.doc_status} modelData="doc_status"/>}</td>
				</tr>)}
				{tableImport.invalid.map(c => 
				<tr key = {c}>
					<td>{c.last_name + ' ' + c.first_name + ' ' + c.middle_name}</td>
					<td>Данные не импортированы</td>
					<td></td>
				</tr>)}
			</tbody>
		</table>}

		<div style={{display: "flex", paddingTop:"2em"}}>
			<table className="simpleTable importErrTable">
			<caption>Таблица №1. Типы возможных ошибок</caption>
				<thead>
					<tr>
						<th>Типы ошибок</th>
						<th>Причина возникновения</th>
						<th>Уточнение ошибки</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>После загрузки файла видна только строка с ошибкой: "Не та строка заголовка"</td>
						<td>1. Строка заголовка заполнена некорректно</td>
						<td>Строка заголовка — ячейки, выделенные синим цвета — содержит пустые или неверно заполненные ячейки.<br/>Требуется исправить строку заголовка.</td>
					</tr>
					<tr>
						<td rowSpan={3}>Ячейка выделена красным, ошибка: "строки, содержащие ошибки, будут проигнорированы!"</td>
						<td>1. Ячейка, выделенная красным, пустая</td>
						<td>Ячейка обязательна для заполнения.<br/>Требуется заполнить ячейку.</td>
					</tr>
					<tr>
						<td rowSpan={2}>2. У ячейки, выделенной красным, неверный формат заполнения</td>
						<td>Введено значение неверного формата.<br/>Введите значение в корректном формате, см. Таблицу 2</td>
					</tr>
					<tr>
						<td>Количество заполненных значений элемента не совпадает с количеством заданных колонок раздела.<br/>
							Требуется сравнить количество заданных колонок раздела и заполненных значений элемента данного раздела
						</td>
					</tr>
					<tr>
						<td rowSpan={2}>После загрузки файла в таблице результат: “Обновление данных невозможно”</td>
						<td>1. Статус документа отличается от “Проверка”</td>
						<td>Обновить данные документа можно только в статусе “Проверка”.
							<br/><br/>*Если требуется обновить данные, входящие в документ, то требуется вернуть его в статус “Проверка”, в случае, если он уже подписан, заменить документ в “Реестре”.
							<br/><br/>**Если требуется обновить блок “Дополнительная информация” в анкете, то воспользуйтесь файлом импорта “корректировка дополнительной информации” соответствующего уровня образования.
						</td>
					</tr>
					<tr>
						<td>2. Документ не найден.</td>
						<td>В таблице результата импорта не указано ФИО и статус документа. Требуется выполнить импорт всего документа в модуль ЕР ЦДО (а не только дополнительной информации).</td>
					</tr>
				</tbody>
			</table>
			<table className="simpleTable importErrTable">
			<caption>Таблица №2. Правила заполнения</caption>
				<thead>
					<tr>
						<th>Ячейки, требующие определенного формата заполнения</th>
						<th>Описание</th>
						<th>Пример корректного заполнения</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Дата рождения</td>
						<td>Требуется заполнить в формате: 'ДД.ММ.ГГГГ</td>
						<td>'01.01.2001</td>
					</tr>
					<tr>
						<td>Год окончания</td>
						<td>Требуется указать год окончания учебного заведения</td>
						<td>2023</td>
					</tr>
					<tr>
						<td>9/11 *<br/>* - Для общего образования (школы)</td>
						<td>Варианты заполнения: «9» или «11». Заполняется в соответствии с завершенным уровнем образования 
							(для основного общего образования — 9, для среднего общего образования — 11).
						</td>
						<td>9</td>
					</tr>
					<tr>
						<td>Код направления подготовки/специальности/профессии*<br/>* - Для СПО, ВО</td>
						<td>Требуется указать код направления подготовки/специальности/профессии в формате: 'XX.XX.XX <br/><br/>
							Для уровня аспирантуры код направления подготовки может быть заполнен в формате: 'X.X.X
						</td>
						<td>'09.03.02 <br/><br/>
							Для уровня аспирантуры: '5.3.2
						</td>
					</tr>
					<tr>
						<td>Вид документа</td>
						<td>Требуется указать «Вид документа»  без кавычек:<br/>
							1. « » - пустое значение указывается в случае, если выдается документ
							соответствующий уровню образования<br/>
							2. «с отличием» - если документ с отличием<br/>
							3. «справка» - если документ «Справка об обучении»<br/>
							4. «свидетельство» - если документ «Свидетельство об обучении»
						</td>
						<td>1. <br/>
							2. с отличием<br/>
							3. справка<br/>
							4. свидетельство
						</td>
					</tr>
					<tr>
						<td>Пол</td>
						<td>Варианты заполнения: «М» или «Ж».</td>
						<td>М</td>
					</tr>
					<tr>
						<td>СНИЛС</td>
						<td>Требуется заполнить в формате:<br/>
							XXX-XXX-XXX XX
						</td>
						<td>111-111-111 11</td>
					</tr>
					<tr>
						<td>На ЕПГУ</td>
						<td>Варианты заполнения: «Да» или «Нет»</td>
						<td>Да</td>
					</tr>
					<tr>
						<td>На бланке</td>
						<td>Варианты заполнения: «Да» или «Нет»</td>
						<td>Нет</td>
					</tr>
					<tr>
						<td>Гражданство</td>
						<td>Требуется заполнить согласно общероссийскому классификатору стран мира: <br/>
							<u><a href="https://classifikators.ru/oksm" target="_blank">https://classifikators.ru/oksm</a></u>
						</td>
						<td>RU</td>
					</tr>
					<tr>
						<td>Подписи: Роль подписывающего (указывается под наименованием раздела «Подписи»)</td>
						<td>Требуется указать роль в соответствии с ролью подписывающего, выбранной в разделе «Пользователи»</td>
						<td>Руководитель образовательной организации</td>
					</tr>
					<tr>
						<td>Подписи: ФИО подписывающего</td>
						<td>Требуется указать полное ФИО авторизованного пользователя с функцией «Подписание» и выбранной ролью *<br/>
							* - Обращаем Ваше внимание, что роль выбирается для каждого типа документа всех уровней образования </td>
						<td>Иванов Иван Иванович</td>
					</tr>
				</tbody>
			</table>
		</div>

		</div> 
		: <h1 style={{textAlign:"center", color:"red"}}>
				{emptyOrgFields(orginfo)}</h1>
		}
	</>
}