import {isPlainObject} from  './helpers'

/**
 * merge two array sequentially
 * and keep same keys as one elemen
 *  (a,b)=> [key,a[i],b[j]] if keys are the same
 * 					[key, a[j], undefined]
 * 					[key, undefined, b[j]]
 */
export function combine_arrays(a,b, key) {
		a = a || []
		b = b || []

		let ret = []
		function out(k, e, idx) {
				let arr = idx.get(k)
				if(!arr) debugger 
				arr.shift()
				if(!idx.get(k).length) idx.delete(k)
				return e;
		} 

		let ia = new Map()
		a.forEach((ea,i)=>{
			const k = key(ea)
			if(ia.has(k)) ia.get(k).push(i)
			else ia.set(k,[i])
		})
		let ib = new Map()
		b.forEach((eb,i)=>{
			const k = key(eb)
			if(ib.has(k)) ib.get(k).push(i)
			else ib.set(k,[i])
		})

		let i = 0;
		let j = 0;
		while(i < a.length && j < b.length) {
			let ea = a[i]
			let eb = b[j]
			let ka = key(ea)
			let kb = key(eb)

			if(!ib.has(ka)) {
				ret.push([ka, out(ka,ea,ia), undefined]);
				++i;
				continue;
			}
			if(!ia.has(kb)) {
				ret.push([kb, undefined, out(kb,eb,ib)]);
				++j;
				continue;
			}

			if(ka === kb) {
				ret.push([ka,out(ka,ea,ia),out(kb,eb,ib)]);
				++i; ++j;
				continue;
			}

			//ambiquous!
			// find best aligment

			// a-array loop
			let end = ib.get(ka)[0];
			let diff =  end - j; // out 0 from a and [j..end) from b
			let found = i;
			let t
			for(t = i+1; t < end; ++t) {
				const k = key(a[t])
				if(!ib.has(k)) continue;
				const ndiff = Math.max(t-i, ib.get(k)[0]-j )
				if( ndiff > diff) continue;
				diff = ndiff; found = t;
			}
			end = ib.get(key(a[found]))[0];
			// now `found` holds then best balanced synk position
			// i.e. with shotest (min of max) output length from both sides
			while(i < found) {
				const k = key(a[i])
				ret.push([k,out(k,a[i],ia), undefined])
				++i;
			}
			while(j < end) {
				const k = key(b[j])
				ret.push([k, undefined, out(k,b[j],ib)])
				++j;
			}
			const k = key(a[i])
			ret.push([k, out(k, a[i], ia), out(k, b[j], ib)])
			++i; ++j;
		}

		for(;i < a.length; ++i)
			ret.push([key(a[i]), a[i], undefined])
		for(;j < b.length; ++j)
			ret.push([key(b[j]), undefined, b[j]])

		return ret;
}

export function object_struct_diff(a, b) {
	if(isPlainObject(a) && isPlainObject(b)) {
		let ret = new Map
		for(const i in a) {
			const r = object_struct_diff(a[i],b[i])
			if(r !== undefined) ret.set(i,r)
		}
		return ret.size? Object.fromEntries(ret.entries()) : undefined;
	}
	return a!==b? a : undefined
}
