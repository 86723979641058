import React, {useState} from 'react'

import {ModalButton, PopupFrame} from 'azlib/components/modals'
import {ModalEdit} from 'azlib/components/std-controls'

//import {registerControlsEditors} from 'azlib/components/model.controls.reg'


const OKSM = [
['004','АФГАНИСТАН','Переходное Исламское Государство Афганистан','AF','AFG'],
['008','АЛБАНИЯ','Республика Албания','AL','ALB'],
['010','АНТАРКТИДА','','AQ','ATA'],
['012','АЛЖИР','Алжирская Народная Демократическая Республика','DZ','DZA'],
['016','АМЕРИКАНСКОЕ САМОА','','AS','ASM'],
['020','АНДОРРА','Княжество Андорра','AD','AND'],
['024','АНГОЛА','Республика Ангола','AO','AGO'],
['028','АНТИГУА И БАРБУДА','','AG','ATG'],
['031','АЗЕРБАЙДЖАН','Республика Азербайджан','AZ','AZE'],
['032','АРГЕНТИНА','Аргентинская Республика','AR','ARG'],
['036','АВСТРАЛИЯ','','AU','AUS'],
['040','АВСТРИЯ','Австрийская Республика','AT','AUT'],
['044','БАГАМЫ','Содружество Багамы','BS','BHS'],
['048','БАХРЕЙН','Королевство Бахрейн','BH','BHR'],
['050','БАНГЛАДЕШ','Народная Республика Бангладеш','BD','BGD'],
['051','АРМЕНИЯ','Республика Армения','AM','ARM'],
['052','БАРБАДОС','','BB','BRB'],
['056','БЕЛЬГИЯ','Королевство Бельгия','BE','BEL'],
['060','БЕРМУДЫ','','BM','BMU'],
['064','БУТАН','Королевство Бутан','BT','BTN'],
['068','БОЛИВИЯ, МНОГОНАЦИОНАЛЬНОЕ ГОСУДАРСТВО','Многонациональное Государство Боливия','BO','BOL'],
['070','БОСНИЯ И ГЕРЦЕГОВИНА','','BA','BIH'],
['072','БОТСВАНА','Республика Ботсвана','BW','BWA'],
['074','ОСТРОВ БУВЕ','','BV','BVT'],
['076','БРАЗИЛИЯ','Федеративная Республика Бразилия','BR','BRA'],
['084','БЕЛИЗ','','BZ','BLZ'],
['086','БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ','','IO','IOT'],
['090','СОЛОМОНОВЫ ОСТРОВА','','SB','SLB'],
['092','ВИРГИНСКИЕ ОСТРОВА (БРИТАНСКИЕ)','Британские Виргинские острова','VG','VGB'],
['096','БРУНЕЙ-ДАРУССАЛАМ','','BN','BRN'],
['100','БОЛГАРИЯ','Республика Болгария','BG','BGR'],
['104','МЬЯНМА','Республика Союза Мьянма','MM','MMR'],
['108','БУРУНДИ','Республика Бурунди','BI','BDI'],
['112','БЕЛАРУСЬ','Республика Беларусь','BY','BLR'],
['116','КАМБОДЖА','Королевство Камбоджа','KH','KHM'],
['120','КАМЕРУН','Республика Камерун','CM','CMR'],
['124','КАНАДА','','CA','CAN'],
['132','КАБО-ВЕРДЕ','Республика Кабо-Верде','CV','CPV'],
['136','ОСТРОВА КАЙМАН','','KY','CYM'],
['140','ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА','','CF','CAF'],
['144','ШРИ-ЛАНКА','Демократическая Социалистическая Республика Шри-Ланка','LK','LKA'],
['148','ЧАД','Республика Чад','TD','TCD'],
['152','ЧИЛИ','Республика Чили','CL','CHL'],
['156','КИТАЙ','Китайская Народная Республика','CN','CHN'],
['158','ТАЙВАНЬ (КИТАЙ)','','TW','TWN'],
['162','ОСТРОВ РОЖДЕСТВА','','CX','CXR'],
['166','КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА','','CC','CCK'],
['170','КОЛУМБИЯ','Республика Колумбия','CO','COL'],
['174','КОМОРЫ','Союз Коморы','KM','COM'],
['175','МАЙОТТА','','YT','MYT'],
['178','КОНГО','Республика Конго','CG','COG'],
['180','КОНГО, ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА','Демократическая Республика Конго','CD','COD'],
['184','ОСТРОВА КУКА','','CK','COK'],
['188','КОСТА-РИКА','Республика Коста-Рика','CR','CRI'],
['191','ХОРВАТИЯ','Республика Хорватия','HR','HRV'],
['192','КУБА','Республика Куба','CU','CUB'],
['196','КИПР','Республика Кипр','CY','CYP'],
['203','ЧЕХИЯ','Чешская Республика','CZ','CZE'],
['204','БЕНИН','Республика Бенин','BJ','BEN'],
['208','ДАНИЯ','Королевство Дания','DK','DNK'],
['212','ДОМИНИКА','Содружество Доминики','DM','DMA'],
['214','ДОМИНИКАНСКАЯ РЕСПУБЛИКА','','DO','DOM'],
['218','ЭКВАДОР','Республика Эквадор','EC','ECU'],
['222','ЭЛЬ-САЛЬВАДОР','Республика Эль-Сальвадор','SV','SLV'],
['226','ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ','Республика Экваториальная Гвинея','GQ','GNQ'],
['231','ЭФИОПИЯ','Федеративная Демократическая Республика Эфиопия','ET','ETH'],
['232','ЭРИТРЕЯ','Государство Эритрея','ER','ERI'],
['233','ЭСТОНИЯ','Эстонская Республика','EE','EST'],
['234','ФАРЕРСКИЕ ОСТРОВА','','FO','FRO'],
['238','ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)','','FK','FLK'],
['239','ЮЖНАЯ ДЖОРДЖИЯ И ЮЖНЫЕ САНДВИЧЕВЫ ОСТРОВА','','GS','SGS'],
['242','ФИДЖИ','Республика Фиджи','FJ','FJI'],
['246','ФИНЛЯНДИЯ','Финляндская Республика','FI','FIN'],
['248','ЭЛАНДСКИЕ ОСТРОВА','','АХ','ALA'],
['250','ФРАНЦИЯ','Французская Республика','FR','FRA'],
['254','ФРАНЦУЗСКАЯ ГВИАНА','','GF','GUF'],
['258','ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ','','PF','PYF'],
['260','ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ','','TF','ATF'],
['262','ДЖИБУТИ','Республика Джибути','DJ','DJI'],
['266','ГАБОН','Габонская Республика','GA','GAB'],
['268','ГРУЗИЯ','','GE','GEO'],
['270','ГАМБИЯ','Исламская Республика Гамбия','GM','GMB'],
['275','ПАЛЕСТИНА, ГОСУДАРСТВО','Государство Палестина','PS','PSE'],
['276','ГЕРМАНИЯ','Федеративная Республика Германия','DE','DEU'],
['288','ГАНА','Республика Гана','GH','GHA'],
['292','ГИБРАЛТАР','','GI','GIB'],
['296','КИРИБАТИ','Республика Кирибати','KI','KIR'],
['300','ГРЕЦИЯ','Греческая Республика','GR','GRC'],
['304','ГРЕНЛАНДИЯ','','GL','GRL'],
['308','ГРЕНАДА','','GD','GRD'],
['312','ГВАДЕЛУПА','','GP','GLP'],
['316','ГУАМ','','GU','GUM'],
['320','ГВАТЕМАЛА','Республика Гватемала','GT','GTM'],
['324','ГВИНЕЯ','Гвинейская Республика','GN','GIN'],
['328','ГАЙАНА','Кооперативная Республика Гайана','GY','GUY'],
['332','ГАИТИ','Республика Гаити','HT','HTI'],
['334','ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД','','HM','HMD'],
['336','ПАПСКИЙ ПРЕСТОЛ (ГОСУДАРСТВО - ГОРОД ВАТИКАН)','','VA','VAT'],
['340','ГОНДУРАС','Республика Гондурас','HN','HND'],
['344','ГОНКОНГ','Специальный административный район Гонконг Китайской Народной Республики','HK','HKG'],
['348','ВЕНГРИЯ','Венгрия','HU','HUN'],
['352','ИСЛАНДИЯ','Республика Исландия','IS','ISL'],
['356','ИНДИЯ','Республика Индия','IN','IND'],
['360','ИНДОНЕЗИЯ','Республика Индонезия','ID','IDN'],
['364','ИРАН (ИСЛАМСКАЯ РЕСПУБЛИКА)','Исламская Республика Иран','IR','IRN'],
['368','ИРАК','Республика Ирак','IQ','IRQ'],
['372','ИРЛАНДИЯ','','IE','IRL'],
['376','ИЗРАИЛЬ','Государство Израиль','IL','ISR'],
['380','ИТАЛИЯ','Итальянская Республика','IT','ITA'],
['384','КОТ Д\'ИВУАР','Республика Кот д\'Ивуар','CI','CIV'],
['388','ЯМАЙКА','','JM','JAM'],
['392','ЯПОНИЯ','','JP','JPN'],
['398','КАЗАХСТАН','Республика Казахстан','KZ','KAZ'],
['400','ИОРДАНИЯ','Иорданское Хашимитское Королевство','JO','JOR'],
['404','КЕНИЯ','Республика Кения','KE','KEN'],
['408','КОРЕЯ, НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА','Корейская Народно-Демократическая Республика','KP','PRK'],
['410','КОРЕЯ, РЕСПУБЛИКА','Республика Корея','KR','KOR'],
['414','КУВЕЙТ','Государство Кувейт','KW','KWT'],
['417','КИРГИЗИЯ','Киргизская Республика','KG','KGZ'],
['418','ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА','','LA','LAO'],
['422','ЛИВАН','Ливанская Республика','LB','LBN'],
['426','ЛЕСОТО','Королевство Лесото','LS','LSO'],
['428','ЛАТВИЯ','Латвийская Республика','LV','LVA'],
['430','ЛИБЕРИЯ','Республика Либерия','LR','LBR'],
['434','ЛИВИЯ','Государство Ливия','LY','LBY'],
['438','ЛИХТЕНШТЕЙН','Княжество Лихтенштейн','LI','LIE'],
['440','ЛИТВА','Литовская Республика','LT','LTU'],
['442','ЛЮКСЕМБУРГ','Великое Герцогство Люксембург','LU','LUX'],
['446','МАКАО','Специальный административный район Макао Китайской Народной Республики','MO','MAC'],
['450','МАДАГАСКАР','Республика Мадагаскар','MG','MDG'],
['454','МАЛАВИ','Республика Малави','MW','MWI'],
['458','МАЛАЙЗИЯ','','MY','MYS'],
['462','МАЛЬДИВЫ','Мальдивская Республика','MV','MDV'],
['466','МАЛИ','Республика Мали','ML','MLI'],
['470','МАЛЬТА','Республика Мальта','MT','MLT'],
['474','МАРТИНИКА','','MQ','MTQ'],
['478','МАВРИТАНИЯ','Исламская Республика Мавритания','MR','MRT'],
['480','МАВРИКИЙ','Республика Маврикий','MU','MUS'],
['484','МЕКСИКА','Мексиканские Соединенные Штаты','MX','MEX'],
['492','МОНАКО','Княжество Монако','MC','MCO'],
['496','МОНГОЛИЯ','','MN','MNG'],
['498','МОЛДОВА, РЕСПУБЛИКА','Республика Молдова','MD','MDA'],
['499','ЧЕРНОГОРИЯ','','ME','MNE'],
['500','МОНТСЕРРАТ','','MS','MSR'],
['504','МАРОККО','Королевство Марокко','MA','MAR'],
['508','МОЗАМБИК','Республика Мозамбик','MZ','MOZ'],
['512','ОМАН','Султанат Оман','OM','OMN'],
['516','НАМИБИЯ','Республика Намибия','NA','NAM'],
['520','НАУРУ','Республика Науру','NR','NRU'],
['524','НЕПАЛ','','NP','NPL'],
['528','НИДЕРЛАНДЫ','Королевство Нидерландов','NL','NLD'],
['531','КЮРАСАО','','CW','CUW'],
['533','АРУБА','','AW','ABW'],
['534','СЕН-МАРТЕН (нидерландская часть)','','SX','SXM'],
['535','БОНЭЙР, СИНТ-ЭСТАТИУС И САБА','','BQ','BES'],
['540','НОВАЯ КАЛЕДОНИЯ','','NC','NCL'],
['548','ВАНУАТУ','Республика Вануату','VU','VUT'],
['554','НОВАЯ ЗЕЛАНДИЯ','','NZ','NZL'],
['558','НИКАРАГУА','Республика Никарагуа','NI','NIC'],
['562','НИГЕР','Республика Нигер','NE','NER'],
['566','НИГЕРИЯ','Федеративная Республика Нигерия','NG','NGA'],
['570','НИУЭ','Ниуэ','NU','NIU'],
['574','ОСТРОВ НОРФОЛК','','NF','NFK'],
['578','НОРВЕГИЯ','Королевство Норвегия','NO','NOR'],
['580','СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА','Содружество Северных Марианских островов','MP','MNP'],
['581','МАЛЫЕ ТИХООКЕАНСКИЕ ОТДАЛЕННЫЕ ОСТРОВА СОЕДИНЕННЫХ ШТАТОВ','','UM','UMI'],
['583','МИКРОНЕЗИЯ, ФЕДЕРАТИВНЫЕ ШТАТЫ','Федеративные штаты Микронезии','FM','FSM'],
['584','МАРШАЛЛОВЫ ОСТРОВА','Республика Маршалловы Острова','MH','MHL'],
['585','ПАЛАУ','Республика Палау','PW','PLW'],
['586','ПАКИСТАН','Исламская Республика Пакистан','PK','PAK'],
['591','ПАНАМА','Республика Панама','PA','PAN'],
['598','ПАПУА-НОВАЯ ГВИНЕЯ','Независимое Государство Папуа Новая Гвинея','PG','PNG'],
['600','ПАРАГВАЙ','Республика Парагвай','PY','PRY'],
['604','ПЕРУ','Республика Перу','PE','PER'],
['608','ФИЛИППИНЫ','Республика Филиппины','PH','PHL'],
['612','ПИТКЕРН','','PN','PCN'],
['616','ПОЛЬША','Республика Польша','PL','POL'],
['620','ПОРТУГАЛИЯ','Португальская Республика','PT','PRT'],
['624','ГВИНЕЯ-БИСАУ','Республика Гвинея-Бисау','GW','GNB'],
['626','ТИМОР-ЛЕСТЕ','Демократическая Республика Тимор-Лесте','TL','TLS'],
['630','ПУЭРТО-РИКО','','PR','PRI'],
['634','КАТАР','Государство Катар','QA','QAT'],
['638','РЕЮНЬОН','','RE','REU'],
['642','РУМЫНИЯ','','RO','ROU'],
['643','РОССИЯ','Российская Федерация','RU','RUS'],
['646','РУАНДА','Руандийская Республика','RW','RWA'],
['652','СЕН-БАРТЕЛЕМИ','','BL','BLM'],
['654','СВЯТАЯ ЕЛЕНА, ОСТРОВ ВОЗНЕСЕНИЯ, ТРИСТАН-ДА-КУНЬЯ','','SH','SHN'],
['659','СЕНТ-КИТС И НЕВИС','','KN','KNA'],
['660','АНГИЛЬЯ','','AI','AIA'],
['662','СЕНТ-ЛЮСИЯ','','LC','LCA'],
['663','СЕН-МАРТЕН (французская часть)','','MF','MAF'],
['666','СЕН-ПЬЕР И МИКЕЛОН','','PM','SPM'],
['670','СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ','','VC','VCT'],
['674','САН-МАРИНО','Республика Сан-Марино','SM','SMR'],
['678','САН-ТОМЕ И ПРИНСИПИ','Демократическая Республика Сан-Томе и Принсипи','ST','STP'],
['682','САУДОВСКАЯ АРАВИЯ','Королевство Саудовская Аравия','SA','SAU'],
['686','СЕНЕГАЛ','Республика Сенегал','SN','SEN'],
['688','СЕРБИЯ','Республика Сербия','RS','SRB'],
['690','СЕЙШЕЛЫ','Республика Сейшелы','SC','SYC'],
['694','СЬЕРРА-ЛЕОНЕ','Республика Сьерра-Леоне','SL','SLE'],
['702','СИНГАПУР','Республика Сингапур','SG','SGP'],
['703','СЛОВАКИЯ','Словацкая Республика','SK','SVK'],
['704','ВЬЕТНАМ','Социалистическая Республика Вьетнам','VN','VNM'],
['705','СЛОВЕНИЯ','Республика Словения','SI','SVN'],
['706','СОМАЛИ','Федеративная Республика Сомали','SO','SOM'],
['710','ЮЖНАЯ АФРИКА','Южно-Африканская Республика','ZA','ZAF'],
['716','ЗИМБАБВЕ','Республика Зимбабве','ZW','ZWE'],
['724','ИСПАНИЯ','Королевство Испания','ES','ESP'],
['728','ЮЖНЫЙ СУДАН','Республика Южный Судан','SS','SSD'],
['729','СУДАН','Республика Судан','SD','SDN'],
['732','ЗАПАДНАЯ САХАРА','','EH','ESH'],
['740','СУРИНАМ','Республика Суринам','SR','SUR'],
['744','ШПИЦБЕРГЕН И ЯН МАЙЕН','','SJ','SJM'],
['748','ЭСВАТИНИ','Королевство Эсватини','SZ','SWZ'],
['752','ШВЕЦИЯ','Королевство Швеция','SE','SWE'],
['756','ШВЕЙЦАРИЯ','Швейцарская Конфедерация','CH','CHE'],
['760','СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА','','SY','SYR'],
['762','ТАДЖИКИСТАН','Республика Таджикистан','TJ','TJK'],
['764','ТАИЛАНД','Королевство Таиланд','TH','THA'],
['768','ТОГО','Тоголезская Республика','TG','TGO'],
['772','ТОКЕЛАУ','','TK','TKL'],
['776','ТОНГА','Королевство Тонга','TO','TON'],
['780','ТРИНИДАД И ТОБАГО','Республика Тринидад и Тобаго','TT','TTO'],
['784','ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ','','AE','ARE'],
['788','ТУНИС','Тунисская Республика','TN','TUN'],
['792','ТУРЦИЯ','Турецкая Республика','TR','TUR'],
['795','ТУРКМЕНИСТАН','Туркменистан','TM','TKM'],
['796','ОСТРОВА ТЕРКС И КАЙКОС','','TC','TCA'],
['798','ТУВАЛУ','','TV','TUV'],
['800','УГАНДА','Республика Уганда','UG','UGA'],
['804','УКРАИНА','','UA','UKR'],
['807','СЕВЕРНАЯ МАКЕДОНИЯ','Республика Северная Македония','MK','MKD'],
['818','ЕГИПЕТ','Арабская Республика Египет','EG','EGY'],
['826','СОЕДИНЕННОЕ КОРОЛЕВСТВО','Соединенное Королевство Великобритании и Северной Ирландии','GB','GBR'],
['831','ГЕРНСИ','','GG','GGY'],
['832','ДЖЕРСИ','','JE','JEY'],
['833','ОСТРОВ МЭН','','IM','IMN'],
['834','ТАНЗАНИЯ, ОБЪЕДИНЕННАЯ РЕСПУБЛИКА','Объединенная Республика Танзания','TZ','TZA'],
['840','СОЕДИНЕННЫЕ ШТАТЫ','Соединенные Штаты Америки','US','USA'],
['850','ВИРГИНСКИЕ ОСТРОВА (США)','Виргинские острова Соединенных Штатов','VI','VIR'],
['854','БУРКИНА-ФАСО','','BF','BFA'],
['858','УРУГВАЙ','Восточная Республика Уругвай','UY','URY'],
['860','УЗБЕКИСТАН','Республика Узбекистан','UZ','UZB'],
['862','ВЕНЕСУЭЛА (БОЛИВАРИАНСКАЯ РЕСПУБЛИКА)','Боливарианская Республика Венесуэла','VE','VEN'],
['876','УОЛЛИС И ФУТУНА','','WF','WLF'],
['882','САМОА','Независимое Государство Самоа','WS','WSM'],
['887','ЙЕМЕН','Йеменская Республика','YE','YEM'],
['894','ЗАМБИЯ','Республика Замбия','ZM','ZMB'],
['895','АБХАЗИЯ','Республика Абхазия','AB','ABH'],
['896','ЮЖНАЯ ОСЕТИЯ','Республика Южная Осетия','OS','OST'],
['897','ДНР','Донецкая Народная Республика','DN','DNR'],
['898','ЛНР','Луганская Народная Республика','LN','LNR'],
];

const OKSMobj = OKSM.map(e=>({
	oksm_code: e[0]
	, letter2: e[3]
	, letter3: e[4]
	, country_sname: e[1]
	, country_name: e[2]
}))

let indexedOKSM_bycode = new Map()
let indexedOKSM_by2 = new Map()
let indexedOKSM_by3 = new Map()
let indexedOKSM_bySname = new Map()
OKSMobj.forEach(e=>{
	indexedOKSM_bycode.set(e.oksm_code,e)
	indexedOKSM_by2.set(e.letter2,e)
	indexedOKSM_by3.set(e.letter3,e)
	indexedOKSM_bySname.set(e.country_sname,e)
})

export function countryCodeObj() {
	let obj = {};
	obj[""] = "";
	OKSM.forEach((v) => {
		let key = v[3];
		let value = v[3];
		obj[key] = value;
	});
	return obj
}
export function OKSM_Select({...props}) {
	let [filter, setFilter] = useState({})
	return <PopupFrame
			style={{display:"flex column"}}
		>
		<div><input onChange={(e)=>{
			let val = e.target.value.trim()
			if(!val) setFilter({})
			if(/^[0-9]/.test(val))
				setFilter({k:val})
			else
			if(/^[a-z][a-z]$/i.test(val))
				setFilter({l2:val.toUpperCase()})
			else
				setFilter({v:val.toUpperCase()})
		}} 
			style={{width:"90%", margin:"0 auto 1em auto", display:"block"}}
			  autoComplete="off"
		/>
		</div>
		<div style={{height:"80vh", width:"30em", overflow:"auto", position:"relative"}}>
				<ModalButton
					value=""
					className="link block"
				>
					<div style={{width:"5em", position:"absolute"}}>---</div>
					<div style={{marginLeft:"6em"}}>&nbsp;</div>
				</ModalButton>
		{
			OKSMobj
			.filter(e=>
				filter.k? e.oksm_code === filter.k
				: filter.l2? e.letter2 === filter.l2
				: filter.v? e.country_sname.toUpperCase().indexOf(filter.v) >= 0
				: true
			)
			.map(e=>
				<ModalButton key={e.letter2} 
					value={e.letter2}
					className="link block"
				>
					<div style={{width:"5em", position:"absolute"}}>{e.letter2}</div>
					<div style={{marginLeft:"6em"}}>{e.country_sname}</div>
				</ModalButton>
			)
		}
		</div>
	</PopupFrame>
}

function decodeL2(code) {
	if(!code) return ''
	return indexedOKSM_by2.get(code)?.country_sname ?? code
}

export function OKSM_Edit(props) {
	return <ModalEdit {...props}
		dialog={<OKSM_Select />} closeBox
		text={decodeL2}
	/>
}


//registerControlsEditors({country:OKSM_Edit})