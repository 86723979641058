import React, {useContext, useState, useEffect} from 'react'
import { Formik, useFormikContext, FormikProvider } from 'formik'

import {
	ModelField, LabeledField, Calculate, formikExtra
	, Field, modalFormik, ExtendFormikContext, LabeledFieldLabel
} from 'azlib/components/formik-fields'

import {showModal, PopupMenu, ModalButton, prompt}
	from 'azlib/components/modals'

import Sorter from 'azlib/components/list_sort'

import {showDocFeedbacks} from 'docs/feedback'

import {setDocNumbers, DelPrintedDocs, DocNumbers} from 'docs/print2'

import {internal_fields} from 'docs/params_descr'

import {localISOtoday, localISOnow} from 'azlib/components/calendar'

import {has_spec_code, MegaMonsterEdit} from 'config/ed_levels_map'

import {InfosetEditor} from './infoset_edit'

import {FieldAsPrompt, Textarea} from 'azlib/components/std-controls'

import {ReactComponent as PrintSVG}  from './print.svg'
import {ReactComponent as EditSVG} from 'contingent/edit.svg'
import {spec_name_by_kind} from "../config/doctype_typepros";
import {ErrorMessage} from "../azlib/components/formik-fields";
import {number, object, string} from "yup";

import {alert} from 'azlib/components/modals'

export function NumberCol({docid, doctype_kind, header, orginfo, form, readOnly, ...props}){
	const fc = useFormikContext();
	return <PopupMenu
		trigger={<button>Печать</button>}
		bindX={form?"left":"right"}
	>
		<ModalButton className="block"
					 onClick={async ()=> {
						 setDocNumbers({
							 fc
							 ,kind:doctype_kind
							 ,docid
							 , header
							 , orginfo
							 , readOnly
						 })
					 }}
		>проставить номера</ModalButton>
		<ModalButton className="block"
					 onClick={async ()=>{
						 await showModal(<DelPrintedDocs
							 fc={fc}
							 kind={doctype_kind}
							 docid={docid}
							 header={header}
							 orginfo={orginfo}
							 readOnly={readOnly}
						 />, {closeBox:true})
					 }}
		>отозвать выписку</ModalButton>
		<hr/>
		{
			window.AppBundle.data.doc_forms.decode[doctype_kind] &&
			window.AppBundle.data
				.doc_forms.decode[doctype_kind]
				.filter(f=>orginfo.orgtree_docforms.some(
					x=>
						x.kind === doctype_kind
						&&
						x.form_type === f
				))
				.map((f)=>
					<PopupMenu key={f} className="flexContainer"
							   trigger={<button type="button" className="block">{f}</button>}
							   bindX="right"
							   onHide={async form=>{
								   if(form) {
									   let {printPdf} = await import('genpdf/pdf-form')
									   await printPdf(form,docid,orginfo)
								   }
							   }}
					><div className="flexContainerColumn">
						{
							orginfo.orgtree_docforms
								.filter(x=>
									x.kind === doctype_kind
									&&
									x.form_type === f
								)
								.map(x=>
									<ModalButton key={x.form}
												 value={x.form}
									>
										{x.formname}
									</ModalButton>
								)
						}</div>
					</PopupMenu>
				)
		}
	</PopupMenu>
}

export function ShowPrintedDocs({kind, docid,orginfo}) {
	let [docs,setDocs] = useState(null);
	useEffect(()=>{
		orginfo.DbX.fetch_rds("prints"
			, "form_type docdate docnumber regnumber deleted delete_reason signer last_name first_name middle_name position"
			, {docid})
			.then(a=>{
				a = a.sort((a,b) => Sorter.cmp(b.docdate,a.docdate))
				setDocs(a)
			})
	}, [docid])
	return <DocNumbers kind={kind} docs={docs} />
}
export function FeedbackButton({orginfo, docid, header}) {
	return <button type="button"
				onClick={
					async ()=> {
						await showDocFeedbacks(orginfo, docid, header)}}
		>Обращения</button>
}

export function StatusCol({docid, snils, orginfo, header, readOnly, form, ...props}) {
	const fc = useFormikContext();
	return  <PopupMenu trigger={<button type="button">Действия</button>}
				   bindX={form?'left':'right'}
				   popup={<>
					   <ModalButton value="issued-replaced" className="statusDriver block"
					   >Заменить документ</ModalButton>
					   <ModalButton value="issued-rejected" className="statusDriver block"
					   >Отменить документ</ModalButton>
					   <ModalButton value="epgu-send" className="statusDriver block"
					   >(Пере)отправить на ЕПГУ</ModalButton>
					   <ModalButton value="mail-resend" className="statusDriver block" disabled={readOnly}
					   >Переотправить документ на почту</ModalButton>
				   </>}
		>{async code =>{
			/* eslint-disable-next-line default-case */
			switch(code) {
				case 'epgu-send':
					await resendToEPGU({docid,snils,header,orginfo})
					break;
				case 'mail-resend':
					console.log(docid);
					let rez=await orginfo.DbX.call_function('mail_refresh_job', { docid: docid });
					await alert('Ссылка на документ будет переотправлена на электронную почту, указанную ' +
						'в анкете обучающегося. Почта должна быть предварительно подтверждена обладателем документа')
					//console.log(rez);
					break;
				case 'issued-rejected':
					let upd =  await
						{
							docid: docid
							, status: code
							, status_message: (await prompt('Уточнение причины', ''))
								|| ''
						}
						if(orginfo.registry) {
							await orginfo.DbX.save_form('issued', null, upd);
							await fc.setFormikState(prev=>
								({  ...prev
									, status: {...prev.status, skipLocalSave: true}
									, values: {...prev.values
												, ...upd
												, status_stamp: localISOtoday()
												}
								}))
						}
						else
							alert("Недостаточно прав!")
						break;
				case 'issued-replaced':
		  			let cloned = await cloneIssued(docid, orginfo)
					/* eslint-disable-next-line default-case */
					switch(await showModal(<div>
						{/*<ModalButton value="lost" className="block"*/}
						{/*>Взамен утраченного</ModalButton>*/}
						<ModalButton value="fio" className="block" disabled={readOnly}
						>Изменение ФИО</ModalButton>
						<ModalButton value="correction" className="block" disabled={readOnly}
						>Корректировка ошибок</ModalButton>
					</div>)
						){
						// case 'lost':
						// 	{let reason = await prompt('Уточнение причины', '')
						// 	reason = reason?'Взамен утраченного: '+reason:'Взамен утраченного';
						// 		cloned.status_message = reason;
						// 		let inserted = await saveReplaced(cloned, orginfo);
						// 		await fc.setFormikState(prev=>
						// 			({  ...prev
						// 				, status: {...prev.status, skipLocalSave: true}
						// 				, values: {...prev.values,
						// 										status: 'issued-replacing'
						// 										, status_message: reason
						// 										, replaced_with: inserted
						// 										//TODO: our_number here!
						// 									}
						// 			}))
						// 		break;
						// 	}
						case 'fio':
						{let reason = await prompt('Уточнение причины', '')
							reason = reason?'Измение ФИО: '+reason:'Измение ФИО';
							cloned.status_message = reason;
							cloned = await editFIO({cloned})
							let inserted = await saveReplaced(cloned, orginfo);
							await fc.setFormikState(prev=>
								({  ...prev
									, status: {...prev.status, skipLocalSave: true}
									, values: {...prev.values,
										status: 'issued-replacing'
										, status_message: reason
										, replaced_with: inserted
										, status_stamp: localISOtoday()
										//TODO: our_number here!
									}
								}))
							break;
						}
						case 'correction':
						{let reason = await prompt('Уточнение причины', '')
							reason = reason?'Коррекция: '+reason:'Коррекция';
							cloned.status_message = reason;
							cloned = await editFIO({cloned})
							let data = await orginfo.DbX.fetch_row('issued', internal_fields, [docid])
							cloned = {...data, ...cloned, our_number:''}
							cloned = await replaceDoc({cloned,orginfo}
								, {closeBox:true
									, style: {width:"90vw"}
								})
							let inserted = await saveReplaced(cloned, orginfo);
							await fc.setFormikState(prev=>
								({ ...prev
									, status: {...prev.status, skipLocalSave: true}
									, values: {...prev.values,
										status: 'issued-replacing'
										, status_message: reason
										, replaced_with: inserted
										, status_stamp: localISOtoday()
										//TODO: our_number here!
									}
								}))
							break;
						}
						//default:  throw undefined;
					}
					break;
			}
		}}
		</PopupMenu>
}

async function cloneIssued(docid, orginfo) {
	let cloned = await orginfo.DbX.fetch_row('issued'
		, "infoset ed_level doctype_kind doctype_infoset" //infoset_orginfo
		+" tid snils epgu"
		+" last_name first_name middle_name bdate bplace "
		+" year_completion spec_code spec_name qualification course_length gov_doc"
		+" prefix_name"
		+" last_name_lat first_name_lat middle_name_lat prefix_name_lat"
		+" additional_info"
		, [docid])
  	
  	if(!cloned) throw String('docid not found')

	cloned.replaced = docid;
	cloned.status_message = ''

	return cloned
}

async function saveReplaced(doc, orginfo) {
	let cloned = Object.assign({}, doc);
	console.log(cloned)
	cloned.infoset = JSON.parse(cloned.infoset)
	for(const i in cloned)
		if(cloned[i] === '')
			cloned[i] = null;

	cloned.signed = null;
  	cloned.signers = null;
  	cloned.signatures = null;
  	cloned.cached_numbers = null;
  	cloned.infoset_parsed = null;
	cloned.infoset_orginfo = null;

	let inserted = await orginfo.DbX.call_function(
		'replace_doc', {cloned:JSON.stringify(cloned)}
	);
	return inserted
}

function editFIO({cloned}) {
	return modalFormik({
		initialValues:cloned
		, initialStatus:{}
		,
		validationSchema:
		object({
			last_name: string().required('Обязательное поле')
			, first_name: string().required('Обязательное поле')
			, bdate: string().required('Обязательное поле')
		})
	},
	<ExtendFormikContext value={{[formikExtra]: { storeWithFields: {store:'docs_preparing'} }}}>
		<div className="flexContainer PopupLabel"><label>Фамилия</label><ModelField label=""  name="last_name" />
		</div>
		<div className="flexContainer PopupLabel"><label>Имя</label><ModelField label=""  name="first_name" />
		</div>
		<div className="flexContainer PopupLabel"><label>Отчество</label><ModelField label=""  name="middle_name" />
		</div>
		<div className="flexContainer PopupLabel"><label>Дата рождения</label><ModelField label="" name="bdate" />
		</div>
		<button className="blueModalButton" type="submit">Ок</button>
	</ExtendFormikContext>
	)
}

function replaceDoc({cloned, orginfo}) {
	console.log(cloned)

	return modalFormik({
		initialValues:cloned
		,initialStatus:{}
		},
	<ExtendFormikContext value={{
			[formikExtra]: { storeWithFields: {store:'docs_preparing'} }
		}}
	>
			<ModelField style={{width:"auto"}} name="year_completion"/>
			{cloned.spec_code?<>
					<LabeledFieldLabel
						label="Специальность"
					/>
					<div className="flexContainer">
						<div>
							<LabeledField style={{minHeight:"0", padding:"0.3em 1em"}}
								name="spec_code" name2="spec_name" name3="qualification"
								ed_level={cloned.ed_level}
								as={MegaMonsterEdit}
								orginfo={orginfo}
								placeholder="--.--.--"
								required
							/>
						</div>
						<div style={{width: "calc(100% - 15em - 1ex)"
							, margin: "auto 0 auto 1ex"
						}}>
							<LabeledField style={{margin:"auto"}}
								name="spec_name" as={Textarea}
								readOnly
								required
							/>
						</div>
						<div>
							<LabeledField undecorated name="spec_name"
										  prompt="Редактировать наименование специальности"
										  as={FieldAsPrompt}
							>
								<EditSVG tabIndex={0} alt="Редактировать" title="Редактировать"
										 style={{height:"1em"
											 , verticalAlign:"bottom"
											 , display: "inline-block"
										 }}
								/>
							</LabeledField>
						</div>
					</div>
				<ModelField name="qualification" style={{width:"auto"}}
							ed_level={cloned.ed_level}
				/>
				</>
				:null
			}

			<LabeledField
						  name="infoset"
						  doc_kind={cloned.doctype_kind}
						  template={cloned.doctype_infoset}
						  as={InfosetEditor}
						  readOnly={!orginfo.registry}
			/>

		<button style={{margin:"auto"}} type="submit" className="formButtons">Ок</button>

	</ExtendFormikContext>
	)
}

function resendToEPGU({docid, snils, header, orginfo}) {
	return modalFormik({
			initialValues: {
				docid: docid
				, snils: snils
				, epgu: 1
				, epgu_stamp: localISOnow()
			}
			, validationSchema:
				object({
					snils: string()
						.required('СНИЛС необходим')
						.matches(/^(\d\d\d-\d\d\d-\d\d\d) (\d\d)$/
							, { message: 'неверный формат', excludeEmptyString: true }
						)
				})
			, onSubmit: async (values, ops) => {
				if(!values.snils) {
					await alert('Нет СНИЛС')
					return;
				}
				await orginfo.DbX.save_form('issued', null, values);
				ops.modalContext.hide()
			}
		}
		,
		<>
		{header} 
		<hr/>
		СНИЛС:
		<Field name="snils"/>
		<div className="error_in_groups"><ErrorMessage name="snils" /></div>
		<button style={{margin:"auto"}} type="submit" disabled={!orginfo.registry} className="formButtons">Ок</button>
		</>
	)
}

export function Header({stud, regnumb}){
	if (regnumb)
	{return <header>{stud.last_name + ' ' + stud.first_name + ' ' + stud.middle_name + ' ' + stud.our_number}</header>}
	return <header>{stud.last_name + ' ' + stud.first_name + ' ' + stud.middle_name}</header>
}
