import React, {useEffect, useState, useContext} from 'react'

import { Formik, Form } from 'formik'
import { object, string, date, number } from 'yup';
import {
	ExtendFormikContext,
	Field,
	formikExtra,
	LabeledField,
	modalFormik,
	ModelField
} from 'azlib/components/formik-fields'

import {prompt} from 'azlib/components/modals'

import {ru_date} from 'azlib/components/locales/ru/ru_date'

import { formatLocalDate, localISOtoday } from 'azlib/components/calendar'
import {DateInput} from 'azlib/components/std-controls'

import Sorter from 'azlib/components/list_sort'

import {OrgInfoContext} from 'org/org'
import {ErrorMessage} from "../azlib/components/formik-fields";
import * as yup from "../azlib/components/yup_plus";

/*
print
1) set number(s) => show modal dialog to set(add) new printed numbers
	(with form types)
2) import print numbers => show importer (table parser)
	and set multiple docnumbers at once
3) print individual doc
	show dialog with button(s) "print" and ask printed number here

4) print many docs 
	select them with filter by types and checkboxes
   and individually confirm sucessfully print by form type
   (not modal!!!!)	
*/

function formatNumberCell(reg, rowdate, f, d, modifyCell) {
	let txt = `${d.docnumber}`
	let body = d.deleted ?
			<span><del>{txt}</del></span>
		: <span>{txt}</span>
	return modifyCell ? modifyCell(body, f, d) : body;
}

export function DocNumbers({kind,docs,modifyCell}) {
	let reg = false;
	let forms = window.AppBundle.data.doc_forms.decode[kind];
	let res = [];
	if(docs) {
		// arrange docs by date, reg number and form pos
		let ord = Array.from(docs)
		ord.sort((a,b)=>
			a.docdate !== b.docdate?
				Sorter.cmp(a.docdate, b.docdate)
			:
			a.regnumber !== b.regnumber?
				Sorter.cmp(a.regnumber,b.regnumber)
				:
			( (forms.indexOf(a.form_type)+1||1000) 
				- 
			  (forms.indexOf(b.form_type)+1||1000)
			)
		)
		let mgr = null; 
		for(const x of ord) {
			if(x.regnumber) reg = true;
			if(mgr)
			if(mgr.regnumber !== x.regnumber
				|| mgr.docdate !== x.docdate
				|| mgr.forms[x.form_type]
			) 
			{
				res.push(mgr);
				mgr = null;
			}
			x.fio = x.last_name && `${x.last_name} ${x.first_name} ${x.middle_name}`
			if(!mgr) {
				mgr = {
					regnumber: x.regnumber
					, docdate: x.docdate
					, forms: {}
				}
			}
			mgr.forms[x.form_type] = x;
		}
		if(mgr) res.push(mgr)
	}


	return res.length ? 
		<table className="simpleTable">
		<thead><tr>
			{reg && <th>Рег. номер</th>}
			<th>Дата выдачи</th>
			{
				forms.map(f=>
					<th key={f}>{f}</th>
				)
			}
			<th>Отзыв</th>
		</tr></thead>
		<tbody>
		{res.map(r=><tr key={`${r.docdate}:${r.regnumber}:${Object.keys(r.forms).map(n=>n+'#'+r.forms[n].docnumber).join('##')}`}>
			{reg && <td>{r.regnumber}</td>}
			<td>{formatLocalDate(r.docdate)	}</td>
			{forms.map(f=>
				<td key={f}>
					{f in r.forms ?
						formatNumberCell(reg, r.docdate, f, r.forms[f], modifyCell)
					:null
				    }
				</td>
			)}
				<td>
					{forms.filter(f=>r.forms[f]?.deleted)
					.map(f=><div key={f}>
						{f}{': '}{ru_date(r.forms[f].deleted)}
								{' '}
								{r.forms[f].delete_reason}
						</div>)
					}
				</td>
			</tr>
			)
		}
		</tbody>
		</table>
		: 'нет'
}

export async function setDocNumbers({fc, kind, docid, header, orginfo, readOnly}) {

	let reg = window.AppBundle.data.doc_kind_regnumber.decode[
			kind
		];

	let forms = window.AppBundle.data.doc_forms.decode[kind];

	let docs = await orginfo.DbX.fetch_rds("prints"
			, "form_type docdate docnumber regnumber deleted delete_reason signer last_name first_name middle_name position"
			, {docid})

	docs = docs.sort((a,b) => Sorter.cmp(b.docdate,a.docdate))

	let val = {
				docdate: window.localStorage['default-docdate'] 
						?? localISOtoday()
				, regnumber: ''
			}
	let scheme = {
		docdate: yup.date().required('Обязательное поле')
		, regnumber: yup.string()
	}

	for(const f of forms){
		val[f] = '';
		scheme[f] = yup.string().max(30);
	}

	scheme = yup.object(scheme)

	if(!docs) alert('---')

	let v = await modalFormik({
			initialValues:val
			, initialStatus:{}
			, validationSchema:scheme
		}
		,
		<ExtendFormikContext value={{[formikExtra]: { storeWithFields: {store:'prints'} }}}>
		{header}
		<hr/>
		<DocNumbers kind={kind} docs={docs} />
		<div>
			<hr/>
			<label>Дата выдачи</label>
			<div><Field name="docdate" as={DateInput} autoComplete="off"/></div>

			<fieldset>
			<legend>Номера</legend>
			{
				forms.map(f=>
					<div key={f}>
						<label>{f}</label>
						<div><Field name={f}  autoComplete="off"/></div>
						<div className="error_in_groups"><ErrorMessage name={f} /></div>
					</div>
				)
			}
			</fieldset>

			{reg&&<>
			<div><LabeledField name="regnumber" label="Регистрационный номер" max_length={30}
							   autoComplete="off"/></div>
			</>}
			<div>
				<button className="blueModalButton" disabled={readOnly} type="submit">ОК</button>
			</div>
		</div>
		</ExtendFormikContext>
	,{closeBox:true})

	window.localStorage['default-docdate'] = v.docdate
	let common = {docid: docid,
					docdate:v.docdate, 
					regnumber: v.regnumber,
					deleted: null,
					delete_reason: null,
				}
	for(const f of forms) {
		if(v[f])
			await orginfo.DbX.upsert_direct("prints",null,{
				...common
				, form_type: f
				, docnumber: v[f]
			})
	}
	await updateNumber(fc,docid, orginfo);

}

async function updateNumber(fc, docid, orginfo){
	let cached_numbers = await orginfo.DbX.fetch_column("issued"
		, "cached_numbers"
		, docid)

	fc.setFormikState(prev=>
		({  ...prev
			, values: {...prev.values, ...{cached_numbers}}
			, status: {...prev.status, skipLocalSave: true}
		}))
}

export function DelPrintedDocs({fc, kind, docid, header, orginfo, readOnly}) {
	let [docs,setDocs] = useState(null);
	async function doDel(f,d) {
		let reason = await prompt("Причина",'', true)
		await orginfo.DbX.save_form('prints',null,
			{docid, form_type: f, docdate: d.docdate, docnumber: d.docnumber,
			 deleted: localISOtoday(), delete_reason: reason})
		let ndocs =docs.map(a=>a.form_type === f && a.docdate === d.docdate ?
			{...a, deleted: localISOtoday(), delete_reason:reason}
			:a) 
		console.log(ndocs, d.docdate)
		setDocs(ndocs)
		await updateNumber(fc,docid, orginfo);
	}
	function cellWithDel(txt, f, d) {
		if(d.deleted) return txt
		return <>{txt}<button type="button" disabled={readOnly} className="del small"
				onClick={()=>doDel(f,d)} /></>
	}
	useEffect(()=>{
		orginfo.DbX.fetch_rds("prints"
			, "form_type docdate docnumber regnumber deleted delete_reason signer last_name first_name middle_name position"
			, {docid})
		.then(a=>{
			a = a.sort((a,b) => Sorter.cmp(b.docdate,a.docdate))
			setDocs(a)
		})
	}, [docid])
	return <div>
				{header}
				<DocNumbers kind={kind} docs={docs} modifyCell={cellWithDel} />
		   </div>
}
