import {smart_ed_level} from 'config/ed_levels_map'
import {letIn} from 'azlib/components/helpers'
import {alert} from 'azlib/components/modals'

function parseSignersRoles(orginfo, signerRoles){
    signerRoles = signerRoles? JSON.parse(signerRoles) : {}
    const customSchemes = window.AppBundle.data.signers_scheme.decode;

	const schemes = window.AppBundle.data.doc_kinds.orderedKeys
					.filter(k=>smart_ed_level(k) in orginfo.app_ed_level)
					.map(k=>[ k,
								customSchemes[k]?
								[ ...customSchemes[k]
										, ...orginfo.app_custom_signers
								]
								:
								['Руководитель образовательной организации'
										, ...orginfo.app_custom_signers
								]
							]
						)
					;
	const decode = (k) => window.AppBundle.data.doc_kinds.decode[k];

    let rolesArray = [];

    {letIn(schemes.filter(([k,s])=> k in signerRoles))
    (used=>
        used.length &&
        used.map(([k,s])=>
            rolesArray.push([k + ';' + decode(k), s.filter(p => p in signerRoles[k]).join(';')])
    ))}

    return rolesArray
}

function genFileName(doverNum){
    const prefix = 'ON_EMCHD'
    const yyyymmdd = new Date().toISOString().slice(0, 10).replace(/-/g, '')
    return prefix + '_' + yyyymmdd + '_' + doverNum + '.xml'
}

export function generateEMCHD(
    doverNum
    , pd
    , uinfo
    , person$
    , position
    , signerRoles
    , orginfo
    , admin
)
{
    if (!doverNum) {
        alert('Ошибка формирования МЧД: номер доверенности не сформирован!')
        return null
    }
    else if (!pd) {
        alert('Ошибка формирования МЧД: не получены данные об основной организации!')
        return null
    }
    else if (!position) {
        alert('Ошибка формирования МЧД: не задана должность пользователя!')
        return null
    }
    else if (!signerRoles) {
        alert('Ошибка формирования МЧД: не заданы новые роли подписывающего!')
        return null
    }

    let bossPosition = ''
    if (pd[5] !== '') {
        const boss = JSON.parse(pd[5])
        let uinfoBossFio = (uinfo.last_name + ' ' + uinfo.first_name + ' ' + uinfo.middle_name).toUpperCase()
		Object.entries(boss).map(function([pos, fio]) {
            fio = fio.toUpperCase().replace(/\s+/,' ').trim()
            if (fio === uinfoBossFio)
				bossPosition = pos
        })
    }
    else
        bossPosition = pd[3]

    let start_date = new Date().toISOString().split('T')[0]
    let currDate = new Date()
        currDate.setFullYear(new Date().getFullYear() + 1)
    let end_date = currDate.toISOString().split('T')[0]

    const xmlDoc = document.implementation.createDocument("urn://x-artefacts/EMCHD_1", "Доверенность", null)

    const firstRow = xmlDoc.createProcessingInstruction("xml", 'version="1.0" encoding="UTF-8"');
        xmlDoc.insertBefore(firstRow, xmlDoc.firstChild);

    const doverennost = xmlDoc.documentElement
        doverennost.setAttribute("ВерсФорм", "EMCHD_1")
        doverennost.setAttribute("ПрЭлФорм", "00000000")
        doverennost.setAttribute("ИдФайл", genFileName(doverNum))

    const doc = xmlDoc.createElement("Документ")
    const dover = xmlDoc.createElement("Довер")

    const svDov = xmlDoc.createElement("СвДов")
        svDov.setAttribute("ВидДовер", "1")
        svDov.setAttribute("ПрПередов", "0")
        svDov.setAttribute("ВнНомДовер", doverNum)
        svDov.setAttribute("НомДовер", doverNum)
        svDov.setAttribute("ДатаВнРегДовер", start_date)
        svDov.setAttribute("ДатаВыдДовер", start_date)
        svDov.setAttribute("СрокДейст", end_date)

    const svedSys = xmlDoc.createElement("СведСист")
        svedSys.textContent = "ЕР ЦДО"
        svDov.appendChild(svedSys)

    const svDoverit = xmlDoc.createElement("СвДоверит")
        svDoverit.setAttribute("ТипДоверит", "ЮЛ")

    const doverit = xmlDoc.createElement("Доверит")
    const rosOrgDover = xmlDoc.createElement("РосОргДовер")
    const svRosOrgTip = xmlDoc.createElement("СвРосОргТип")
        svRosOrgTip.setAttribute("НаимОрг", pd[4])
        svRosOrgTip.setAttribute("ИННЮЛ", pd[1])
        svRosOrgTip.setAttribute("КПП", pd[2])
        svRosOrgTip.setAttribute("ОГРН", pd[0])

    const lBDov = xmlDoc.createElement("ЛицоБезДов")
    const svFL = xmlDoc.createElement("СвФЛ")
        svFL.setAttribute("СНИЛС", uinfo.login)
        svFL.setAttribute("Должность", admin? 'Администратор' : bossPosition)

    const svedFL = xmlDoc.createElement("СведФЛ")
    const fio = xmlDoc.createElement("ФИО")
        fio.setAttribute("Фамилия", uinfo.last_name)
        fio.setAttribute("Имя", uinfo.first_name)
        fio.setAttribute("Отчество", uinfo.middle_name)

    svedFL.appendChild(fio)
    svFL.appendChild(svedFL)
    lBDov.appendChild(svFL)
    rosOrgDover.appendChild(svRosOrgTip)
    rosOrgDover.appendChild(lBDov)
    doverit.appendChild(rosOrgDover)
    svDoverit.appendChild(doverit)
  
    const svUpPredTip = xmlDoc.createElement("СвУпПредТип")
        svUpPredTip.setAttribute("ТипПред", "ФЛ")

    const pred = xmlDoc.createElement("Пред")
    const svedFisL = xmlDoc.createElement("СведФизЛ")
        svedFisL.setAttribute("СНИЛС", person$.login)
        svedFisL.setAttribute("Должность", position)

    const svedFL2 = xmlDoc.createElement("СведФЛ")
    const fioFL = xmlDoc.createElement("ФИО")
        fioFL.setAttribute("Фамилия", person$.last_name)
        fioFL.setAttribute("Имя", person$.first_name)
        fioFL.setAttribute("Отчество", person$.middle_name)

    svedFL2.appendChild(fioFL)
    svedFisL.appendChild(svedFL2)
    pred.appendChild(svedFisL)
    svUpPredTip.appendChild(pred)
  
    const svPoln = xmlDoc.createElement("СвПолн")
        svPoln.setAttribute("ТипПолн", "1")
        svPoln.setAttribute("ПрСовмПолн", "1")

    let roles = parseSignersRoles(orginfo, signerRoles)

    roles && roles.map(([k,s]) => {
        let kk = k.split(';')
        let ss = s.split(';')

        ss.map((role)=> {
            const mashPoln = xmlDoc.createElement("МашПолн")
            mashPoln.setAttribute("КодПолн", kk[0])
            mashPoln.setAttribute("НаимПолн", kk[1] + ': ' + role)

            svPoln.appendChild(mashPoln)
        })
    });

    
    dover.appendChild(svDov)
    dover.appendChild(svDoverit)
    dover.appendChild(svUpPredTip)
    dover.appendChild(svPoln)
    doc.appendChild(dover)
    doverennost.appendChild(doc)

    let xml = new XMLSerializer().serializeToString(xmlDoc)

    console.log(xml)
    return(xml)
}

export function cancelEMCHD(doverNum){
    const xmlDoc = document.implementation.createDocument("urn://x-artefacts/EMCHD_1", "Файл", null)

    const firstRow = xmlDoc.createProcessingInstruction("xml", 'version="1.0" encoding="UTF-8"');
        xmlDoc.insertBefore(firstRow, xmlDoc.firstChild);
    
    const f = xmlDoc.documentElement
    f.setAttribute("ВерсФорм", "EMCHD_1")

    const doc = xmlDoc.createElement("Документ")
    const svZ = xmlDoc.createElement("СвЗаяв")
    svZ.setAttribute("НомДовер", doverNum)
    svZ.setAttribute("ПричОтз", 'Отзыв у подписывающего всех ролей')

    doc.appendChild(svZ)
    f.appendChild(doc)

    let xml = new XMLSerializer().serializeToString(xmlDoc)

    console.log(xml)
    return(xml)
}

