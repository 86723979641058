import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "./fonts/vfs_fonts"
import { PDFDocument } from 'pdf-lib'

import {toLocalTime} from 'azlib/components/helpers'
import {ru_date, ru_date_text} from 'azlib/components/locales/ru/ru_date'

import {alert} from 'azlib/components/modals'

import {parseInfosetValue} from 'docs/infoset_edit'
import {calculate_internal, internal_fields} from 'docs/params_descr'


pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
	DejaVuSerif: {
		normal: 'DejaVuSerif.ttf',
	}
};

function infoset_as_data(doc_kind, infoset, all_values) {
	let merged = parseInfosetValue(doc_kind, null, infoset)

	return merged
			.filter(e=>e.internal!==false)
			.map( ({name, paragraph, insert,  
					internal, values, columns, adhoc}) =>
					insert? null
					:
					paragraph 
					?//show columns
					(columns?.length?
						{name, columns} //new table header
						:
						name //one col value
					)
					: //show plain parameter
					internal? ((v)=> v !== null &&
						[name,v] //two col name+value
						)((calculate_internal[name]??(()=>null)) (all_values))
					:
					values === null? null //skip
					:
					adhoc? {name,adhoc, values}
					:
					[name, ...values] //name + value columns
				)
			.filter(Boolean)
			//.flat()
}

export function preview_infoset(row) {
	const infoset_data = infoset_as_data(row.doctype_kind, row.infoset, row)
	
	//console.log(infoset_data)
	const ln = infoset_data[0].name === 'Фамилия'?
				 infoset_data[0].columns[0] : ''
	const fn = infoset_data[1].name === 'Имя'?
				 infoset_data[1].columns[0] : ''
	const mn = infoset_data[2].name === 'Отчество'?
				 infoset_data[2].columns[0] : ''

	let columns = [
		[509] // 1/12 = 42.5
		, [288-5,222-5]
		, [242-6,120-6,148-6]
		, [174-7,68-7,120-7,148-7]
		, [174-8,68-8,68-8,73-8,130-8]
	]
	let tables = [
			{text: window.AppBundle.data.doc_kinds.decode[row.doctype_kind] }
		]
	let cols = columns[1]
	let content = []
	function out_content() {
		if(content.length)
			tables.push({
				table: {
					widths: cols
					, body: content
				, ext: 1
				}
			})
		content = []
	}
	function append_empties(v, cnt) {
		v = v.slice(0,cnt)
		return Object.assign(Array(cols.length).fill(''), v)
	}
	for(const item of infoset_data) {
		if(typeof item === 'string') {
			out_content()
			tables.push( {
				table:{
					widths: columns[0]
					, body: [ [item] ]
				}
			})
			continue;
		}
		if(item.columns) { //table header
			out_content()
			tables.push( {
				table:{
					widths: columns[0]
					, body: [ [item.name] ]
				}
			})
			cols = columns[item.columns.length-1]
			content = [ item.columns.map(s=>s.replace(/:[0-9]+$/,'')) ]
			continue
		}
		if(item.adhoc) {
			out_content()
			cols = columns[item.values.length]
			content.push(append_empties([item.name,...item.values], cols.length))
			continue
		}
		content.push(append_empties(item, cols.length))
	}
	out_content()

	//console.log('aa', tables)

	return pdfMake.createPdf({
		content: [...tables]
		,defaultStyle: {
	    	font: 'DejaVuSerif'
	  	}
	  	, footer: function(currentPage, pageCount) { 
	  		return {columns:[
	  				{text: 
	  					ru_date_text(toLocalTime(new Date()).substr(0,16))
	  				, alignment: 'left'
	  				, margin: [50,0,5,0]
	  				}
	  				,
	  				{ text: 
	  				currentPage.toString() + ' / ' + pageCount
	  				, alignment: 'center'
	  				}
	  				] 
	  			}
	  	}
 		, header: function(currentPage, pageCount, pageSize) {
    		return {columns:[{text: "Предпросмотр документа"
    				+" "
    					, color: '#ff5500'
    					, margin: [50,5] 
    					, width: "auto"
    				}
    				, {text: `${ln} ${fn} ${mn}`
    					, margin: [0,5]
    			      }
    				]}
    	}
    	, info: {
			title: 'Предпросмотр документа',
			author: 'DigDoc',
  		}
  		, pageBreakBefore: (node) => {
  			//console.log(node);
  		}
	})
}

export function pdfBudder(pdf) {
	return new Promise((resolve,reject)=>{
		pdf.getBuffer(resolve)
	})
}

export async function appendDoc(dest, buffer) {
	let src = await PDFDocument.load(buffer);
	const pages = await dest.copyPages(src, src.getPageIndices());
	for(const p of pages) {
		await dest.addPage(p)
	}
}

export async function preview_infoset_array(rows, orginfo) {
	if(rows.length>100) {
		await alert('Не более 100 документов')
		return
	}
	let dest = await PDFDocument.create()

	for(const row of rows) {
		let r = await orginfo.DbX.fetch_row("docs"
			, internal_fields
			, row.docid 
			)

		let p = await preview_infoset(r)
		await appendDoc(dest, await pdfBudder(p))
	}

	//console.log('ld',dest.getPageIndices())

	let saved = await dest.save()
	//console.log(saved)
	
	let win = window.open('', '_blank');
	let blob = new Blob([saved.buffer], { type: 'application/pdf' });
	let pdfUrl = (window.URL || window.webkitURL).createObjectURL(blob);
	win.location.href = pdfUrl;
}