/*
spell out russian number

ru_number($n, $lcmd, $rcmd = null, $left = '', $right = '')

$left - command before dot
$left - command after dot

command:
	1) N - split by N digits
	2) 0 - output as simple number
	3) null - skip
	4) 'str' - m/f/n - gender
	left/right: 
		str or
		array [ 'рубль', 'рубля', 'рублей' ]
*/

export function ru_number_take_word($n, $wlist) {
	$n = String($n);
	if(!Array.isArray($wlist)) 
		$wlist=$wlist.split(':').map(v=>v.trim());
	if($wlist.length<=1) return $wlist[0]??'';
	switch(+($n.slice(-1))) {
		case 1: return $n.slice(-2,-1) !== '1' ? $wlist[0] : $wlist[2];
		case 2: case 3: case 4: return $n.slice(-2,-1) !== '1' ? $wlist[1] : $wlist[2];
		default: return $wlist[2];
	}
}

const $ru_number_arr = [ '-'
	, { 'm' : 'один', 'f' : 'одна', 'n' : 'одно' }
	, { 'm' : 'два', 'f' : 'две', 'n' : 'два' }
	,'три' ,'четыре' ,'пять' ,'шесть' ,'семь' ,'восемь' ,'девять'
	,'десять' 
	,'одиннадцать' ,'двенадцать' ,'тринадцать' ,'четырнадцать' ,'пятнадцать'
	,'шестнадцать' ,'семнадцать' ,'восемнадцать' ,'девятнадцать'
];
const $ru_number_arr_10 = [
	' - '
	, 'десять'
	,'двадцать' ,'тридцать' ,'сорок' ,'пятьдесят' 
	,'шестьдесят' ,'семьдесят' ,'восемьдесят' ,'девяносто'
];
const $ru_number_arr_100 = [
	' - '
	,'сто' ,'двести' ,'триста' ,'четыреста' ,'пятьсот'
	,'шестьсот' ,'семьсот' ,'восемьсот' ,'девятьсот'
];

const $ru_number_arr_grps = [
	[]
	,[['тысяча' ,'тысячи' ,'тысяч'], 'f']
	,[['миллион' ,'миллиона' ,'миллионов'], 'm']
	,[['миллиард' ,'миллиарда' ,'миллиардов'], 'm']
];

function ru_number_grp($n, $lcmd, $wl, $last) {
	let $r = '';
 	$n = Math.floor($n); 
 	$last = $last || $n;
	if($n >= 100) {
		$r += $ru_number_arr_100[$n/100|0];
		$n = $n % 100;
	}
	if($n >= 20) {
		if($r) $r += ' ';
		$r += $ru_number_arr_10[$n/10|0];
		$n = $n % 10;
	}
	if($n) {
		if($r) $r += ' ';
		if(+$n === 1 || +$n === 2)
			$r += $ru_number_arr[ $n|0 ][$lcmd];
		else
			$r += $ru_number_arr[ $n|0 ];
	}
	if($last)
		$r += ' ' + ru_number_take_word($n, $wl);
	return $r;
}

export function ru_number($n, $lcmd = 3, $rcmd = null, $left = '', $right = '') {
	let $r = '';
	$n = String($n);
	if($n === '0') {
		if($lcmd !== null) {
			if(typeof $lcmd === 'string')
				$r = 'ноль ' + ru_number_take_word(0, $left);
			else if(typeof $lcmd === 'number')
				$r = '0 ' + ru_number_take_word(0, $left);
		}
		if($rcmd !== null) {
			if($r !== '') $r += ' ';
			if(typeof $rcmd === 'string')
				$r = 'ноль ' + ru_number_take_word(0, $right);
			else if(typeof $rcmd === 'number')
				if($rcmd===0)
					$r = '0 ' + ru_number_take_word(0, $right);
				else
					$r =  '0'.repeat($rcmd) + ' ' + ru_number_take_word(0, $right);
		}
		return $r;
	}
	$n = number_format( $n, typeof $rcmd === 'string'? 2 : $rcmd, '.', ' ');
	$n = $n.split('.');
	let $nl = $n[0].split(' '); let $nr = $n[1];
	if($lcmd !== null) { 
			let $nlimp = $nl.join('');
			if(typeof $lcmd === 'string') {
				let $grp_n = $nl.length;
				for(const $grp of $nl) {
					if($r) $r += ' ';		
					--$grp_n;			
					$r += ru_number_grp($grp 
							, $grp_n ? $ru_number_arr_grps[$grp_n][1] : $lcmd
							, $grp_n ? $ru_number_arr_grps[$grp_n][0] : $left
							, !$grp_n
						);
				}
			}
			else if(typeof $lcmd === 'number')
				$r = ($lcmd === 3 ? $n[0] : $nlimp) 
					+ ru_number_take_word($nlimp, $left);
	}
	if($rcmd !== null) {
		if($r !== '') $r += ' ';
		if($rcmd === 'string')
			$r += ru_number_grp($nr, $rcmd, $right, true);
		else if($rcmd === 'number')
				$r +=  $nr + ' ' + ru_number_take_word($nr, $right);
	}
	return $r;
}


export function number_format($num, $decimals, $decimal_separator, $thousands_separator) {
	let sign = $num<0?'-':'';
	$num = Math.abs($num);
	$decimals = Math.min($decimals, 5);
	let div = +('1'+'0'.repeat($decimals))
	let lpart = Math.floor($num)
	let rpart = $num - lpart
	let c = $num;
	let r = []
	while(c) {
		r.shift(c % div)
		c = c / div|0
	}
	return sign + r.join($thousands_separator??',') 
			+ (rpart?
				$decimal_separator + rpart
			:'')
}