import React, {useContext} from 'react'
import {Link} from "react-router-dom"
import {FormikConsumer} from 'formik'

import {DbTableEdit as Table} from 'azlib/components/super-table'

import {toLocalTime} from 'azlib/components/helpers'
import {fullPersonName} from 'azlib/components/name_funcs'
import {showModal, PopupMenu, ModalButton,promptBig
} from 'azlib/components/modals'

import {ru_date_text} from 'azlib/components/locales/ru/ru_date'

import {localISOnow} from 'azlib/components/calendar'

import { OrgInfoContext } from 'org/org'

import { SearchFilter } from 'azlib/components/search_filter'

import Filter from 'azlib/components/list_filter'
import {fioExtract, fioCondition} from 'azlib/components/filter_helpers'

export function FeedbackList({orginfo, docid, header}){
	const oi = useContext(OrgInfoContext)
	orginfo = orginfo??oi;
	//console.log(Stud)

	return <>
		<h1>Обращения обратной связи
		</h1>
		<div style={{marginBottom:"1em"}}>{header}</div>
		<div>
		<Table className="simpleTable"
			store={orginfo.DbX.store("docs_trace", 
				{ tid: orginfo.tid, subcode: 'epgu-feedback' } )}
			extra="status_seq tid response_stamp"
			handleSave={Table.directSave}
			>
			<Table.Before>
				<div className="templatesSearch backgroundAngular">
					<SearchFilter
						input={
						<Filter.Multi className="FilterInput" placeholder={"Найти обучающегося"}
							extract={
								[
									[fioExtract, fioCondition(
										row=>[row.docid$.last_name, row.docid$.first_name, row.docid$.middle_name]
										)
									]
								]
							}
							saveAs="multi"
						/>							
						}
					/>

					<div className="flexContainer">
					<button className="checkBoxBut">
				В том числе закрытые: 
				<Filter.Check 
					condition={v => v==='active'? 
									row=> !row.response_stamp 
									: row=>true
							  }
					value='all' uncheckedValue='active'
					saveAs="allCheck"
				/></button>
					</div>
				</div>
			</Table.Before>
			{!docid && <Table.Col label="ФИО" name="docid"
				extra="docid{last_name first_name middle_name}"
			>
				{(value,row) =>
					row.status === 'doc-ready'?
						fullPersonName(row) || '--???--'
					:
					<Link to={`/org/${orginfo.tid}/issued/${value}`} >{fullPersonName(row.docid$) || '--???--'
					}</Link>
				}
			</Table.Col>
			}
			{!docid && <Table.Col label="Реестровый номер" colKey="our_number" 
				extra="docid{our_number}">{(v,r)=>
				<Link to={`/org/${orginfo.tid}/issued/${r.docid}`} >{
					r.docid$?.our_number
					}</Link>
			}</Table.Col>
			}
			<Table.Col label="Дата обращения" name="stamp">{gmt=> (v=>
				v && 
				ru_date_text(v.substr(0,10))
				)(toLocalTime(gmt))
			}</Table.Col>
			<Table.Col label="Время обращения" colKey="@stamp" name="stamp">{gmt=> (v=>
				v && 
				v.substr(11,5)
				)(toLocalTime(gmt))
			}</Table.Col>
			<Table.ModelCol label="Текст обращения" name="message"/>
			<Table.Col label="Закрыто" name="response_stamp"
				ifDistinct={[row=>row.response_stamp,""]}
			>{gmt=> (v=>
				v ?
					ru_date_text(v.substr(0,10))
					+' '
					+v.substr(11,5)
				: 'в работе'
				)(toLocalTime(gmt))
			}</Table.Col>
			<Table.ModelCol name="response" 
				ifDistinct={[row=>row.response_stamp,""]}
			/>
			<Table.Col colKey="@ops" label="Действия">
				{
					(_, row) =>
					!row.response_stamp &&
						<FormikConsumer>{fc=>
						<PopupMenu
							trigger={
								<button type="button">
									ответ
								</button>
							}
							bindX="left"
							onHide={async (res)=>{
								if(res!==undefined) {
									let text = await promptBig("ответ")
									await orginfo.DbX.save_form('docs_trace'
										, null
										, {
											status_seq: row.status_seq
											, response: text
											, response_object:
												JSON.stringify({
													result: res
												})
										})
									fc.setFieldValue('response_stamp', 
										localISOnow())
								}
							}}
						>
							<ModalButton type="button" disabled={!orginfo.registry}
								className="block"
								value={true}
							>Да</ModalButton>
							<ModalButton type="button" disabled={!orginfo.registry}
								className="block"
								value={false}
							>Нет</ModalButton>
						</PopupMenu>
						}</FormikConsumer>
				}
			</Table.Col>
			<Table.Empty>Нет обращений</Table.Empty>
		</Table>
		</div>
	</>
}


export async function showDocFeedbacks(orginfo, docid, header) {
	//console.log(Stud)
	await showModal(
		<FeedbackList orginfo={orginfo} docid={docid} header={header}/>
		, {closeBox:true}
		);
}