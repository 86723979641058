import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from 'formik';

import {mainDbX} from 'azlib/components/db'
import {shardedDbX} from 'azlib/components/db'

import {PopupLog} from 'contingent/log'

import {defer, letIn} from 'azlib/components/helpers'

import {Link} from 'react-router-dom'

import { SearchFilter } from 'azlib/components/search_filter'

import { calculateShard } from './create_org'
import {combine_arrays, object_struct_diff} from "../azlib/components/diff_helpers";
import {caption_format} from "../config/docform_structure";

export function SysOpOpenOrg() {
	let navigate = useNavigate()
  let [lst, setLst] = useState([]);
  let [del, setDel] = useState([]);
  let [searched, setSearched] = useState(false);
  let [noResults, setNoResults] = useState(false);
	return <>
	  <h1>Просмотр организации</h1>
      <Formik 
        initialValues={{
        	gov_regnum:''
    	}}
      >{bag=>
		  <Form className="viewOrg">
            <p></p>
            {window.localStorage['spectrated-org']
             && letIn(JSON.parse(window.localStorage['spectrated-org']))(e=>
              <div
               className="OrgSelectItem"
               path={e.path}
               level={e.path.replace(/^\D+\d/,'').length/3}
               style={{
                  paddingLeft: 1+(e.path.replace(/^\D+\d/,'').length/3*2)+"em"
                  , background: "rgb(255 187 245)"
               }}
              >
              Последняя просмотренная:<br/>
              <Link to={`/org/${e.tid}`}>{e.sname || e.orgtree_full_name ||'---???---'}</Link>
              </div>
            )}
            <SearchFilter
              input={
                <Field name="gov_regnum" placeholder={"Найти организацию"} 
                  className="FilterInput"/>
              }
              buttonProps={{
                  onClick: async (values) =>{
                      //lookup in main db (consolidated table)

                      let tid = await (await mainDbX.fetch_get(
                            '/app/sysop/org_shard'
                            , {gov_regnum:bag.values.gov_regnum.trim()}
                      )).text()
                      let DbX =
                        +tid % 10 === 0? mainDbX
                        : shardedDbX(await calculateShard(bag.values.gov_regnum.trim()))

                      let oi = await DbX.fetch_get_as_json('/app/sysop/open_org?'
                        +'gov_regnum='+bag.values.gov_regnum.trim()
                      )
                      setLst(oi?.actual ?
                        oi.actual : [])
                      setDel(oi?.deleted ?
                        oi.deleted : [])
					  setNoResults((oi?.actual.length || oi?.deleted.length) === 0);
					  setSearched(bag.values.gov_regnum.trim() !== '');
                    }
              }}
            />

            <p className="error" ><ErrorMessage name="gov_regnum"/></p>
            <p></p>
			  {searched &&
				  <div style={{margin: "2em auto 1em", width: "50vw"}}>
            {
				(!noResults) ?
					<>
					{
				lst.map(e=><button className="OrgSelectItem" style={{width:"100%", marginBottom: "1em", justifyContent:"left",
                    paddingLeft: 1+(e.path.replace(/^\D+\d/,'').length/3*2)+"em"
                }}
                key={e.tid}
                onClick={()=>{
					          window.localStorage['spectrated-org']=JSON.stringify(e);
                    navigate(`/org/${e.tid}/orginfo`)
                }}
                >
                <span>{e.gov_regnum}</span>
                :
                <span style={{marginLeft:"1em"}}>{e.name}</span>
              </button>)
            }
			<div className="backgroundRoundedDark templatesSearch">
			{del.length?<h2>Удалённые организации:</h2>:null}
            {
              del.map(e=><div style={{marginTop:"1em", display:"flex"}}
                key={e.tid}
                >
                  <span>{e.gov_regnum}</span>
                  :
                  <span style={{marginLeft:"1em"}}>{e.name}</span>
                  <PopupLog table="orgtree" tkey={e.tid}
                            transform={diff_transform}
                            transformContext={e}
                            deffered={true}
                            tid={e.tid}
                  />
              </div>)
            }
			</div>

			</>
				:
			<div className="backgroundRoundedDark templatesSearch">Организация не найдена</div>
			}
				  </div>
			}
          </Form>
      }</Formik>
	</>
}
const diff_transform = {
    tid: null
    , path: null
    , global_level: null
    , toplevel: null
    , docid: null
    , student: null
    , status_stamp: null
    , status_prev: null
    , doctype_infoset: null
    , signer: null
    , signed: null
    , form: null
    , fields_source: null
    , row_locker: null
    , row_lock_seat: null
    , row_lock_stamp: null
    , person: null
    , student_scope: null
    , doc_scope: null
    , student_scope_new: null
    , ext_filled: null
    , auth: null
    , app_ed_level: e=>{
        let ret = []
        e = JSON.parse(e||'{}')
        for(const i in e) {
            ret.push(window.AppBundle.data.ed_levels.decode[i]??i)
        }
        return ret.map(x=><div>{x}</div>)
    }
    , signers: e=> !e? '':
        JSON.parse(e).map(s=>
            `${s.i[0]}: ${s.i[1]} ${s.i[2]} ${s.i[3]}`
        )
    , gcode: (e,orginfo) =>
        (new Map(
                orginfo?.orgtree_groups?
                    orginfo.orgtree_groups.map(e=>[+e.gcode,e])
                    : []
            )
        ).get(+e)?.group_name ?? e ? `[id: ${e}]` : ''
    , infoset_orginfo: (e) =>
        letIn(e && JSON.parse(e))(e=>e&& <div>
            <div>{e.name}</div>
            <div>{e.gov_regnum}</div>
            <div>{e.app_place}</div>
            <div>{e.app_boss_fio}</div>
            <div>{e.app_boss_position}</div>
        </div>)
    , "infoset.compare": (name,o,n) => {
        o = o? JSON.parse(o): []
        n = n? JSON.parse(n): []
        let comb = combine_arrays(o,n,(e=>e?.[0]+"\n"+(e?.[2]??0)))
        comb = comb
            .filter(([key,eo,en])=> key && key !== "\n" &&
                JSON.stringify(eo) !== JSON.stringify(en)
            )
        return comb
            .map( ([key,eo,en]) =>
                ({
                    name: '['+key.split("\n")[0]+']'
                    , o: eo ? stripNameIC(eo) : null
                    , n: en ? stripNameIC(en) : null
                })
            )
    }
    , pdf: e=>'<pdf file>'
    , scan: e=>'<image file>'
    , form_type: (e,ctx,first)=> e
    , "definition.compare":
        (name,o,n) => {
            //console.log(o,n)
            o = o? JSON.parse(o): []
            n = n? JSON.parse(n): []
            let comb = combine_arrays(o,n,(e=>e?.name))
            comb = comb
                .filter(([name,eo,en])=>
                    JSON.stringify(eo) !== JSON.stringify(en)
                )
            return comb
                .map( ([name,eo,en]) =>
                    ({
                        name: '['+name+']'
                        , o: eo ? stripNameDC(object_struct_diff(eo,en)||{}) : null
                        , n: en ? stripNameDC(object_struct_diff(en,eo)||{}) : null
                    })
                )
        }
    , doctype: (e,orginfo)=>
        (new Map(
                orginfo.orgtree_doctypes?.map(e=>[e.doctype,e]) ?? []
            )
        ).get(+e)?.typename ??
        e ? `[id: ${e}]` : ''
    , "doc_scope_new.compare": (name,o,n) => {
        o = o? JSON.parse(o): {}
        n = n? JSON.parse(n): {}
        let ao = []
        for(const i in o)
            if( ! (i in n) ) ao.push(i)
        let an = []
        for(const i in n)
            if( ! (i in o) ) an.push(i)
        ao = ao.map(e=>window.AppBundle.data.doc_scope_new.decode[e])
        an = an.map(e=>window.AppBundle.data.doc_scope_new.decode[e])
        return { name,
            o: ao.map(e=><div key={e}>{e}</div>),
            n: an.map(e=><div key={e}>{e}</div>)
        }
    }
}
function stripNameIC(e) {
    let v = e[1];
    console.log(v)
    if(typeof v === "string") return v;
    if(Array.isArray(v)) return v.join(" | ")
    if(v && v.columns && v.th)
        return `[${v.columns.join(' | ')}]`
    if(v && v.columns )
        return v.columns.join(' | ')
    //return v && JSON.parse(v);
}
function stripNameDC({name,format,view,page,columns,...e}){
    return <div>
        {view &&<div>x:{view.x} y:{view.y}</div>}
        {format &&<div>{caption_format(format,columns)}</div>}
        {page &&<div>стр {page}</div>}
    </div>
}