import React, {useContext, useState, useEffect} from 'react';
import {
  Outlet, Link
  , useNavigate
  , useOutletContext
  , useMatch
} from "react-router-dom"


import {useTypedParams} from 'azlib/components/router_helpers'
import {later} from 'azlib/components/helpers'
import {fullPersonName} from 'azlib/components/name_funcs'
import {Async, AsyncOnce} from 'azlib/components/async'
import {ModelDecode} from 'azlib/components/std-controls'

import {OrgInfoContext} from 'org/org'
import {showModalProcessing, alert} from 'azlib/components/modals'

import UserInfoContext from 'UserInfo'

import {
	Common_CheckForPlugIn
	, FillCertList_Async
	, FillCertInfo_Async
	, Common_SignCadesBES
} from './wrapper_ca'

import { DbTableEdit as Table } from 'azlib/components/super-table'

import Filter from 'azlib/components/list_filter'
import Sorter from 'azlib/components/list_sort'

import plugin_text from './cadesplugin_api.js.txt' 

import {RedGrayGreen} from 'azlib/components/red_gray_green';
import {ReactComponent as Next_arrow} from './Img/next_arrow.svg'
import {ReactComponent as Prev_arrow} from './Img/prev_arrow.svg'
import css from './sign.module.css'
import {сhangedSignScopesForSign, isDiff} from '../org/members.js'
import {parse_islod} from 'org/islod_parser'
import {generateEMCHD, cancelEMCHD, genDoverNumber, genFileName, parseSignersRoles} from './gen-emchd.js'

function Dot(v, alt) {
	return <RedGrayGreen value={v} alt={alt} />
}

//console.log(plugin_text);

let cacheCertList = null;

function isUserCert(c) {
	const uname = `${window.UserInfo.last_name} ${window.UserInfo.first_name} ${window.UserInfo.middle_name}`
								.toUpperCase().replace(/\s+/,' ').replace(/Ё/g, 'Е').trim()
	return c.CN === window.UserInfo.login
		|| c.CN.toUpperCase().replace(/\s+/,' ').replace(/Ё/g, 'Е').trim() === uname					
		|| `${c.SN??''} ${c.G??''}`.toUpperCase().replace(/\s+/,' ').replace(/Ё/g, 'Е').trim() === uname
}

function compareFIOWithEGRUL(dataEGRUL, OwnerInfo) {
	if (dataEGRUL.details.app_all_bosses) {
		const boss = JSON.parse(dataEGRUL.details.app_all_bosses)
		let f = false
		Object.entries(boss).map(function([pos, fio]) {
			fio = fio.toUpperCase().replace(/\s+/,' ').replace(/Ё/g, 'Е').trim()
			if(fio === OwnerInfo.CN.toUpperCase().replace(/\s+/,' ').replace(/Ё/g, 'Е').trim()
			|| fio === `${OwnerInfo.SN??''} ${OwnerInfo.G??''}`.toUpperCase().replace(/\s+/,' ').replace(/Ё/g, 'Е').trim())
				f = true
		})
		return f
	}
	else if (dataEGRUL.details.app_boss_fio) {
		const boss_fio = dataEGRUL.details.app_boss_fio.toUpperCase().replace(/\s+/,' ').replace(/Ё/g, 'Е').trim()
		return boss_fio === OwnerInfo.CN.toUpperCase().replace(/\s+/,' ').replace(/Ё/g, 'Е').trim()
			|| boss_fio === `${OwnerInfo.SN??''} ${OwnerInfo.G??''}`.toUpperCase().replace(/\s+/,' ').replace(/Ё/g, 'Е').trim()
	}
}

function compareOGRNWithEGRUL(dataEGRUL, OwnerInfo) {
	return dataEGRUL.details.gov_regnum === OwnerInfo.OGRN
		|| dataEGRUL.details.gov_regnum === OwnerInfo.O
		|| dataEGRUL.details.gov_regnum === OwnerInfo.ОГРН
		|| dataEGRUL.details.gov_regnum === OwnerInfo.OGRNCODE
}

export function SignPage(props) {
	const orginfo = useContext(OrgInfoContext)
	let [loadedExt, setLoadedExt] = useState(null)
	let [loadedPlug, setLoadedPlug] = useState(null)
	let [loadedPlugText, setLoadedPlugText] = 
		useState("Плагин: ожидание загрузки расширения")
	let [PlugInVersionTxt, setPlugInVersionTxt] = useState('?')
	let [CSPVersionTxt, setCSPVersionTxt] = useState('?')
	let [CspEnabledTxt, setCspEnabledTxt] = useState('Криптопровайдер: ожидание загрузки плагина')
	let [CSPNameTxt, setCSPNameTxt] = useState('')
	let [CspEnabledImg,setCspEnabledImg] = useState(null)
	let [CertList, setCertList] = useState()

	useEffect(()=>{
    		console.log('init sign');
			(!cacheCertList?
				(loadedPlug?Promise.resolve(loadedPlug)
				:
					(loadedExt?Promise.resolve(loadedExt)
					: //orginfo.DbX.fetch_get('/cadesplugin_api.js')
					//.then(e=>e.text())
					//.then(plugin=>eval(plugin))
					Promise.resolve(eval(plugin_text))
					)
					.then(()=>{
						setLoadedExt(true)
						setLoadedPlugText("Плагин не загружен")

						return window.cadesplugin
						.then(()=> Common_CheckForPlugIn(
									        {   setLoadedPlug
									            , setLoadedPlugText
									            , setPlugInVersionTxt
									            , setCSPVersionTxt
									            , setCspEnabledTxt
									            , setCSPNameTxt
									            , setCspEnabledImg
									        }
		                            	)
						)
					})
				)
                .catch(error=> {
                            if (loadedExt) {
                            	setLoadedPlug(false)
                            	setLoadedPlugText(error)
                            }
                })
				.then(FillCertList_Async)
				.then(certs=>{
					let ret = new Map()
					for(const [k,c] of certs.entries())
						if(c.isValid && isUserCert(c)){
							ret.set(k,c)
							c.active = true;
							console.log()
						}
						else if(!c.isValid) {
							c.reason = "Недействительный сертификат";
							c.active = false;
							ret.set(k,c)
						}
						else if(!isUserCert(c)) {
							c.reason = "Не совпадает имя пользователя с именем владельца сертификата";
							c.active = false;
							ret.set(k,c)
						}
					return ret
				})
			: Promise.resolve(cacheCertList)
			)
			.then(CertList=>cacheCertList=CertList)
			.then(setCertList)
	},[]);

	return CertList?
				<Outlet context={
					{
						loadedExt
						, loadedPlugText
						, loadedPlug
						, CspEnabledTxt
						, CspEnabledImg
						, PlugInVersionTxt
						, CSPVersionTxt
						, CSPNameTxt
						, CertList
					}
				}/>
			: <div className="loadingWrapper"><div className="loading"></div></div>
}

export function SignSelectCert(props) {
	const orginfo = useContext(OrgInfoContext);
	let c = useOutletContext();
	let navigate = useNavigate();
	let {
		loadedExt
		, loadedPlugText
		, loadedPlug
		, CspEnabledTxt
		, CspEnabledImg
		, PlugInVersionTxt
		, CSPVersionTxt
		, CSPNameTxt
		, CertList
	} = c;
	let [Cert, setCert] = useState()
	return <>
		{props.roles?<h1>Выбор сертификата для подтверждения ролей</h1>:<h1>Выбор сертификата для подписи документов</h1>}
		<div className="flexContainer" style={{margin:"1em 2em"}}>
			<div>
        {CertList?
	    	CertList.size === 0?
	    		<h2>Нет сертификатов</h2>
	    	:
	    	<div>
	            <h2>Выберите cертификат:</h2>
				<br/>
	            {Array.from(CertList)
	            .map(([tp,{text,cert,active,reason}])=>
	            	<button className={css.certButton} type="button" disabled={!active}
	            		key={tp}
	            		onClick={async ()=>{
	            			let info = await showModalProcessing(
	            				async () => {
			            			await later(1000)
	            					let r = await FillCertInfo_Async(cert)
	            					return r;
	            				}
	            				, props=><div className="loadingWrapper"><div className="loading"></div></div>
	            				)
	            			console.log('CI', info)
			            	setCert({tp, cert, info})
	            		}}
					><div>{text}<br/><span>{reason}</span></div>
	            	</button>
	            )
	            }
	    	</div>
	    :null}
	    {
	    	Cert?<>
	            	<div style={{marginTop:"2em"}}
	            		onClick={async()=>{
							if(props.roles) {
								let parentData = await orginfo.DbX.call_function('get_parent_data'
									, {ppath: orginfo.path})//gov_regnum,fin_regnum,fin_regnum2,app_boss_position,name
								let pd = parentData.split(';')
								let dataEGRUL = await parse_islod(pd[0])

								console.log('Информация о сертификате:', c.CertList.get(Cert.tp))
								
								if (!compareFIOWithEGRUL(dataEGRUL, c.CertList.get(Cert.tp)))
									alert("Подтверждение ролей доступно только руководителю организации!")
								else if (!compareOGRNWithEGRUL(dataEGRUL, c.CertList.get(Cert.tp)))
									alert("Сертификат должен содержать ОГРН организации, ОГРН должен совпадать с ОГРН текущей организации!")
								else
									navigate(`/org/${orginfo.tid}/signRoles/${Cert.tp}`)
							}
							else {
								if(orginfo.docs.signer)
								navigate(`/org/${orginfo.tid}/sign/${Cert.tp}`)
							else
								alert("Недостаточно прав!")}
	            		}}
	            	>
	                <div className={css.certInfo}>
	                    <h2>Информация о сертификате:</h2>
	                    <p className="info_field">{Cert.info.subject}</p>
	                    <p className="info_field">{Cert.info.issuer}</p>
	                    <p className="info_field">{Cert.info.from}</p>
	                    <p className="info_field">{Cert.info.till}</p>
	                    <p className="info_field">{Cert.info.provname}</p>
	                    <p className="info_field">{Cert.info.privateKeyLink}</p>
	                    <p className="info_field">{Cert.info.algorithm}</p>
	                    <p className="info_field">{Cert.info.status}</p>
	                    <p className="info_field">{Cert.info.location}</p>
						<h2>-- ИСПОЛЬЗОВАТЬ ЭТОТ СЕРТИФИКАТ --</h2>
	                </div>
	            	</div>
	            </>
	    :null
	    }
			</div>
			<div className="backgroundRounded" style={{textAlign:"left", marginLeft:"auto", padding:"1em 2em", color:"#3C5773", height:"max-content", lineHeight:"1.8em"}}>
                <span id="ExtensionEnabledTxt">
                	{ loadedExt?
						"Расширение загружено "
						:
						"Расширение не загружено "
					}
                </span>
				{Dot(loadedExt, "Расширение")}
				<br/>
				<span id="PlugInEnabledTxt">{loadedPlugText} </span>
				{Dot(loadedPlug, "Плагин")}
				<br/>
				<span>{CspEnabledTxt} </span>
				{Dot(CspEnabledImg, "Провайдер")}
				<br/>
				<span>{PlugInVersionTxt} </span>
				<br/>
				<span>{CSPVersionTxt} </span>
				<br/>
				<span>{CSPNameTxt} </span>
				<div>
					Проверить, правильно ли установлен плагин для цифровой подписи, сертификаты и т.п.
					<br/> можно на сайте{" "}
					<a href="https://www.cryptopro.ru/sites/default/files/products/cades/demopage/cades_bes_sample.html"
					   target="_blank" rel="noreferrer"
					   style={{textDecoration:"underline", color:"#23396A"}}
					>Критопро: Проверка создания электронной подписи</a>
				</div>
			</div>
		</div>
	</>
}

//storeFilter={row=>	+row.signer === +person}
export function SignSignDocs(props) {
	const uinfo = useContext(UserInfoContext);
	const person = uinfo.personid;
	const match = useMatch({path:"/org/:d/sign/:tp", end:true})
	const orginfo = useContext(OrgInfoContext);
	let tp = useTypedParams().cert;
	let c = useOutletContext()	
	let Cert = c.CertList.get(tp)
	return <Async value={
		FillCertInfo_Async(Cert.cert)
	}>{info=><>
		<h1>Подписание ({info.subject})</h1>
		<div>
		{match?
		<Table className="simpleTable"
			store={orginfo.DbX.store('doc_signer_persons', 
				{ tid: orginfo.tid, status: 'doc-sign', signer:person} )}
			extra="signer cito"
			defaultSorter={(a,b)=>
						a.cito !== b.cito? 
							(b.cito||0) - (a.cito||0)
						:	Sorter.cmp(
								   `${a.last_name} ${a.first_name} ${a.middle_name}`
								,  `${b.last_name} ${b.first_name} ${b.middle_name}`
								)
				}
			>
			<Table.Before>
				<button className="checkBoxBut" style={{marginLeft:"2em"}}>Срочные:<Filter.Check
					condition={v => v? row => row.cito : row=>true}
					saveAs="cito"
				/></button>
			</Table.Before>

			<Table.Col label="Фамилия Имя Отчество" name="docid"
				extra="last_name first_name middle_name"
			>
				{(value,row) =>
					<Link to={`/org/${orginfo.tid}/sign/${tp}/${value}`} >
						{fullPersonName(row)}
					</Link>
				}
			</Table.Col>
			<Table.Col label="Год выпуска" name="year_completion">
				{v=>v}
			</Table.Col>
			<Table.Col label="Специальность" name="spec_code"
					   ifDistinct={[row=>row.spec_code,'']}
			>{(v,r)=>
				v
			}</Table.Col>
			<Table.Col label="Тип документа об образовании" name="doctype_kind">{
				(kind, row) =>
				<Link to={`/org/${orginfo.tid}/sign/${tp}/${row.docid}`} >
				<ModelDecode modelData="doc_kinds" value={kind} />
				</Link>
			}
			</Table.Col>

			<Table.Col label="Срочно" name="cito">
				{(value,row) => value ? '◷' : ''}
			</Table.Col>

			<Table.Col label="Комментарий" name="status_message">{msg=>msg}</Table.Col>

			<Table.Empty>Нет документов</Table.Empty>
		</Table>
		:null}
		<Outlet context={{ 
			Cert, tp
		}}/>			
		</div>
	</>}</Async>
}

let listCache = undefined

export function SignSignPage(props) {
	const uinfo = useContext(UserInfoContext);
	const person = uinfo.personid;
	const orginfo = useContext(OrgInfoContext);
	let params = useTypedParams();
	let Cert = useOutletContext().Cert;
	let tp = useOutletContext().tp;
	const navigate = useNavigate();
	const docid = params.Docid;

	let [list, setList] = useState(listCache)
	let current = docid

	if(list===undefined) {
		setList(null)
		orginfo.DbX.fetch_rds('doc_signer_persons', 'docid signer'
					, { tid: orginfo.tid, status: 'doc-sign', signer: person }
				)
		.then(rows=> setList(listCache=rows))
	}

	const inList = list?.findIndex(e=>e.docid===current)
	const nextDoc = list?.[inList+1]?.docid
	const prevDoc = list?.[inList-1]?.docid

	return <div key={docid} className={css.sign}
		>
		<AsyncOnce value={()=>orginfo.DbX.fetch_row('docs_preparing', 'signed', [current])}>
		{row=>{
			let text = row.signed
			return <div>
				<button type="button" className={css.exit}
						onClick={()=>{navigate(`/org/${orginfo.tid}/sign/${tp}`)}}
				>Назад</button>
				<div>
			<div className={css.container}>{sliceIntoChunks(text, 65).map(e=>
			<pre>{e}</pre>
			)}</div>

				<div style={{display:"flex", gap:"1em", justifyContent:"center", margin:"0 1em"}}>

					<button type="button" className={css.prev_arrow} onClick={()=>{
						navigate(`/org/${orginfo.tid}/sign/${tp}/${prevDoc}`)
					}}
							disabled={!prevDoc}
					><Prev_arrow alt="prev_arrow"/></button>
					<button type="button" className={css.signButton} onClick={async ()=>{
						let signature = await
							Common_SignCadesBES(Cert.cert, text)
						//console.log(signature)
						await orginfo.DbX.call_function('sign_doc',{
							text, signature, doc:current
						})

						setList(listCache=
							list.filter(e=>e.docid!==current)
						)
						if (!nextDoc && !prevDoc) {
							var doc = window.document;
							var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
							if(doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement || doc.msFullscreenElement) {
								cancelFullScreen.call(doc);
							}
						}
						navigate(
							nextDoc? `/org/${orginfo.tid}/sign/${tp}/${nextDoc}`
								: prevDoc? `/org/${orginfo.tid}/sign/${tp}/${prevDoc}`
									: `/org/${orginfo.tid}/sign/${tp}`)
					}}
							style={{
								display:"block"
								, width: "18em"
								, padding: "1em"
								, flexShrink: 0
								, flexGrow: 0
								, background: "#3C5773"
							}}
					>
						ПОДПИСАТЬ
					</button>
					<button type="button" className={css.next_arrow} onClick={()=>{
						navigate(`/org/${orginfo.tid}/sign/${tp}/${nextDoc}`)
					}}
							disabled={!nextDoc}
					><Next_arrow alt="next_arrow"/></button>

				</div>
				</div>
				<button type="button" className={css.full}
						onClick={e=>{
							var doc = window.document;
							var docEl = doc.documentElement;
							var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
							var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
							if(typeof window.ActiveXObject!="undefined") {
								// for Internet Explorer
								var wscript = new window.ActiveXObject("WScript.Shell");
								if (wscript != null) {
									wscript.SendKeys("{F11}");
								}
							}
							else if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
								requestFullScreen.call(docEl);
							}
							else {
								cancelFullScreen.call(doc);
							}
						}}
				></button>
			</div>

		}}
		</AsyncOnce>

	</div>
}
function sliceIntoChunks(arr, chunkSize) {
	const rows = arr.split(/\r\n|\r|\n/g);
	const res = [];
	for (let i = 0; i < rows.length; i += chunkSize) {
		const chunk = rows.slice(i, i + chunkSize).join("\n");
		if (chunk) res.push(chunk);
	}
	return res;
}

export function ConfirmRolesPage(props) {
	const uinfo = useContext(UserInfoContext);
	const person = uinfo.personid;
	const orginfo = useContext(OrgInfoContext);
	const tid = orginfo.tid;
	let tp = useTypedParams().cert;
	let c = useOutletContext()	
	let Cert = c.CertList.get(tp)

	return <>
	<h1>Подписание: Подтверждение ролей</h1>
	<Table className="simpleTable"
			store={orginfo.DbX.store("persons2tree", { tid }) }
			extra="dover_num"
			storeFilter={row=>
					isDiff(row.sign_scope, row.sign_scope_changed)
				}>
		<Table.Col label="Фамилия Имя Отчество" colKey="pname" extra="person{login last_name first_name middle_name}"
			>{(v,row)=> fullPersonName(row.person$, true)}
		</Table.Col>

		<Table.Col label="Срок действия">
			1 год
		</Table.Col>

		<Table.Col label="Организация">
			{orginfo.name}
		</Table.Col>

		<Table.Col label="ОГРН">
			{orginfo.gov_regnum}
		</Table.Col>

		<Table.ModelCol name="position"/>

		<Table.Col label="Измененные роли" colKey="sign" extra="sign_scope sign_scope_changed">
			{(v,row)=>{
				return сhangedSignScopesForSign(row.sign_scope, row.sign_scope_changed, orginfo)
			}}
		</Table.Col>

		<Table.Col label="Подписать" colKey="signBut">
			{(v,row) =>
				<div className='intableOp'>
					<button type="button"
						onClick={async()=>{
							if (Object.keys(row.sign_scope_changed).length > 0) {
								let parentData = await orginfo.DbX.call_function('get_parent_data'
									, {ppath: orginfo.path})//gov_regnum,fin_regnum,fin_regnum2,app_boss_position,name,app_all_bosses
								let pd = parentData.split(';')
								let doverNum = await orginfo.DbX.call_function('gen_dover_num'
									, {p_person: row.person, p_tid: row.tid})
								let xml = generateEMCHD(
									doverNum
									, pd
									, uinfo
									, row.person$
									, row.position
									, row.sign_scope_changed
									, orginfo
								)
								if (!xml) return;
								else {
									let signature = await Common_SignCadesBES(Cert.cert, xml)

									let done = await orginfo.DbX.call_function('sign_signers_roles',{
										p_dover_num: doverNum, p_person: row.person, p_tid: row.tid,
										p_emchd: xml, p_signature: signature, active: 1
									})
									if (done)
										window.location.reload()
									else {
										alert('Недостаточно прав!')
										return;
									}
								}
							}
							else {
								let xml_revoked = cancelEMCHD(row.dover_num)
								let signature = await Common_SignCadesBES(Cert.cert, xml_revoked)
								if(!row.dover_num) {
									alert('Ошибка отмены ролей, ранее не сформирована МЧД!')
									return;
								}

								let done = await orginfo.DbX.call_function('sign_signers_roles',{
									p_dover_num: row.dover_num, p_person: row.person, p_tid: row.tid,
									p_emchd: xml_revoked, p_signature: signature, active: 0
								})
								if (done)
									window.location.reload()
								else {
									alert('Недостаточно прав!')
									return;
								}
							}
						}}
					>Подписать</button>
				</div>
			}
		</Table.Col>
		<Table.Empty>Нет документов</Table.Empty>
	</Table>
	</>
}