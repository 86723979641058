import React, {useEffect, useContext} from 'react';
import ReactDOM from 'react-dom';

const Ctx = React.createContext({})

export function GlobalPageAreas({title, menu, settings, children}) {
	return <Ctx.Provider value={{
				title: title
				, menu: menu
		, settings: settings
			}}>{children}</Ctx.Provider>
}

export function PageTitle({title, doctitle, sub}) {
	let c = useContext(Ctx)
	useEffect(()=>{
		document.title = `ЕР ЦДО ${doctitle??''}`;
	})
	//console.log(c.title)
	return c.title && ReactDOM.createPortal(
		title??''
	, c.title
	);
}

export function PageMenu(props) {
	let c = useContext(Ctx)
	return c.menu && ReactDOM.createPortal(
		props.children
	, c.menu
	);
}
export function PageSettings({settings}) {
	let c = useContext(Ctx)
	return c.settings && ReactDOM.createPortal(
		settings??''
		, c.settings
	);
}