import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "./fonts/vfs_fonts"
import { PDFDocument, degrees } from 'pdf-lib'

import {letIn,XLOG,toLocalTime} from 'azlib/components/helpers'
import {alert} from 'azlib/components/modals'
import {sliceIterator} from 'azlib/components/helpers'
import {titledName, titledName2, fullPersonName} from 'azlib/components/name_funcs'
import {ru_date_to_iso,ru_date,ru_date_text} from 'azlib/components/locales/ru/ru_date'

import {parseInfosetValue} from 'docs/infoset_edit'

import {decode_signature_cert} from 'sign/cert'
import {appendDoc, pdfBudder} from "./pdf-gen";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
	DejaVuSans: {
		normal: 'DejaVuSans.ttf',
		/*		italics: 'DejaVuSans-Oblique.ttf',
                bold: 'DejaVuSans-Bold.ttf',
                bolditalics: 'DejaVuSans-BoldOblique.ttf',*/
	}
	, DejaVuSansMono: {
		normal: 'DejaVuSansMono.ttf',
		/*		italics: 'DejaVuSansMono-Oblique.ttf',
		bold: 'DejaVuSansMono-Bold.ttf',
		bolditalics: 'DejaVuSansMono-BoldOblique.ttf',*/
	}
	, DejaVuSerif: {
		normal: 'DejaVuSerif.ttf',
		italics: 'DejaVuSerif-Italic.ttf',
		bold: 'DejaVuSerif-Bold.ttf',
		bolditalics: 'DejaVuSerif-BoldItalic.ttf',
	}
};


function makeQR(format, idx) { //toplevel ONLY
	let ret = []
	format
		.replace(/\[.+?\]|\S+/g,
			(n)=>{
				if(n.match(/^\[\.+\]$/))
				{
					ret.push(' '.repeat(n.length-2))
					return ''
				}
				if(n.match(/^\[.+\]$/)) {
					let par = n.slice(1,-1).replace(/_/g,' ')
					if(idx.has(par)) {
						ret.push(
						 idx.get(par).element.values[0]
						)
						return ''
					}
				}
				ret.push(n)
				return ''
			})
	return ret.join('')
}

const mm_to_pt = 2.8346456693;

function getSignature(signers, code) {
	let ret = null;
	if(!code) {
		ret = signers[signers.length-1]
	} else
	if(code.match(/^\s*(\d+)\s*$/))
		ret = signers[+RegExp.$1-1]
	else
		ret = signers.find(s=>s.r.toUpperCase() === code.trim().toUpperCase())
	if(!ret)
		ret = {i:['','','','',''], r:""}
	return ret
}

function getSignatureFio(signers, code) {
	let r = getSignature(signers, code);
	return {
		last_name: r.i[1]
		, first_name: r.i[2]
		, middle_name: r.i[3]
	}
}

async function createPDFDocumentBrochure(buffer) {
	let src = await PDFDocument.load(buffer);
	let dest = await PDFDocument.create();

	src.getAuthor() && dest.getAuthor(src.getAuthor())
	src.getCreationDate() && dest.setCreationDate(src.getCreationDate())
	src.getCreator() && dest.setCreator(src.getCreator())
	src.getKeywords() && dest.setKeywords(src.getKeywords())
	//dest.setLanguage(src.getLanguage())
	src.getModificationDate() && dest.setModificationDate(src.getModificationDate())
	src.getProducer() && dest.setProducer(src.getProducer())
	src.getSubject() && dest.setSubject(src.getSubject())
	src.getTitle() && dest.setTitle(src.getTitle())

	const pages = await dest.embedPages(src.getPages(src.getPageIndices()));
	while (pages.length % 4) {
		pages.push(null);
	}

	let i = 0;
	let j = pages.length - 1;
	while (i < j) {
		const sp1 = pages[i++];
		const sp2 = pages[i++];
		const sp4 = pages[j--];
		const sp3 = pages[j--];

		const sw = Math.max(sp1?.width ?? 0, sp2?.width ?? 0, sp3?.width ?? 0, sp4?.width ?? 0);
		const sh = Math.max(sp1?.height ?? 0, sp2?.height ?? 0, sp3?.height ?? 0, sp4?.height ?? 0);

		const p1 = await dest.addPage([sw * 2, sh]);
		const p2 = await dest.addPage([sw * 2, sh]);

		sp4 && await p1.drawPage(sp4, { x: 0, y: 0 });
		sp1 && await p1.drawPage(sp1, { x: sw, y: 0 });
		sp2 && await p2.drawPage(sp2, { x: 0, y: 0 });
		sp3 && await p2.drawPage(sp3, { x: sw, y: 0 });
	}

	return dest.save();
}

export async function printPdf(form, docid, orginfo, allPrint = false) {
	let data = await orginfo.DbX.fetch_row('docs',
	'doctype_kind our_number our_date'
	 +' last_name first_name middle_name'
	 +' infoset serverside_internal infoset_orginfo'
	 +' signers signatures url'
	 , [docid])

	data.serverside_internal = JSON.parse(data.serverside_internal)
	//console.log('is',data.infoset)
	let infoset_data = parseInfosetValue(data.doctype_kind, null, data.infoset)

	let signers = JSON.parse(data.signers||'[]')
    let signatures = JSON.parse(data.signatures||'{}')

	//console.log(data.serverside_internal, infoset_data);

	form = await orginfo.DbX.fetch_row('doc_forms'
		, "definition paper_width paper_height brochure margin_left margin_top margin_right margin_bottom font_size line_height"
		, [form])
	if (!form.definition) alert("Не задан состав полей в используемом бланке документа")
	form.definition = JSON.parse(form.definition)
	form.definition.forEach(field=>{
		if (!field.page)
		{
			alert("Измените свойства состава полей в используемом бланке документа")
			throw 'error';
		}
	})
	const our_number = data.our_number

	let idx = new Map()
	//console.log(infoset_data)
	for(let i=0; i < infoset_data.length; ++i) {
		let e = infoset_data[i]
		if(!e.level) {
			//toplevel element (only!)
			let children = 
			Array.from(
				sliceIterator(infoset_data,i+1,null
				, c=>c.level === 0? undefined: true
				)
			)
			idx.set(e.name,{element:e,children}) 
		}
	}
	for(const si of data.serverside_internal) {
		idx.set(si.k,{element:{values:[si.v], children:[]}})
	}
	idx.set('Фамилия Имя Отчеcтво', {element: {values:
											[
												fullPersonName(data)
											]
										}
									, children:[]})
	idx.set('Фамилия И.О.', {element: {values:
											[
												titledName(data)
											]
										}
									, children:[]})
	idx.set('И.О. Фамилия', {element: {values:
											[
												titledName2(data)
											]
										}
									, children:[]})

	idx.set('Реестровый номер', {element: {values:
											[
												data.our_number
											]
										}
									, children:[]})

	idx.set('Реестровая дата', {element: {values:
											[
												data.our_date?
													ru_date(data.our_date)
												: ''
											]
										}
									, children:[]})
	idx.set('Дата выписки', {element: {values:
											[
												ru_date(toLocalTime(new Date()).substr(0,10))
											]
										}
									, children:[]})

	idx.set('Наименование организации', {element: {values:
											[
												data.infoset_orginfo ?
													JSON.parse(data.infoset_orginfo).name
												: ''
											]
										}
									, children:[]})

	idx.set('URL', {element: {values:
											[
												data.url
											]
										}
									, children:[]})

	console.log(form.definition, idx, infoset_data);

	const margins = [ 0, 0, 0, 0]

	let content = []
	let cols = [10]
	let col_headers = null
	for(const f of form.definition) {
		switch(f.name) {
		case '\f': 
			//next page
			content.push({text:' ', pageBreak: 'before'})
			break;
		case 'QR':
			//next page
			content.push({qr: 
				//"\\000026" + // ECI magic
					makeQR(f.format?.caption??'-', idx)
				, eccLevel:"M"
				, mode: 'octet'
				, absolutePosition: {
					x: (f.view?.x??0) * mm_to_pt + margins[0]
					, y: (f.view?.y??0) * mm_to_pt + margins[1]
				}
				, fit: (f.view?.w??30) * mm_to_pt
			})	
			break;
		case '<Текст>':
			content.push({
				columns: [{
					width: (f.view?.w??30) * mm_to_pt
					, text: f.format?.caption??''
				}]
				, absolutePosition: {
					x: (f.view?.x??0) * mm_to_pt + margins[0]
					, y: (f.view?.y??0) * mm_to_pt + margins[1]
				}
				, width: (f.view?.w??30) * mm_to_pt
				, italics: f.view?.fStyle? true : false
				, bold: f.view?.fWeight? true : false
				, fontSize: f.view?.fSize??content.defaultStyle
				, alignment: f.view?.tAlign??content.defaultStyle
			})	
			break;
		case '<Подпись: Фамилия Имя Отчеcтво>':
			content.push({
				columns: [{
					width: (f.view?.w??30) * mm_to_pt
					, text: fullPersonName(getSignatureFio(signers,f.format?.caption))
				}]
				, absolutePosition: {
					x: (f.view?.x??0) * mm_to_pt + margins[0]
					, y: (f.view?.y??0) * mm_to_pt + margins[1]
				}
				, width: (f.view?.w??30) * mm_to_pt
				, italics: f.view?.fStyle? true : false
				, bold: f.view?.fWeight? true : false
				, fontSize: f.view?.fSize??content.defaultStyle
				, alignment: f.view?.tAlign??content.defaultStyle
			})	
			break;
		case '<Подпись: Фамилия И.О.>':
			content.push({text: titledName(getSignatureFio(signers,f.format?.caption))
				, absolutePosition: {
					x: (f.view?.x??0) * mm_to_pt + margins[0]
					, y: (f.view?.y??0) * mm_to_pt + margins[1]
				}
				, width: (f.view?.w??30) * mm_to_pt
				, italics: f.view?.fStyle? true : false
				, bold: f.view?.fWeight? true : false
				, fontSize: f.view?.fSize??content.defaultStyle
				, alignment: f.view?.tAlign??content.defaultStyle
			})
			break;
		case '<Подпись: И.О. Фамилия>':
			content.push({text: titledName2(getSignatureFio(signers,f.format?.caption))
				, absolutePosition: {
					x: (f.view?.x??0) * mm_to_pt + margins[0]
					, y: (f.view?.y??0) * mm_to_pt + margins[1]
				}
				, width: (f.view?.w??30) * mm_to_pt
				, italics: f.view?.fStyle? true : false
				, bold: f.view?.fWeight? true : false
				, fontSize: f.view?.fSize??content.defaultStyle
				, alignment: f.view?.tAlign??content.defaultStyle
			})	
			break;
		case '<Подпись: Должность>':
			content.push({
				columns: [{
					width: (f.view?.w??30) * mm_to_pt
					, text: getSignature(signers,f.format?.caption).i[0]
				}]
				, absolutePosition: {
					x: (f.view?.x??0) * mm_to_pt + margins[0]
					, y: (f.view?.y??0) * mm_to_pt + margins[1]
				}
				, width: (f.view?.w??30) * mm_to_pt
				, italics: f.view?.fStyle? true : false
				, bold: f.view?.fWeight? true : false
				, fontSize: f.view?.fSize??content.defaultStyle
				, alignment: f.view?.tAlign??content.defaultStyle
			})	
			break;
		case '<Страница>':
			// only in header/footer (per page)
			break;
		case '<ЭЦП>':
			const id = getSignature(signers, f.format?.caption).id
			const cert = decode_signature_cert(signatures[id])
			if(cert)
			  content.push(
				{
					stack: [
					{ canvas:[ {type: 'rect'
						, x: 0 
						, y: 0
						, w: 100 * mm_to_pt
						, h: 40 * mm_to_pt
						, r: 4 * mm_to_pt
						, lineColor: "#00f"  
					  }]
					}
					, {columns: [
						{
							width: 100 * mm_to_pt
							, stack: [
								{ text: "Документ подписан усиленной квалифицированной электронной подписью"
								, margin: [
											3 * mm_to_pt, 
											-(40) * mm_to_pt,
											3 * mm_to_pt,
											0
										]
								, alignment: "center"
								, lineHeight: 0.9
								, color: "#00f"
								, fontSize: 12
								}
								, {text: "Сертификат: "+ 
										cert.serialNumber.toUpperCase()
								, color: "#00f"
								, fontSize: 8
								, margin: [ 3 * mm_to_pt, 1 * mm_to_pt]
								, lineHeight: 0.9
								}
								, {text: "Владелец: "
										+ cert.CN
								, color: "#00f"
								, fontSize: 12
								, margin: [ 3 * mm_to_pt, 1 * mm_to_pt]
								, lineHeight: 0.9
								}
								, {text: "Действителен:"
										+" c "+ru_date(cert.notBefore.toISOString().substr(0,10))
										+" по "+ru_date(cert.notAfter.toISOString().substr(0,10))
								, color: "#00f"
								, fontSize: 10
								, margin: [ 3 * mm_to_pt, 1 * mm_to_pt]
								, lineHeight: 0.9
								}
							]
						}						
					] }
				] 
				, absolutePosition: {
					x: (f.view?.x??0) * mm_to_pt + margins[0]
					, y: (f.view?.y??0) * mm_to_pt + margins[1]
				}
				, width: (f.view?.w??30) * mm_to_pt
			})
			break;
		default:
			if(idx.has(f.name)) {
				let e = idx.get(f.name)
				if(e.element.paragraph) {
					//toplevel table start
					if(f.format?.widths) {
						cols = 
							f.format.widths
							.map(w=>w * mm_to_pt)
					} else {
						cols =
							[(f.view?.w??30) * mm_to_pt];
					}
					const theight = (f.format?.tableMarginTop||0) * mm_to_pt;
					let body = []
					if(f.columns?.length)
						col_headers = f.columns
					if(e.element.columns?.length)
						col_headers = e.element.columns
					if(col_headers)
							body.push(
								[...col_headers.slice(0,cols.length)]
								.map(c=>
									!f.format?.noHeaders? c
									: {text:' '
									//, height: (f.format?.tableMarginTop||0) * mm_to_pt
									, margin: [0,theight,0,0]
									, lineHeight: 0
									}
								)
							)
					for(const c of e.children) {
						if (c.values===null) continue;
						const colspan = cols.length - 
							(c.values?.length||0)
						//in pdfmake colSpan has a BUG -> it replace next cell
						// with {_span:true...} paddings
						// so, we can do it manually
						body.push([
							/*
								colspan > 1?
									{text:c.name, colSpan: colspan}
								:
									{text:c.name}
							*/
							//BUG WORKAROUND
								{text:c.name}
								, ...(  colspan>0?
										Array(colspan-1).fill(
										{_span:true, _minWidth: 0, _maxWidth: 0})
										:
										[]
									)
								, 
								 ...(colspan > 0 ? c.values :
										c.values.slice(0,cols.length-1)
									)
								])
					}
					content.push({stack:[
							f.format?.noTitle? '' : e.element.name
							,
							{table:{
								widths: cols
								, headerRows: col_headers ? 1 : 0
								, dontBreakRows: true
								, body
								}
							,layout:"noBorders"
							, margin: [0,-theight,0,0]
							}
						]
						, absolutePosition: {
							x: (f.view?.x??0) * mm_to_pt + margins[0]
							, y: (f.view?.y??0) * mm_to_pt + margins[1]
						}
						, width: (f.view?.w??30) * mm_to_pt
						, italics: f.view?.fStyle? true : false
						, bold: f.view?.fWeight? true : false
						, fontSize: f.view?.fSize??content.defaultStyle
						, alignment: f.view?.tAlign??content.defaultStyle
						})
				} else {
					let v = e.element.values[0] //one value
					if(f.format?.mode) {
						/* eslint-disable-next-line default-case */
						switch(f.format?.mode) {
						case 'full-ggg':
							v = ru_date_text(
									ru_date_to_iso(v)
								) + ' года'; break;
						case 'full-gg':
							v = ru_date_text(
									ru_date_to_iso(v)
								) + ' г.'; break;
						case 'full-g':
							v = ru_date_text(
									ru_date_to_iso(v)
								); break;
						case 'short-ggg':
							v = ru_date(
									ru_date_to_iso(v)
								) + ' года'; break;
						case 'short-gg':
							v = ru_date(
									ru_date_to_iso(v)
								) + ' г.'; break;
						}
					}
					content.push({
						columns: [{
							width: (f.view?.w??30) * mm_to_pt
							, text:v
						}]
						, absolutePosition: {
							x: (f.view?.x??0) * mm_to_pt + margins[0]
							, y: (f.view?.y??0) * mm_to_pt + margins[1]
						}
						, italics: f.view?.fStyle? true : false
						, bold: f.view?.fWeight? true : false
						, fontSize: f.view?.fSize??content.defaultStyle
						, alignment: f.view?.tAlign??content.defaultStyle
					})
				}
			}
		}
	}

	//console.log('c', content, form)

	const pm = pdfMake.createPdf({
		content: content
		,defaultStyle: {
	    	font: 'DejaVuSerif'
	    	, fontSize: form.font_size || 14
	    	, lineHeight: form.line_height || 1
			, alignment: 'left'
	  	}
	  	,styles: {
	  	}
	  	,pageSize: {
	  		width: (form.paper_width||210) * mm_to_pt
	  		,height: (form.paper_height||298) * mm_to_pt
	  	}
	  	,pageMargins: [
	  		form.margin_left * mm_to_pt
	  		, form.margin_top * mm_to_pt
	  		, form.margin_right * mm_to_pt
	  		, form.margin_bottom * mm_to_pt
	  		]
    	, info: {
			title: 'Документ', //TODO: kind here
			author: 'DigDoc',
  		}
  		//, pageBreakBefore: (node,followingNodesOnPage,nodesOnNextPage) => {
  		//}
  		, header: function(currentPage, pageCount, pageSize) {
    		// you can apply any logic and return any valid pdfmake element
			return (form.definition.filter(f=>f.name === "<Страница>")
    			.map(f=>
    				letIn({
    					x: (f.view?.x??0) * mm_to_pt
    					, y: (f.view?.y??0) * mm_to_pt
    					, w: (f.view?.w??30) * mm_to_pt
    				})
    				(p=>
    				({
    				text: !f.format?.caption? currentPage
    					: f.format.caption.replace(/%./g,(m=>{
							/* eslint-disable-next-line default-case */
    						switch(m) {
    						case '%%': return '%'
    						case '%P': return currentPage
    						case '%T': return pageCount
    						case '%R': return our_number || ''
    						}
    						return ''
    					}))
					, absolutePosition: {
						x: f.format?.mirror_pos
							&& (currentPage % 2 === 0)
						   ? pageSize.width - p.w - p.x
						   : p.x 
						, y: p.y
					}
					, width: p.w
					, italics: f.view?.fStyle? true : false
					, bold: f.view?.fWeight? true : false
					, fontSize: f.view?.fSize??content.defaultStyle
					, alignment:
						letIn(f.view.tAlign??content.defaultStyle)
						(align=>
							f.format?.mirror_align 
							&& (align === 'left' || align === 'right')
							&& (currentPage % 2 === 0)
							?
							  {left:'right',right:'left'}[align]
							: align
						)
    			}))))
  		}
  		, footer: function(currentPage, pageCount, pageSize) {
    		// you can apply any logic and return any valid pdfmake element
    		console.log(pageSize)
			return (form.definition.filter(f=>f.name === "<Страница>")
    			.map(f=>
    				letIn({
    					x: (f.view?.x??0) * mm_to_pt
						, y: (f.view?.y??0) * mm_to_pt
    					, w: (f.view?.w??30) * mm_to_pt
    				})
    				(p=>
    				({
    				text: !f.format?.caption? currentPage
    					: f.format.caption.replace(/%./g,(m=>{
							/* eslint-disable-next-line default-case */
    						switch(m) {
    						case '%%': return '%'
    						case '%P': return currentPage
    						case '%T': return pageCount
    						case '%R': return our_number || ''
    						}
    						return ''
    					}))
					, absolutePosition: {
						x: f.format?.mirror_pos
							&& (currentPage % 2 === 0)
						   ? pageSize.width - p.w - p.x
						   : p.x
						, y: p.y
								- pageSize.height
								+ form.margin_bottom * mm_to_pt
					}
					, width: (f.view?.w??30) * mm_to_pt
					, italics: f.view?.fStyle? true : false
					, bold: f.view?.fWeight? true : false
					, fontSize: f.view?.fSize??content.defaultStyle
					, alignment:
						letIn(f.view.tAlign??content.defaultStyle)
						(align=>
							f.format?.mirror_align 
							&& (align === 'left' || align === 'right')
							&& (currentPage % 2 === 0)
							?
							  {left:'right',right:'left'}[align]
							: align
						)
    			}))))
    	}
	})

	if(allPrint && form.brochure) {
		return new Promise((resolve, reject) => {
			pm.getBuffer(async buffer => {
				try {
					let saved = await createPDFDocumentBrochure(buffer)
					resolve(saved);
				} catch (error) {
					reject(error);
				}
			});
		});
	}
	else if (allPrint)
		return pm
	else if(form.brochure) {
		await pm.getBuffer(async buffer=>{
			//console.log(buffer);

			let saved = await createPDFDocumentBrochure(buffer)
			//console.log(saved)
			
			let win = window.open('', '_blank');
			let blob = new Blob([saved.buffer], { type: 'application/pdf' });
			let pdfUrl = (window.URL || window.webkitURL).createObjectURL(blob);
			win.location.href = pdfUrl;
		})
	} else
		pm.open()
	// return pm;
}

export async function allPrintPdf(rows, form, state){
	let dest = await PDFDocument.create();

	for (const row of rows) {
		let {printPdf} = await import('genpdf/pdf-form')
		let singlePdf = await printPdf(form.form,row.docid,state.orginfo, true)
		if (singlePdf instanceof Uint8Array)
			await appendDoc(dest, singlePdf)
		else await appendDoc(dest, await pdfBudder(singlePdf))
	}
	dest.setTitle('Документ');

	let saved = await dest.save()
	let win = window.open('', '_blank');
	let blob = new Blob([saved.buffer], { type: 'application/pdf' });
	let pdfUrl = (window.URL || window.webkitURL).createObjectURL(blob);
	win.location.href = pdfUrl;

}
