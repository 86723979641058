import {later} from 'azlib/components/helpers'
import {showModalProcessing} from 'azlib/components/modals'

import {shardedDbX} from 'azlib/components/db'

export function updateUa(uinfo){
	window.UserInfo = uinfo || {};

	let ua = {}

	switch(window.UserInfo.sysrole) {
	case 'admin': ua.admin = true;
	case 'sysop': ua.sysop = true;
	default:      ua.user = !!window.UserInfo.login;
	}
	window.UserInfo.userAreas = ua;

	console.log('ua ok '+ window.UserInfo.app_bundle_stamp)
}

export function statusSorter(a,b) {
	return (
	(window.AppBundle.data.doc_status.orderedKeys.indexOf(a.status)+100)
	-
	(window.AppBundle.data.doc_status.orderedKeys.indexOf(b.status)+100)
	)
}

export function kindSorter(a,b) {
	return (
	(window.AppBundle.data.doc_kinds.orderedKeys.indexOf(a.doctype_kind)+100)
	-
	(window.AppBundle.data.doc_kinds.orderedKeys.indexOf(b.doctype_kind)+100)
	)
}

export function combineSorters(sorters) {
	return (a,b) => {
		for(const s of sorters) {
			let r = s(a,b)
			if(r) return r;
		}
		return 0
	}
}

/**
 * wait until uinfo updated
 * now it called at:
 * persons
 * if user orgs changed, uinfo of course changed too!
 * groups add/del: same as orginfo now
 */
export async function uinfo_changed() {
	await showModalProcessing(
		async (hide)=>{
			for(let i = 0; i < 10; ++i) {
				if(!window.localStorage['cachedUinfo']) return true;
				console.log('wait...')
				await later(1000)
			}
			return null;
		}
		, props=>
		<div>Сохранение</div>
	)
	window.document.location.reload()
}

/**
 * wait until uinfo updated
 * now it called at:
 * persons
 * orginfo (save) if user orgs changed, uinfo of course changed too!
 * groups add/del: same as orginfo now
 */
export async function orginfo_changed(orginfo) {
	const DbX = shardedDbX(orginfo?.tid);
	const tag = orginfo.etag;
	console.log('etag0:', tag)
	await showModalProcessing(
		async (hide)=>{
			for(let i = 0; i < 10; ++i) {
				const u = await DbX.fetch_get_as_json('/app/common/org-info',{org:orginfo.tid});
				console.log('etag'+tag+':', u.etag)
				if(u.etag !== tag) return u.etag;
				await later(1000)
			}
			return null;
		}
		, props=>
		<div>Сохранение</div>
	)

	window.document.location.reload()
}