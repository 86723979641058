import React, {useState, useRef, useCallback} from 'react';
import ReactDOM from 'react-dom';
import {useParams} from "react-router-dom"
import {Calendar, formatLocalDate, parseLocalDate, formatLocalISO, parseLocalISO} from './calendar';
import {XLOG, applyPlaceholder, defer} from './helpers';
import {getXY, AutosizeTextarea, useOnce, useOnceAsync} from './ui_helpers';
import {set_array_element, swap_array_elements} from 'azlib/components/struct_assign'
import {prompt as promptBox, promptBig 
    , showModal, PopupMenu
    , confirm
    } from './modals';

import localDb from './local-db'

import {shardedDbX} from 'azlib/components/db'
import {alert} from './modals';
import css from './controls.module.css'
import styles from "../../config/doctype.module.css";
export const fakeEvent = (name, value) =>
        ({target:{type:'', value, name}})
export const fakeCtrlEvent = (e) => ({target:e})

export const FieldInput = React.forwardRef(
  ({refFieldValidate,...props}, ref) => <input ref={ref}
  {...props} 
  onBlur={(e)=>{
    e.target.value = e.target.value.trim()
    props.onChange?.(e)
    props.onBlur?.(e)
  }}
  autoComplete="off"
/>
)

export const IntInput = ({refFieldValidate,...props}) => <input 
  {...props} 
  onKeyPress={(e)=>{
    if(e.charCode < 0x30 || e.charCode > 0x39) {
      e.preventDefault()
    }
  }}
  onChange={(e)=>{
    let v = e.target.value.trim()
    if (v === '' || (parseInt(v) >= 1 && parseInt(v) <= 999)) {
      if (v.length > 1 && v[0] === '0') {
          v = v.slice(1);
      }
      if(v!=e.target.value) {
        const sel = e.target.selectionStart;
        e.target.value = v;
        if(sel<=1) e.target.setSelectionRange(0,0)
      }
    }else {
        e.target.value = props.value || '';
    }
    props.onChange?.(e)
  }} 
  autoComplete="off"
/>

function find_in_options(value, options) {
  for(const c of options)
    if(Array.isArray(c)) {
      for(const ca of c)
        if(ca.props.value === value) 
          return ca.props.children
    } else if(c.props.value === value) 
      return c.props.children
  return ''
}

/**
 * replace select with input if readonly
 * 
 */


function ExtSelect({refFieldValidate,...props}) {
  return   <select {...props} />
}
export const FieldSelect = ({value, refFieldValidate, children, ...props}) => 
  props.readOnly ? <input {...props} value={find_in_options(value, children)} />
  :
   <ExtSelect {...props} value={value} value-reflect={value}
    autoComplete="off"
    >{children}</ExtSelect>

export const ModelSelect = ({modelData, children, readOnly, emptyLabel, filter_data, ...props}) => {
  let appBundleData = window.window.AppBundle.data[modelData].orderedKeys
  if(filter_data instanceof Function)
    appBundleData = appBundleData.filter(filter_data)
  return <FieldSelect {...props} disabled={readOnly}>
      {emptyLabel ? <option value="">{emptyLabel}</option>:null}
      {appBundleData
          .map( k =>
                  <option key={k} value={k}>{window.window.AppBundle.data[modelData].decode[k]}</option>
              )
          }
      </FieldSelect>

}

export const Checkbox2 = ({modelData, name, value, onChange, onBlur
  , readOnly, refFieldValidate, ...props}) => {
  const options = modelData ? 
          Array.isArray(modelData) ? modelData
          : window.window.AppBundle.data[modelData].orderedKeys
      : ['',1];
  return <div><input type="checkbox" name={name}
      checked={value!==options[0] && value!==null} 
      onChange={(e) => { if(readOnly) return;
          onChange?.(fakeEvent(e.target.name,
              e.target.checked?
                options[1] : options[0]
            ))
          onBlur?.(e)
      }} 
    /></div>
} 

//TODO: direct values
export const InlineSelect = ({modelData, name, value, onChange, onBlur
  , readOnly, refFieldValidate, ...props}) => {
  return <span className={css.inlineSelect}>
      {
        window.window.AppBundle.data[modelData].orderedKeys
        .filter(k=>k!=='')
        .map( k => 
            <button type="button" name={name} key={k} value={k}
                  className={value === k? 'selected' : null} 
                  onClick={(e) => {
                    if(readOnly) return;
                    onChange?.(fakeEvent(e.target.name,
                        value===e.target.value?'':e.target.value
                      ))
                    onBlur?.(e)
                  }} 
            >{window.window.AppBundle.data[modelData].decode[k]}</button> 
        )
      }
    </span>
} 


export function Textarea({resize,refFieldValidate,...props}){
  return resize ?? true ?
      <AutosizeTextarea {...props} />
      : <textarea {...props} />
}


const maskOps = {
  make_mask: (m) => {
    var msk = []
    for(var i = 0 ; i < m.length; ++i)
      switch(m[i]) {
      case '0': msk.push(/[0-9]/); break;
      case '9': msk.push(/[0-9]+/); break;  
      case '_': msk.push(/./); break;
      case '[': 
        var s = '';
        for(var j = i+1; j< m.length && m[j] !== ']'; ++j)
          s += m[j];
        msk.push(new RegExp('['+s+']'))
        i = j;
        break;
      default: msk.push(m[i])
      }
    return msk;
  }
  , make_empty: (mask, placeholder) => {
    var msk = maskOps.make_mask(mask)
    var r = ''
    for(var j = 0; j < msk.length; ++j)
      r += typeof msk[j] === 'string' ? 
        msk[j] : 
        /\+\//.test(msk[j]) ? '' : placeholder;
    return r;    
  }
  , applyValue: (msk, ph, val, sel) => {
    var r = ''
    var sel_ret = sel
    //console.log(val, len)
    var j = 0;
    for(var i = 0; i < val.length && j < msk.length; ++i) {
      if(val[i] === ph) {
        if(typeof msk[j] !== 'string') {
          if(!/\+\//.test(val[i])) {
            r += val[i];
          }
          j++;
        }
      } else {
        var p0 = r.length;
        var k = j;
        for(; j < msk.length; ++j) {
          if(typeof msk[j] === 'string') {
            r += msk[j];
            if(msk[j] === val[i])
              break;
          } else if(/\+\//.test(msk[j])) {
            if(msk[j].test(val[i])) {
              r += val[i];
              j--; //stay on this mask pos
              break;
            }
          } else {
            if(msk[j].test(val[i])) {
              r += val[i];
              break;
            } else {
              r += ph;
            }
          }
        }
        if(j >= msk.length) {
          j = k;
          r = r.substr(0,p0);
        } else {
          j++;
        }
        //1.( ==> (1
        //(1. ===>
      }
      if(i===sel-1)
        sel_ret = r.length
    }
    for(; j < msk.length; ++j)
      r += typeof msk[j] === 'string' ? 
        msk[j] : 
        /\+\//.test(msk[j]) ? '' : ph;
      
    return {masked_value: r, sel: sel_ret};
  }
  , onInput: (e) => {
          //console.log('ch', e.target.xMask)
          const info = e.target.xMask;
          const inp = e.target;
          let {masked_value,sel} =
            maskOps.applyValue(info.mask, info.placeholder
              , inp.value
              , inp.selectionStart)
          inp.value = masked_value;
          inp.setSelectionRange(sel,sel)

          //console.log('in', inp.value)
          if(inp.value === info.empty) {
            inp.value = '' //hack! it will be reverted to empty mask
            console.log('ch0',masked_value,sel)
          }
      }
}


export const MaskedInput = React.forwardRef(
  ({value, mask, refFieldValidate, ...props}, ref) => {
    const placeholder = '_'

    const parsed = useOnce(()=>maskOps.make_mask(mask??''))
    const emptyValue = useOnce(()=>maskOps.make_empty(parsed, placeholder))

    //  console.log('me-render', value, field.name, form)
    return <input type="text" ref={(e) => 
        {
          //console.log('c', e)
          if(ref) ref.current = e;
          if(e) {
            e.xMask = { 
              mask: parsed
              , placeholder
              , empty: emptyValue
              , onChange: props.onChange
            } 
            if(!value)
              e.setSelectionRange(0,0)
          }
        }
      }
      {...props}
      masked={value?'filled' :'empty'}
      value={value||emptyValue}
      onInput={maskOps.onInput}
      autoComplete="off"
    />
    // onInput set emptyMask -->'' 
    // and then onChange setFieldValue 
    // and then value=='' --> empty mask
})


/*
+- N => days
+- N months => months
+- N years => 12*months
*/

function relativeDate(dt) {
  if(!dt) return
  let m
  if((m=dt.match(/\s*(?:([+-])\s*(\d+)\s*(months|years)?|(\d{4}-\d{2}-\d{2}))\s*$/))) {
    if(m[1]) {
      let sign = m[1] === '-'? -1 : 1;
      let r = new Date()
      r = new Date(r.getFullYear(), r.getMonth(), r.getDate())
      let offset = 
        m[3] === 'years' ? [(+m[2]) * 12 * sign, 0]
        : m[3] === 'months' ? [+m[2] * sign, 0]
        : [ 0,  +m[2] * sign ]
      return new Date(r.getFullYear(), r.getMonth()+offset[0], r.getDate()+offset[1])
    } else {
      return parseLocalISO(m[4])
    }
  }
}

function adjustPos(pos, ref) {
  if(true || !ref.current) return pos;
  let ret = {...pos}
  if(ret.x > ref.current.parentNode.offsetWidth - ref.current.offsetWidth)
    ret.x = ref.current.parentNode.offsetWidth - ref.current.offsetWidth;
  if(ret.y > ref.current.parentNode.offsetHeight - ref.current.offsetHeight)
    ret.y = ref.current.parentNode.offsetHeight - ref.current.offsetHeight;
  if(ret.x < 0) ret.x = 0;
  if(ret.y < 0) ret.y = 0;
  return ret;
}

const invalid_date = Symbol('invalid_date')

export function DateInput({value, onChange, onBlur, onFocus
  , readOnly, refFieldValidate, min_value, max_value
  ,...props}) {
  let calendarRef = useRef(null)
  let clicked = useRef(false)
  let [popped, setPopped] = useState(false)
  let ref = React.createRef(null)
  let pos = useRef({x:0,y:0, fixed: false})
  let min = relativeDate(min_value)
  let max = relativeDate(max_value)

  let lastValue = useRef('')

  if(refFieldValidate) {
    refFieldValidate.current =
      (value) => {
        if(Number.isNaN(value)) return 'Не дата'
        if(!value?.trim()) return
        value = parseLocalISO(value)
        if(Number.isNaN(value)) return 'Не дата'
        if(min && value < min) return 'Дата вне допустимого диапазона:'+formatLocalDate(min)
        if(max && value > max) return 'Дата вне допустимого диапазона:'+formatLocalDate(max)
      }
  }
  /*
    formatLocalDate
      empty -> empty
      date -> local formatted date 
      strign -> try parse, if success -> local formatted date
                        , if error -> unchanged

    formatLocalISO
      if NaN -> NaN
      if valid date -> date in local time zone iso-formatted
  */
  return (<>
        <input ref={ref} readOnly={readOnly}
          {...props}
          onClick={()=>{
            if(ref.current) {
              const tp = getXY(ref.current);
              const {x,y,w,h} = tp;
              pos.current = {x,y:y+h, fixed: tp.fixed}
            }
            !readOnly && setPopped(!popped)
          }}
           value={Number.isNaN(props.json?formatLocalISO(parseLocalISO(value)):value)? lastValue.current
               : formatLocalDate(value)}
          onKeyDown={()=>setPopped(false)}
          onChange={(e)=>{
            lastValue.current = e.target.value
            if(!e.target.value.trim()) {
              e.target.value = ''
              onChange?.(e);
              return;
            }
            const parsed = parseLocalDate(e.target.value)
            //console.log(parsed)
            onChange?.(fakeEvent(e.target.name, formatLocalISO(parsed)))
          }}
          onFocus={e=>{
            clicked.current = false;
            return onFocus?.(e)
          }}
          onBlur={e=>{
              //console.log('blur',e, e.relatedTarget)
              onBlur?.(e);
              if(!clicked.current) {
                setPopped(false);
              }
            }
          }
          autoComplete="off"
        />
        {popped?
            ReactDOM.createPortal(
              <Calendar ref={calendarRef}
                style={{
                  position: pos.current.fixed ? "fixed" : "absolute"
                  ,left: adjustPos(pos.current, calendarRef.current).x
                  ,top: adjustPos(pos.current, calendarRef.current).y
                }}
                onMouseDown={e=>{
                  clicked.current = true;
                }}
                onBlur={(e) => {
                  //console.log('blur2',e, e.relatedTarget)
                  //if(!document.getElementById('transient').contains(e.relatedTarget)) 
                  //if(e.relatedTarget!==ref.current)
                  //  setPopped(false);
                }}
                onClick={e=>{
                  //console.log('cl')
                }}
                onChoose={(dt)=>{
                  setPopped(false);
                  //console.log(ref.current, dt, formatLocalDate(dt))
                  if(ref.current) 
                    ref.current.value = formatLocalDate(dt)
                  onChange?.(fakeEvent(props.name, formatLocalISO(dt)))
                }}
                min={min} max={max}
              />
          , document.getElementById('transient'))
        : null}
      </>
      )
}

export const FieldAsPrompt = ({name, value, onChange, onBlur, refFieldValidate
    , placeholder, label, prompt, required, readOnly, multilne
    , children, ...props}) => {
  return <button type="button" className={css.editWithPrompt}
    onClick={async ()=>{
        if(readOnly) return;
        let n = await (multilne?promptBig:promptBox)
                      (prompt ?? label, value, required)
        onChange?.(fakeEvent(name,n))
        onBlur?.(fakeEvent(name))
    }} 
    {...props}>{children?
          children instanceof Function ? children(value, name)
          : children
        : applyPlaceholder(value, placeholder)
    }</button>
}

export const PopupEdit = ({name, value, trigger, onChange, onBlur, refFieldValidate
    , placeholder, children, ...props}) => {
  return <PopupMenu
            trigger={
              trigger?
                trigger instanceof Function? 
                  trigger(value, placeholder,
                      <button type="button" className={css.popupEdit}>
                        {applyPlaceholder(value,placeholder)}
                      </button>
                    )
                : trigger
              :
              <button type="button" className={css.popupEdit}>
                {applyPlaceholder(value,placeholder)}
              </button>
            }
            onHide={value=>{
              if(value!==undefined) {
                onChange?.(fakeEvent(name,value))
                onBlur?.(fakeEvent(name))
              }
            }}
            {...props}
          >{children}
        </PopupMenu>
}

/**
 * text - decode value (row --> trigger text)
 * to change text after we need prefix instead of text
 * 
 */
export const ModalEdit = ({name, value, onChange, onBlur
        , closeBox
        , dialog
        , trigger, text, placeholder
        , readOnly
        , refFieldValidate
        , narrowValue
        , ...props}) => {
  value = text instanceof Function ? text(value, placeholder)
            : (text??value)
  const onClick = async ()=>{ if(readOnly) return;
        let n = await showModal(dialog, {closeBox})
        if(narrowValue) n = await narrowValue(n)
        if('$' in Object(n)) {
          //it's multi-value
          let {$,...a} = n
          onChange(fakeEvent(name,$))
          //FIXME: strange to know it here!
          onChange(fakeEvent(name+'$',a))
        } else {
          onChange(fakeEvent(name,n))
        }
        await defer()
        onBlur?.(fakeEvent(name))
      }

  trigger =
    React.cloneElement(trigger??
        <button type="button" className={css.editWithPrompt} />
        , {...props, children: applyPlaceholder(value,placeholder), onClick} )

  return trigger
}

export function blobToDataURL(value) {
  return new Promise((resolve, reject)=>{
    let reader = new FileReader()
    reader.onload = function () {
      let data = this.result
      resolve(data)
    }
    reader.readAsDataURL(value);    
  })
}
export function blobToText(value) {
    return new Promise((resolve, reject)=>{
        let reader = new FileReader()
        reader.onload = function () {
            let data = this.result
            resolve(data)
        }
        reader.readAsText(value);
    })
}
export function blobToJson(value) {
    return new Promise((resolve, reject)=>{
        let reader = new FileReader()
        reader.onload = function () {
            try {
                let data = JSON.parse(this.result);
                resolve(data)
            }
            catch (e) {
                alert("Некорректный файл");
            }
        }
        reader.readAsText(value);
    })
}
function typedBlobViever(image, pdf, text){
  return (<>
      <style dangerouslySetInnerHTML={{__html:`
        html, body, #root {
          width: 100%;
          height: 100%;
        }
        `
      }}/>
      {
      image&& <img src={image} alt="Изображение"/>
      ||
      pdf&& <embed type="application/pdf" 
        style={{width:"100%", height:"100%"}}
        src={pdf}
        />
      ||
      text&& <pre>{text}</pre>
    }
    </>)
}

export function FileUrl(props) {
  let params = useParams()
  let tid = params.tid
  let store = params.store
  let key = params.key.split(',')
            .map(decodeURIComponent)
            .map(k=>k[0] === '.'? k.substr(1) : +k)
  let name = params.name

  let [text, setText] = useState(null)
  let [image, setImage] = useState(null)
  let [pdf, setPdf] = useState(null)

  useOnceAsync(async () =>{
    let d = await localDb.get(store, key) //try local first!
    if(d) {
      let data_url = d[name] ?? "";
      if(data_url !== true) { //placeholder (file exist, but not an file itself)
        let [ctype, cdata] = data_url.split(',',2)
        if(/^data:image[/]/.test(ctype)) 
            setImage(data_url)
        else 
        if(/^data:application[/]pdf[;,]/.test(ctype)) 
            setPdf(data_url)
        else 
            setText(atob(cdata))
        return true;
      }
    }
    let e = await shardedDbX(tid)
              .fetch_get(`/az2/server/filer?-t=${store}&-name=${name}&`
                          +key.map(k=>`-k[]=${k}`).join('&')
              );
    if(!e.ok) return false;
    let ctype = (e.headers.get('Content-Type')??'')
                .split(';', 2)[0]
    if(/^image[/]/.test(ctype)) {
      e = await e.blob()
      e = await URL.createObjectURL(e)
      setImage(e)
    } else if(/^application[/]pdf$/.test(ctype)) { 
      e = await e.blob()
      e = await URL.createObjectURL(e)
      setPdf(e)
    } else {
      e = await e.text();
      setText(e)
    }
    return true;
  })

  return typedBlobViever(image, pdf, text)
}

function data_url_view(name, ld) {
  let key = ld.key.map(k=>
              typeof k === 'string'?'.'+k
              : String(k)
              ).map(encodeURIComponent)
              .join(',') //encoded in component by encodeURIComponent
  return `/blob${ld.prefix}/${ld.store}/${key}/${name}`
}
export function FileInput({value, name, readOnly, onChange, onBlur, fileHref
  , refFieldValidate
  , ...props}) {
  return <>
      {value?
        <div><a className="buttonBackground buttonBackgroundFlex" href={data_url_view(name, fileHref)}
                target="_blank"
              >Открыть файл
            </a>
            {!readOnly &&
            <button className="buttonBackgroundFlex" type="button" name={name}
              onClick={async (e)=>{
                await confirm('Удалить?', true)
                let v = ''
                onChange?.(fakeEvent(e.target.name,v))
              }}
            >Удалить</button>}
        </div>
        :null}
      {readOnly?null:
      <label className="buttonBackground buttonBackgroundFlex">
      {value? 'Заменить файл':
        'Выберите файл'
      }
      <input type="file" {...props} 
      name={name} 
      style={{width:0, height:0, overflow:"hidden"}} 
      onChange={async (e)=>{
        if(e.target.files[0]) {
          let v = await blobToDataURL(e.target.files[0])
          onChange?.(fakeEvent(e.target.name,v))
        } else {
          //do nothing
        }
        onBlur?.(e)
      }}
      />
      </label>
      }
    </>
}

export function Viewer(props) {
  return <>{applyPlaceholder(props.value, props.placeholder)}</>
}

export function CLOBView({value,...props}) {
  return <pre viewer="" {...props}>{value}</pre>
}

export function modelDecode(modelData, value) {
  return window.AppBundle.data[modelData].decode[value]
} 
export const ModelDecode = ({value, modelData, name, ...props}) => {
  if(!modelData) return value;
  return <Viewer {...props} value={modelDecode(modelData, value)} />
}

export const DateView = (props) => <Viewer 
  {...props}  value={formatLocalDate(props.value)}
/>

export const BoolView = (props) => <Viewer
  {...props} value={props.value? '✔' :''} 
/>


/**
 * Edit array value
 * 
 * if value is a string it parsed as json and composed back
 * 
 * children is a template which shows array item
 *    it is a render prop or element to clone
 * 
 * keyFunction(value,index) give array key for each item (default: index)
 * editFunction(value,index,array) popup editor for given value (or undefined for new item)
 *            returns edited value or undefined if no changes was made (or throw)
 * checkUnique(array) optionally check if all items unique in some sence (throw if not)
 * 
 * placehoder will show if no items
 * 
 * ItemComponent component (props=>element) which show item 
 * RepositionComponent component which reposition given item
 * DeleteComponent component which delete given item
 * AddComponent  component which add new item
 *  
 * ArrayEditor pass to children an object with
 *  {
  *  value: array item value
  *  index: array item index
  *  reposition: control which reposition item
  *  remove: control which delete this item
  *  editor: value,index,array->value function which popped up modal item editor
 *  }
 * 
 */
export function ArrayEditor({name, value, onChange, onBlur, readOnly
    , keyFunction
    , editFunction
    , checkUnique
    , withBackground
    , ItemComponent
    , RepositionComponent
    , DeleteComponent
    , AddComponent

    , placeholder

    , children

    }) {
  
  const isJSON = typeof value === 'string';
  if(isJSON) value = value && JSON.parse(value) || []

  const changed = useCallback(value=>
      onChange?.(fakeEvent(name, isJSON? JSON.stringify(value) : value))
      ,[name,isJSON])

  const editor = useCallback(async (value,index,array) => {
    if(readOnly) return;
    value = await editFunction?.(value,index,array)
    if(value !== undefined) {
      let new_array = set_array_element(array,index,value);
      if((await checkUnique?.(value,index,new_array))??true) {
        changed(new_array)
      }
    }
  }, [readOnly, editFunction, checkUnique]);

  if(!DeleteComponent) DeleteComponent = SmallDeleteButton
  if(!RepositionComponent) RepositionComponent = UpDown

  ItemComponent = useCallback(ItemComponent ?? (
    ({value,index,array, reposition, remove, editor, readOnly})=>
      <div className={styles.listItem + " flexContainer"}>
          {reposition}
          <button type="button" className={css.arrayString}
            onClick={()=>editor(value,index,array)}
          >
          {value}
          </button>
          {remove}
      </div>
    )
    , [ItemComponent])
  
  AddComponent = useCallback(AddComponent ?? SmallAddButton
    , [AddComponent])

  let items = value.length?
        value.map((v,i,array)=>
        <ItemComponent key={keyFunction?keyFunction(v,i):i}
          readOnly={readOnly}
            value={v} index={i} array={array}
            editor={editor}
            reposition={<RepositionComponent value={value} index={i} readOnly={readOnly} changed={changed}/>}
            remove={<DeleteComponent value={value} index={i} readOnly={readOnly} changed={changed} withBackground={withBackground}/>}
          />
        )
        :
        placeholder

  const gen = children instanceof Function? children :
              (props) => React.cloneElement(children, props,null)

  return gen({
            items
            , add: <AddComponent value={value} readOnly={readOnly} editor={editor} withBackground={withBackground}/>
          })
}

const UpDown = ({value,index,readOnly,changed}) =>
  !readOnly && <>
    {index-1>=0 
     && <button type="button" className={css.arrow+ ' up'} 
      onClick={()=>changed(swap_array_elements(index,index-1,value))}
    >↑</button>}
    {index+1<value.length
      && <button type="button" className={css.arrow+ ' down'}
      onClick={()=>changed(swap_array_elements(index,index+1,value))}
      >↓</button>}
  </>

const SmallDeleteButton = ({value, index, readOnly,changed, withBackground})=>
            !readOnly && <button type="button" className={withBackground? "del simple":"del small"}
              onClick={e=>changed(set_array_element(value,index, undefined))}
            />

const SmallAddButton = ({value, readOnly, editor, withBackground})=>
            !readOnly && <button type="button" className={withBackground? "add simple":"add small"}
              onClick={()=>editor(undefined, null, value)}
            />

