import React, {useContext, useEffect, useState} from 'react';

import {
  Link,
  useNavigate
} from "react-router-dom";

import {letIn} from 'azlib/components/helpers'

import {prompt, alert, PopupMenu} from 'azlib/components/modals'

import {fakeEvent} from 'azlib/components/std-controls'

import styles from './doctype.module.css';

import {
	DbFormik, FormValues
	, LabeledField
	, ModelField, Calculate
	, SwitchModificationState, IfSaved, IfModified
	, StatusDriver
	, Conditional
} from 'azlib/components/formik-fields'

import {useTypedParams} from 'azlib/components/router_helpers'
import {combine_arrays} from 'azlib/components/diff_helpers'

import { DbTable as Table } from 'azlib/components/super-table'
import Filter from 'azlib/components/list_filter'

import {ShowLocker, lockToEdit, saveIfOwned, cancelLock, alien_locked} from 'config/row_locker'

import {ModelDecode, ArrayEditor} from 'azlib/components/std-controls'

import {OrgInfoContext} from 'org/org'
import UserInfoContext from 'UserInfo'
import {TypeStructureEditor, defaultTemplate, spec_name_by_kind} from './doctype_typepros'

import { hasSpecialities, smart_ed_level,
		docKindModalSelector, MegaMonsterEdit} from 'config/ed_levels_map'

import { SearchFilter } from 'azlib/components/search_filter'

import {PopupLog} from 'contingent/log'
import {ReactComponent as QuestionGrey} from './../question_grey.svg'

import {orginfo_changed} from 'config/common'

export function DocTypes() {
	const orginfo = useContext(OrgInfoContext);
	const navigate = useNavigate();

	const [doctypesCount, setDoctypesCount] = useState(0);
	const [selectedLevel, setSelectedLevel] = useState('');
	
	useEffect(() => {		  
		orginfo.DbX.fetch_rds("doc_types", 'doctype kind', { tid: orginfo.tid })
			.then((doctypesData) => {
				let data = selectedLevel !== '' ? doctypesData.filter(doctypes => doctypes.kind.split("-")[0] === selectedLevel): doctypesData
				setDoctypesCount(data.length);
			})
	  }, [orginfo, selectedLevel]);

	function handleFilterChange(filterValue) {
		setSelectedLevel(filterValue)
	}

	return <>
		<div
			style={{float:"right", margin:"1em 1em 0 0"}}
		>
			<PopupLog table="doc_types" tkey={orginfo.tid}
					  fixed={{
						  tname: 'orgtree'
						  ,stname:'doc_types'}}
					  transform={diff_transform}
					  transformContext={orginfo}
					  deffered={true}
			/>
		</div>
		<h1>Формы документов</h1>
		<Table className="simpleTable"
			store={orginfo.DbX.store("doc_types", { tid: orginfo.tid } )}
			extra="parent infoset"
			>
			<Table.Before>
				<div className="templatesSearch backgroundAngular">
					<SearchFilter
						input={
						<Filter.Multi placeholder={"Найти форму"} className="FilterInput"
							extract={
								[
									[/(.+)/, txt =>	row => row.typename 
											&& row.typename.toUpperCase().includes(txt.toUpperCase())
									]
								]
							}
							saveAs="multi"
						/>
						}
					/>
						<div className="flexContainer">
						<Table.Add command={async (ops) => {
							let name = ops.filterLeafs().multi
							let lvl = ops.filterLeafs().level

							let choosen = await docKindModalSelector(orginfo.tid % 10 === 0,
								lvl && lvl in orginfo.app_ed_level && lvl
								|| orginfo.app_ed_level
								, orginfo.orgtree_doctypes)
							if(choosen.parent)
								choosen.infoset =
									await orginfo.DbX.read_column('doc_types', 'infoset', [choosen.parent])
								choosen.typename = await prompt("Название",name, true)
								
							if(orginfo.tuner) {
								let key = await ops.append_direct(choosen)
								ops.add_to_list(key)
								navigate(`/org/${orginfo.tid}/templates/${key[0]}`)
							}
							else
								alert("Недостаточно прав!")
						}}>
							Создать новую форму
						</Table.Add>
				<Filter Element={<select style={{marginLeft:"auto"}}>
						<option value="">Уровень:</option>
						{
							window.AppBundle.data.ed_levels.orderedKeys
							.filter(e=>!!e)
							.filter(e=>e in orginfo.app_ed_level)
							.map(e=><option key={e} value={e}>
									{window.AppBundle.data.ed_levels.decode[e]}
								</option>)
						}
					</select>}
					condition={v => row => row.kind.startsWith(v+'-')}
					saveAs="level"
					changeFilter={handleFilterChange}
				/>
				<div className="backgroundRounded" style={{textAlign:"center", padding:"1em 1em", color:"#3C5773", lineHeight:"1em"}}>
					<label>Количество форм: {doctypesCount}</label>
				</div>
				</div>
				</div>
			</Table.Before>
			<Table.ModelCol label="Тип документа" name="kind">
				{(view,value,row) =>
					<Link to={`/org/${orginfo.tid}/templates/${row.doctype}`}>
						{view}
					</Link>
				}
			</Table.ModelCol>
			<Table.Col label="Название" name="typename">
				{(value,row) =>
					<Link to={`/org/${orginfo.tid}/templates/${row.doctype}`}>
						{value || '--???--'}
					</Link>
				}
			</Table.Col>
			<Table.Col label="Статус" name="active">
				{(value, row) =>
					value? 'Активирован': 'Не активирован'
				}
			</Table.Col>
			<Table.Col label="" colKey="@del" className="del-paddings-del-button">
				<Table.Del confirm disabled={!orginfo.tuner}
					command={async (row,ops)=>{
						await ops.remove(row);
						orginfo_changed(orginfo);
					}}
				/>
			</Table.Col>
		</Table>
		</>
}

export function DocTypeForm(props) {
	const orginfo = useContext(OrgInfoContext);
	const uinfo = useContext(UserInfoContext);
	const params = useTypedParams();
	const navigate = useNavigate();
	const store = 'doc_types';
	const savedState = (v,fc) => !fc.status.modified;
	return (
	<DbFormik
		store={orginfo.DbX.store(store, { tid: orginfo.tid })} 
		rowKey={[params.Doctype]}
		onKeyChanged={key =>
			navigate(`/org/${orginfo.tid}/templates/${key[0]}`, { replace: true })
		}
	>
		<Calculate name="kind">{(kind,row)=><>
			<div
				style={{float:"right", margin:"1em 1em 0 0"}}
			>
				<PopupLog table="orgtree" tkey={orginfo.tid}
						  fixed={{stname:"doc_types", stkey:row.doctype}}
						  transform={diff_transform} />
			</div>
		<h1>
				<ModelDecode modelData="doc_kinds" value={kind} /> : форма документа
		</h1>
		</>}</Calculate>

		<ShowLocker show={orginfo.tuner || uinfo.userAreas.sysop || uinfo.userAreas.admin} readOnly={!orginfo.tuner}/>
		<div className="templatesSearch">
		<div className="backgroundRounded templatesSearch">
		<div className="flexContainer">
		<SwitchModificationState readOnly={!orginfo.tuner}
			fastDoNotEdit={alien_locked}
			superusers={uinfo.userAreas.admin || uinfo.userAreas.sysop}
			canEdit={lockToEdit}
			prepareEdit={fc=>{
					fc.setFieldValue('infoset',
						JSON.stringify(defaultTemplate(fc.values.kind, fc.values.infoset))
					)
			}}
			prepareSave={saveIfOwned}
			cancelEdit={cancelLock}
		/>
		<IfModified fastDoNotEdit={alien_locked} superusers={uinfo.userAreas.admin || uinfo.userAreas.sysop}>
			<StatusDriver name="active" readOnly={!orginfo.tuner}
				prepareSave={saveIfOwned}
				map={{
					'': [{to: true, text:'Активировать'}]
					, true: [{to:'', text:'Деактивировать'}]
				}}
			/>
		</IfModified>
		</div>
			<div className="gridContainer" style={{gridTemplateColumns:"40% 40% 10% 10%"}}>
		<ModelField name="typename" readOnly={savedState} />

		<FormValues>{r=>
			<Conditional name="kind" when={k=>hasSpecialities(k)} extra="spec_code">
			<LabeledField 
				label={
					spec_name_by_kind(smart_ed_level(r.kind))
					??
					r.kind+"Специальность"
				} 
				name="spec_code" 
				ed_level={r.kind}
				as={MegaMonsterEdit}
			  orginfo={orginfo} // spec_code only
				placeholder="--.--.--"
				readOnly={savedState}
			/>
			</Conditional>
		}</FormValues>

		<div className="flexContainer" style={{flexWrap:"nowrap"}}><ModelField name="local" readOnly={savedState} /><QuestionGrey className="question"
		title="Если флаг не установлен - организациям ниже по структуре (элементам структуры) будет доступна данная форма документа"/></div>
		{ (orginfo.tid % 10 === 0) && // if org is in main shard
			<ModelField name="global_template" readOnly={savedState} />
		}
			</div>
		</div>

		<FormValues>{r=><>
			<LabeledField name="infoset"
				as={TypeStructureEditor}
				readOnly={savedState}
				doc_kind={r.kind}
			/></>
		}</FormValues>

		<p/>

		<div className="backgroundRounded templatesSearch">
		<FormValues>{r=>
			<LabeledField name="signer_roles" label="Подписывающие"
				as={SignersEditor}
				readOnly={savedState}
				doc_kind={r.kind}
				orginfo={orginfo}
			/>
		}</FormValues>
		</div>

		</div>

	</DbFormik>)
}


function SignersEditor({name, value, doc_kind, onChange, readOnly, orginfo, ...props}) {
	let parsed = value? JSON.parse(value) : {}

	const [radioBut, setRadioBut] = useState(Object.keys(parsed).length === 0 ? 'default' : 'custom')
	
	useEffect(() => {
        setRadioBut(Object.keys(parsed).length === 0 ? 'default' : 'custom');
    }, [parsed]);

	const changeRadioBut = (value) => {
		if(value === 'custom') {
			onChange?.(fakeEvent(name, '{"Руководитель образовательной организации": ""}'))
			setRadioBut('custom')
		}
		else {
			onChange?.(fakeEvent(name, '{}'))
			setRadioBut('default')
		}
	}

	const schemes = ['Руководитель образовательной организации'
						, ...orginfo.app_custom_signers
					]

					return (
					<div>
						<div style={{margin:"0 0 10px 0"}}>
						  <label>
							<input
							  type="radio"
							  value="default"
							  checked={radioBut === 'default'}
							  onChange={() => changeRadioBut('default')}
							  disabled={readOnly}
							/>
							Подписывающие по умолчанию:
						  </label>
						</div>

						<p style={{margin:"10px 0 30px 20px"}}><i>Руководитель образовательной организации</i></p>

						<div style={{margin:"0 0 10px 0"}}>
						  <label>
							<input
							  type="radio"
							  value="custom"
							  checked={radioBut === 'custom'}
							  onChange={() => changeRadioBut('custom')}
							  disabled={readOnly}
							/>
							Указать подписывающих:
						  </label>
						</div>

	{radioBut === 'custom' &&
	<PopupMenu readOnly={readOnly} trigger={
						  <div className="buttonLarge signerRoles" >
							  {letIn(schemes.filter((k)=> k in parsed))(used=>
								used.length?
								used.map((s)=><div key={s} style={{padding:"5px 0 0 0"}}>
									<i>{s}</i>
								</div>)
								: 'Задать подписывающих'
							  )}
						  </div>
					  }>
		{schemes
				.map((k)=>
					<div key={k}>
					<label>
						<input type="checkbox" checked={k in parsed}
							   onChange={(event)=>{
								const nv = {...parsed}
          
								delete nv[k];
								if(event.target.checked) nv[k] = '';
								
								onChange?.(fakeEvent(name, JSON.stringify(nv)))
							}}
						/>
						{k}
					</label>
				</div>)
		}
	</PopupMenu>
	}
	</div>);
}

function stripName({name,internal,...e}){return e}

const diff_transform = {
		tid: null
		, doctype: null
		, path: null
		, row_locker: null
		, row_lock_seat: null
		, row_lock_stamp: null
		, "infoset.compare":
			(name,o,n) => {
				//console.log(o,n)
				o = o? JSON.parse(o): []
				n = n? JSON.parse(n): []
				let comb = combine_arrays(o,n,(e=>e?.name))
				comb = comb
					.filter(([name,eo,en])=>
							JSON.stringify(eo) !== JSON.stringify(en)
						)
				return comb
					.map( ([name,eo,en]) => 
							({
								name: '['+name+']'
								, o: eo ? JSON.stringify(stripName(eo)) : null
								, n: en ? JSON.stringify(stripName(en)) : null
							})
						)
			}
	}
