import React, {useContext, useState} from 'react';

import {
  Routes,
  Route,
  Navigate,
  NavLink,
  Outlet,
  useRoutes
} from "react-router-dom"

import {Async, AsyncOnce} from 'azlib/components/async'
import {defer} from 'azlib/components/helpers'

import {shardedDbX} from 'azlib/components/db'

import {PageTitle, PageMenu, PageSettings} from 'azlib/components/page-header'
import {useTypedParams} from 'azlib/components/router_helpers'
import {PopupMenu, MenuLink, ModalButton} from 'azlib/components/modals'

import {OrginfoPage} from 'org/orginfo'
import {OrgstructPage} from 'org/orgstruct'
import {OrgMembers} from 'org/members'
import {DocTypes, DocTypeForm} from 'config/doctype'
import {DocPapers, DocPaper} from 'config/docforms'
import {GroupsPage} from 'contingent/groups'
import {CardList, CardPage} from 'contingent/cards'
import {DocList} from 'docs/doclist'
import {StatisticsList} from 'docs/statistics'
import {DocForm} from 'docs/docedit'
import {IssuedList} from 'docs/issued'
import {SignPage,SignSelectCert, SignSignDocs, SignSignPage, ConfirmRolesPage} from 'sign/sign'
import {PrintDocs} from 'docs/print'

import {FeedbackList} from 'docs/feedback'

import { Import } from 'docs/import';

import {IssuedSearchPage} from 'docs/issued_search'

import {exportDocSign} from './export'

import UserInfoContext from 'UserInfo'

import {terminology} from './org'
import {OrgInfoContext} from './org'

import {ReactComponent as Gear} from './gear.svg'

import {ReactComponent as Img_docs} from "./org-img/docs.svg";
import {ReactComponent as Img_org_import} from "./org-img/import.svg";
import {ReactComponent as Img_groups} from "./org-img/groups.svg";
import {ReactComponent as Img_feedbacks} from "./org-img/feedbacks.svg";
import {ReactComponent as Img_students} from "./org-img/students.svg";
import {ReactComponent as Img_print} from "./org-img/print.svg";
import {ReactComponent as Img_sign} from "./org-img/sign.svg";
import {ReactComponent as Img_issued} from "./org-img/issued.svg";
import {ReactComponent as Img_statics} from "./org-img/statics.svg";
import {ReactComponent as Img_line} from "./org-img/line.svg";


export function OrgRoutes() {
	const orginfo = useContext(OrgInfoContext)
	const uinfo = useContext(UserInfoContext);

	return <Routes>
			{ ( 
				orginfo.m || orginfo.registry || orginfo.tuner || orginfo.docs.some
				|| uinfo.userAreas.sysop
				)
				&& <Route path="orginfo" element={<OrginfoPage/>} />}
			{ (  
				orginfo.tuner
				|| uinfo.userAreas.sysop
				)
				&& <Route path="orgstruct" element={<OrgstructPage/>} />}
			{ (  
				orginfo.m || orginfo.tuner || true
				|| uinfo.userAreas.sysop
				)
				&& <Route path="members" element={<OrgMembers/>} />}

			{ ( 
				orginfo.tuner
				|| uinfo.userAreas.sysop
				)
			&& <Route path="templates">
				<Route index element={<DocTypes/>}/>
				<Route path=":Doctype" element={<DocTypeForm/>}/>
			</Route>}

			{ ( 
				orginfo.tuner
				|| uinfo.userAreas.sysop
				)
			&& <Route path="forms">
				<Route index element={<DocPapers/>}/>
				<Route path=":Form" element={<DocPaper/>}/>
			</Route>}

			{ ( 
				orginfo.docs.operator || orginfo.docs.validator
				|| uinfo.userAreas.sysop
				)
				&& <Route path="contingent">
						<Route path="" element={<GroupsPage prefix="groups/"/>} />
						<Route path="groups" element={<GroupsPage/>} />
						<Route path=":Grp/cards/" element={<CardList/>} />
						<Route path="cards/" element={<CardList />} />
						<Route path="cards/:Docid" element={<CardPage />} />
				</Route>}

			{ ( 
				orginfo.docs.operator || orginfo.docs.validator
				|| orginfo.docs.printer || orginfo.docs.secretary
				|| uinfo.userAreas.sysop
				)
				&& <Route path="docs">
					<Route index element={<DocList/>} />
					<Route path=":Docid" element={<DocForm/>} />
					<Route path="cards/:Docid" element={<CardPage />} />
				</Route>}

			{ ( 
				orginfo.docs.operator
				|| uinfo.userAreas.sysop
				)
			&& <Route path="import" element={<Import/>} />}

			{ ( 
				orginfo.docs.signer
				|| uinfo.userAreas.sysop
				)
			&& <Route path="sign" element={<SignPage />} >
				<Route index element={<SignSelectCert />} />
				<Route path=":cert" element={<SignSignDocs />}>
					<Route path=":Docid" element={<SignSignPage/>} />
				</Route>
			</Route>}

			{ ( 
				orginfo.docs.signer
				|| uinfo.userAreas.sysop
				)
			&& <Route path="signRoles" element={<SignPage />} >
				<Route index element={<SignSelectCert roles/>} />
				<Route path=":cert" element={<ConfirmRolesPage />}/>
			</Route>}

			{ ( 
				orginfo.docs.printer
				|| uinfo.userAreas.sysop
				)
			&& <Route path="print" element={<PrintDocs/>} />}

			{ ( 
				orginfo.docs.some || orginfo.registry
				|| uinfo.userAreas.sysop
				)
				&& <Route path="issued">
					<Route index element={<IssuedList/>} />
					<Route path=":Docid" element={<DocForm issued/>} />
					<Route path="cards/:Docid" element={<CardPage />} />
				</Route>}

			{ ( 
				orginfo.registry
				|| uinfo.userAreas.sysop
				)
				&& <Route path="feedbacks" element={<FeedbackList />} />}


			{ ( 
				orginfo.docs.operator || orginfo.docs.validator 
				|| orginfo.docs.printer || orginfo.docs.secretary
				|| uinfo.userAreas.sysop
				)
			&& <Route path="statistics" element={<StatisticsList/>} />}

			{ ( 
				orginfo.registry
				|| uinfo.userAreas.sysop
				)
				&& <Route path="search" element={<IssuedSearchPage/>} />}

  	  		<Route index element={<>
  	  				<div
  	  					style={{
  	  						margin: "5em auto 1em auto"
  	  						, textAlign: "center"
  	  						, fontSize: "40pt"
  	  						, fontWeight: "bold"
  	  						, color: "#3C5773"

  	  					}}
  	  				>
  	  					ДОБРО ПОЖАЛОВАТЬ
  	  				</div>
  	  		</>} />
	  	 	<Route path="*" element="404" />
	</Routes>
}

let orginfo_cache = {} //FIXME: why do we need it?

export function OrgPage() {
	const [clickSignMenu, setClickSignMenu] = useState(false);
	const [clickSignLink, setClickSignLink] = useState(false);
	let uinfo = useContext(UserInfoContext);
	let params = useTypedParams();
	let org = params.Org;
	let orgDbX = shardedDbX(org);
	if(uinfo.orgs.every(i=>i.tid !== org)
		&& 
		!uinfo.userAreas.sysop
	) 
		return <div
					style={{
						margin: "5em auto 1em auto"
						, textAlign: "center"
						, fontSize: "40pt"
						, fontWeight: "bold"
						, color: "#3C5773"

					}}
			>Нет такой организации
		</div>;
	return <Async key={org} value={
				orginfo_cache[org] ?? (orginfo_cache[org] =
				orgDbX.fetch_get_as_json('/app/common/org-info', {org})
				.then(e=>e && ({
					...e
        , orgtree_doctypes: JSON.parse(e.orgtree_doctypes||'[]')
        , orgtree_docforms: JSON.parse(e.orgtree_docforms||'[]')
        , orgtree_ancestors: JSON.parse(e.orgtree_ancestors||'[]')
        , orgtree_groups: JSON.parse(e.orgtree_groups||'[]')
        , orgtree_childs: JSON.parse(e.orgtree_childs||'[]')
        , orgtree_signers: JSON.parse(e.orgtree_signers||'[]')
        , app_ed_level: e.app_ed_level? JSON.parse(e.app_ed_level) : {}
        , app_spec_list: e.app_spec_list? JSON.parse(e.app_spec_list) : {}
        , app_custom_signers: e.app_custom_signers? JSON.parse(e.app_custom_signers) : []
        , docs: {}
				}))
				)
	}>{orginfo=>{
		if(!orginfo) return <div>Нет такой организации</div>

		orginfo.DbX = orgDbX; 

		let orgX = uinfo.orgs?.find(e=>e.tid === orginfo.tid)

		if(orgX) {
			orginfo.m = orgX.m
			orginfo.tuner = orgX.tuner
			orginfo.registry = orgX.registry
			orginfo.doc_scope_new = orgX.doc_scope_new

			orginfo.docs.operator = orginfo.doc_scope_new && 'operator' in orginfo.doc_scope_new;
			orginfo.docs.validator = orginfo.doc_scope_new && 'validator' in orginfo.doc_scope_new;
			orginfo.docs.printer = orginfo.doc_scope_new && 'printer' in orginfo.doc_scope_new;
			orginfo.docs.secretary = orginfo.doc_scope_new && 'secretary' in orginfo.doc_scope_new;
			orginfo.docs.signer = orginfo.doc_scope_new && 'signer' in orginfo.doc_scope_new;

			orginfo.docs.some = 
						 orginfo.docs.operator
					|| orginfo.docs.validator 
					|| orginfo.docs.printer
					|| orginfo.docs.secretary
					|| orginfo.docs.signer;
		}

		let school = false;
		let def = false;
		for(const i in orginfo.app_ed_level)
			if(i === 'mn' || i === 'm') school = true;
			else def = true;
		const terms = 
			school&&def ? terminology.both
			: school ? terminology.school 
			: terminology.def
		orginfo.terms = terms;
		orginfo.school = school && def? null : school;

		orginfo.terms_by_level = (ed_level) =>
			!ed_level? terminology.both
			: ed_level === 'mn' || ed_level === 'm'? terminology.school
			: terminology.def

		orginfo.group_info = (gcode) =>
			orginfo.orgtree_groups.find(g=>g.gcode===gcode)


		return <>
			<PageTitle
				title={<span>{orginfo.sname ?? orginfo.orgtree_full_name}</span>}
				doctitle={orginfo.sname ?? orginfo.orgtree_full_name}
			/>
			<PageSettings
				settings={(uinfo.userAreas.sysop || orginfo.tuner || orginfo.m || orginfo.registry || orginfo.docs.some) &&
					<PopupMenu trigger={<Gear alt="Управление"
											  title="Управление"
											  style={{height:"1em"
												  , verticalAlign:"base-line"
												  , marginLeft: "auto"
											  }}
											/>}
							   bindX="right"
					>
						{((uinfo.userAreas.sysop || uinfo.userAreas.admin) && orginfo.orgtree_ancestors.length > 0)
							&& <p><MenuLink className="menuLink" to={`/org/${orginfo.orgtree_ancestors.at(-1).ancestor_tid}`}>Перейти в основную организацию</MenuLink></p>}
						{(uinfo.userAreas.sysop || orginfo.m || orginfo.registry || orginfo.tuner || orginfo.docs.some)
							&& <p><MenuLink className="menuLink" to='orginfo'>Данные организации</MenuLink></p>}
						{(uinfo.userAreas.sysop || orginfo.tuner) && <p><MenuLink className="menuLink" to='orgstruct'>Структура организации</MenuLink></p>}
						{(uinfo.userAreas.sysop || orginfo.m || orginfo.tuner) && <p><MenuLink className="menuLink" to='members'>Пользователи</MenuLink></p>}
						{(uinfo.userAreas.sysop || orginfo.tuner) && <p><MenuLink className="menuLink" to='templates'>Формы документов</MenuLink></p>}
						{(uinfo.userAreas.sysop || orginfo.tuner) && <p><MenuLink className="menuLink" to='forms'>Бланки документов</MenuLink></p>}
						{/* {(uinfo.userAreas.sysop || orginfo.docs.secretary) && 
						<p><ModalButton disabled={!orginfo.docs.secretary && !uinfo.userAreas.admin} className="modalButWithBackground" onClick={async ()=> {
							await exportDocSign(orginfo)
						}}>
							Экспорт документов на подпись</ModalButton></p>} */}
					</PopupMenu>
				}
			/>
			<PageMenu>
				{(uinfo.userAreas.sysop || orginfo.docs.operator || orginfo.docs.validator)
					&& <NavLink to='contingent/cards' onClick={()=>{setClickSignLink(false)}}><Img_students alt="обучающиеся"
					/><p>{terms.students}</p></NavLink>}
				{(uinfo.userAreas.sysop || orginfo.docs.operator || orginfo.docs.validator)
					&& <NavLink to="contingent/groups" onClick={()=>{setClickSignLink(false)}}><Img_groups alt="группы"
					/><p>{terms.groups}</p></NavLink>}
				{(uinfo.userAreas.sysop || orginfo.docs.operator || orginfo.docs.validator || orginfo.docs.printer || orginfo.docs.secretary)
					&& <NavLink to='docs' onClick={()=>{setClickSignLink(false)}}><Img_docs alt="документы"
					/><p>Документы</p></NavLink>}
				{(uinfo.userAreas.sysop || orginfo.docs.operator) && <NavLink to='import' onClick={()=>{setClickSignLink(false)}}><Img_org_import alt="импорт"
					/><p>Импорт</p></NavLink>}
				{(uinfo.userAreas.sysop || orginfo.docs.signer) && <div
					className={`signMenu ${clickSignMenu ? 'openSubMenu' : ''} ${clickSignLink ? 'activeLink' : ''}`}
					onMouseLeave={()=>{setClickSignMenu(false)}}
					onClick={()=>{setClickSignMenu(true)}}
				>
					<Img_sign alt="подпись"/>
					<p>Подпись</p>
					<div className="subMenu">
						<NavLink onClick={()=>{setClickSignLink(true)}} to='sign'>Подписание документов</NavLink>
						<Img_line className='Img_line'/>
						<NavLink onClick={()=>{setClickSignLink(true)}} to='signRoles'>Подтверждение ролей</NavLink>
					</div>
				</div>
				}
				{(uinfo.userAreas.sysop || orginfo.docs.printer) && <NavLink to='print' onClick={()=>{setClickSignLink(false)}}><Img_print alt="печать"
				/><p>Печать</p></NavLink>}

				{(uinfo.userAreas.sysop || orginfo.docs.some || orginfo.registry === true) && <NavLink to='issued' onClick={()=>{setClickSignLink(false)}}><Img_issued alt="реестр"
				/><p>Реестр</p></NavLink>}

				{(uinfo.userAreas.sysop || orginfo.registry === true) && <NavLink to='feedbacks' onClick={()=>{setClickSignLink(false)}}><Img_feedbacks alt="обращения"
				/><p>Обращения</p></NavLink>}

				{(uinfo.userAreas.sysop || orginfo.docs.operator || orginfo.docs.validator || orginfo.docs.printer || orginfo.docs.secretary)
					&& <NavLink to='statistics' onClick={()=>{setClickSignLink(false)}}><Img_statics
													 alt="статистика"
					/><p>Статистика</p></NavLink>}
			</PageMenu>

			<OrgInfoContext.Provider value={orginfo}>
				<Outlet />
				<OrgRoutes />
			</OrgInfoContext.Provider>
		</>
	}}</Async>
}
