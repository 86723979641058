import React, {useState, useRef, useContext, useEffect} from 'react';

import {localStorageGet,localStorageSet} from './local_storage'

import {useOnce} from  './ui_helpers'

const FilterContext = React.createContext(null)


/*
 has two finction
 	1) setNamed: name, value/condition ==> store with name
 	2) setCurrent: oldCondition, newCondition ==>store in current condition 

*/

export function loadFilterSavedState(store) {
	const name = store.store
	const tid = store.Db.tid ?? '-'
	let s = JSON.parse(localStorageGet(tid+':list-filters:'+name)||'{}')
	return {
			saveLeaf: (n,v) => {
				if(n) {
					s[n] = v
					localStorageSet(tid+':list-filters:'+name,
						JSON.stringify(s))
				}
			}
			, loadLeaf: (n) => (s[n] ?? '')
			, currentState: () => s
		}
}


let num = 0;
const useCondition = (ctx) => { 
	let r = useOnce(()=>++num)

	return (name, new_cond, idx) =>{
		if(name) {
			ctx.setNamed(name, new_cond)
		} else {
			ctx.setCurrent(r*1000+(idx??0), new_cond)
		}
	}
}

const useConditionLeaf = (ctx) => { 
	const setCondition = useCondition(ctx)
	return (condition, value, idx) => {
		if(typeof condition ==='string') {
			// named
			//set named value in context
			setCondition(condition, value, idx)
		} else {
			// direct
			let new_cond = value? condition(value) : null 
			setCondition(null, new_cond, idx)
		}
	}
}

export default function Filter({Element, condition, saveAs, changeFilter, ...props}) {
	const ctx = useContext(FilterContext)
	let setCondition = useConditionLeaf(ctx)

	let [value, setValue] = useState(()=>ctx.leafSaver?.loadLeaf(saveAs))

	const helpers = {
		value
		, onChange: (e) => {
			setValue(e.target.value)
			ctx.leafSaver?.saveLeaf(saveAs, e.target.value)
			if (changeFilter)
				changeFilter(e.target.value)
		}
	}

	useEffect(()=>{
			setCondition(condition, value) 
			if (changeFilter)
				changeFilter(value)
	}, [condition, value])

	return React.cloneElement(Element??<input/>, {...helpers, ...props}) 
}

function Check({Element, value, uncheckedValue, condition, saveAs, ...props}) {
	const ctx = useContext(FilterContext)
	let setCondition = useConditionLeaf(ctx)

	let [checked, setChecked] = useState(()=>ctx.leafSaver?.loadLeaf(saveAs))

	const helpers = {
		checked
		, value
		, onChange: (e) => {
			setChecked(e.target.checked)
			ctx.leafSaver?.saveLeaf(saveAs, e.target.checked)
		}
	}

	useEffect(()=>{
			setCondition(condition, checked? value?? true: uncheckedValue??null) 
	}, [condition, checked, value])

	return React.cloneElement(Element??<input type="checkbox"/>, {...helpers, ...props}) 
}
Filter.Check = Check


function Multi({Element, extract, saveAs, ...props}) {
	const ctx = useContext(FilterContext)
	let setCondition = useConditionLeaf(ctx)

	let [value, setValue] = useState(()=>ctx.leafSaver?.loadLeaf(saveAs))

	let applyValue = value =>
			extract.forEach(([extractor, condition, feedback], i)=>{
				let val = null
				if(extractor instanceof RegExp) {
					if(value.match(extractor)) {
						val = RegExp.$1||RegExp.$2
						value = value.replace(extractor,'')
					}
				} else {
					//function
					let [found, rest] = extractor(value, props.minlength? props.minlength : 1)
					val = found
					value = rest
				}
				setCondition(condition, val, i)
				feedback?.(val)
			})

	const helpers = {
		value
		, onChange: (e) => {
			setValue(e.target.value)
			ctx.leafSaver?.saveLeaf(saveAs, e.target.value)
		}
	}

	useEffect(()=>{
		applyValue(value)
	}, [extract, value])

	return React.cloneElement(Element??<input/>, {...helpers, ...props})
}

Filter.Multi = Multi

export function and(arr) {
	return row => arr.filter(c=>c instanceof Function).every(c=>c(row))
}
export function or(arr) {
	return row => arr.length ? arr.filter(c=>c instanceof Function).some(c=>c(row)) : row
}

/**
 * HOC: if currentProcessor provided, use it when combine children
 * if setCondition set => use root processing, i.e.  produce one value
 * root object do not nave name
 * named conditions should be reduced to unnamed inside child procession
 * i.e. with explicit 'Combine' combinator
 * all uncatched names ignored at root!
 */
function withCombinator(currentProcessor) {
	return function({name, names, condition
		, onConditionChange
		, onConditionInit
		, leafSaver, children}) {
		const ctx = useContext(FilterContext) //create new context
		let setCondition = useCondition(ctx)

		let lastCond = useRef(null)

		let unnamed = useRef(new Map())
		let named = useRef(new Map())
		let combine = {
			leafSaver: leafSaver ?? ctx.leafSaver
			, setNamed: condition?
				(name,val) => {
					if(names && !names.find(name))
						return ctx.setNamed(name,val) // pass unknown names to parent
					if(val)
						named.current.set(name, val) //collect named HERE!
					else
						named.current.delete(name)
					//changed
					let obj = Object.fromEntries(named.current.entries())
					let cond = condition(obj)
					if(!onConditionChange) //ignore unprocessed names!!!!
						setCondition(name, cond)
				}
				: ctx?.setNamed //translate name to parent diretly
			, setCurrent: 
				currentProcessor?
					(idx,next) => {
					if(next)
						unnamed.current.set(idx, next)
					else
						unnamed.current.delete(idx)
					//console.log(idx, next, unnamed.current)
					let cond = currentProcessor(Array.from(unnamed.current.values()))
					//catch condition, if prop set
					if(onConditionInit)
						lastCond.current = cond;
					else if(onConditionChange) //report only when UI change
						onConditionChange(cond)
					else setCondition(name, cond)
				}
				: ctx?.setCurrent //pass direct condition to parent
		} 

		useEffect(()=>{
			if(onConditionInit) //rendered
				onConditionInit(lastCond.current)
		})
		return <FilterContext.Provider value={combine} children={children} />
	}
}

Filter.And = withCombinator(and)
Filter.Or = withCombinator(or)

/**
 * generate current condition from named
 */
Filter.Combine = withCombinator()

/**
 * this is root filter, it give applicable function
 * all current conditionsL from filter at top and column filters
 * combined with 'and'
 */
Filter.Root = withCombinator(and)


