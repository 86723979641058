

export function seat_code() {
	let seat = localStorage.getItem('seat')
	if(!seat) {
		const u8 = new Uint8Array(32);
		window.crypto.getRandomValues(u8);
		seat = btoa(String.fromCharCode.apply(null, u8))
			.replace(/[+]/g,'-')
			.replace(/[/]/g,'_')
			.replace(/[=]/g,'')
		localStorage.setItem('seat', seat)
	}
	return seat
}

export function localStorageGet(key) {
	return localStorage.getItem(`${window.UserInfo.personid}:${key}`)
}

export function localStorageSet(key,value) {
	return localStorage.setItem(`${window.UserInfo.personid}:${key}`,value)
}

