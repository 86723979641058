import React, {useContext, useRef} from 'react';
import {Link} from "react-router-dom";
import {
	DbFormik
	, LabeledField, LabeledOutput
	, ModelField
	, Calculate, FormValues
	, StatusDriver, statusMessageBig
	, modalFormik
	, cancelLocalEdit
	, IfModified
} from 'azlib/components/formik-fields'
import { confirm } from 'azlib/components/modals'

import {XLOG, letIn} from 'azlib/components/helpers'
import {useTypedParams} from 'azlib/components/router_helpers'
import {fullPersonName} from 'azlib/components/name_funcs'
import {Async} from 'azlib/components/async'
import { promptBig, alert} from 'azlib/components/modals'
import {localISOnow} from 'azlib/components/calendar'
import { Formik, Form, Field } from 'formik'
import { object, string, date, number, array } from 'yup';


import {in_local_cache} from 'azlib/components/db'

import {ModelDecode} from 'azlib/components/std-controls'

import {OrgInfoContext} from 'org/org'

import { formatLocalDate } from 'azlib/components/calendar'

import {InfosetEditor} from './infoset_edit'

import {internal_fields, calculate_internal, is_gov_doc} from './params_descr'

import {StatusHistory} from 'contingent/status_history'

import {showDocFeedbacks} from 'docs/feedback'

import UserInfoContext from 'UserInfo'

import {
	ShowLocker,
	lockToEdit,
	cancelLock,
	clearLockerFields,
	alien_locked,
	saveIfOwnedOrFree
} from 'config/row_locker'

import {FeedbackButton, NumberCol, ShowPrintedDocs, StatusCol, Header} from './issued_op'

import {CertDiv} from 'sign/cert'
import css from "./infoset_edit.module.css";


async function setSigner(orginfo, kind, signer_roles, replaced) {
	let signers = 
					(signer_roles && JSON.parse(signer_roles))
					|| window.AppBundle.data.signers_scheme.decode[kind]
					|| [ 'Руководитель образовательной организации' ]

	if(!Array.isArray(signers)) signers =  Object.keys(signers)

	if (signers.length == 0) signers = [ 'Руководитель образовательной организации' ]

	// if the doc replaced another we take LAST signer only

	if(replaced)
		signers = [ 'Руководитель образовательной организации' ]
	let defs = {}
	let scheme = {}
	for(const s of signers){
		defs[s] = orginfo.orgtree_signers.find(
			    		s=>+s.person===+window.localStorage['default-signer-'+orginfo.tid+'-'+s] 
			    	)?.person ?? ''
		scheme[s] = number().required()
	}

	return await modalFormik({
			initialValues:defs
			, validationSchema:object(scheme)
			, output: v=>{
				let ret = []
				for(const s of signers){
					window.localStorage['default-signer-'+orginfo.tid+'-'+s] = v[s];
					ret.push({
						c:'1000'
						, id: v[s]
						, r: s
					})
				}
				if(signers.length === 0){
					alert('Ошибка, не выбран подписант!')
					return
				}
				return {signers: JSON.stringify(ret)}
			}
		},
		<>
			<label>{signers.length>1?'Подписывают':'Подписывает'}</label>
			<hr/>
			{signers.map(s=>
				<div key={s}>
					<label>{s}</label>
					<Field name={s} as="select" autoComplete="off" style={{marginTop:"1em"}}>
					<option key="" value={null}/>
					{
						orginfo.orgtree_signers
						.filter(e=>e.position !== null && e.sign_scope !== null)
						.filter(e=>!e.sign_scope //if nothing checked, use everywhere
							|| e.sign_scope[kind]
								&& s in e.sign_scope[kind]
						)
						.map(e=><option key={e.person} value={e.person}>
								{`${e.last_name} ${e.first_name} ${e.middle_name}`}
							</option>)
					}
					</Field>
				</div>
			)}
			<hr/>
			<div>
				<button type="submit">На подпись</button>
			</div>
		</>
	,{style: {width:"30em"}, closeBox:true})
}

export function DocForm(props) {
	const orginfo = useContext(OrgInfoContext);
	const uinfo = useContext(UserInfoContext);
	let masterKeyRef = useRef(null)
	let urlRef = useRef(null)

	const params = useTypedParams();
	const statusMap = row =>{
		let doc_statuses_manual = {}
		let doc_statuses_auto = {}
		if(orginfo.docs.operator || uinfo.userAreas.sysop || uinfo.userAreas.admin) {
			doc_statuses_manual['doc-ready'] = [
							{ to: 'doc-check'
	 						  , text: "На проверку"
	 						  , prepare: () => is_gov_doc(row, orginfo)
	 						  				? { gov_doc: 1 } : {} 
							  , reload: ['signed']
							  , readOnly: !orginfo.docs.operator
							}, 
						]
			doc_statuses_manual['doc-fill'] = [ 
						  { to: 'doc-check'
							, text: "На проверку"
							, prepareSave: saveIfOwnedOrFree
	 						, prepare: () => is_gov_doc(row, orginfo)
	 						  				? { gov_doc: 1 } : {} 
							, reload: ['signed']
							, readOnly: !orginfo.docs.operator
						  }
						]
		}
		if(orginfo.docs.validator || uinfo.userAreas.sysop || uinfo.userAreas.admin) {
			doc_statuses_manual['doc-check'] = [
							{to:'doc-fill'
							  , text: 'Вернуть'
							  , reload: ['signed']
							  , prepare: () => statusMessageBig('Комментарий')
							  , readOnly: !orginfo.docs.validator
							},
							{to:'doc-sign'
							  , text: 'На подпись'
							  , reload: ['signed','our_number', 'our_date', 'signers']
							  , prepare: () => setSigner(orginfo, row.doctype_kind
							  							, row.doctype_used$.signer_roles, row.replaced)
							  , readOnly: !orginfo.docs.validator
							}
						]
			doc_statuses_auto['doc-check'] = [
							{to:'doc-sign'
							  , text: 'На подпись'
							  , reload: ['signed','our_number', 'our_date', 'signers']
							  , prepare: () => setSigner(orginfo, row.doctype_kind
							  							, row.doctype_used$.signer_roles, row.replaced)
							  , readOnly: !orginfo.docs.validator
							}
						]
		}
		return row.doctype_used?doc_statuses_manual:doc_statuses_auto;
	}


	return (
	<DbFormik
		store={orginfo.DbX.store(props.issued? 'issued' : 'docs_preparing'
			, { docid: params.Docid })} 
		rowKey={[params.Docid]}
		extra={internal_fields}
		handleSave={async (values,changed,fc, ...rest)=>{
			if(fc.status.skipLocalSave) {
				// not a real data editing here
				DbFormik.localSave(values,changed,fc, ...rest)
				return;
			}
			if(alien_locked(values)) {
				// ignore if alien_locked
				return;
			}
			if(changed) {
				console.log('try start edit')
				let locker = values.row_locker? true
							:  await lockToEdit(fc)
				if(locker){
					// locked
					await DbFormik.localSave(
						{ ...values, ...locker} 
						,changed,fc, ...rest
					)
				} else {
					cancelLocalEdit(fc)
				}
			}
		}}
		onSaving={(values)=>{
			console.log('saving')
			if(values.row_locker) {
				values = clearLockerFields(values)
			}
			return values
		}}
	><FormValues>{(row,fc)=>
		letIn(alien_locked(row))(fastDoNotEdit=> 
		<>
		<Calculate extra="last_name first_name middle_name">{(row)=><>
		<h1>
				{
						fullPersonName(row)
				}
			<Link className="buttonBackground" to={props.issued? `/org/${orginfo.tid}/issued/cards/${row.docid}` :
																`/org/${orginfo.tid}/docs/cards/${row.docid}`}
			>Анкета</Link>
		</h1>
		</>}</Calculate>

		<ShowLocker show={orginfo.docs.operator || uinfo.userAreas.sysop || uinfo.userAreas.admin} readOnly={!orginfo.docs.operator}/>

		<IfModified fastDoNotEdit={alien_locked} superusers={uinfo.userAreas.admin || uinfo.userAreas.sysop}>
			<button type="button" style={{margin:"0 0 1em 2em"}} disabled={!orginfo.docs.operator}
				onClick={()=>cancelLocalEdit(fc,cancelLock)}
			>Отменить редактирование</button>
		</IfModified>

		<div className="templatesSearch backgroundAngular flexContainer">
		<h2>
		<Calculate name="doctype_kind"
				extra="doctype_name doctype_used{. typename signer_roles} our_date our_number"
		>{(kind, row)=>
				<>
					{
						<ModelDecode modelData="doc_kinds" value={kind} /> 
					}
					{' '}
					{row.our_number?<>
							<nobr> № {row.our_number}</nobr>
							<nobr>{row.our_date && ` от ${formatLocalDate(row.our_date)}`}</nobr>
								</> 
					: row.doctype_used$.typename}
				</>
		}</Calculate>
		</h2>
		<Calculate name="doctype_used">{(doctype, row)=>
			orginfo.docs.operator
			&& (row.status === 'doc-ready' || row.status==='doc-fill')
			&& <Async value={in_local_cache('doc_types',[doctype])}
				fallback="">{v=>
				v && <div className="error">
					Форма этого документа в настоящее время редактируется вами же
				</div>}
			</Async>
		}</Calculate>
		<Calculate name="status" extra="status_message">{(st,row)=><>
			<h3>Статус: <ModelDecode modelData="doc_status" value={st} /></h3>
			{row.status_message?.trim()?
					<h3>
						{row.status_message}
					</h3>
			:null
			}
			<div
				style={{marginLeft:"auto"}}
			>
				<StatusHistory docid={row.docid}
							   bindX="right"
				/>
			</div>
		</>}</Calculate>
		</div>
		<div className="templatesSearch">
			{(row.status === 'doc-ready' || row.status === 'doc-fill')
			}

			{
				row.doctype_infoset &&
				(row.status === 'doc-ready' || row.status === 'doc-fill') ?
				<LabeledField label="Набор сведений"
					name="infoset"
					extra="doctype_infoset signed"
					doc_kind={row.doctype_kind}
					template={row.doctype_infoset}
					as={InfosetEditor}
					readOnly={!orginfo.docs.operator || fastDoNotEdit}
				/> 
				: <LabeledOutput label="Набор сведений" name="signed"
					extra="doctype_infoset infoset"
					children={v=>
						(v)?
						<pre
							style={{
								margin:"2em auto"
								, padding: "1em"
								, background: "aliceblue"
								, overflow: "auto"
								, width: "fit-content"
							}}
						>
							{v}
						</pre>
						: 
						row.doctype_infoset?
							<LabeledField
								name="infoset"
								extra="doctype_infoset signed"
								doc_kind={row.doctype_kind}
								template={row.doctype_infoset}
								as={InfosetEditor}
								readOnly={true}
							/> 
						:
							<div className="error">
								Не выбрана форма документа
							</div>
					}
					/>
			}

			<Calculate name="signers" extra="signatures">{(s,row)=><>
				<h2>Подписи</h2>
				<div style={{display:"flex"}}>{
				letIn(JSON.parse(s||'[]'))
				(s=>
					letIn(JSON.parse(row.signatures||'{}'))
					(signs=>
						s.length? s.map((s,i)=><div key={i} style={{border:"1px solid",padding:5}}>
						{s.i[0]??'-'}:{' '}
						{s.i[1]??'-'}{' '}{s.i[2]??'-'}{' '}{s.i[3]??'-'}
						{signs[s.id] && <CertDiv sign={signs[s.id]}/>}
					</div>
					)
					: 'нет'
					)
				)
				}</div>
			</>}</Calculate>
			{row.status?.startsWith('issued-') &&
				<h2 style={{marginTop:"1em"}}>
					Документ сформирован в {!orginfo?.toplevel ?
					(orginfo?.is_branch ? 'филиале' : 'элементe структуры')
					: 'основной организации'}
				</h2>
			}
			<div className={css.InfosetEditor + " flexContainer"} style={{marginTop:"1em"}}>
			{(orginfo.docs.operator || orginfo.docs.validator || uinfo.userAreas.sysop || uinfo.userAreas.admin) &&
				<StatusDriver name="status" map={statusMap(row)} />
			}
			<Calculate name="status">{status=>
			// ограничение в статусе "Проверка", если есть форма документа (сказали пока не надо это)
			// ((orginfo.docs.operator || uinfo.userAreas.sysop || uinfo.userAreas.admin) && (status === 'doc-fill' || status === 'doc-ready')
			// || (orginfo.docs.validator  || uinfo.userAreas.sysop || uinfo.userAreas.admin) && status === 'doc-check' && !!row.doctype_infoset)
			// &&
				(uinfo.userAreas.sysop && (status === 'doc-fill' || status === 'doc-ready' || status === 'doc-check')
				|| orginfo.docs.operator && (status === 'doc-fill' || status === 'doc-ready')
				|| orginfo.docs.validator && status === 'doc-check')
				&&
				<button disabled={!orginfo.docs.operator && !orginfo.docs.validator} 
					type="button"  className="statusDriver"
					onClick={async ()=>{
						let msg = await promptBig('Комментарий')

						let vals = { docid: row.docid //key
									, status: ''
									, status_prev: status
									, status_message: msg
									}
			
						//save to db (also clear locally saved values)
						await orginfo.DbX.save_form('docs_preparing', null, vals) //use all fields in vals
						//set local values
						await cancelLocalEdit(fc,cancelLock);
						await fc.setFormikState(prev=>
					      ({...prev
					      , status: {...prev.status, skipLocalSave: true}
					      , values: {...prev.values, ...vals}
					      })
						)
					}}
				>Вернуть на заполнение анкеты</button>
			}</Calculate>
			{row.status === 'doc-sign' &&
				<>{
				(orginfo.docs.operator || orginfo.docs.validator || uinfo.userAreas.sysop)
				&& <button disabled={!orginfo.docs.operator && !orginfo.docs.validator}
						   type="button" className="statusDriver"
						   onClick={async () => {
							let msg = await promptBig('Комментарий')
							   let vals = {
								   docid: row.docid
								   , signers: ''
								   , status_prev: row.status
								   , status: 'doc-check'
								   , status_message: msg
							   }

							   //save to db (also clear locally saved values)
							   await orginfo.DbX.save_form('docs_preparing', null, vals)

							   //set local values
							   await fc.setFormikState(prev=>
								   ({...prev
									   , status: {...prev.status, skipLocalSave: true}
									   , values: {...prev.values, ...vals}
								   })
							   )
						   }}
				>
					Забрать с подписи
				</button>
			}
			{
				(orginfo.docs.secretary || uinfo.userAreas.sysop )
				&& <button disabled={!orginfo.docs.secretary}
						   type="button" className="statusDriver"
						   onClick={async () => {
							   let vals = { docid: fc.values.docid //key
								   , cito: fc.values.cito? '' : 1
							   }
							   //save to db (also clear locally saved values)
							   await orginfo.DbX.save_form('docs_preparing', null, vals) //use all fields in vals
							   //set local values
							   await fc.setFormikState(prev=>
								   ({...prev
									   , status: {...prev.status, skipLocalSave: true}
									   , values: {...prev.values, ...vals}
								   })
							   )
						   }}
				>
					{fc.values.cito?'В несрочные':'В срочные'}
				</button>
			}</>}
			{
				row.status?.startsWith('doc-')
				&& (orginfo.docs.operator || orginfo.docs.validator || orginfo.docs.printer || orginfo.docs.secretary || uinfo.userAreas.sysop)
				&& row.status !== 'doc-fill'
				&& <button type="button" className="statusDriver"
				onClick={async ()=>{
					let {preview_infoset} = await import('genpdf/pdf-gen')
					preview_infoset(row).open()
				}}>Предпросмотр документа</button>
			}
			{
				row.status?.startsWith('issued-') && !!row.feedbacks_count
				&& <button type="button"  className="statusDriver info"
				onClick={async ()=>{
					await showDocFeedbacks(orginfo, row.docid)
				}}>обращения ({row.feedbacks_count})</button>
			}
			{ (orginfo.registry || uinfo.userAreas.sysop) 
					&& row.status === 'issued-ok' &&
					<StatusCol docid={row.docid} snils={row.snils} orginfo={orginfo} readOnly={!orginfo.registry}
						form
					/>
			}
			{ (orginfo.docs.printer || uinfo.userAreas.sysop)
					&& row.status === 'issued-ok' &&
					<NumberCol docid={row.docid} doctype_kind={row.doctype_kind} orginfo={orginfo} readOnly={!orginfo.docs.printer}
						header={<Header stud={row} regnumb/>}
						form
					/>
			}
			{ row.status?.startsWith('issued-') &&
				(orginfo.registry || uinfo.userAreas.sysop)
					&&
					<FeedbackButton orginfo={orginfo} 
						docid={row.docid} 
						header={<Header stud={row} regnumb/>}
					/>
			}

			</div>

		{(orginfo.registry || orginfo.docs.printer || uinfo.userAreas.sysop) &&
            <Calculate extra="master_key">
                { row.status?.startsWith('issued-')
                    && <>
                        Код доступа:{' '}
                        <input readOnly style={{margin:"0.5em 0"}}
                            name="master_key"
                            value={row.master_key}
                            ref={masterKeyRef}
                        />
                        <div className="flexContainer">
                            <button type="button" disabled={!orginfo.registry && !orginfo.docs.printer}
                                onClick={()=>{
                                    masterKeyRef.current.focus()
                                    masterKeyRef.current.select()
                                    document.execCommand('copy')
                                    alert('Код скопирован в буфер обмена')
                                }}
                                >Скопировать код
                            </button>

                            <button type="button" disabled={!orginfo.registry && !orginfo.docs.printer}
                                onClick={async ()=>{
                                    await confirm("Перегенирировать код?")
                                    alert("Код будет перегенирован в течение ...")
                                    await orginfo.DbX.save_form('docs', null, {
                                            docid: row.docid
                                            ,master_key_stamp: localISOnow()})
                                }}
                            >Перегенерировать код
                            </button>
                        </div>
                    </>
                }
            </Calculate>
		}

		{(orginfo.registry || orginfo.docs.printer || uinfo.userAreas.sysop) &&
            <Calculate extra="url">
                { row.status?.startsWith('issued-')
                    &&
                    <>
                        URL:{' '}
                        <input readOnly style={{margin:"0.5em 0"}}
                            name="url"
                            value={"https://mycdo.obrnadzor.gov.ru/doc/"+row.url}
                            ref={urlRef}
                        />
                        <div className="flexContainer">
                        <button type="button" disabled={!orginfo.registry && !orginfo.docs.printer}
                            onClick={()=>{
                                urlRef.current.focus()
                                urlRef.current.select()
                                document.execCommand('copy')
                                alert('URL скопирован в буфер обмена')
                            }}
                            >Скопировать URL
                        </button>
                        </div>
                    </>
                }
            </Calculate>
		}

			<div className={css.InfosetEditor + " flexContainer"} style={{marginTop:"1em"}}>
			<Calculate name="infoset">{iset=>
				(orginfo.docs.operator || uinfo.userAreas.sysop) && row.status==='doc-fill' &&
				<button type="button" className="statusDriver danger" disabled={!orginfo.docs.operator}
						onClick={()=> {
							fc.setFieldValue('infoset','')
						}}>Стереть данные</button>
			}</Calculate></div>
			{
				row.status?.startsWith('issued-')
				&&<>
					<h2>Печатные варианты</h2>
					<ShowPrintedDocs kind={row.doctype_kind} docid={row.docid} orginfo={orginfo} />
				</>
			}
		</div>
	</>)}</FormValues>
	</DbFormik>)
}

