import * as yup from 'yup'
import {ed_regions, edRegionsObj} from "../../config/regions";
import {countryCodeObj} from "../../config/oksm";

export * from 'yup'


function dateIsValid(dateStr) {
	const date = new Date(dateStr);
	return date.toISOString().startsWith(dateStr);
}

export const string_date = () => yup.string()
				.transform(value=>{
					if(!value) return value;
					if(value.match(/\d{4}-\d{2}-\d{2}/) && dateIsValid(value)) return value;
					if(value.match(/(\d{1,2})[.](\d{1,2})[.](\d{4})/)){
						value = `${RegExp.$3}-${RegExp.$2.padStart('0')}-${RegExp.$1.padStart('0')}`
						if(dateIsValid(value)) return value;
					}
					return false;
				})

export const string_bool = () => yup.number()
				.transform((_,v)=>(
						{
							'ДА':1, 'НЕТ':null
							, 'YES':1, 'NO':null
							, '': null
						}
					)[v?.toUpperCase()])
				.nullable()
				.min(1).max(1)

export const string_model = (modelData) => yup.string()
	.transform((_,v)=>(window.window.AppBundle.data[modelData].decode)[v?.charAt(0).toUpperCase() + v?.slice(1)]??null)

export const string_multy_model = (modelData) => yup.object()
	.transform((_,v)=>v.split(/\s\|\s|\s\||\|\s|\|/).reduce(function(acc, curr, i, arr) {
		if ((window.window.AppBundle.data[modelData].decode)[curr?.charAt(0).toUpperCase() + curr?.slice(1)])
			acc[(window.window.AppBundle.data[modelData].decode)[curr?.charAt(0).toUpperCase() + curr?.slice(1)]] = "";
		else if (arr == "") return {};
		else {
			arr = null;
			return null;
		}
		return acc;
}, {}))
export const region = () => yup.string()
	.transform((_,v)=>edRegionsObj()[v]??null)
export const country = () => yup.string()
	.transform((_,v)=>countryCodeObj()[v?.toUpperCase()]??null)
export const string_notempty = () => yup.string()
						.min(1)

