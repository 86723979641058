import React, {useState, useContext} from 'react';

import {
  Routes,
  Route,
  Link, NavLink,
  Navigate, Outlet
} from "react-router-dom"

import {getTimezone} from 'azlib/components/helpers'

import UserInfoContext from 'UserInfo'

import {titledName} from 'azlib/components/name_funcs'

import {PopupMenu, MenuLink} from 'azlib/components/modals'

import {GlobalPageAreas, PageTitle, PageMenu} from 'azlib/components/page-header'

import {PersonPage} from 'persons/person'
import {OrgPage} from 'org/org-routes'

import {SysOpCreateOrg} from 'sysop/create_org'
import {SysOpOpenOrg} from 'sysop/open_org'
import {SysOpOpenUser} from 'sysop/open_user'
import {SysUsers} from 'sysop/sys_users'

import {IssuedSearchPage} from 'docs/issued_search'

import {ImportIslod} from 'sysop/import_islod'

import {ViewRlic, ViewAcred} from 'org/orginfo'

import {FaqPage} from 'config/faq/faq-page'

import {esiaLogout} from 'esia/login'

import * as gitVersion from 'git-version';

import {ReactComponent as Question} from './question.svg'
import {ReactComponent as Img_admins} from "./app-img/admins.svg";
import {ReactComponent as Img_import} from "./app-img/import.svg";
import {ReactComponent as Img_operators} from "./app-img/operators.svg";
import {ReactComponent as Img_docsearch} from "./app-img/searchDoc.svg";
import {ReactComponent as Img_organization} from "./app-img/viewOrg.svg";
import {ReactComponent as Img_registration} from "./app-img/registration.svg";
import {ReactComponent as Img_logo} from "./app-img/logo.svg";
import {ReactComponent as Img_export} from "./app-img/export.svg";
import {ReactComponent as Img_departments} from "./app-img/departments.svg";
import {ReactComponent as Img_monitoring} from "./app-img/monitoring.svg";
import {ExportStatistics} from "./sysop/export_statistic";
import {Departments} from "./sysop/departments";
import {Monitoring} from "./monitoring";

const MainApp = () => {
	let uinfo = useContext(UserInfoContext);
	let [t,setT] = useState(null);
	let [m,setM] = useState(null);
	let [s,setS] = useState(null);
	//console.log(uinfo)
	return (<>
<GlobalPageAreas title={t} menu={m} settings={s}>
<header id="App-header">
 <Link to="/"><Img_logo id="App-logo" alt="logo" title='Главная страница'/></Link>
 <span id="TitlePrefix">ЦДО</span> <span id="PageTitle" ref={setT}/>
 <span id="uinfo">
 <PopupMenu trigger={
 		<span>{titledName(uinfo)}</span>
 	}
	bindX="right"
  >
	 <div className="userActions">
	<p>
			<MenuLink to="/person">Информация</MenuLink>
	</p>
	<p>
	    <button className="modalButWithBackground" onClick={async ()=>{
		  			await fetch('/logout')
	  				if(window.UserInfo.esia) {
	  					esiaLogout()
			  		}else
			  			window.open("/","_self")
				}}
		>
			Выход
		</button>
	</p>
		 </div>
 </PopupMenu>
 </span>
	<span id="settings" ref={setS} style={{marginRight:"0.7em"}}/>
	<span>
<PopupMenu trigger={<Question alt="Помощь"
		title="Техническая поддержка"
		style={{height:"1em"
				, verticalAlign:"base-line"
				, marginRight:"1em"
			}}
	/>}
	bindX="right"
>
	<MenuLink to="/faq">FAQ</MenuLink>
	<hr/>
	<p>Текущий часовой пояс:</p>
	<p>{getTimezone()}</p>
	<hr/>
	<p>ercdo@citis.ru </p>
	<hr/>
	<p>версия: <i>{gitVersion.numCommits}{gitVersion.dirty?'/D':''}</i></p>
</PopupMenu></span>
</header>
<nav id="App-menu" ref={setM}/>
<main id="App-main">
  	  <Routes>

  	  	<Route path="person" element={<PersonPage />} />
		<Route path="monitoring" element={<>
		<PageMenu>
			{(uinfo.userAreas.sysop) &&
				<NavLink to='/sysop/new-org'><Img_registration
					alt="Регистрация организации"
				/><p>Регистрация организации</p></NavLink>}
			{(uinfo.userAreas.sysop) &&
				<NavLink to='/sysop/open-org'><Img_organization
					alt="Просмотр организации"
				/><p>Просмотр организации</p></NavLink>}
			{(uinfo.userAreas.sysop) &&
				<NavLink to='/sysop/open-user'><Img_organization
					alt="Просмотр юзера"
				/><p>Просмотр юзера</p></NavLink>}
			{(uinfo.userAreas.sysop) &&
				<NavLink to='/sysop/search'><Img_docsearch
					alt="Поиск документа"
				/><p>Поиск документа</p></NavLink>}
			{uinfo.userAreas.admin &&
				<NavLink to='/sysop/sysops'><Img_operators
					alt="Операторы"
				/><p>Операторы</p></NavLink>}
			{uinfo.userAreas.admin &&
				<NavLink to='/sysop/admins'><Img_admins
					alt="Админы"
				/><p>Админы</p></NavLink>}
			{(uinfo.userAreas.admin) &&
				<NavLink to='/sysop/departments'><Img_departments
					alt="РОИВ/ФОИВ"
				/>РОИВ/ФОИВ</NavLink>}
			{(uinfo.userAreas.admin || uinfo.department) &&
				<NavLink to='/monitoring'><Img_monitoring
					alt="Мониторинг"
				/>Мониторинг</NavLink>}
			{uinfo.userAreas.admin &&
				<NavLink to='/sysop/import'><Img_import
					alt="Импорт"
				/><p>Импорт</p></NavLink>}
			{(uinfo.userAreas.admin || uinfo.userAreas.sysop) &&
				<NavLink to='/sysop/export_statistic'><Img_export
					alt="Экспорт статистики"
				/><p>Экспорт статистики</p></NavLink>}
		</PageMenu>
		{<Monitoring />}</>} />
  	  	<Route path="org/:Org/*" element={<OrgPage />} />

		  	<Route path="faq" element={<FaqPage/>} />

  	  	<Route index element={<>
  					<PageMenu>
						{(uinfo.userAreas.sysop) &&
								<NavLink to='/sysop/new-org'><Img_registration
																  alt="Регистрация организации"
								/><p>Регистрация организации</p></NavLink>}
						{(uinfo.userAreas.sysop) &&
								<NavLink to='/sysop/open-org'><Img_organization
																   alt="Просмотр организации"
								/><p>Просмотр организации</p></NavLink>}
						{(uinfo.userAreas.sysop) &&
								<NavLink to='/sysop/open-user'><Img_organization
																   alt="Просмотр юзера"
								/><p>Просмотр юзера</p></NavLink>}
						{(uinfo.userAreas.sysop) &&
								<NavLink to='/sysop/search'><Img_docsearch
																 alt="Поиск документа"
								/><p>Поиск документа</p></NavLink>}
						{uinfo.userAreas.admin &&
									<NavLink to='/sysop/sysops'><Img_operators
																	 alt="Операторы"
									/><p>Операторы</p></NavLink>}
						{uinfo.userAreas.admin &&
									<NavLink to='/sysop/admins'><Img_admins
																	 alt="Админы"
									/><p>Админы</p></NavLink>}
						{(uinfo.userAreas.admin) &&
							<NavLink to='/sysop/departments'><Img_departments
							alt="РОИВ/ФОИВ"
						/>РОИВ/ФОИВ</NavLink>}
						{(uinfo.userAreas.admin || uinfo.department) &&
							<NavLink to='/monitoring'><Img_monitoring
							alt="Мониторинг"
						/>Мониторинг</NavLink>}
						{uinfo.userAreas.admin &&
									<NavLink to='/sysop/import'><Img_import
																	 alt="Импорт"
									/><p>Импорт</p></NavLink>}
						{(uinfo.userAreas.admin || uinfo.userAreas.sysop) &&
							<NavLink to='/sysop/export_statistic'><Img_export
								alt="Экспорт статистики"
							/><p>Экспорт статистики</p></NavLink>}
						</PageMenu>
					{
			  	uinfo.orgs.length === 0 ?
			  		<span> Нет доступных организаций </span>
			  	: uinfo.orgs.length === 1 && !uinfo.userAreas.admin && !uinfo.userAreas.sysop && !uinfo.department ?
			  		<Navigate to={`org/${uinfo.orgs[0].tid}`} replace={true} />
			  	: <>
			  	  <h1>Выбор организации</h1>
			  	  <div
			  	  	style={{
			  	  		margin:"2em auto 1em auto"
			  	  		, width: "30em"
			  	  	}}
			  	  >
			  	  {uinfo.orgs &&
			  	   uinfo.orgs.sort(
			  	  	(a,b)=> a.path<b.path?-1:a.path>b.path?1:0
			  	  )
			  	  .map(
			  		({tid,sname,orgtree_full_name, path}) =>
			  			<div key={tid}
			  			 className="OrgSelectItem"
			  			 path={path}
			  			 level={path.replace(/^\D+\d/,'').length/3}
			  			 style={{
							    paddingLeft: 1+(path.replace(/^\D+\d/,'').length/3*2)+"em"
			  			 		, background: "var(--button_background_color)"
			  			 }}
			  			><Link to={`org/${tid}`}>{sname || orgtree_full_name ||'---???---'}</Link></div>
			  	  )}
			  	  </div>
		  	    </>
		  	  }
	  	 	</>}
	  	 	/>
	  	 	{uinfo.userAreas.sysop && <Route path="sysop"
	  	 		element={<>
					  <PageTitle title={<span>Админинистрирование</span>} doctitle="Админинистрирование" />
						<PageMenu>
							<NavLink to='/sysop/new-org'><Img_registration
															  alt="Регистрация организации"
							/><p>Регистрация организации</p></NavLink>
							<NavLink to='/sysop/open-org'><Img_organization
															   alt="Просмотр организации"
							/><p>Просмотр организации</p></NavLink>
							<NavLink to='/sysop/open-user'><Img_organization
															   alt="Просмотр юзера"
							/><p>Просмотр юзера</p></NavLink>
							<NavLink to='/sysop/search'><Img_docsearch
															 alt="Поиск документа"
							/><p>Поиск документа</p></NavLink>
						{uinfo.userAreas.admin &&
							<NavLink to='/sysop/sysops'><Img_operators
															 alt="Операторы"
							/><p>Операторы</p></NavLink>}
						{uinfo.userAreas.admin &&
							<NavLink to='/sysop/admins'><Img_admins
															 alt="Админы"
							/><p>Админы</p></NavLink>}
							{uinfo.userAreas.admin && <NavLink to='/sysop/departments'><Img_departments
								alt="РОИВ/ФОИВ"
							/>РОИВ/ФОИВ</NavLink>}
							{(uinfo.userAreas.admin ||  uinfo.department) && <NavLink to='/monitoring'><Img_monitoring
								alt="Мониторинг"
							/>Мониторинг</NavLink>}
						{uinfo.userAreas.admin &&
							<NavLink to='/sysop/import'><Img_import
															 alt="Импорт"
							/><p>Импорт</p></NavLink>}
							<NavLink to='/sysop/export_statistic'><Img_export
								alt="Экспорт статистики"
							/><p>Экспорт статистики</p></NavLink>
						</PageMenu>
				    <Outlet/>
    			</>}
	  	 	>
	  	 		<Route path="new-org" element={<SysOpCreateOrg/>} />
	  	 		<Route path="open-org" element={<SysOpOpenOrg />} />
	  	 		<Route path="open-user" element={<SysOpOpenUser />} />
			 		<Route path="search" element={<IssuedSearchPage/>} />
	  	 		{uinfo.userAreas.admin &&
	  	 		<Route path="sysops" element={<SysUsers sysrole="sysop"
	  	 				dropRole="" title="Операторы" />} />
	  	 		}
	  	 		{uinfo.userAreas.admin &&
	  	 		<Route path="admins" element={<SysUsers sysrole="admin"
	  	 				dropRole="sysop" title="Админы" />} />
	  	 		}
				{uinfo.userAreas.admin &&
					<Route path="departments" element={<Departments/>} />}
	  	 		{uinfo.userAreas.admin &&
	  	 		<Route path="import" element={<ImportIslod/>} />
	  	 		}
				{(uinfo.userAreas.admin || uinfo.userAreas.sysop) &&
					<Route path="export_statistic" element={<ExportStatistics/>} />
				}
	  	 	</Route>}

	  	 	<Route path="/details-lic/:lic" element={<ViewRlic/>} />
	  	 	<Route path="/details-acred/:acc" element={<ViewAcred/>} />

	  	 	<Route path="*" element="404" />
	  </Routes>
</main>
</GlobalPageAreas>
</>);
}
export default MainApp;
