import React, {useContext, useState, useEffect, useRef, useCallback} from 'react'
import {Link, useLocation} from "react-router-dom";

import {FormikConsumer, useFormikContext} from 'formik'
import {Field, modalFormik} from 'azlib/components/formik-fields'
import { object, number } from 'yup';

import {XLOG} from 'azlib/components/helpers'

import {fullPersonName} from 'azlib/components/name_funcs'
import {ModalButton, showModal, alert, promptBig} from 'azlib/components/modals'

import {OrgInfoContext} from 'org/org'

import {DbTableEdit as Table } from 'azlib/components/super-table'

import {blobToText, FieldInput, ModelDecode} from 'azlib/components/std-controls'

import {DoctypeModalSelector} from 'config/doctype_selector'

import Filter from 'azlib/components/list_filter'
import {fioExtract, fioCondition} from 'azlib/components/filter_helpers'
import Sorter from 'azlib/components/list_sort'


import {statusSorter} from 'config/common'
import { Header } from './issued_op';

import { SearchFilter } from 'azlib/components/search_filter'

import {preview_infoset_array} from 'genpdf/pdf-gen'

import {prefillExactValues} from './infoset_edit'

import UserInfoContext from 'UserInfo'
import {exportScheme} from "../org/export";
import md5 from "crypto-js/md5";
import _ from "lodash";
import {emptyOrgFields} from "../contingent/cards";
import { YearCtrl } from 'contingent/groups';

const thisYear = (new Date()).getFullYear()

function checkReturn(st, row) {
	const prevs = {
		'doc-sign:doc-ready': 'возврат с подписания'
		, 'doc-check:doc-ready': 'возврат с проверки'
		, 'doc-sign:doc-fill': 'возврат с подписания'
		, 'doc-check:doc-fill': 'возврат с проверки'
		, 'doc-check:': 'возврат с проверки'
		, 'st-verification:': 'возврат с проверки'
	}
	let decoded = window.AppBundle.data.doc_status.decode[st];
	let pair = `${row.status_prev}:${st||''}`
	return pair in prevs ?
		`${decoded} (${prevs[pair]})`
		: decoded
}

function RedirectButton (props) {
	const orginfo = props.org;
	const readOnly = props.readOnly;

	return (
		<button style={props.style} type={props.type} onClick={
			async ()=> {
				await docRedirect({orginfo}, readOnly, {closeBox:true})
			}
		}>
			{props.children}
		</button>
	);
};
function importInfoset(fc,orginfo,dataFile,checkSum){
	if (md5(dataFile).toString() === checkSum) {
		dataFile = JSON.parse(dataFile);
		dataFile.forEach(async (row) => {
			let data = await orginfo.DbX.fetch_rds('docs', "docid signers cito doctype_kind our_number"
				+ " ed_level snils epgu"
				+ " last_name first_name middle_name bdate "
				+ " year_completion spec_code qualification signed doctype_name",
				{docid: row.docid, status: 'doc-sign'})
			if(data.length!==0) {
                let sign = row.sign;
                delete row.sign;
				let value = exportScheme(data[0]);
				Object.keys(value).forEach(key => value[key] === undefined && delete value[key])
                if (_.isEqual(row, value)) {
                    for (const [key, value] of Object.entries(sign)) {
                        await orginfo.DbX.call_function('import_sign_doc', {
                            doc:row.docid, signature:value, signer:key, text:row.signed
                        })
                    }
                    await orginfo.DbX.call_function("write_to_log",{
                        docid: row.docid
                        , status: 'doc-sign'
                        , status_message: 'Документ импортирован'
                    })

                } else alert("Документ изменялся");
			}
			else alert("Соответствующий документ не найден");
		})
	}
	else alert("Файл был повреждён");
}
async function docRedirect({orginfo}, readOnly){
	let signers = new Set(
		(await orginfo.DbX.fetch_rds('doc_signer_persons', "signer",
				{tid : orginfo.tid, status: 'doc-sign', signature: null})
		)
			.map(row=>row.signer)
	)

	signers = orginfo.orgtree_signers.filter(e=>signers.has(e.person))

	return modalFormik({
			initialValues:
				{
					wassigner: ''
					, willsigner: orginfo.orgtree_signers.find(
						s=>+s.person===+window.localStorage['default-signer-'+orginfo.tid]
					)?.person ?? ''
				}
			, validationSchema:
				object({
					wassigner: number().required()
					, willsigner: number().required()
				})
			, onSubmit: async (v,ops)=>{
				//window.localStorage['default-signer-'+orginfo.tid] = v.willsigner;

				await orginfo.DbX.call_function('update_signers', {
					tid: orginfo.tid
					, old_signer: v.wassigner
					, new_signer: v.willsigner
					, new_status_message: v.status_message
				})

				ops.modalContext.hide()
			}
		}
		, ({values})=>
			<div>
				<div>
					<label>От кого:</label>
					<div>
						<Field name="wassigner" as="select" style={{width:"100%"}}>
							<option key="" value={null}/>
							{
								signers
									.map(e=><option key={e.person} value={e.person}>
										{`${e.last_name} ${e.first_name} ${e.middle_name}`}
									</option>)
							}
						</Field>
					</div>
				</div>
				<div>
					<label>Кому:</label>
					<div>
						<Field name="willsigner" as="select" style={{width:"100%"}}>
							<option key="" value={null}/>
							{
								orginfo.orgtree_signers
									.filter(e=>e.position !== null && e.person !== +values.wassigner)
									.map(e=><option key={e.person} value={e.person}>
										{`${e.last_name} ${e.first_name} ${e.middle_name}`}
										{console.log(e.person)}
									</option>)
							}
						</Field>
					</div>
				</div>
				<div>
					<label>Указание причины:</label>
					<div>
						<Field style={{width:"100%"}}
							name="status_message"
						/>
					</div>
				</div>
				<div className="flexContainer" style={{marginTop:"0"}}>
					<button type="submit" class="modalButton" disabled={readOnly}>Изменить подписывающего</button>
					<ModalButton>Отмена</ModalButton>
				</div>
			</div>
	)
}

function useStateRef(def) {
	const [cur, setCur] = useState(def)
	const ref = useRef(cur)
	ref.current = cur
	const read = useCallback(()=>ref.current,[ref])
	return [read, setCur]
}

export function DocList({status,gcode,...props}) {
	const location = useLocation();
	let filter_status = new URLSearchParams(location.search).get("status")
	let filter_doctype_kind = new URLSearchParams(location.search).get("doctype_kind")
	let filter_path = new URLSearchParams(location.search).get("path")
	const orginfo = useContext(OrgInfoContext);
	const uinfo = useContext(UserInfoContext);
	let terms = orginfo.terms
	let grpName = ''
	let grp_fix = null;
	const [inPrint,setInPrint] = useStateRef(false)
	const fc = useFormikContext();
	/*
		useStateRef =>
		take state, set it in context
		and return function dependent from this context
		(stabe function)
		us
	*/
	const key = `${filter_doctype_kind}:${filter_status}`

	if(gcode) {
		let ginfo = orginfo.group_info(gcode)
		terms = orginfo.terms_by_level(ginfo?.ed_level)
		grpName = ginfo?.group_name
		grp_fix = {gcode}
	}
	const choosen = async (fc, {$,doctype,typename,kind}) => {
		doctype = doctype ?? $
		//new doctype definition
		let doctype_infoset = await orginfo.DbX.fetch_column('doc_types', 'infoset', [doctype])
		let doc = await orginfo.DbX.read_form('docs', 'infoset', [fc.values.docid])
		let vals = { docid: fc.values.docid //key
			, doctype_used: doctype
			, doctype_kind: kind
			, doctype_used$: {
				typename: typename
			}
			, doctype_infoset
			, infoset: prefillExactValues(kind, doctype_infoset, doc.data.infoset)
			, status: 'doc-fill'
		}
		//save to db (also clear locally saved values)
		await orginfo.DbX.save_form('docs_preparing', null, vals) //use all fields in vals
		//set local values
		await fc.setFormikState(prev=>
			({...prev
				, status: {...prev.status, skipLocalSave: true}
				, values: {...prev.values, ...vals}
			})
		)
	}
	return <>
		<h1>Документы {grpName && `${terms.group_gen} ${grpName}`}</h1>
		{orginfo.contact_tel
			&& orginfo.contact_email
			&& (orginfo.app_region || orginfo.toplevel && orginfo.is_branch || !orginfo.toplevel)
			&& (orginfo.app_place || orginfo.toplevel && orginfo.is_branch || !orginfo.toplevel)
			&& (orginfo.app_toplevel_region || orginfo.toplevel)
			&& (orginfo.app_toplevel_place || orginfo.toplevel)
			&& orginfo.contact_addr
			&& orginfo.contact_person ?
		<Table className="simpleTable" fetchKey={key}
			   store={orginfo.DbX.store('docs_preparing', filter_path? { "path*": filter_path
					   , status: filter_status || undefined
					   , doctype_kind: filter_doctype_kind ||
						   (filter_doctype_kind === '' ? null
							   : undefined)
					   ,  ...grp_fix  }
				   : { tid: orginfo.tid
					   , status: filter_status || undefined
					   , doctype_kind: filter_doctype_kind ||
						   (filter_doctype_kind === '' ? null
							   : undefined)
					   ,  ...grp_fix  } )}
			   defaultSorter={statusSorter}
			   storeFilter={row=>row.status && row.status.startsWith('doc-')}
			   extra="cito year_completion"
		>

			<Table.Before>
				<div className="templatesSearch backgroundAngular">
					<div className="flexContainer">
						{(orginfo.docs.operator || orginfo.docs.validator || orginfo.docs.printer || uinfo.userAreas.sysop) &&
							<Table.SelectionMode>{setMode=>inPrint()?
								<button type="button" onClick={()=>{setMode(null); setInPrint(false)}}>Отменить печать</button>
								:
								<button type="button" onClick={()=>{setMode(true); setInPrint(true)}}>Печать</button>
							}</Table.SelectionMode>
						}
						<Table.ForSelection
							command={async (arr,tableContext)=>{
								let sel = arr.map(([k])=>
									tableContext.source.get(JSON.stringify(k)).data
								)
								await preview_infoset_array(sel, orginfo)
							}}
						>Напечатать выбранное</Table.ForSelection>
						{/*(uinfo.userAreas.sysop || orginfo.docs.secretary) && <label className="buttonBackground">
							Импорт подписанных документов
							<input type="file" accept=".json, .txt" disabled={!orginfo.docs.secretary} multiple
								   style={{width:0, height:0, overflow:"hidden"}}
								   onChange={async e => {
									   let dataFile;
									   let checkSum;
									   let inputSize = e.target.files.length;
									   if (inputSize === 2) {
										   for (let i = 0; i < inputSize; i++) {
											   if (e.target.files[i].type == "application/json") dataFile = await blobToText(e.target.files[i]);
											   if (e.target.files[i].type == "text/plain") checkSum = await blobToText(e.target.files[i]);
										   }
									   } else alert("Необходимо загрузить два файла");
									   if (!dataFile) alert("Файл с документами не загружен");
									   else if (!checkSum) alert("Подпись не загружена");
									   else importInfoset(fc,orginfo,dataFile,checkSum);
								   }}
							/>
						</label>*/}
					</div>
					<SearchFilter
						input={
						<Filter.Multi className="FilterInput" placeholder={"Найти документ"} minlength="3"
									  extract={
										  [
											  [fioExtract, fioCondition(
												  row=>[row.last_name, row.first_name, row.middle_name]
											  )
											  ]
										  ]
									  }
									  saveAs="multi"
						/>							
						}
					/>
					<div className="flexContainer">
						<Filter Element={<select>
							<option value="">Все классы/группы</option>
							{
								orginfo.orgtree_groups
									.map(e=><option key={e.group_name} value={e.group_name}>
										{e.group_name}
									</option>)
							}
						</select>
						}
								condition={v => row => row.gcode$?.group_name === v}
								saveAs="groups"
						/>
						{filter_status ? null :
							<Filter Element={<select>
								<option value="">Все статусы</option>
								<option value="doc-input">ввод</option>
								<option value="doc-ready">- подготовлен</option>
								<option value="doc-fill">- ввод сведений</option>
								<option value="doc-check">проверка</option>
								<option value="doc-sign">подписание</option>
							</select>}
									condition={v => v === "doc-input"?
										row => row.status==="doc-fill" ||
											row.status ==="doc-ready"
										: row => row.status === v
									}
									saveAs="statuses"
							/>}
						<Sorter.Select slot="combo">
							<option comparator={(a,b)=>
								Sorter.cmp(
									`${a.last_name} ${a.first_name} ${a.middle_name}`
									,  `${b.last_name} ${b.first_name} ${b.middle_name}`
								)
							}>По ФИО ↗</option>
							<option comparator={(a,b)=>
								-Sorter.cmp(
									`${a.last_name} ${a.first_name} ${a.middle_name}`
									,  `${b.last_name} ${b.first_name} ${b.middle_name}`
								)
							}>По ФИО ↘</option>
							<option comparator={(a,b)=>statusSorter(a,b)}
							>По статусу ↗</option>
							<option comparator={(a,b)=>statusSorter(b,a)}
							>По статусу ↘</option>
						</Sorter.Select>
						<Filter Element={<select>
							<option value="">Уровень:</option>
							{
								window.AppBundle.data.ed_levels.orderedKeys
									.filter(e=>!!e)
									.filter(e=>e in orginfo.app_ed_level)
									.map(e=><option key={e} value={e}>
										{window.AppBundle.data.ed_levels.decode[e]}
									</option>)
							}
						</select>}
								condition={v => row => row.ed_level === v}
								saveAs="level"
						/>
						<Filter
							Element={<YearInput value={thisYear}/>}
							condition={v => row => +row.year_completion === +v}
							saveAs="year_completion"
						/>
						<button className="checkBoxBut">
							Срочные:<Filter.Check
							condition={v => v? row => row.cito : row=>true}
							saveAs="cito"
						/>
						</button>
						{(orginfo.docs.operator || orginfo.docs.validator || uinfo.userAreas.sysop || uinfo.userAreas.admin) &&
							<div style={{marginLeft:"auto"}}>
								<RedirectButton org = {orginfo} type="button" readOnly={!orginfo.docs.operator && !orginfo.docs.validator}
								>
									Изменить подписывающего
								</RedirectButton>
							</div>
						}
					</div>
				</div>
			</Table.Before>
			<Table.OperationChecks
				hiddenIf={()=>!inPrint()}
			/>
			<Table.Col label="Фамилия Имя Отчество" name="docid"
					   extra="last_name first_name middle_name bdate status"
			>
				{(value,row) =>
					row.doctype_used
					||
					row.status !== 'doc-ready'
					&&
					row.status !== 'doc-fill'
						?
						<Link to={`/org/${orginfo.tid}/docs/${value}`} >{fullPersonName(row) || '--???--'
						}</Link>
						:
						fullPersonName(row) || '--???--'
				}
			</Table.Col>
			<Table.Col label={terms.group}
					   name="gcode"
					   extra="gcode{group_name}"
			>{
				(v, row) => row.gcode$?.group_name
			}
			</Table.Col>
			<Table.Col
				label="Форма документа"
				colKey="typename"
				extra="gcode{. doctype{. kind typename} } doctype_kind doctype_used{. typename}">{
				(gcode, row) =>
					row.doctype_used?
						<div>
							<Link to={`/org/${orginfo.tid}/docs/${row.docid}`} >
								<ModelDecode modelData="doc_kinds" value={row.doctype_kind} />
								&nbsp; ( {row.doctype_used$.typename} )
							</Link>
						</div>
						: gcode && row.gcode$.doctype?
							<FormikConsumer>{fc=>
								<button type="button" className="intableOp"
										onClick={()=>{
											choosen(fc,{doctype:row.gcode$.doctype,
												typename:row.gcode$.doctype$.typename,
												kind:row.gcode$.doctype$.kind})
										}}>
									<ModelDecode modelData="doc_kinds" value={row.gcode$.doctype$.kind} />
									&nbsp; ( {row.gcode$.doctype$.typename} )
								</button>
							}</FormikConsumer>
							: null
			}
			</Table.Col>
			<Table.Col label="Статус" name="status" extra="status_prev">{(st,row)=>
				checkReturn(st,row)}
			</Table.Col>
			{(orginfo.docs.operator || orginfo.docs.validator || orginfo.docs.secretary
				|| uinfo.userAreas.sysop) 
			&&
				<Table.Col style={{minWidth:"15em"}}
						   label="Действия"
						   colKey="@choose"
						   name="gcode"
						   extra="ed_level spec_code doctype_used"
				>{
					(gcode, row) =>
						<FormikConsumer>{fc=>
							(row.status === 'doc-ready' || row.status === 'doc-fill') &&
							(orginfo.docs.operator || uinfo.userAreas.sysop) &&
							<div>
								<button type="button"  className="intableOp block"
										onClick={async ()=>{
											let dt = await showModal(
												<DoctypeModalSelector
													doctypes={orginfo.orgtree_doctypes}
													ed_level={row.ed_level}
													spec_code={row.spec_code}
													header = {<Header stud = {row}/>}
												/>
												, {closeBox:true}
											)
											if(!orginfo.docs.operator) {
												alert("Недостаточно прав!")
												return;
											}
											choosen(fc, dt)
										}}
								>
								{fc.values.gcode && row.gcode$.doctype === fc.values.doctype_used ? 'Другая форма документа'
									: fc.values.doctype_used?
									'Заменить форму документа'
									: 'Выбрать форму документа'
								}
								</button>
							</div>
							||
							row.status === 'doc-sign' &&
							<div>{(orginfo.docs.operator || orginfo.docs.validator || uinfo.userAreas.sysop)
								&& <button disabled={!orginfo.docs.operator && !orginfo.docs.validator}
										   type="button"  className="intableOp block"
										   onClick={async () => {
											let msg = await promptBig('Комментарий')
											   let vals = {
												   docid: row.docid
												   , signers: ''
												   , signed: ''
												   , status_prev: row.status
												   , status: 'doc-check'
												   , status_message: msg
											   }

											   //save to db (also clear locally saved values)
											   await orginfo.DbX.save_form('docs_preparing', null, vals)

											   //set local values
											   await fc.setFormikState(prev=>
												   ({...prev
													   , status: {...prev.status, skipLocalSave: true}
													   , values: {...prev.values, ...vals}
												   })
											   )
										   }}
								>
									Забрать с подписи
								</button>
							}

								{(orginfo.docs.secretary || uinfo.userAreas.sysop )
									&& <button disabled={!orginfo.docs.secretary}
											   type="button"  className="intableOp block"
											   style={{marginTop:"3px"}}
											   onClick={async () => {
												   let vals = { docid: fc.values.docid //key
													   , cito: fc.values.cito? '' : 1
												   }
												   //save to db (also clear locally saved values)
												   await orginfo.DbX.save_form('docs_preparing', null, vals) //use all fields in vals
												   //set local values
												   await fc.setFormikState(prev=>
													   ({...prev
														   , status: {...prev.status, skipLocalSave: true}
														   , values: {...prev.values, ...vals}
													   })
												   )
											   }}
									>
										{fc.values.cito?'в несрочные':'в срочные'}
									</button>}
							</div>
						}</FormikConsumer>
				}
				</Table.Col> }
			<Table.ModelCol name="cito" ifDistinct={[row=>row.cito,'']}
			>{(v,st)=> st ? <span style={{color:"red"}}>Срочно</span> : null}
			</Table.ModelCol>
			<Table.Col label="Комментарий" name="status_message">{msg=>msg}</Table.Col>
		</Table>
			: <h1 style={{textAlign:"center", color:"red"}}>
				{emptyOrgFields(orginfo)}
				</h1>
		}
	</>
}
export function YearInput({value,strict,full,...props}) {
	return <button className="yearInput">Год:
		<FieldInput type="number" defaultValue={value||thisYear} {...props}></FieldInput>
	</button>
}