let cadesplugin = window.cadesplugin

function CertificateAdjuster()
{
}
CertificateAdjuster.prototype.checkQuotes = function(str)
{
    var result = 0, i = 0;
    for(i;i<str.length;i++)if(str[i]==='"')
        result++;
    return !(result%2);
}

CertificateAdjuster.prototype.extract = function(from, what)
{
    let certName = "";

    var begin = from.indexOf(what);
    if (begin < 0)
        return '';

    begin=begin+what.length;

    if(begin>=0)
    {
        var end = from.indexOf(', ', begin);
        while(end > 0) {
            if (this.checkQuotes(from.substr(begin, end-begin)))
                break;
            end = from.indexOf(', ', end + 1);
        }
        certName = (end < 0) ? from.substr(begin) : from.substr(begin, end - begin);
    }

    return certName;
}

CertificateAdjuster.prototype.Print2Digit = function(digit)
{
    return (digit<10) ? "0"+digit : digit;
}

CertificateAdjuster.prototype.GetCertDate = function(paramDate)
{
    var certDate = new Date(paramDate);
    return certDate.toLocaleDateString()
    //return this.Print2Digit(certDate.getUTCDate())+"."+this.Print2Digit(certDate.getUTCMonth()+1)+"."+certDate.getFullYear() + " " +
    //         this.Print2Digit(certDate.getUTCHours()) + ":" + this.Print2Digit(certDate.getUTCMinutes()) + ":" + this.Print2Digit(certDate.getUTCSeconds());
}

CertificateAdjuster.prototype.GetCertName = function(certSubjectName)
{
    return this.extract(certSubjectName, 'CN=');
}

CertificateAdjuster.prototype.GetIssuer = function(certIssuerName)
{
    return this.extract(certIssuerName, 'CN=');
}

CertificateAdjuster.prototype.GetCertInfoString = function(certSubjectName, certFromDate)
{
    return this.extract(certSubjectName,'CN=') 
    + "; Выдан: " 
    + this.GetCertDate(certFromDate);
}

export function Common_CheckForPlugIn(ops) {
    console.log('Plugin check');

    cadesplugin = window.cadesplugin
    //cadesplugin.set_log_level(cadesplugin.LOG_LEVEL_DEBUG);
    var canAsync = !!cadesplugin.CreateObjectAsync;
    if(canAsync)
    {
        return CheckForPlugIn_Async(ops);
    } else
    {
        //SYNC return CheckForPlugIn_NPAPI();
    }
}

function CheckForPlugIn_Async(
        {   setLoadedPlug
            , setLoadedPlugText
            , setPlugInVersionTxt
            , setCSPVersionTxt
            , setCspEnabledTxt
            , setCSPNameTxt
            , setCspEnabledImg
        }
    ) {
    function VersionCompare_Async(ObjectVersion)
    {
        if(typeof(ObjectVersion) == "string")
            return -1;

        return cadesplugin.async_spawn(function *() {

            setPlugInVersionTxt("Версия плагина: " + (yield CurrentPluginVersion.toString()));

            var oAbout = yield cadesplugin.CreateObjectAsync("CAdESCOM.About");
            var ver = yield oAbout.CSPVersion("", 80);
            var ret = (yield ver.MajorVersion) + "." + (yield ver.MinorVersion) + "." + (yield ver.BuildVersion);
            setCSPVersionTxt("Версия криптопровайдера: " + ret);

            try
            {
                var sCSPName = yield oAbout.CSPName(80);
                setCspEnabledImg(true);
                setCspEnabledTxt("Криптопровайдер загружен");
                setCSPNameTxt("Криптопровайдер: " + sCSPName);
            }
            catch(err){
                //throw err;
            }
        });
    }

    setCspEnabledImg(null);
    setCspEnabledTxt("КриптоПро CSP не загружен");
    var CurrentPluginVersion;
    return cadesplugin.async_spawn(function *() {
        var oAbout = yield cadesplugin.CreateObjectAsync("CAdESCOM.About");
        console.log('plg')
        setLoadedPlug(true);
        setLoadedPlugText("Плагин загружен");
        CurrentPluginVersion = yield oAbout.PluginVersion;
        yield VersionCompare_Async(CurrentPluginVersion);
    }); //cadesplugin.async_spawn
}

function CertStatusEmoji(isValid, hasPrivateKey) {
    if (isValid) {
        return "\u2705";
    } else {
        return "\u274C";
    }
}

/*
list is a map: thumb => cert 
*/
 
export function FillCertList_Async() {
    if(!cadesplugin)
        return Promise.resolve(new Map())
    return cadesplugin.async_spawn(function *() {
        var retList = new Map()
        var MyStoreExists = true;
        try {
            var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
            if (!oStore) {
                alert("Create store failed");
                return;
            }

            yield oStore.Open();
        }
        catch (ex) {
            MyStoreExists = false;
        }

        var certCnt;
        var certs;
        if (MyStoreExists) {
            try {
                certs = yield oStore.Certificates;
                certCnt = yield certs.Count;
            }
            catch (ex) {
                alert("Ошибка при получении Certificates или Count: " + cadesplugin.getLastError(ex));
                return;
            }
            for (let i = 1; i <= certCnt; i++) {
                let cert;
                try {
                    cert = yield certs.Item(i);
                }
                catch (ex) {
                    alert("Ошибка при перечислении сертификатов: " + cadesplugin.getLastError(ex));
                    return;
                }

                var oOpt = {};
                //var dateObj = new Date();

                console.log('certificate_info = ', yield cert.SubjectName)

                try {
                    let ValidFromDate = new Date((yield cert.ValidFromDate));
                    let ValidToDate = new Date(yield cert.ValidToDate);
                    let IsValid = ValidToDate > Date.now();
                    let emoji = CertStatusEmoji(IsValid);
                    oOpt.isValid = IsValid;
                    oOpt.CN = new CertificateAdjuster().extract(yield cert.SubjectName,"CN=")
                    oOpt.SN = new CertificateAdjuster().extract(yield cert.SubjectName,"SN=")
                    oOpt.G = new CertificateAdjuster().extract(yield cert.SubjectName,"G=")
                    oOpt.ОГРН = new CertificateAdjuster().extract(yield cert.SubjectName,"ОГРН=")
                    oOpt.OGRN = new CertificateAdjuster().extract(yield cert.SubjectName,"OGRN=")
                    oOpt.O = new CertificateAdjuster().extract(yield cert.SubjectName,"O=")//en
                    oOpt.OGRNCODE = new CertificateAdjuster().extract(yield cert.SubjectName,"OID.1.2.643.100.1=")
                    oOpt.text = emoji + new CertificateAdjuster().GetCertInfoString(yield cert.SubjectName, ValidFromDate);
                }
                catch (ex) {
                    alert("Ошибка при получении свойства SubjectName: " + cadesplugin.getLastError(ex));
                }
                try {
                    oOpt.cert = cert;
                    retList.set(yield cert.Thumbprint, oOpt)
                }
                catch (ex) {
                    alert("Ошибка при получении свойства Thumbprint: " 
                        + cadesplugin.getLastError(ex));
                }
            }

            yield oStore.Close();
        }

        //В версии плагина 2.0.13292+ есть возможность получить сертификаты из 
        //закрытых ключей и не установленных в хранилище
        try {
            yield oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
            certs = yield oStore.Certificates;
            certCnt = yield certs.Count;
            for (let i = 1; i <= certCnt; i++) {
                let cert = yield certs.Item(i);
                //Проверяем не добавляли ли мы такой сертификат уже?
                let tprint = yield cert.Thumbprint;
                if(retList.has(tprint)) continue;
                let oOpt = {};
                let ValidFromDate = new Date((yield cert.ValidFromDate));
                let ValidToDate = new Date(yield cert.ValidToDate);
                let IsValid = ValidToDate > Date.now();
                let emoji = CertStatusEmoji(IsValid);
                oOpt.text = emoji + new CertificateAdjuster().GetCertInfoString(yield cert.SubjectName, ValidFromDate);
                oOpt.cert = cert;
                retList.set(tprint, oOpt)
            }
            yield oStore.Close();
        }
        catch (ex) {
        }
        return retList
    });//cadesplugin.async_spawn
}

export function FillCertInfo_Async(certificate)
{
    return cadesplugin.async_spawn (function*(args) {
        var ret = {}

        var Adjust = new CertificateAdjuster();

        var ValidToDate = new Date((yield args[0].ValidToDate));
        var ValidFromDate = new Date((yield args[0].ValidFromDate));
        var Validator;
        var IsValid = false;
        //если попадется сертификат с неизвестным алгоритмом
        //тут будет исключение. В таком сертификате просто пропускаем такое поле
        try {
            Validator = yield args[0].IsValid();
            IsValid = yield Validator.Result;
        } catch(e) {

        }
        var hasPrivateKey = yield args[0].HasPrivateKey();
        var Now = new Date();

        ret.subject = "Владелец: " + Adjust.GetCertName(yield args[0].SubjectName);
        ret.issuer = "Издатель: " + Adjust.GetIssuer(yield args[0].IssuerName);
        ret.from = "Выдан: " + Adjust.GetCertDate(ValidFromDate) + " UTC";
        ret.till = "Действителен до: " + Adjust.GetCertDate(ValidToDate) + " UTC";
        var pubKey = yield args[0].PublicKey();
        var algo = yield pubKey.Algorithm;
        var fAlgoName = yield algo.FriendlyName;
        ret.algorithm = "Алгоритм ключа: " + fAlgoName + "";
        if (hasPrivateKey) {
            var oPrivateKey = yield args[0].PrivateKey;
            var sProviderName = yield oPrivateKey.ProviderName;
            ret.provname = "Криптопровайдер: " + sProviderName + "";
            try {
                var sPrivateKeyLink = yield oPrivateKey.UniqueContainerName;
                ret.privateKeyLink = "Ссылка на закрытый ключ: " + sPrivateKeyLink + "";
            } catch (e) {
                ret.privateKeyLink = "Ссылка на закрытый ключ: " + e.message + "";
            }
        } else {
            ret.provname = "Криптопровайдер:";
            ret.privateKeyLink = "Ссылка на закрытый ключ:";
        }
        if(Now < ValidFromDate) {
            ret.status = "Статус: Срок действия не наступил";
        } else if( Now > ValidToDate){
            ret.status = "Статус: Срок действия истек";
        } else if( !hasPrivateKey ){
            ret.status =  "Статус: Нет привязки к закрытому ключу";
        } else if( !IsValid ){
            ret.status = "Статус: Ошибка при проверке цепочки сертификатов. Возможно на компьютере не установлены сертификаты УЦ, выдавшего ваш сертификат";
        } else {
            ret.status =  "Статус: Действителен";
        }
        return ret;
    }, certificate);//cadesplugin.async_spawn
}

export function Common_SignCadesBES(certificate, text, setDisplayData)
{
    var canAsync = !!cadesplugin.CreateObjectAsync;
    if(canAsync)
    {
        //include_async_code().then(function(){
            return SignCadesBES_Async(certificate, text, setDisplayData);
        //});
    }
    //else
    //{
      //  return SignCadesBES_NPAPI(certificate, text, setDisplayData);
    //}
}

function SignCadesBES_Async(certificate, dataToSign, setDisplayData) {
    return cadesplugin.async_spawn(function*(arg) {

        dataToSign = Base64.encode(dataToSign);

        //FillCertInfo_Async(certificate);
        var errormes = "";
        try {
            var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
        } catch (err) {
            errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
            throw errormes;
        }
        var oSigningTimeAttr = yield cadesplugin.CreateObjectAsync("CADESCOM.CPAttribute");

        yield oSigningTimeAttr.propset_Name(cadesplugin.CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME);
        var oTimeNow = new Date();
        yield oSigningTimeAttr.propset_Value(oTimeNow);
        var attr = yield oSigner.AuthenticatedAttributes2;
        yield attr.Add(oSigningTimeAttr);

        var oDocumentNameAttr = yield cadesplugin.CreateObjectAsync("CADESCOM.CPAttribute");
        yield oDocumentNameAttr.propset_Name(cadesplugin.CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME);
        yield oDocumentNameAttr.propset_Value("Document Name");
        yield attr.Add(oDocumentNameAttr);

        if (oSigner) {
            yield oSigner.propset_Certificate(certificate);
        }
        else {
            errormes = "Failed to create CAdESCOM.CPSigner";
            throw errormes;
        }

        var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
        if (dataToSign) {
            // Данные на подпись ввели
            yield oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY); //
            yield oSignedData.propset_Content(dataToSign);
        }
        yield oSigner.propset_Options(cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN);
        if (typeof (setDisplayData) != 'undefined') {
            //Set display data flag flag for devices like Rutoken PinPad
            yield oSignedData.propset_DisplayData(1);
        }

        try {
            //NOTE true ===  detached!!!
            return yield oSignedData.SignCades(oSigner, cadesplugin.CADESCOM_CADES_BES, true);
        }
        catch (err) {
            errormes = "Не удалось создать подпись из-за ошибки: " + cadesplugin.getLastError(err);
            throw errormes;
        }
    }); //cadesplugin.async_spawn
}

//-----------------------------------
var Base64 = {


    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",


    encode: function(input) {
            var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;

        input = Base64._utf8_encode(input);

        while (i < input.length) {

            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                    enc4 = 64;
            }

            output = output + this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) + this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);

        }

        return output;
    },


    decode: function(input) {
            var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;

        input = input.replace(/[^A-Za-z0-9+/=]/g, "");

        while (i < input.length) {

            enc1 = this._keyStr.indexOf(input.charAt(i++));
            enc2 = this._keyStr.indexOf(input.charAt(i++));
            enc3 = this._keyStr.indexOf(input.charAt(i++));
            enc4 = this._keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output = output + String.fromCharCode(chr1);

            if (enc3 !== 64) {
                    output = output + String.fromCharCode(chr2);
            }
            if (enc4 !== 64) {
                    output = output + String.fromCharCode(chr3);
            }

        }

        output = Base64._utf8_decode(output);

        return output;

    },

    _utf8_encode: function(string) {
            string = string.replace(/\r\n/g, "\n");
        var utftext = "";

        for (var n = 0; n < string.length; n++) {

            var c = string.charCodeAt(n);

            if (c < 128) {
                    utftext += String.fromCharCode(c);
            }
            else if ((c > 127) && (c < 2048)) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        }

        return utftext;
    },

    _utf8_decode: function(utftext) {
            var string = "";
        var i = 0;
        var c = 0; //ANDY
        var c1 = 0; //ANDY
        var c2 = 0; //ANDY
        var c3 = 0; //ANDY

        while (i < utftext.length) {

            c = utftext.charCodeAt(i);

            if (c < 128) {
                    string += String.fromCharCode(c);
                i++;
            }
            else if ((c > 191) && (c < 224)) {
                    c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            }
            else {
                    c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }

        }

        return string;
    }

}


export function addNewNode() {
    try {
        var newSiteName = window.location.protocol+'//'+window.location.host;

        cadesplugin.async_spawn(function *() {
            var PluginObject = yield window.cpcsp_chrome_nmcades.CreatePluginObject();

            var g_oCfg = yield PluginObject.CreateObjectAsync("CAdESCOM.PluginConfiguration");

            var g_oSites = yield g_oCfg.TrustedSites;

            try {
                yield g_oSites.Add(newSiteName);
                yield g_oSites.Save();
            }
            catch (e) {
                alert("Не удалось добавить доверенный узел."
                    + GetErrorMessage(e)
                    );
                return;
            }
        }); //async_spawn
    }
    catch (e) {
        alert("Ошибка при добавлении доверенного узла." + GetErrorMessage(e));
    }
}

function decimalToHexString(number) {
    if (number < 0) {
        number = 0xFFFFFFFF + number + 1;
    }

    return number.toString(16).toUpperCase();
}

function GetErrorMessage(e) {
    var err = e.message;
    if (!err) {
        err = e;
    } else if (e.number) {
        err += " (0x" + decimalToHexString(e.number) + ")";
    }
    return err;
}
