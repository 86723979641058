import React, {useState, useEffect, useRef, useContext} from 'react';
import { Link } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from 'formik';

import * as yup from 'yup';

import {shardedDbX, mainDbX} from 'azlib/components/db'

import {alert} from 'azlib/components/modals'

import {XLOG, letIn, defer, later} from 'azlib/components/helpers'

import {RGRJU} from 'org/orginfo'

import {parse_islod} from 'org/islod_parser'

import {sha256}  from  'js-sha256'

import {EdRegionEdit} from "../config/regions";

import UserInfoContext from 'UserInfo'

import {ed_regions_from_ogrn, EdRegionEditInRegOrg} from "../config/regions";

export async function calculateShard(ogrn) { // random shard
    let shards = window.UserInfo.shards;
    let sha = sha256(ogrn);
    let crc = 0;
    for(let i = 0; i < sha.length; ++i)
      crc += parseInt(sha[i],16);
    crc = crc % shards.length;
    let shard = shards[crc];
    return +shard.slice(-1);
}

const validationSchema = yup.object({
  gov_regnum: yup
    .string('ОГРН')
    .required('ОГРН необходим')
    .matches(/^\d{13}$|^\d{15}$/
      , { message: 'неверный формат', excludeEmptyString: true }
    )
  , login: yup
    .string('Основной пользователь')
    .required('Без него никак')
    .matches(/^\/$|^\d\d\d-\d\d\d-\d\d\d[ -]\d\d$|^[/]/
      , { message: 'неверный формат', excludeEmptyString: true }
      )
 , login_2: yup
   .string('второй пользователь')
   .matches(/^\/$|^\d\d\d-\d\d\d-\d\d\d[ -]\d\d$|^[/]/
       , { message: 'неверный формат', excludeEmptyString: true }
   )
});

export function SysOpCreateOrg() {
  const uinfo = useContext(UserInfoContext);
	let [key,setKey] = useState(1);
	let ed_levels = window.AppBundle.data.ed_levels.orderedKeys.filter(Boolean);
	let ed_level_decode = window.AppBundle.data.ed_levels.decode;

  let [ourlic, setOurLic] = useState(null)
  let [extNameOrg, setNameOrg] = useState(null)

  let [choosenShard, setShard] = useState(null)

	return <>
	  <h1>Регистрация организации</h1>
      <Formik 
      	key={key}
        initialValues={{
        	gov_regnum:''
        	, login: ''
        	, login_2: ''
            , app_region: ''
        	, app_ed_level:
              Object.fromEntries(ed_levels.map(e=>[e,false]))
          , app_spec_list: ''
          , global_org: ''
    	  }}
        validationSchema={validationSchema}
        onSubmit={async (values) =>{
              //console.log(values); return;
              let send = { ...values}
              if(send.login && send.login.match(/^(\d\d\d-\d\d\d-\d\d\d)-(\d\d)$/))
                send.login = RegExp.$1+' '+RegExp.$2
              if(send.login[0] === '/') send.login = send.login.substr(1)
              if(send.login_2 && send.login_2.match(/^(\d\d\d-\d\d\d-\d\d\d)-(\d\d)$/))
                  send.login_2 = RegExp.$1+' '+RegExp.$2
              if(send.login_2[0] === '/') send.login_2 = send.login_2.substr(1)
              
              let orgInShardTid = await (await mainDbX.fetch_get(
                '/app/sysop/org_shard'
                , {gov_regnum:send.gov_regnum}
              )).text()
              let shard = orgInShardTid === 'false' ? values.global_org ? 0
                          : await calculateShard(send.gov_regnum)
                          : orgInShardTid.slice(-1)
              let orgDbX = shardedDbX(shard)
              
              await orgDbX.call_function('enshure_sysuser', {p_person: uinfo.personid})

          		let ntid = await orgDbX.call_function("reg_org",{
                  gov_regnum: values.gov_regnum
                  , login: send.login
                  , login_2: send.login_2
                  , app_region: values.app_region
                  , p: send
                })

              if (ntid) {
                if (orgInShardTid === 'false')
          		    await alert("Зарегистрирована!")
                else
                  await alert("Данная организация уже зарегистрирована и находится в " + (shard === '0' ? 'main' : 'shard_0' + shard))
              
          		  setKey(key=>key+1)
                setNameOrg(null)
              }
              else
                await alert("Организация не зарегистрирована!")
      		}
        }
      >{fc=>
          <Form className="inputForm backgroundRounded"
          >
            <div className="flexContainer">
              <div className="flexColumn">
                <p>ОГРН</p>
                <p><Field name="gov_regnum"  style={{width:"100%"}}
                    readOnly={fc.values.name}
                  /></p>
                <p className="error" ><ErrorMessage name="gov_regnum"/></p>
              </div>
                <div className="flexColumn">
                <p>СНИЛС основного пользователя</p>
                <p><Field name="login"  style={{width:"100%"}} /></p>
                <p className="error" ><ErrorMessage name="login"/></p>
              </div>
              <div className="flexColumn">
                <p>СНИЛС второго пользователя</p>
                <p><Field name="login_2"  style={{width:"100%"}} /></p>
                <p className="error" ><ErrorMessage name="login_2"/></p>
              </div>
            </div>

              <div className="flexContainer">
                  <div className="flexColumn">
                      <p>Регион</p>
                      <Field name="app_region" className="selectLikeInput"
                             style={{width: "100%", justifyContent: "left"}}
                             value={ed_regions_from_ogrn(fc.values.gov_regnum)}
                             fc={fc}
                             as={EdRegionEditInRegOrg}
                      />
                      <p>Проверка лицензии</p>
                      <Field name="app_skip_license" as="select" className="selectLikeInput"
                             style={{width: "100%"}}
                      >
                          <option value=""/>
                          <option value="Y">не нужна</option>
                          <option value="N">нужна</option>
                      </Field>
                      <p>Проверка аккредитации</p>
                      <Field name="app_skip_acred" as="select" className="selectLikeInput"
                             style={{width: "100%"}}
                      >
                          <option value=""/>
                          <option value="Y">не нужна</option>
                          <option value="N">нужна</option>
                      </Field>
                  </div>
                  <div style={{minWidth:"20em", flexBasis:"20em", flexGrow:1}}>
                  <p>Уровни образования</p>
                  <FlexColumnsMin className="edLevelBox">
                      {
                          ed_levels.map(e => <div key={e}
                                style={{minWidth:"15em", flexGrow:0, flexShrink:0}}
                              >
                              <Field type="checkbox" id={`app_ed_level.${e}`} name={`app_ed_level.${e}`}/>
                              <div>
                                  <label htmlFor={`app_ed_level.${e}`}>{ed_level_decode[e]}</label>
                              </div>
                          </div>)
                      }
                  </FlexColumnsMin>
              </div>
            </div>

            {fc.values.gov_regnum && <button type="button" 
              onClick={async ()=>{
                let data = await parse_islod(fc.values.gov_regnum)
                console.log(data)
                setNameOrg(data.details.name)
                if(data) {                  
                  fc.setValues(values=>({
                    ...values
                    , name: data.details.name
                    , sname: data.details.sname
                    , fin_regnum: data.details.fin_regnum
                    , fin_regnum2: data.details.fin_regnum2
                    , app_boss_fio: data.details.app_boss_fio
                    , app_boss_position: data.details.app_boss_position
                    , app_all_bosses: data.details.app_all_bosses
                    , app_ed_level:
                      Object.fromEntries(ed_levels.map(e=>[e, e in data.merged]))
                    , app_spec_list: data.merged
                    , app_islod_info: data.info
                  }))
                }
              }}
              >Заполнение из разных баз</button>
            }
            {
              extNameOrg && 
              <div className="flexContainer">
                <div className="flexColumn">
                <p>Наименование</p>
                <p>{extNameOrg}</p>
                </div>
              </div>
            }


           {false && fc.values.gov_regnum && <button type="button"
              onClick={async ()=>{
                let arr = await mainDbX.fetch_rds("import_islod_licenses","spec_list", 
                    {ogrn: fc.values.gov_regnum}) || []
                arr = arr[0]?.spec_list ?? '{}'; //FIXME: many orgs with one orgn
                console.log(arr)
                let lic = JSON.parse(arr)

                arr = await mainDbX.fetch_rds("import_islod_acred","spec_list", 
                    {ogrn: fc.values.gov_regnum}) || []
                arr = arr[0]?.spec_list ?? '{}'; //FIXME: many orgs with one orgn
                console.log(arr)
                arr = JSON.parse(arr||'{}')
                for(const ed in arr)
                  for(const sp in arr[ed])
                      lic[ed][sp].push('+')
                setOurLic(lic)
              }}
            >
              Лицензии и аккредитации из нашей базы
            </button>}
            {ourlic && Object.keys(ourlic).length &&
              <button type="button"
                onClick={()=>{
                    fc.setFieldValue('app_ed_level',
                      Object.fromEntries(ed_levels.map(e=>[e, e in ourlic]))
                    )
                    fc.setFieldValue('app_spec_list', ourlic??'')
                    setOurLic(null)
                }}>Заполнить
              </button>
            }
            {ourlic && <div>{
              letIn(ourlic)(lst=>
                  Object.keys(lst)
                  .map(ed=><div key={ed}>
                    <h5>{window.AppBundle.data.ed_levels.decode[ed]}</h5>
                    {
                      Object.keys(lst[ed])
                      .map(sp=><span key={sp}>
                        {sp}:{lst[ed][sp][0]}{lst[ed][sp] && '+'}
                        {' '}
                      </span>)
                    }
                  </div>)
              )
              }</div>
            }

            <p>
            <div className="flexContainer">
              <button type="submit">Зарегистрировать</button>
              <Field name="global_org" as="select">
                  <option value="">Обычная организация</option>
                  <option value="G">Глобальная организация</option>
              </Field>
            </div>
            </p>
          </Form>
      }</Formik>
	</>
}

export function FlexColumnsMin({style,...props}) {
  let r = useRef();
  let lastGoog = useRef();
  let def = defer();
  //let [h,setH] = useState(1);
  useEffect(()=>{
    if(r.current) {
      lastGoog.current = null; 
      //console.log('e', r.current)
      let obs = new ResizeObserver(
          (entries) =>{
            for (const entry of entries) {
              if(lastGoog.current === entry.target.offsetWidth && lastGoog.current === entry.target.scrollWidth||
                !entry.target.offsetWidth) continue;

              entry.target.style.height = "auto"
              let maxH = entry.target.offsetHeight

              let c = maxH+50; let cp = maxH+50;
              while(entry.target.scrollWidth <= entry.target.offsetWidth) {
                cp = c;
                entry.target.style.height = (c=Math.ceil(c*0.8))+"px"
                if(entry.target.scrollHeight > entry.target.offsetHeight) break;
              }
              entry.target.style.height = 2*cp +"px";
              def = def.then(()=>{
                  if(cp < 37) entry.target.style.height = "37px";
                  else entry.target.style.height = cp + "px";
              })
              lastGoog.current = entry.target.offsetWidth;
            }
          }
        )
      obs.observe(r.current)
      return ()=>obs.disconnect();
    }
  }, [r.current]);
  return <div ref={r} style={style} {...props} />  
}