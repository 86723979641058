import React from 'react'

import {transformImported} from 'contingent/import_defs'
import {ed_level_by_spec} from 'config/mega_monster'
import {alert} from 'azlib/components/modals'

function find_signer(orginfo, position, fio) {
	return orginfo.orgtree_signers.find(s=>
			`${s.last_name} ${s.first_name} ${s.middle_name}`
			.trim().toUpperCase() === fio.toUpperCase()
			)
}

function check_signer(orginfo, position, fio, spec_code, doctype_kind, ed_level_ap_p) {
	let ed_level = ({'9':'mn','11':'m'})[spec_code]
					?? ({'ПРОФЕССИОНАЛЬНОЕ ОБУЧЕНИЕ': 'p'
						, 'ДОПОЛНИТЕЛЬНОЕ ПРОФЕССИОНАЛЬНОЕ ОБРАЗОВАНИЕ': 'ap'})[ed_level_ap_p?.toUpperCase()]
					?? ed_level_by_spec(spec_code)

	doctype_kind = ({
					'С ОТЛИЧИЕМ':'-ok-good'
					, '':'-ok'
					, 'СВИДЕТЕЛЬСТВО':'-0'
					, 'СПРАВКА': '-fail'
					, 'СВИДЕТЕЛЬСТВО О ПРОФЕССИИ РАБОЧЕГО, ДОЛЖНОСТИ СЛУЖАЩЕГО': '-0'
					, 'СВИДЕТЕЛЬСТВО ОБ ОСВОЕНИИ ДОПОЛНИТЕЛЬНЫХ ПРЕДПРОФЕССИОНАЛЬНЫХ ПРОГРАММ В ОБЛАСТИ ИСКУССТВ': '-0'
					, 'ДИПЛОМ О ПРОФЕССИОНАЛЬНОЙ ПЕРЕПОДГОТОВКЕ': '-ok'
					, 'УДОСТОВЕРЕНИЕ О ПОВЫШЕНИИ КВАЛИФИКАЦИИ': '-1'
					, 'СВИДЕТЕЛЬСТВО В ОБЛАСТИ ФИЗИЧЕСКОЙ КУЛЬТУРЫ И СПОРТА': '-2'
					})
					[doctype_kind?.toUpperCase()]

	doctype_kind = ed_level + doctype_kind

	return orginfo.orgtree_signers.find(s=>
			`${s.last_name} ${s.first_name} ${s.middle_name}`
			.trim().toUpperCase() === fio.toUpperCase()
			&&
			s.sign_scope
			&&
			s.sign_scope.hasOwnProperty(doctype_kind)
			&&
			Object.keys(s.sign_scope[doctype_kind]).find(r => r === position)
	)
}

export function revalidateSigners(orginfo) {
	return table => {
		if(table.length<2) return table

		let idx = table[0].findIndex(e=>e.value.toUpperCase()==='ПОДПИСЬ' 
										|| e.value.toUpperCase()==='ПОДПИСИ')
		if(idx<0) return table;

		let ids = table[0].findIndex(e=>e.value.toUpperCase()==='9/11' 
			|| e.value.toUpperCase()==='КОД СПЕЦИАЛЬНОСТИ'
			|| e.value.toUpperCase()==='КОД НАПРАВЛЕНИЯ ПОДГОТОВКИ'
			|| e.value.toUpperCase()==='КОД ПРОФЕССИИ'
		)
		//if(ids<0) return table;

		let ide = table[0].findIndex(e=>e.value.toUpperCase()==='УРОВЕНЬ ОБРАЗОВАНИЯ')
		//if(ide<0) return table;

		let idk = table[0].findIndex(e=>e.value.toUpperCase()==='ВИД ДОКУМЕНТА'
									|| e.value.toUpperCase()==='ТИП ДОКУМЕНТА')
		if(idk<0) return table;

		if(table[1][0].value) return null; //не позаголовок
		for(let j = idx+1; j<table[0].length; ++j)
			if(table[0][j].value)
				return null; //хрень после подписи
		/*
		for(let j = idx; j<table[1].length; ++j)
			if(!table[1][j].value)
				return null; //нет должности
		*/
		table = [...table] //clone

		for(let i = 2; i < table.length; ++i)
			if(table[i][0].value)
				for(let j = idx; j < table[i].length; ++j) {
					if(table[1][j].value &&
						!check_signer(orginfo, table[1][j].value, table[i][j].value, ids>0 ? table[i][ids].value : null, table[i][idk].value, ide>0 ? table[i][ide].value : null))
						table[i][j].valid = false;
				}
		return table;
	}
}

export async function importInfosets(orginfo, changeField, value, scheme) {

	if(!value.length) {
		await alert('Нет данных для импорта')
		return;
	}

	console.log('value', value)

	let invalid = value.filter(x=>!x.valid).map(({valid,...x})=>x)
	value = value.filter(x=>x.valid).map(({valid,...x})=>x)

	//get first line
	let caption = value.shift()

	//formats
	//1. single header row, only known cols
	//2. single header row, known cols and infoset cols
	//3. two header rows, known cols and infoset cols with pargaraphs

	// infoset cols (if one header row), we skip col if caption is empty!

	let colHeaders = [caption.$]
	while(value[0] && !value[0].first_name) {
		colHeaders.push(value.shift().$ || [])
	}
	// pad first row
	if (colHeaders[0]) {
		colHeaders.forEach((e, i) => {
			colHeaders[0] = Object.assign(Array(e.length).fill(''), colHeaders[0])
		})
		colHeaders = colHeaders[0].map(
			(col0, i) =>
				colHeaders
					.map(e => e[i] || '')
		) //convert [r1,r2,...] => [[c1],[c2],...] (transpose)

		let levels_min = colHeaders.map(c =>
			//last nonempty value
			c.reduce((a, e, i) => a || (e ? i + 1 : a), 0)
		)
		let levels_max = colHeaders.map(c =>
			//last nonempty value
			c.reduce((a, e, i) => e ? i + 1 : a, 0)
		)


		value = value.map(({$, ...s}) => {
			//known cols in the 's' variable
			let last_level = 0;
			let infoset = [];
			$ && $.forEach((v, i, a) => {
				v = v.toString();
				let level_min = levels_min[i]
				let level_max = levels_max[i]

				let subheader = null;
				if (level_min < level_max) {
					if (colHeaders[i][level_max - 1][0] === '+') //started with '+'
					{
						subheader = colHeaders[i][level_max - 1].substr(1).trim()
						--level_max;
					}
				}
				if (level_min < level_max) {
					// process header lines (from level_min to level_max excluded)
					// this works with +subheader & empty headers too
					for (let k = level_min; k < level_max; ++k) {
						const hdr = colHeaders[i][k - 1];
						if (hdr.match(/^(.*?)\[(.*)\]$/)) {
							// header has columns
							let hdr = RegExp.$1.trim();
							infoset.push([hdr, {
								columns:
									RegExp.$2.trim().split('|').map(e => e.trim())
								, th: true
							}
								, k - 1])
						} else {
							if (hdr) infoset.push([hdr, {th: true}, k - 1])
							else {
								colHeaders[i].push(colHeaders[i].splice(colHeaders[i].indexOf(""), 1)[0]);
								level_max = level_max - 1;
							}
						}
					}
					last_level = level_max - 1; // paragraph set last_level
				}
				if (level_min === level_max && level_max === 1) last_level = 0;
				//default param name = last col header
				// if no colheaders, level_max === 0 => pname will be undefined
				let pname = colHeaders[i]?.[level_max - 1];

				if (pname === undefined || pname === '-') {
					// empty header => take name from the value itself
					// '-' is a special case => take name from the value itself
					if (v.match(/^([^|]+)\s*[|]\s*(.*)/)) {
						// value has is a name~value pair
						pname = RegExp.$1.trim()
						v = RegExp.$2.trim()
					} else {
						// value is a name (without value)
						pname = v;
						v = ' ';
					}
				}

				//output value
				if (pname && v) {
					let vals = v.split('|')
					if (subheader !== null) {
						infoset.push([pname, {
							columns: vals.map(e => e.trim())
							, group: true
						}, level_max - 1])
						if (subheader)
							infoset.push([subheader, [], level_max])
						last_level = level_max; // group set level max
					} else if (vals.length > 1) {
						// plain params has own or default level
						infoset.push([pname, vals.map(e => e.trim()),
							last_level ? last_level : level_max - 1
						])
					} else {
						infoset.push([pname, v.trim() ? v.trim() : [],
							last_level ? last_level : level_max - 1
						])
					}
					// a      a +
					// p p x    p x
				}
			})

			//clean empty headers
			for (let i = infoset.length - 1; i >= 0; i--)
				if (infoset[i][1].hasOwnProperty("th"))
					if (i === infoset.length - 1 || infoset[i][2] >= infoset[i + 1][2])
						infoset.splice(i, 1)

			if (infoset.length)
				return {...s, infoset: infoset, doctype_infoset: ''};
			return s;
		})

		// extract signers
		value = value.map(s => {
			if (!s.infoset) return s
			let i;
			for (i = 0; i < s.infoset.length; ++i) {
				if (s.infoset[i][2] === 0 &&
					(s.infoset[i][0].toUpperCase() === 'ПОДПИСЬ'
						|| s.infoset[i][0].toUpperCase() === 'ПОДПИСИ'
					)
				) {
					break;
				}
			}
			if (i === s.infoset.length)
				//no signers
				return s;

			//found signers
			let infoset = s.infoset.slice(0, i)
			let signers = []
			for (i = i + 1; i < s.infoset.length; ++i) {
				if (s.infoset[i][0] && s.infoset[i][1].trim())
					signers.push({
						c: '1000'
						, id: find_signer(orginfo, s.infoset[i][0], s.infoset[i][1]).person
						, r: s.infoset[i][0]
					})
			}
			return signers.length ? {...s, infoset, signers}
				: {...s, infoset}
		})

		// if we have docnumbers and infoset, ask signer and go to doc-sign (and force reacalc sign)
		// if we have no infoset go to st-check

		// also
		// calc spec_name
		// calc ed_level
		// calc doc_kind
		// it is in postprocessImport
	}

	value = transformImported(value, orginfo, scheme)
	
	//invalid = [...invalid, ...value.filter(x=>x.valid===false).map(({valid,...x})=>x)]
	//value = value.filter(x=>x.valid!==false).map(({valid,...x})=>x)
	console.log(value);

	value = value.map(row=>({...row, tid: orginfo.tid})) //set tid for all docs

	let imported;
	if(value.length !== 0) {
		imported = await orginfo.DbX.call_function('import_docs_adv', {
			s: value
		})
	}else
		await alert('Данные не были импортированы, так как файл импорта сформирован неверно');
//	console.log('return jsonb', imported)

//	console.log('inv', invalid)

	changeField({
		import : imported
		, invalid : invalid
	})
}

export function mergeHeaderRow(value) {
	let headerRow=[];
	let count = 1;
	let curIndex = 0;
	value.forEach((val, index, arr) => {
		headerRow[index]={}
		headerRow[index].value=val.value;
		if ((val.value === "") && (index !== 0) && (index !== arr.length - 1)) {
			count += 1;
		}
		else if ((val.value === "") && (index === arr.length - 1)){
			headerRow[curIndex].colspan = count+1;
		}
		else if(index === arr.length - 1){
			headerRow[curIndex].colspan = count;
			headerRow[index].colspan = 1;
		}
		else {
			headerRow[curIndex].colspan = count;
			curIndex = index;
			count = 1;
		}
	})
	headerRow = headerRow.filter(e => e.colspan !== undefined)
	return headerRow;
}