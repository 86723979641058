//import { openDB } from 'idb';

const dbName = 'digdoc_db';

let stores = {keygen: []}
let storesAuto = {keygen: []}

export let storesModel = {};

export const initStores = (model) => {  
	storesModel = model;
	for(const i in model) {
			if(model[i].$.BASE) {
				stores[i] = { base: model[i].$.BASE
										, uri: model[i].$.RDS_URI 
										, curi: model[i].$.CRUD_URI 
									}
				continue;
			}
			let pk = []
			let fpk = []
			for(const f in model[i])
				if(model[i][f].PK) {
					pk[ model[i][f].PK - 1 ] = f;
				}
			//if(pk.length)
			stores[i] = pk;
			storesAuto[i] = model[i].$.AUTO_KEY;
	}
}

const baseStore = (store) => stores[store].base ?? store; 
const storeRdsUri = (store) => stores[store].uri; 
const storeCrudUri = (store) => stores[store].curi; 


function openDB(name, ver, ops) {
	let req = window.indexedDB.open(name, ver)
	req.onupgradeneeded = event=> {
		ops.upgrade(event.target.result, event.oldVersion, event.newVersion)
	}
	return new Promise((resolve,reject)=>{
			req.onsuccess = e => resolve({
						transaction(obj, mode) {
								let db = e.target.result;
								let ret = db.transaction(obj, mode)
								let baseOS = ret.objectStore.bind(ret)
								ret.done = new Promise((resolve,reject)=>
										ret.oncomplete = resolve
									)
								if(typeof obj === 'string')
									ret.store = wrappedStore(baseOS(obj))
								ret.objectStore = name => wrappedStore(baseOS(name))
								return ret
							}
							, get(obj, key) {
								return this.transaction(obj,'readonly').store.get(key)
							}
			})
	})
}

const DB = openDB(dbName, 3, {
  upgrade(db, oldVersion) {
  	console.log('upgrade db from', oldVersion)
  	/* eslint-disable-next-line default-case */
	  switch(oldVersion||0) {
	  	case 0:{
	  		db.createObjectStore('keygen', { autoIncrement : true })
	  		db.createObjectStore('objects')
	  		const sets = db.createObjectStore('sets', { autoIncrement : true })
		  		sets.createIndex('key', 'key')
		  		sets.createIndex('filter', 'filter')
		  	}
	  	case 1:{
	  		db.deleteObjectStore('sets')
	  		db.deleteObjectStore('objects')
	  		let obj= db.createObjectStore('objects',{
	  			keyPath: ['store', 'key']
	  		})
	  		obj.createIndex('store', 'store')
	  		}
	  	case 2:{
	  		db.deleteObjectStore('objects')
	  		const obj= db.createObjectStore('objects')
	  		obj.createIndex('store', ['$$$store','$$$pid'])
	  		}
	  	//
	  	// ..etc  
	  }
  },
});

function wrap(req) {
	return new Promise((resolve, reject)=> {
		req.onsuccess = e=>resolve(e.target.result)
		req.onerror = reject
	})
}
function wrapResult(res) { return wrap(res) }
function wrapCursor(c) {
	return c && wrap(c).then(r=>(r && {
		key: r.key
		, primaryKey: r.primaryKey
		, continue(key) {
			let x = r.continue()
			return wrapCursor(x)
		}
		, delete() {
			return wrapResult(r.delete())
		}
	}))
}

function wrappedStore(s) {
	return {
		add(data) { return wrapResult(s.add(data)) }
		, get(key) { return wrapResult(s.get(key)) }
		, put(data,key) { return wrapResult(s.put(data,key)) }
		, delete(key) { return wrapResult(s.delete(key)) }
		, getAll(q,c) { return wrapResult(s.getAll(q,c)) }
		, getAllKeys(q,c) { return wrapResult(s.getAllKeys(q,c)) }
		, openCursor(range, direction) {
						return wrapCursor(s.openCursor(range, direction))
		}
		, index(name) { let idx = s.index(name);
										return {
											get(key) { return wrapResult(idx.get(key)) }
											, getKey(key) { return wrapResult(idx.getKey(key)) }
											, getAll(q,c) { return wrapResult(idx.getAll(q,c)) }
											, getAllKeys(q,c) { return wrapResult(idx.getAllKeys(q,c)) }
											, openCursor(range, direction) {
															return wrapCursor(idx.openCursor(range, direction))
											}
									} 
		}
	}
}

/*
used methods
+ openDB
+ (await DB) - wait db promise (on???)
+ .transaction (name/array, mode) => tr-n
+ if one name => make objectStore
+ .done => promose onComplete
+ .objectStore => get store
- in store
+- .add
+- .get in stor and in DB
+- .put
+- .delete
+- .index - get index by name
+- .openCursor in store and in index
+- .getAll in store and in index
-- .delete in cursor
-- .continue in cursor
-- .fetch in cursor(one step back to continue)

*/

const keyFields = store => stores[baseStore(store)] 
const keyFieldsAuto = store => storesAuto[baseStore(store)] 

export const generate = async () => {
	const tr = (await DB).transaction('keygen', 'readwrite')
	let key = await tr.store.add({})
	await tr.store.delete(key)
	await tr.done;
	return -(key+1); //starts from one < 0  to distinct from real keys
}

//we can left key field undefined
// OR use locallly unique value
// to mark them as 'generated'
export const isGenerated = key => key === undefined ||
																		typeof key === 'number' && key < 0;
export const isGeneratedKey = key => key.find(isGenerated)

export const getKeyValues = (store, data) => keyFields(store).map(k=>data[k])

export const getKeyValueByName = (store, data, name) => {
	let kf = keyFields(store)
	let idx = kf.indexOf(name)
	return getKeyValues(store, data)[idx]
}

const getStoreKey = (store, key) => [baseStore(store), window.UserInfo.personid, key]
		
export const get = async (store, key) => {
	key = getStoreKey(store, key)
	let local = await (await DB).get('objects', key)
	return local && 
		(delete local.$$$store
		,delete local.$$$pid
		,local)
}


//emulate normal db update with less fields than full object
export const put = async (store, data) => {
	let data_key = getKeyValues(store,data)
	let key = getStoreKey(store,data_key)
	const tr = (await DB).transaction('objects', 'readwrite')
	let prev = (await tr.store.get(key))?.data
	await tr.store.put( {...data
			, $$$store: key[0]
			, $$$pid: key[1]
			}
			, key) 
	await tr.done
}

export const remove = async (store, key) => {
	key = getStoreKey(store,key)
	const tr = (await DB).transaction('objects', 'readwrite')
	await tr.objectStore('objects').delete(key)
	await tr.done
}

const compareWithFixed = fixed => a =>
	{
		for(const i in fixed)
			if(fixed[i] !== undefined && fixed[i] !== a[i]) return false;
		return true;
	}

/*
	objects: key => data
*/

const read_set = async (store, fixed) => {
	let base = baseStore(store)
	let local = (await (await DB).transaction('objects')
		.store.index('store')
		.getAll( IDBKeyRange.only([base, window.UserInfo.personid]) )
	)
	.map(e=>(delete e.$$$store, delete e.$$$pid, e))
	.filter(compareWithFixed(fixed))
	return local
}

const LocalDB = {storeRdsUri,storeCrudUri,baseStore,get,put,remove,read_set, generate
	, keyFields, getKeyValues, keyFieldsAuto}
export default LocalDB;