import React, {useContext} from 'react';
import { useFormContext } from './formik-fields'

import {useRowContext, SuperTableContext} from './super-table-int'

import {findViewer} from './model.controls.js';


export function selectionMode(tableContext, op, sel) {
  	tableContext.setSharedState(state=> ({...state,
  		multiRowOp: op && {
		  				op
		  				, selected: sel ?? new Map() //row keys
		  			}
  	}))
}

export function SelectionMode(props) {
	const tableContext = useContext(SuperTableContext)
	const mrs = tableContext.sharedState?.multiRowOp
	return props.children(
	 		op => selectionMode(tableContext, op)
	 	)
}

// this components are stable between all calls of the same form
// they are 'imperative' methods
function formikSafeToStore(formik) {
	let safe ={
    resetForm: null,
    validateForm: null,
    validateField: null,
    setErrors: null,
    setFieldError: null,
    setFieldTouched: null,
    setFieldValue: null,
    setStatus: null,
    setSubmitting: null,
    setTouched: null,
    setValues: null,
    setFormikState: null,
    submitForm: null,
	}

	for(const i in safe)
		safe[i] = formik[i]

	return safe
}

function RowCheckBox(props) {
	const formik = useFormContext()
	const ctx = useRowContext();
	const mrs = ctx.tableContext?.sharedState.multiRowOp

	if(formik.values.status == 'doc-sign' || !formik.values.status)
		return null
	if((formik.values.doctype_kind || formik.values.doctype_kind === '') 
			&& formik.values.status !== 'doc-check' 
			&& !formik.values.status.startsWith('issued-'))
		return null
	if(!props.command && !mrs)
		return null
	if(mrs?.op.guard && !mrs?.op.guard(formik.values)) {
		return null
	}
	return <input type="checkbox"
						checked={mrs?.selected.has(ctx.stringKey)??false}
						onChange={e=>{
									const nset = mrs?.selected ?? new Map()
									e.target.checked
									?
										nset.set(ctx.stringKey, 
											formikSafeToStore(formik)
										)
									:
										nset.delete(ctx.stringKey)
									;
									selectionMode(ctx.tableContext
										, props.command ?? mrs?.op 
										, nset)
							}}
				/>
}


function MultiRowOpHeader(props) {
	const tableContext = useContext(SuperTableContext)
	const mrs = tableContext.sharedState?.multiRowOp
	if(!mrs) return tableContext.source.size===0?null
			: <div className="MultiRowOpHeader trigger">
			 {//render props - mode selector setter
			 	props.children(
			 		op => selectionMode(tableContext, op)
			 	)
			 }
			</div>
	return <div className="MultiRowOpHeader">
					<button className="block ok"
					onClick={async ()=>{
						try{
						let arr = Array.from(mrs.selected)
											.map(([k,f])=>[JSON.parse(k),f])
						let op = mrs.op.processor?
									mrs.op.processor
									:
									mrs.op;

						await op(arr, tableContext)

						} finally {
							selectionMode(tableContext, null)
						}
					}}
					>✔</button>
					<button className="block undo"
					onClick={()=>{selectionMode(tableContext, null)}}
					>↶</button>
				</div>
}

export function OperationCol() {
	return <RowCheckBox />
}

OperationCol.columnProps = (props) => 
	({
		colKey: '@operations'
		, ...props
		, label: <MultiRowOpHeader>{props.label}</MultiRowOpHeader>
	})


export function OperationChecks({command}) {
	return <RowCheckBox command={command??true}/>
}

OperationChecks.columnProps = ({command,...props}) => 
	({
		colKey: '@operations'
		, ...{style: {...props?.style, textAlign:"left"}, ...props}
		, label: <CheckAll command={command} />
	})

function CheckAll({command}) {
	const tableContext = useContext(SuperTableContext)
	const mrs = tableContext.sharedState?.multiRowOp
	return !!tableContext.source.size && <input type="checkbox"
						checked={
							Array.from(tableContext.source.keys()).every(
								k => mrs?.selected.has(k) 
							)
						}
						onChange={e=>{ 
							const nset = mrs?.selected ?? new Map()
							for(const [k,v] of tableContext.source.entries()) {
									e.target.checked
									?
										(!mrs?.op.guard || mrs?.op.guard(v.data))
										&&
										nset.set(k, 
											null //formikSafeToStore(formik)
										)
									:
										nset.delete(k)
									;
							}
							selectionMode(tableContext
								, command ?? mrs?.op ?? true
								, nset)
						}}
				/>
}

//model Col:
//no children - use viewer
//children is an element - clone children an replace child
export function ModelCol({value, rowObj, status, placeholder, name, children, ...props}) {
	const tableContext = useContext(SuperTableContext)
	const Viewer = findViewer(tableContext.store, name, props);
	const V = Viewer({value,placeholder})
	return children? 
				children instanceof Function?
					children(V,value,rowObj,status)
					: React.cloneElement(children, {}, V)
				: V
}

ModelCol.columnProps = (props, ops) =>
	({
		label: window.AppBundle.model[ops.store][props.name].caption
		, ...props
	})