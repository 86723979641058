import React, {useContext, useState} from 'react'

import {fullPersonName} from 'azlib/components/name_funcs'
import {ModalButton, PopupMenu, PopupFrame, confirm, alert} from 'azlib/components/modals'

import org, {OrgInfoContext} from 'org/org'

import {later,letIn} from 'azlib/components/helpers'

import { DbTableEdit as Table } from 'azlib/components/super-table'

import {generateEMCHD, cancelEMCHD} from '../sign/gen-emchd.js'

import {
	ModelField,
	LabeledField,
	Field,
	modalFormik,
	formikExtra,
	ExtendFormikContext
} from 'azlib/components/formik-fields'
import {fakeEvent, FieldAsPrompt, Checkbox2} from 'azlib/components/std-controls'
import UserInfoContext from 'UserInfo'
import {object, string} from "yup";

import {smart_ed_level} from 'config/ed_levels_map'
import {Details} from 'azlib/components/ui_elems'

import {PopupLog} from 'contingent/log'

import {ReactComponent as EditSVG} from 'contingent/edit.svg'
import {ErrorMessage} from "formik";


function MultyScopes({value, name, onChange, readOnly, person, orginfo, ...props}) {
	let [edit, setEdit] = useState(false)

	let parsed = value? JSON.parse(value) : {}

	const scopes = window.AppBundle.data.doc_scope_new.orderedKeys;
	const decode = (k) => window.AppBundle.data.doc_scope_new.decode[k];

	return edit ? <>
				<button type="button" onClick={()=>setEdit(false)}>X</button>
				{
					scopes
						.filter(Boolean)
						.map(e=><div key={e}>
							<label>
								<input type="checkbox" checked={e in parsed} disabled={readOnly}
									   onChange={async (event)=>{
											let nv = Object.assign({},parsed);
											delete nv[e];
											if(event.target.checked) nv[e] = '';
											if(e === 'signer' && e in parsed && !event.target.checked) {
												let docs = await signerHasDocs(orginfo, person);
												if(docs.length > 0) {
													nv[e] = '';
													await alert('Ошибка! Пользователю направлены документы, перед отменой функции измените подписывающего в разделе "Документы"');
												}
											}
										   	
											onChange?.(fakeEvent(name,
												JSON.stringify(nv)
											))
									   	}}
								/>
								{decode(e)}
							</label>
						</div>)
				}
			</>
		:<>
			<button type="button" className="link" onClick={()=>setEdit(true)}><EditSVG/></button>
			  <div style={{textAlign: "center"}}>
				  {letIn(scopes
						  .filter(e => e in parsed))
				  (filtred=>
					  filtred.map(e=><span key={e} style={{lineHeight:"180%",background:"rgba(63, 175, 216, 0.4)",borderRadius: "5px",padding:"0.1em 0.5em"}}>
							[ {decode(e)} ]<br></br>
						</span>)
				  )}
			  </div>
		</>
}

function SignScopes({value, value2, name, name2, onChange, readOnly, orginfo, uinfo, row, confirmRoles, ...props}) {
	let confirmedRoles = value? JSON.parse(value) : {}
	let newRoles = value2? JSON.parse(value2) : {}

	let diff = rolesDiff(confirmedRoles, newRoles)

	let [isChanged, setIsChanged] = useState(Object.keys(diff.addedRoles).length > 0 || Object.keys(diff.removedRoles).length > 0)
	let [addedRoles, setAddedRoles] = useState(diff.addedRoles)
	let [removedRoles, setRemovedRoles] = useState(diff.removedRoles)

	/*
	doctype => { role: '' }
	*/

	const customSchemes = window.AppBundle.data.signers_scheme.decode;

	const schemes = window.AppBundle.data.doc_kinds.orderedKeys
					.filter(k=>smart_ed_level(k) in orginfo.app_ed_level)
					.map(k=>[ k,
								customSchemes[k]?
								[ ...customSchemes[k]
										, ...orginfo.app_custom_signers
								]
								:
								['Руководитель образовательной организации'
										, ...orginfo.app_custom_signers
								]
							]
						)
					;
	const decode = (k) => window.AppBundle.data.doc_kinds.decode[k];

	return (
	<div>
	<PopupMenu trigger={<div>
						  <div style={{textAlign: "center"}}>
						  	<h4>Подтвержденные роли</h4>
							  {letIn(schemes.filter(([k,s])=> k in confirmedRoles))(used=>
								used.length?
								used.map(([k,s])=><div key={k}>
									{decode(k)}:<br/>
									<i>{s.filter(p=>p in confirmedRoles[k])
									  .join('; ')
									}</i>
									<hr/>
								</div>)
								: '...'
							  )}
						  </div>
						</div>
					}>
		{
			schemes
				.map(([k,s])=><Details key={k}
						trigger={decode(k)}
						initialOpen={!!newRoles[k]}
					>
					{s.map(e=><div key={e}>
					<label>
						<input type="checkbox" checked={!!(newRoles[k] && e in newRoles[k])} disabled={readOnly}
							   onChange={(event)=>{
								   let nv = { ... newRoles[k] };
								   delete nv[e];
								   if(event.target.checked) nv[e] = '';
								   let np = { ...newRoles}
								   if(Object.keys(nv).length) {
								   	 np[k] = nv
								   } else {
								   	 delete np[k]
								   }
								   onChange?.(fakeEvent(name2,
									   Object.keys(np).length?
									   	JSON.stringify(np)
									   : null
								   ))

								setIsChanged(true)

								diff = rolesDiff(confirmedRoles, np)
								setAddedRoles(diff.addedRoles)
								setRemovedRoles(diff.removedRoles)
							   }}
						/>
						{e}
					</label>
					</div>)}
				</Details>)
		}
	</PopupMenu>
	
	{isChanged && <div style={{textAlign: "center"}}>
		<h4>Изменения</h4>
		{letIn(schemes.filter(([k,s])=> k in addedRoles))(used=>
			used.length?
			used.map(([k,s])=><div key={k}>
				{decode(k)}:<br/>
				 <span style={{color:"green", fontSize:"14pt"}}><b>+</b></span>&nbsp;
				 <i>{s.filter(p=>p in addedRoles[k])
				.join('; ')
				}</i>
				<hr/>
			</div>)
			: ''
		)}
		{letIn(schemes.filter(([k,s])=> k in removedRoles))(used=>
			used.length?
			used.map(([k,s])=><div key={k}>
				{decode(k)}:<br/>
				<span style={{color:"red", fontSize:"16pt"}}><b>−</b></span>&nbsp;
				<i> {s.filter(p=>p in removedRoles[k])
				.join('; ')
				}</i>
				<hr/>
			</div>)
			: ''
		)}
		{confirmRoles ? <div style={{margin:"10px 0 0 0"}}>
							<button className="intableOp block" 
								onClick={
									async () => {
										if (Object.keys(newRoles).length > 0) {
											let parentData = await orginfo.DbX.call_function('get_parent_data'
												, {ppath: orginfo.path})//gov_regnum,fin_regnum,fin_regnum2,app_boss_position,name,app_all_bosses
											let pd = parentData.split(';')
											let doverNum = await orginfo.DbX.call_function('gen_dover_num'
												, {p_person: row.person, p_tid: row.tid})
											let xml = generateEMCHD(
												doverNum
												, pd
												, uinfo
												, row.person$
												, row.position
												, row.sign_scope_changed
												, orginfo
												, true
											)
											if (!xml) return;
											else {
												let signature = ''
				
												let done = await orginfo.DbX.call_function('sign_signers_roles',{
													p_dover_num: doverNum, p_person: row.person, p_tid: row.tid,
													p_emchd: xml, p_signature: signature, active: 1
												})

												if (done)
													window.location.reload()
												else {
													alert('Недостаточно прав!')
													return;
												}
											}
										}
										else {
											let xml_revoked = cancelEMCHD(row.dover_num)
											let signature = ''
											if(!row.dover_num) {
												alert('Ошибка отмены ролей, ранее не сформирована МЧД!')
												return;
											}
											let done = await orginfo.DbX.call_function('sign_signers_roles',{
												p_dover_num: row.dover_num, p_person: row.person, p_tid: row.tid,
												p_emchd: xml_revoked, p_signature: signature, active: 0
											})

											if (done)
												window.location.reload()
											else {
												alert('Недостаточно прав!')
												return;
											}
										}
										setIsChanged(false)
										}}>
							Подтвердить</button>
						</div> : null}
	</div>
	}
	</div>
	)
}

export function rolesDiff(sign_scope, sign_scope_changed) {
	let removedRoles = {};
	let addedRoles = {};

	for (let key in sign_scope) {
		if (key in sign_scope_changed) {
			if (JSON.stringify(sign_scope[key]) !== JSON.stringify(sign_scope_changed[key])) {
				for (let role in sign_scope[key]) {
					if (!(sign_scope_changed[key] && role in sign_scope_changed[key])) {
						if (!(key in removedRoles)) removedRoles[key] = {};
						removedRoles[key][role] = "";
					}
				}

				for (let role in sign_scope_changed[key]) {
					if (!(sign_scope[key] && role in sign_scope[key])) {
						if (!(key in addedRoles)) addedRoles[key] = {};
						addedRoles[key][role] = "";
					}
				}
			}
		} else {
			for (let role in sign_scope[key]) {
				if (!(key in removedRoles)) removedRoles[key] = {};
				removedRoles[key][role] = "";
			}
		}
	}

	for (let key in sign_scope_changed) {
		if (!(key in sign_scope)) {
			if (!(key in addedRoles)) addedRoles[key] = {};
			for (let role in sign_scope_changed[key])
				addedRoles[key][role] = "";
		}
	}
    return { removedRoles, addedRoles };
}

export function сhangedSignScopesForSign(sign_scope, sign_scope_changed, orginfo) {
	sign_scope = sign_scope? JSON.parse(sign_scope) : {}
	sign_scope_changed = sign_scope_changed? JSON.parse(sign_scope_changed) : {}

	let diff = rolesDiff(sign_scope, sign_scope_changed);

	const customSchemes = window.AppBundle.data.signers_scheme.decode;

	const schemes = window.AppBundle.data.doc_kinds.orderedKeys
					.filter(k=>smart_ed_level(k) in orginfo.app_ed_level)
					.map(k=>[ k,
								customSchemes[k]?
								[ ...customSchemes[k]
										, ...orginfo.app_custom_signers
								]
								:
								['Руководитель образовательной организации'
										, ...orginfo.app_custom_signers
								]
							]
						)
					;
	const decode = (k) => window.AppBundle.data.doc_kinds.decode[k];

	return (
		<div style={{textAlign: "center"}}>
			<h4>Подтвержденные роли</h4>
			{letIn(schemes.filter(([k,s])=> k in sign_scope))(used=>
								used.length?
								used.map(([k,s])=><div key={k}>
									{decode(k)}:<br/>
									<i>{s.filter(p=>p in sign_scope[k])
									  .join('; ')
									}</i>
									<hr/>
								</div>)
								: '...'
			)}
			<h4>Изменения</h4>
			{letIn(schemes.filter(([k, s]) => k in diff.addedRoles))(used =>
				used.length ?
					used.map(([k, s]) => (
						<div key={k}>
							{decode(k)}:<br />
							<span style={{ color: "green", fontSize: "14pt" }}><b>+</b></span>&nbsp;
							<i>{s.filter(p => p in diff.addedRoles[k]).join('; ')}</i>
							<hr />
						</div>
					))
					: ''
			)}
			{letIn(schemes.filter(([k, s]) => k in diff.removedRoles))(used =>
				used.length ?
					used.map(([k, s]) => (
						<div key={k}>
							{decode(k)}:<br />
							<span style={{ color: "red", fontSize: "16pt" }}><b>−</b></span>&nbsp;
							<i>{s.filter(p => p in diff.removedRoles[k]).join('; ')}</i>
							<hr />
						</div>
					))
					: ''
			)}
		</div>
	);
}

export function isDiff(sign_scope, sign_scope_changed){
	let diff = rolesDiff(sign_scope? JSON.parse(sign_scope) : {}
				, sign_scope_changed? JSON.parse(sign_scope_changed) : {}
		);
	if(Object.keys(diff.addedRoles).length > 0 || Object.keys(diff.removedRoles).length > 0)
		return true
}

export function checkManagers({modelData, name, value, onChange, onBlur
							   , readOnly, refFieldValidate,orginfo, ...props})
{
	const options = modelData ?
		Array.isArray(modelData) ? modelData
			: window.window.AppBundle.data[modelData].orderedKeys
		: ['',1];
	return <div><input type="checkbox" name={name}
					   checked={value!==options[0] && value!==null}
					   onChange={async (e) => {
						   if(readOnly) return;
						   let managers =  await orginfo.DbX.fetch_rds('persons2tree', "tid manager",
							   {tid: orginfo.tid, manager: true})
						   if (value && managers.length === 1) await confirm('После подтверждения управление пользователями в организации станет недоступно');
						   e.target.checked = !value;
						   onChange?.(fakeEvent(e.target.name,
							   e.target.checked?
								   options[1] : options[0]
						   ))
						   onBlur?.(e)
					   }}
	/></div>
}

function signerHasDocs(orginfo, person) {
	let signers =
		orginfo.DbX.fetch_rds('doc_signer_persons', "signer",
				{tid : orginfo.tid, status: 'doc-sign', signer:person, signature: null})
	return signers
}

export function OrgMembers() {
	const orginfo = useContext(OrgInfoContext);
	const tid = orginfo.tid;
	let uinfo = useContext(UserInfoContext);
	let currentPerson = uinfo.personid
	let [changed,setChanged] = useState(null)
	return <>
		<div style={{float:"right", margin:"1em 1em 0 0"}}>
		<PopupLog table="persons2tree" tkey={tid}
					  fixed={{
						  tname: 'orgtree'
						  ,stname:'persons2tree'}}
					  transform={diff_transform} />
		</div>
		<h1>Пользователи</h1>
		{changed &&
			<PopupFrame
				style={{
					position:"fixed"
					, top: "1em"
					, right: "1em"
					, color: "red"
					, zIndex: 10
				}}
			>
			Для активации функций ОБНОВИТЕ страницу
			</PopupFrame>
		}
		<Table className="simpleTable"
			store={orginfo.DbX.store("persons2tree", { tid }) }
			extra="dover_num"
			handleSave={(values,changed,...rest)=>{
				if(changed && values.person === currentPerson) 
					later(1).then(()=>setChanged(true))
				return Table.directSave(values, changed, ...rest)
			}}
			>
			<Table.Col label="Фамилия Имя Отчество" colKey="pname" extra="person{login last_name first_name middle_name}"
			>{(v,row)=> fullPersonName(row.person$, true)}
			</Table.Col>
			<Table.ColGroup label="Функции" colKey="@edit" style={{textAlign:"center"}}>
				<Table.Col label="Управление пользователями" colKey="manager">
					<LabeledField name="manager" label=""
						readOnly={!orginfo.m}
						as={checkManagers}
						orginfo={orginfo}
					/>
				</Table.Col>
				<Table.Col label="Конфигурирование" colKey="tuner">
					<ModelField name="tuner" label=""
						readOnly={!orginfo.m && !orginfo.tuner}
					/>
				</Table.Col>
				<Table.Col label="Работа в реестре" colKey="registry">
					<ModelField name="registry" label=""
								readOnly={!orginfo.m && !orginfo.tuner}
					/>
				</Table.Col>
				<Table.Col label="Документы" colKey="docs"  style={{minWidth:"16em"}}>
					{(v,row)=>
						<LabeledField name="doc_scope_new" label=""
									  value={row.doc_scope_new}
									  person={row.person}
									  orginfo={orginfo}
								  as={MultyScopes}
								  readOnly={!orginfo.m && !orginfo.tuner}
					/>  }
				</Table.Col>
				<Table.Col label="Роль подписывающего" colKey="sign" extra="sign_scope sign_scope_changed">
					{(v,row)=>
						<LabeledField name="sign_scope" name2="sign_scope_changed" label=""
									  value={row.sign_scope}
									  value2={row.sign_scope_changed}
									  as={SignScopes}
									  orginfo={orginfo}
									  uinfo={uinfo}
									  readOnly={!orginfo.m && !orginfo.tuner}
									  row={row}
									  confirmRoles={uinfo.userAreas.admin}
						/> }
				</Table.Col>
			</Table.ColGroup>
			<Table.Col label="Должность" colKey="position">
				<LabeledField name="position" placeholder="не задана" style={{justifyContent:"center"}}
					as={FieldAsPrompt}
					readOnly={!orginfo.m && !orginfo.tuner}
				/>
			</Table.Col>
			<Table.Col label="" colKey="@ops" className="del-paddings-del-button">
				{(v,row)=><> 
					{( currentPerson!==row.person
						&& orginfo.m || uinfo.userAreas.sysop || uinfo.userAreas.admin
					) 
					&& <Table.Del confirm readOnly={!orginfo.m} style={{marginBottom:"1em"}}/>}
					<div style={{padding:"inherit"}}>
						{uinfo.userAreas.sysop &&
						<PopupLog table="persons" tkey={row.person}
								  transform={diff_transform_person} />
						}
					</div>
				</>}
			</Table.Col>
			<Table.After>
				{(orginfo.m || uinfo.userAreas.sysop) &&
				<Table.Add disabled={!orginfo.m}
				command={async (ops) => {
					let userInfo = await inviteUser()
					let login = userInfo.login;
					let position = userInfo.position;
					if(login[0] === '/') login = login.substr(1)
					let person = await orginfo.DbX.call_function('invite_user'
					, {tid, login, position})
					await ops.add_to_list([tid,person])
						}}
				>
					Пригласить пользователя
				</Table.Add>
				}
			</Table.After>
		</Table>
	</>
}

function inviteUser(){
	return modalFormik({
		initialValues: {login:'', position: ''}
		, initialStatus:{}
		, validationSchema:
			object({
				login:string()
					.required('Обязательное поле')
					.matches(/^\/(.+)|^\d\d\d-\d\d\d-\d\d\d \d\d$/, "Формат: 111-111-111 11")
				, position: string().required('Обязательное поле')
			})
		}
		,
		<ExtendFormikContext value={{[formikExtra]: { storeWithFields: {store:'persons2tree'} }}}>
			<div style={{marginBottom:"1em"}}>СНИЛС приглашаемого пользователя</div>
			<Field name="login" style={{width:"100%"}} />
			<p className="error" ><ErrorMessage name="login"/></p>
			<ModelField name="position" style={{width:"100%"}}/>
			<div className="flexContainer" style={{marginTop:"1em"}}>
				<button type="submit" className="modalButton">Пригласить</button>
				<ModalButton>Отмена</ModalButton>
			</div>
		</ExtendFormikContext>
	)
}

const diff_transform = {
	tid: null
	, person: null
	, student_scope: null
	, doc_scope: null
	, student_scope_new: null
	, "doc_scope_new.compare": (name,o,n) => {
			o = o? JSON.parse(o): {}
			n = n? JSON.parse(n): {}
			let ao = []
			for(const i in o)
				if( ! (i in n) ) ao.push(i)
			let an = []
			for(const i in n)
				if( ! (i in o) ) an.push(i)
			ao = ao.map(e=>window.AppBundle.data.doc_scope_new.decode[e])
			an = an.map(e=>window.AppBundle.data.doc_scope_new.decode[e])
			return { name,
				o: ao.map(e=><div key={e}>{e}</div>),
				n: an.map(e=><div key={e}>{e}</div>)
			}
		}
}

const diff_transform_person = {
	person: null
	, ext_filled: null
	, auth: null
}