import React, {useContext, useState} from "react";
import {mainDbX} from "./azlib/components/db";
import {emptyOrgFields} from "./contingent/cards";
import {alert} from 'azlib/components/modals'
import UserInfoContext from "./UserInfo";
import {toMoscowTime} from "./azlib/components/helpers";
import {ru_date_text} from "./azlib/components/locales/ru/ru_date";

export function exportAsTable(name,data,format = "xlsx"){
    let XLSX = require("xlsx");
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "list");
    XLSX.writeFile(wb,name + "." + format);
}
export function Monitoring({}) {
    let [ogrn, setOgrn] = useState()
    let [lvl, setLvl] = useState()
    let [otl, setOtl] = useState()
    let [status, setStatus] = useState()
    let [year, setYear] = useState()
    let [year_end, setYearEnd] = useState()
    const uinfo = useContext(UserInfoContext);
    async function download_with_filters() {
        let orgs = await mainDbX.call_function('monitoring_report'
            , {
                login: uinfo.login,
                ogrn: ogrn?'{'+ogrn.split(' ').filter(e => e).toString()+'}':null,
                dtype_kind: lvl,
                good: otl,
                doc_status: status,
                year_start: year,
                year_end: year_end
            });

        let data = [];
        orgs=JSON.parse(orgs);
        orgs?.sort((a,b)=> a.path<b.path?-1:a.path>b.path?1:0).forEach((row) => {
            data.push({
                "ОГРН": row.gov_regnum ? row.gov_regnum : row.parent_ogrn
                , "Наименование": row.name
                , "Заполнение данных организации": emptyOrgFields(row, true)
                , "Пользователи": orgMembers(row, true)
                , "Элементы структуры": orgStruct(row)
                , "Количество документов": row.doc_count || '0'
                , "Дата последнего действия": ru_date_text(toMoscowTime(row.last_action))
            })
        })
        if(data.length) {
            let date = new Date();
            let name = "Мониторинг_" + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
            exportAsTable(name,data)
        }
        else await alert("Нет доступных организаций")
    }
    async function download_all() {
        let orgs = await mainDbX.call_function('monitoring_report_full'
            , {
                login: uinfo.login
            });
        let data = [];
        orgs=JSON.parse(orgs);
        orgs?.sort((a,b)=> a.path<b.path?-1:a.path>b.path?1:0).forEach((row) => {
            data.push({
                "ОГРН": row.gov_regnum ? row.gov_regnum : row.parent_ogrn
                , "Наименование": row.name
                , "Заполнение данных организации": emptyOrgFields(row, true)
                , "Пользователи": orgMembers(row, true)
                , "Элементы структуры": orgStruct(row)
                , "Количество документов": row.sum || '0'
                , "Дата последнего действия": ru_date_text(toMoscowTime(row.last_action))
            })
        })
        if(data.length) {
            let date = new Date();
            let name = "Мониторинг_" + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
            exportAsTable(name,data)
        }
        else await alert("Нет доступных организаций")
    }
    return <>
        <h1>Мониторинг</h1>
        <div>
                    <div className="flexContainer monitoring">
                        <div>
                            <select onChange={e => {
                                setLvl(e.target.value)
                            }}>
                                <option value="">Уровень образования:</option>
                                {
                                    window.AppBundle.data.ed_levels.orderedKeys
                                        .filter(e => !!e)
                                        .map(e => <option key={e} value={e}>
                                            {window.AppBundle.data.ed_levels.decode[e]}
                                        </option>)
                                }
                            </select>
                        </div>
                        <div>
                            <select onChange={e => {
                                setStatus(e.target.value)
                            }}>
                                <option value="">Статус документов:</option>
                                <option value="issued-ok">Действительные</option>
                                <option value="issued-replacing">В процессе замены</option>
                                <option value="issued-replaced">Замененные</option>
                                <option value="issued-rejected">Отмененные</option>
                            </select>
                            <button className="checkBoxBut" style={{height:"3em"}}>
                                Только документы с отличием:
                                <input type="checkbox"
                                       onChange={e => setOtl(e.target.checked)}
                                />
                            </button>
                        </div>
                        <div>
                            <div>ОГРН
                                <div><input value={ogrn}
                                            className="inputNoBorder"
                                            onChange={e => setOgrn(e.target.value)}
                                            autoComplete="off"
                                /></div>
                        </div>
                            <div>Год выдачи C
                            <div>
                            <input className="inputNoBorder" onChange={e => setYear(e.target.value)}/>
                            <span>По</span>
                            <input className="inputNoBorder" onChange={e => setYearEnd(e.target.value)}/>
                            </div>
                        </div>
                        </div>
                    </div>
            <div className="flexContainer" style={{padding: "1em 2em"}}>
                            <span>Будет загружен Excel файл, содержащий информацию о: ОГРН организации, наименовании организации, 
                                наполненности раздела «Данные организации», наполненности раздела «Пользователи» в организации, 
                                наличии ответственных пользователей в элементе структуры, количестве сформированных документов организации, 
                                дате последнего действия в системе пользователей организации.
                            </span>
                <button onClick={download_all}>Скачать всё</button>
                <button onClick={download_with_filters}>Скачать с учётом фильтров</button>
            </div>

        </div>


    </>
}

function orgMembers(row, likeTxt){
    return row.person? (!(row.action || row.sign || row.pos || row.act) ? "Да" : (likeTxt?
        ("Есть ошибки: "
            + (row.action ?"- нет минимального набора функций ":"")
            + (row.sign ? "- нет ролей у подписывающего ":"")
            + (row.pos ? "- не заполнены должности ":"")
            + (row.act ? "- подписывающий не вошел в систему":""))
        :<>
            Есть ошибки:
            {row.action ? <><br/>Нет минимального набора функций</>:"" }
            {row.sign ? <><br/>Нет ролей у подписывающего</>:""}
            {row.pos ? <><br/>Не заполнены должности</>:""}
            {row.act ? <><br/>Подписывающий не вошел в систему</>:""}
        </>)): "Нет пользователей"
}
function orgStruct(row){
    return row.suborgs ? (row.managers ? "Ответственные пользователи добавлены" : "Ответственные пользователи отсутствуют") : null
}