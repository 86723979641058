/*
[.ONE]
[.BOOL]
[.VARCHAR]
[.DECIMAL]
[.INTEGER]
[.BIGINT]
[.DATE]
[.TIMESTAMP]
[.CLOB]
[.BLOB]
*/

/*
1) we look at field props
if 'control' is there use it
2) we proceed to model.data: control 
a) with values
b) if no values, with type!
if 'control' is there use it
3) if no success above type is used

when control found, we use
1) its name
2) its default props

and

owerride props with
1) something from model data

*/

const prop_to_js_map = {
	size: 'max_length'
	, READONLY: 'readOnly'
	, CTRL_MIN: 'min_value'
	, CTRL_MAX: 'max_value'
	, CTRL_RE: 'pattern'
	, MASK: 'mask'
	, REQUIRED: 'required'
	, VALUES: 'modelData'
}

export function findControl(store, field, props) {
	const fprops = window.AppBundle.model[store][field]
	if(!fprops) {
		throw `field ${field} not found in ${store}`;
	}
	let control = fprops.CONTROL
			?? window.AppBundle.data[fprops.VALUES]?.control?.control
			?? (window.AppBundle.data[fprops.VALUES] && 'SELECT')
			?? fprops.type

	if(control === "VARCHAR" && props.mask)
		control = 'MASK'

	const cdef = window.globalControlRegistryEditors[control]

	let fp = {}
	for(const i in prop_to_js_map)
		if(fprops[i]!==undefined && fprops[i]!==null)
			fp[ prop_to_js_map[i] ] =  fprops[i];

    let cprops = 
	    	Object.assign({}
    			, window.AppBundle.data[fprops.VALUES]?.control
    		)
    delete cprops.control; //remove modeldata .control 
	//console.log('ctl', control, cdef, fp, props);
    return [cdef
    , {
    	...cprops
    	, ...fp
    	, label: fprops.caption ?? field
    	, ...props
      }
    ]
}

export function findViewer(store, field, props) {
	const fprops = window.AppBundle.model[store][field]
	let viewer = //fprops.VIEWER
			window.AppBundle.data[fprops?.VALUES]?.control?.viewer
			?? (window.AppBundle.data[fprops?.VALUES] && 'DECODE')
			?? fprops?.type

	const cdef = window.globalControlRegistryViewers[viewer]
				?? window.globalControlRegistryViewers['']
	if(viewer === 'DECODE')
		return (props) => cdef({modelData:fprops.VALUES, ...props})
  return cdef;
}

/*
	$fieldProps = [
		'name', 'type', 'size', 'precision'
		, 'caption', 'BACK_CAPTION', 'REL_CAPTION', 'EXPLICIT', 'TRIVIAL'
		, 'search_op', 'search_re', 'search_priority'
		, 'ctrl_min', 'ctrl_max', 'ctrl_re', 'REQUIRED'
		, 'readonly', 'CHOOSE'
		, 'UI_SIZE', 'UI_GROUP', 'UI_LINE', 'PAGE'
		, 'VALUES'
		, 'target'
		, 'CONTROL'
	];
*/
