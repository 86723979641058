import React from 'react'
import * as x509 from '@peculiar/x509'
//import { Crypto } from "@peculiar/webcrypto"

import {XLOG} from 'azlib/components/helpers'
import {alert} from 'azlib/components/modals'
import {ru_date_text} from 'azlib/components/locales/ru/ru_date'

//const crypto = new Crypto();
//x509.cryptoProvider.set(crypto);
/*
const c = (
`MIIbLwYJKoZIhvcNAQcCoIIbIDCCGxwCAQExDjAMBggqhQMHAQECAgUAMAsGCSqGSIb3DQEHAaCC
Fr0wggVRMIIE/qADAgECAhEAlR+jR3xhBDqt+oWGJ4I0QjAKBggqhQMHAQEDAjCCATsxITAfBgkq
hkiG9w0BCQEWEmRpdEBkaWdpdGFsLmdvdi5ydTELMAkGA1UEBhMCUlUxGDAWBgNVBAgMDzc3INCc
0L7RgdC60LLQsDEZMBcGA1UEBwwQ0LMuINCc0L7RgdC60LLQsDFTMFEGA1UECQxK0J/RgNC10YHQ
vdC10L3RgdC60LDRjyDQvdCw0LHQtdGA0LXQttC90LDRjywg0LTQvtC8IDEwLCDRgdGC0YDQvtC1
0L3QuNC1IDIxJjAkBgNVBAoMHdCc0LjQvdGG0LjRhNGA0Ysg0KDQvtGB0YHQuNC4MRgwFgYFKoUD
ZAESDTEwNDc3MDIwMjY3MDExFTATBgUqhQNkBBIKNzcxMDQ3NDM3NTEmMCQGA1UEAwwd0JzQuNC9
0YbQuNGE0YDRiyDQoNC+0YHRgdC40LgwHhcNMjIwMTA4MTMzMjM5WhcNNDAwMTA4MTMzMjM5WjCC
ATsxITAfBgkqhkiG9w0BCQEWEmRpdEBkaWdpdGFsLmdvdi5ydTELMAkGA1UEBhMCUlUxGDAWBgNV
BAgMDzc3INCc0L7RgdC60LLQsDEZMBcGA1UEBwwQ0LMuINCc0L7RgdC60LLQsDFTMFEGA1UECQxK
0J/RgNC10YHQvdC10L3RgdC60LDRjyDQvdCw0LHQtdGA0LXQttC90LDRjywg0LTQvtC8IDEwLCDR
gdGC0YDQvtC10L3QuNC1IDIxJjAkBgNVBAoMHdCc0LjQvdGG0LjRhNGA0Ysg0KDQvtGB0YHQuNC4
MRgwFgYFKoUDZAESDTEwNDc3MDIwMjY3MDExFTATBgUqhQNkBBIKNzcxMDQ3NDM3NTEmMCQGA1UE
Awwd0JzQuNC90YbQuNGE0YDRiyDQoNC+0YHRgdC40LgwZjAfBggqhQMHAQEBATATBgcqhQMCAiMB
BggqhQMHAQECAgNDAARAWkprpB1rjwuMWr92vPYzbt8Wq7+Df4+vGuD2UT4o/N1u9EfC461uGUn3
aiCZL6LuaEMMY+xaG09V25X0pupAjqOCAdAwggHMMIH1BgUqhQNkcASB6zCB6Aw00J/QkNCa0Jwg
wqvQmtGA0LjQv9GC0L7Qn9GA0L4gSFNNwrsg0LLQtdGA0YHQuNC4IDIuMAxD0J/QkNCaIMKr0JPQ
vtC70L7QstC90L7QuSDRg9C00L7RgdGC0L7QstC10YDRj9GO0YnQuNC5INGG0LXQvdGC0YDCuww1
0JfQsNC60LvRjtGH0LXQvdC40LUg4oSWIDE0OS8zLzIvMi8yMyDQvtGCIDAyLjAzLjIwMTgMNNCX
0LDQutC70Y7Rh9C10L3QuNC1IOKEliAxNDkvNy82LTQ0OSDQvtGCIDMwLjEyLjIwMjEwPwYFKoUD
ZG8ENgw00J/QkNCa0JwgwqvQmtGA0LjQv9GC0L7Qn9GA0L4gSFNNwrsg0LLQtdGA0YHQuNC4IDIu
MDAMBgUqhQNkcgQDAgEAMEMGA1UdIAQ8MDowCAYGKoUDZHEBMAgGBiqFA2RxAjAIBgYqhQNkcQMw
CAYGKoUDZHEEMAgGBiqFA2RxBTAGBgRVHSAAMA4GA1UdDwEB/wQEAwIBBjAPBgNVHRMBAf8EBTAD
AQH/MB0GA1UdDgQWBBTJE1ixTKdiOn7SPzym5xR8nXCjhjAKBggqhQMHAQEDAgNBAIJJeEgKDdJn
9NMK1EmkEW5cZLLc95lMR47B04ci/S1aI3arSY9HMH+jqv0xeMap3N9ga0KurWXkWxdk4jlz/ZIw
ggf5MIIHpqADAgECAgsAz+j/YQAAAAAF9jAKBggqhQMHAQEDAjCCATsxITAfBgkqhkiG9w0BCQEW
EmRpdEBkaWdpdGFsLmdvdi5ydTELMAkGA1UEBhMCUlUxGDAWBgNVBAgMDzc3INCc0L7RgdC60LLQ
sDEZMBcGA1UEBwwQ0LMuINCc0L7RgdC60LLQsDFTMFEGA1UECQxK0J/RgNC10YHQvdC10L3RgdC6
0LDRjyDQvdCw0LHQtdGA0LXQttC90LDRjywg0LTQvtC8IDEwLCDRgdGC0YDQvtC10L3QuNC1IDIx
JjAkBgNVBAoMHdCc0LjQvdGG0LjRhNGA0Ysg0KDQvtGB0YHQuNC4MRgwFgYFKoUDZAESDTEwNDc3
MDIwMjY3MDExFTATBgUqhQNkBBIKNzcxMDQ3NDM3NTEmMCQGA1UEAwwd0JzQuNC90YbQuNGE0YDR
iyDQoNC+0YHRgdC40LgwHhcNMjIwMTEwMTA1NDQ2WhcNMzcwMTEwMTA1NDQ2WjCCAVcxIDAeBgkq
hkiG9w0BCQEWEXVjX2ZrQHJvc2them5hLnJ1MRgwFgYDVQQIDA83NyDQnNC+0YHQutCy0LAxFTAT
BgUqhQNkBBIKNzcxMDU2ODc2MDEYMBYGBSqFA2QBEg0xMDQ3Nzk3MDE5ODMwMWAwXgYDVQQJDFfQ
kdC+0LvRjNGI0L7QuSDQl9C70LDRgtC+0YPRgdGC0LjQvdGB0LrQuNC5INC/0LXRgNC10YPQu9C+
0LosINC0LiA2LCDRgdGC0YDQvtC10L3QuNC1IDExGTAXBgNVBAcMENCzLiDQnNC+0YHQutCy0LAx
CzAJBgNVBAYTAlJVMS4wLAYDVQQKDCXQmtCw0LfQvdCw0YfQtdC50YHRgtCy0L4g0KDQvtGB0YHQ
uNC4MS4wLAYDVQQDDCXQmtCw0LfQvdCw0YfQtdC50YHRgtCy0L4g0KDQvtGB0YHQuNC4MGYwHwYI
KoUDBwEBAQEwEwYHKoUDAgIjAQYIKoUDBwEBAgIDQwAEQLYrV6I3vfyiYUEqD18DOAUdba7sA2Wy
dWH4orjqlKInpikLHOnexOKfJtJpQF4589VV/tpejtNchOvLLJ9s5X6jggRiMIIEXjASBgNVHRMB
Af8ECDAGAQH/AgEAMIGUBgUqhQNkbwSBigyBh9Cf0YDQvtCz0YDQsNC80LzQvdC+LdCw0L/Qv9Cw
0YDQsNGC0L3Ri9C5INC60L7QvNC/0LvQtdC60YEgVmlQTmV0IFBLSSBTZXJ2aWNlICjQvdCwINCw
0L/Qv9Cw0YDQsNGC0L3QvtC5INC/0LvQsNGC0YTQvtGA0LzQtSBIU00gMjAwMFEyKTAOBgNVHQ8B
Af8EBAMCAcYwJwYDVR0gBCAwHjAIBgYqhQNkcQEwCAYGKoUDZHECMAgGBiqFA2RxAzAMBgUqhQNk
cgQDAgEBMIIBfQYDVR0jBIIBdDCCAXCAFMkTWLFMp2I6ftI/PKbnFHydcKOGoYIBQ6SCAT8wggE7
MSEwHwYJKoZIhvcNAQkBFhJkaXRAZGlnaXRhbC5nb3YucnUxCzAJBgNVBAYTAlJVMRgwFgYDVQQI
DA83NyDQnNC+0YHQutCy0LAxGTAXBgNVBAcMENCzLiDQnNC+0YHQutCy0LAxUzBRBgNVBAkMStCf
0YDQtdGB0L3QtdC90YHQutCw0Y8g0L3QsNCx0LXRgNC10LbQvdCw0Y8sINC00L7QvCAxMCwg0YHR
gtGA0L7QtdC90LjQtSAyMSYwJAYDVQQKDB3QnNC40L3RhtC40YTRgNGLINCg0L7RgdGB0LjQuDEY
MBYGBSqFA2QBEg0xMDQ3NzAyMDI2NzAxMRUwEwYFKoUDZAQSCjc3MTA0NzQzNzUxJjAkBgNVBAMM
HdCc0LjQvdGG0LjRhNGA0Ysg0KDQvtGB0YHQuNC4ghEAlR+jR3xhBDqt+oWGJ4I0QjAdBgNVHQ4E
FgQUHYAm0oli5wSBjx5K6KtyknYt3T0wgY8GA1UdHwSBhzCBhDAqoCigJoYkaHR0cDovL3JlZXN0
ci1wa2kucnUvY2RwL2d1YzIwMjIuY3JsMCqgKKAmhiRodHRwOi8vY29tcGFueS5ydC5ydS9jZHAv
Z3VjMjAyMi5jcmwwKqAooCaGJGh0dHA6Ly9yb3N0ZWxlY29tLnJ1L2NkcC9ndWMyMDIyLmNybDBA
BggrBgEFBQcBAQQ0MDIwMAYIKwYBBQUHMAKGJGh0dHA6Ly9yZWVzdHItcGtpLnJ1L2NkcC9ndWMy
MDIyLmNydDCB9QYFKoUDZHAEgeswgegMNNCf0JDQmtCcIMKr0JrRgNC40L/RgtC+0J/RgNC+IEhT
TcK7INCy0LXRgNGB0LjQuCAyLjAMQ9Cf0JDQmiDCq9CT0L7Qu9C+0LLQvdC+0Lkg0YPQtNC+0YHR
gtC+0LLQtdGA0Y/RjtGJ0LjQuSDRhtC10L3RgtGAwrsMNdCX0LDQutC70Y7Rh9C10L3QuNC1IOKE
liAxNDkvMy8yLzIvMjMg0L7RgiAwMi4wMy4yMDE4DDTQl9Cw0LrQu9GO0YfQtdC90LjQtSDihJYg
MTQ5LzcvNi00NDkg0L7RgiAzMC4xMi4yMDIxMAoGCCqFAwcBAQMCA0EANzNUwhsohpVPq9+Am6sx
0kTI+DySOF0035bMcaoOQxuvjW6eNb0dE8xpI5hdBE+cvD7P858nVpOfygQ3cRMu0jCCCWcwggkU
oAMCAQICED8cbTlL3pnpPDFosEUrtR8wCgYIKoUDBwEBAwIwggFXMSAwHgYJKoZIhvcNAQkBFhF1
Y19ma0Byb3NrYXpuYS5ydTEYMBYGA1UECAwPNzcg0JzQvtGB0LrQstCwMRUwEwYFKoUDZAQSCjc3
MTA1Njg3NjAxGDAWBgUqhQNkARINMTA0Nzc5NzAxOTgzMDFgMF4GA1UECQxX0JHQvtC70YzRiNC+
0Lkg0JfQu9Cw0YLQvtGD0YHRgtC40L3RgdC60LjQuSDQv9C10YDQtdGD0LvQvtC6LCDQtC4gNiwg
0YHRgtGA0L7QtdC90LjQtSAxMRkwFwYDVQQHDBDQsy4g0JzQvtGB0LrQstCwMQswCQYDVQQGEwJS
VTEuMCwGA1UECgwl0JrQsNC30L3QsNGH0LXQudGB0YLQstC+INCg0L7RgdGB0LjQuDEuMCwGA1UE
Awwl0JrQsNC30L3QsNGH0LXQudGB0YLQstC+INCg0L7RgdGB0LjQuDAeFw0yMjA2MDcwNjQ5MDBa
Fw0yMzA4MzEwNjQ5MDBaMIICgzELMAkGA1UEBhMCUlUxGTAXBgNVBAgMENCzLiDQnNC+0YHQutCy
0LAxRzBFBgNVBAwMPtCY0L3QttC10L3QtdGALdC/0YDQvtC10LrRgtC40YDQvtCy0YnQuNC6IDEg
0LrQsNGC0LXQs9C+0YDQuNC4MYIBLjCCASoGA1UECgyCASHQpNCV0JTQldCg0JDQm9Cs0J3QntCV
INCT0J7QodCj0JTQkNCg0KHQotCS0JXQndCd0J7QlSDQkNCS0KLQntCd0J7QnNCd0J7QlSDQndCQ
0KPQp9Cd0J7QlSDQo9Cn0KDQldCW0JTQldCd0JjQlSAi0KbQldCd0KLQoCDQmNCd0KTQntCg0JzQ
kNCm0JjQntCd0J3Qq9ClINCi0JXQpdCd0J7Qm9Ce0JPQmNCZINCYINCh0JjQodCi0JXQnCDQntCg
0JPQkNCd0J7QkiDQmNCh0J/QntCb0J3QmNCi0JXQm9Cs0J3QntCZINCS0JvQkNCh0KLQmCDQmNCc
0JXQndCYINCQLtCSLiDQodCi0JDQoNCe0JLQntCZ0KLQntCS0JAiMRYwFAYFKoUDZAMSCzE3MDU3
NzQ5MzkwMRowGAYIKoUDA4EDAQESDDIxMjIwNTI2MzkzNzEiMCAGCSqGSIb3DQEJARYTYW5seXNv
dmFkQGdtYWlsLmNvbTEwMC4GA1UEKgwn0JDQvdCw0YHRgtCw0YHQuNGPINCU0LzQuNGC0YDQuNC1
0LLQvdCwMRUwEwYDVQQEDAzQm9GL0YHQvtCy0LAxPTA7BgNVBAMMNNCb0YvRgdC+0LLQsCDQkNC9
0LDRgdGC0LDRgdC40Y8g0JTQvNC40YLRgNC40LXQstC90LAwZjAfBggqhQMHAQEBATATBgcqhQMC
AiQABggqhQMHAQECAgNDAARASY0te4z2VkShvciK7aE6CnCc0lmM3hQk3XYt2lTAsOikoy1N+lAf
qEmpJlhIVXByS4vA3Zje+RmuH9oaRQDRDKOCBIMwggR/MA4GA1UdDwEB/wQEAwID+DATBgNVHSUE
DDAKBggrBgEFBQcDAjATBgNVHSAEDDAKMAgGBiqFA2RxATAMBgUqhQNkcgQDAgEAMC0GBSqFA2Rv
BCQMItCa0YDQuNC/0YLQvtCf0YDQviBDU1AgKDUuMC4xMTQ1NSkwggGJBgUqhQNkcASCAX4wggF6
DIGH0J/RgNC+0LPRgNCw0LzQvNC90L4t0LDQv9C/0LDRgNCw0YLQvdGL0Lkg0LrQvtC80L/Qu9C1
0LrRgSBWaVBOZXQgUEtJIFNlcnZpY2UgKNC90LAg0LDQv9C/0LDRgNCw0YLQvdC+0Lkg0L/Qu9Cw
0YLRhNC+0YDQvNC1IEhTTSAyMDAwUTIpDGjQn9GA0L7Qs9GA0LDQvNC80L3Qvi3QsNC/0L/QsNGA
0LDRgtC90YvQuSDQutC+0LzQv9C70LXQutGBIMKr0K7QvdC40YHQtdGA0YIt0JPQntCh0KLCuy4g
0JLQtdGA0YHQuNGPIDQuMAxO0KHQtdGA0YLQuNGE0LjQutCw0YIg0YHQvtC+0YLQstC10YLRgdGC
0LLQuNGPIOKEltCh0KQvMTI0LTM3NDMg0L7RgiAwNC4wOS4yMDE5DDTQl9Cw0LrQu9GO0YfQtdC9
0LjQtSDihJYgMTQ5LzcvNi80NTIg0L7RgiAzMC4xMi4yMDIxMGYGA1UdHwRfMF0wLqAsoCqGKGh0
dHA6Ly9jcmwucm9za2F6bmEucnUvY3JsL3VjZmtfMjAyMi5jcmwwK6ApoCeGJWh0dHA6Ly9jcmwu
ZmsubG9jYWwvY3JsL3VjZmtfMjAyMi5jcmwwdwYIKwYBBQUHAQEEazBpMDQGCCsGAQUFBzAChiho
dHRwOi8vY3JsLnJvc2them5hLnJ1L2NybC91Y2ZrXzIwMjIuY3J0MDEGCCsGAQUFBzAChiVodHRw
Oi8vY3JsLmZrLmxvY2FsL2NybC91Y2ZrXzIwMjIuY3J0MB0GA1UdDgQWBBRAVAlwDQM+wbDl77aR
h2J10coMBDCCAXcGA1UdIwSCAW4wggFqgBQdgCbSiWLnBIGPHkroq3KSdi3dPaGCAUOkggE/MIIB
OzEhMB8GCSqGSIb3DQEJARYSZGl0QGRpZ2l0YWwuZ292LnJ1MQswCQYDVQQGEwJSVTEYMBYGA1UE
CAwPNzcg0JzQvtGB0LrQstCwMRkwFwYDVQQHDBDQsy4g0JzQvtGB0LrQstCwMVMwUQYDVQQJDErQ
n9GA0LXRgdC90LXQvdGB0LrQsNGPINC90LDQsdC10YDQtdC20L3QsNGPLCDQtNC+0LwgMTAsINGB
0YLRgNC+0LXQvdC40LUgMjEmMCQGA1UECgwd0JzQuNC90YbQuNGE0YDRiyDQoNC+0YHRgdC40Lgx
GDAWBgUqhQNkARINMTA0NzcwMjAyNjcwMTEVMBMGBSqFA2QEEgo3NzEwNDc0Mzc1MSYwJAYDVQQD
DB3QnNC40L3RhtC40YTRgNGLINCg0L7RgdGB0LjQuIILAM/o/2EAAAAABfYwCgYIKoUDBwEBAwID
QQALFuGNRD6F1LcQIS+hdxj2D8Pk73n+fK4oRk9ldqRmFMVhcMbRgv1WIScPY4dkMOkMvX1LVSRJ
rg+ILj2qP4O8MYIENzCCBDMCAQEwggFtMIIBVzEgMB4GCSqGSIb3DQEJARYRdWNfZmtAcm9za2F6
bmEucnUxGDAWBgNVBAgMDzc3INCc0L7RgdC60LLQsDEVMBMGBSqFA2QEEgo3NzEwNTY4NzYwMRgw
FgYFKoUDZAESDTEwNDc3OTcwMTk4MzAxYDBeBgNVBAkMV9CR0L7Qu9GM0YjQvtC5INCX0LvQsNGC
0L7Rg9GB0YLQuNC90YHQutC40Lkg0L/QtdGA0LXRg9C70L7Quiwg0LQuIDYsINGB0YLRgNC+0LXQ
vdC40LUgMTEZMBcGA1UEBwwQ0LMuINCc0L7RgdC60LLQsDELMAkGA1UEBhMCUlUxLjAsBgNVBAoM
JdCa0LDQt9C90LDRh9C10LnRgdGC0LLQviDQoNC+0YHRgdC40LgxLjAsBgNVBAMMJdCa0LDQt9C9
0LDRh9C10LnRgdGC0LLQviDQoNC+0YHRgdC40LgCED8cbTlL3pnpPDFosEUrtR8wDAYIKoUDBwEB
AgIFAKCCAl0wGAYJKoZIhvcNAQkDMQsGCSqGSIb3DQEHATAcBgkqhkiG9w0BCQUxDxcNMjIxMDE3
MTIxNTEyWjAqBgorBgEEAYI3WAIBMRwEGkQAbwBjAHUAbQBlAG4AdAAgAE4AYQBtAGUAMC8GCSqG
SIb3DQEJBDEiBCChBXUHVXTbodWQJ9dKJrkbef0Hh+bBfRzgCdw7jB4D5jCCAcQGCyqGSIb3DQEJ
EAIvMYIBszCCAa8wggGrMIIBpzAKBggqhQMHAQECAgQgun/sounWcMNd00lchbjqCM5zu/XckqGw
ctaEBMBNtTwwggF1MIIBX6SCAVswggFXMSAwHgYJKoZIhvcNAQkBFhF1Y19ma0Byb3NrYXpuYS5y
dTEYMBYGA1UECAwPNzcg0JzQvtGB0LrQstCwMRUwEwYFKoUDZAQSCjc3MTA1Njg3NjAxGDAWBgUq
hQNkARINMTA0Nzc5NzAxOTgzMDFgMF4GA1UECQxX0JHQvtC70YzRiNC+0Lkg0JfQu9Cw0YLQvtGD
0YHRgtC40L3RgdC60LjQuSDQv9C10YDQtdGD0LvQvtC6LCDQtC4gNiwg0YHRgtGA0L7QtdC90LjQ
tSAxMRkwFwYDVQQHDBDQsy4g0JzQvtGB0LrQstCwMQswCQYDVQQGEwJSVTEuMCwGA1UECgwl0JrQ
sNC30L3QsNGH0LXQudGB0YLQstC+INCg0L7RgdGB0LjQuDEuMCwGA1UEAwwl0JrQsNC30L3QsNGH
0LXQudGB0YLQstC+INCg0L7RgdGB0LjQuAIQPxxtOUvemek8MWiwRSu1HzAMBggqhQMHAQEBAQUA
BEAOGC8TVg4TMywY6VcCG2cw+PRmiHZv+xK1HqNr003tjK4lyW3l8Nmv3Eq7n90yrG9t2IEc1HSe
q4dKOYgFJWHq`).trim();
*/

export function CertDiv({sign}) {

	const certs = new x509.X509Certificates(XLOG(
"-----BEGIN PKCS7-----\n"
+(sign||'').replace(/\r\n/g,"\n").trim()+
"\n-----END PKCS7-----\n"
));
	console.log(certs)
	const cert = certs[certs.length-1]
	console.log(cert.subjectName)
	const notBefore = cert.notBefore
	const notAfter = cert.notAfter

	let CN = cert.subjectName.getField('2.5.4.3')
	let O = cert.subjectName.getField('2.5.4.10')
	if(Array.isArray(CN)) CN = CN.join(' ')
	if(Array.isArray(O)) O = O.join(' ')
console.log(CN,O)

	return (<div>
		<p>
		<b>{CN}</b>
		</p>
		<p>{O}</p>
		<hr/>
		{ru_date_text(notBefore?.toISOString().substr(0,10))} 
		{" ÷ "} 
		{ru_date_text(notAfter?.toISOString().substr(0,10))}
	</div>)
}

export function decode_signature_cert(sign){
	if(!sign) return
	const certs = new x509.X509Certificates((
"-----BEGIN PKCS7-----\n"
+(sign||'').replace(/\r\n/g,"\n").trim()+
"\n-----END PKCS7-----\n"
));
	const cert = certs[certs.length-1]
	const notBefore = cert.notBefore
	const notAfter = cert.notAfter

	let CN = cert.subjectName.getField('2.5.4.3')
	let O = cert.subjectName.getField('2.5.4.10')
	if(Array.isArray(CN)) CN = CN.join(' ')
	if(Array.isArray(O)) O = O.join(' ')

	return {
		serialNumber: cert.serialNumber
		, CN, O
		, notBefore, notAfter
	}
}
