export function ru_date(date) {
	return date.replace(/(\d{4})-(\d{2})-(\d{2})/,'$3.$2.$1')
}

export const ru_months_gen = [
	  "января"
	, "февраля"
	, "марта"
	, "апреля"
	, "мая"
	, "июня"
	, "июля"
	, "августа"
	, "сентября"
	, "октября"
	, "ноября"
	, "декабря"
]

export function ru_date_text(date) {
	return date && date.replace(/(\d{4})-(\d{2})-(\d{2})/,(m,m1,m2,m3)=>`${m3} ${ru_months_gen[+m2-1]} ${m1}`)
}

export function ru_date_to_iso(date) {
	return date && date.replace(/(\d{2})[.](\d{2})[.](\d{4})/,'$3-$2-$1')

}