import React, {useState, useRef, useEffect} from 'react';

import * as yup from 'yup'

import {XLOG, letIn} from 'azlib/components/helpers'
import {
	swap_array_elements, set_array_element, deep_assign, Keyed, Box
}	from 'azlib/components/struct_assign';


import {
	PopupMenu, ModalButton,
	prompt, alert, confirm
} from 'azlib/components/modals';

import {fakeEvent, IntInput, ArrayEditor} from 'azlib/components/std-controls'
import {smart_ed_level} from 'config/ed_levels_map'

import {modalFormik, Field} from 'azlib/components/formik-fields'

import styles from './doctype.module.css';


const predefinedDomains = [
 	  [ '5', '4', '3' ]

 	, [ '5 (отлично)', '4 (хорошо)', '3 (удовлетворительно)' ]
 	, [ '5 (отлично)', '4 (хорошо)', '3 (удовл.)' ]
 	, [ 'отлично', 'хорошо', 'удовлетворительно' ]

 	, [ '5 (отлично)', '4 (хорошо)', '3 (удовлетворительно)', 'зачтено' ]
 	, [ '5 (отлично)', '4 (хорошо)', '3 (удовл.)', 'зачтено'  ]
 	, [ 'отлично', 'хорошо', 'удовлетворительно', 'зачтено' ]

 	, [ '5 (отлично)', '4 (хорошо)', 'зачтено' ]
 	, [ 'отлично', 'хорошо', 'зачтено' ]

 	, [ '5 (отлично)' ]
 	, [	'отлично' ]

	, [ 'зачтено' ]
]

/**
 * Formik-component, not a control per se
 */

function UpDown({i,array,up_down,readOnly,...props}) {
	return !readOnly && <>
		{i-1>=0?<span className={styles.arrow+ ' up'}
			onClick={()=>up_down(i,i-1,array)}
		>↑</span>:null}
		{i+1<array.length?<span className={styles.arrow+ ' down'}
			onClick={()=>up_down(i,i+1,array)}
			>↓</span>:null}
	</>
}

export function spec_name_by_kind(kind) {
	const s = window.AppBundle.data.level_to_spec_label.decode[smart_ed_level(kind)]
	if(!s) return kind+' специальность'
	return s.split('|')[0].trim()
}

const internalParams = kind => [
	"Фамилия", "Имя", "Отчество",
	"Дата рождения",

	"Наименование организации",
	"ОГРН",
	"Место нахождения организации",

	"Год выпуска",

	 letIn(window.AppBundle.data.level_to_spec_label.decode[smart_ed_level(kind)])
	 	(v=>v?
		  [...v.split('|').slice(1), 'Квалификация']
		: []
	    ),
	RegExp("^p").test(kind)? ["Присвоенный квалификационный разряд, класс, категория","Наименование профессий рабочих, должностей служащих"] : [],

    "Аккредитованная программа",

	"Реестровый номер",

].flat()

const noAutoParams = [
	"ОГРН"
	,"Специальность","Код специальности"
	,"Направление подготовки", "Код направления подготовки"
	,"Наименование программы профессионального обучения", "Наименование программы"
	,"Профессия", "Код профессии",
	,"Год выпуска"
	,"Квалификация"
]

const maxLevels = 5;

const internalSet = kind => new Set(internalParams(kind));

export function defaultTemplate(kind, template) {
	template = template? JSON.parse(template) : []

	//console.log(template)

	return [
		...Array.from(internalSet(kind).keys())
			.map(name => ({
				internal: true
				, ...template.find(e=>e.name === name)
				, name
				, domains: undefined
				}))
		, ...template.filter(e=>!internalSet(kind).has(e.name)
				&& !e.internal
				)
	]
	.map(({domain,units,...e})=>(domain?
			domain.internal !== undefined? {...e, internal: domain.internal }
			: {...e, domains:[{domain,units}]}
		:e)) // replace old format
}


export function TypeStructureEditor({name, value, doc_kind, onChange, readOnly, ...props}) {
	let autokey = useRef(null);

	let [infoset, setInfosetBase] = useState([])
	useEffect(
			() => {
				autokey.current = 1;
				setInfosetBase(
					defaultTemplate(doc_kind, value)
					.map(e=>({autokey:autokey.current++, ...e}))
				)
			}
		, [value, doc_kind])

	let infosetA =	infoset
				.filter(e=>internalSet(doc_kind).has(e.name))

	let infosetB =	infoset
				.filter(e=>!internalSet(doc_kind).has(e.name))

	const saveInfoset = infoset => {
		const toSave = infoset.map(({autokey,...e})=>e)
		onChange?.(fakeEvent(name, JSON.stringify(toSave)))
		return infoset
	}

	const setInfoset = (autokey, e, after) => {
		console.log(e)
		setInfosetBase(infoset=>{
			let v = Keyed({autokey}, e)
			let r = deep_assign(infoset, v)
			if(after) {
				let k = r.findIndex(x=>x.autokey===after)
				if(k>=0) {
					r = [
						...r.slice(0,k+1)
						, r[r.length-1]
						, ...r.slice(k+1,-1)
					]
				}
			}
			console.log('ass', infoset, r)
			return saveInfoset(r)
		})
	}

	const up_down = (from,to) =>
		setInfosetBase(infoset =>
			saveInfoset(
				swap_array_elements(from+infosetA.length
						,to+infosetA.length
						,infoset)
			)
		)

	//console.log(infoset, infosetA, infosetB)

	let levels = []

	return (
	<div className={styles.container}>
		<div className="backgroundRounded" style={{padding:"1.5em", margin:"1.5em 0"}}>
			<div style={{background:"white", display:"inline-block", padding:"0.5em 1em"}}>
		<h2>
			Основные
		</h2>
		<table className={styles.values}>
		<thead>
			<tr>
			<th colSpan={2}>Название параметра</th>
			<th>Возможные значения</th>
			</tr>
		</thead>
		<tbody>{
			infosetA
			.map( ({autokey, name, domains, internal}) =>
					<tr key={name}>
						<td/><td>{name}</td>
						<td>
							<ItemInternal readOnly={readOnly}
								autokey={autokey} col={0}
								setInfoset={setInfoset}
								internal={internal}
								name={name}
							/>
						</td>
					</tr>
				)
		}</tbody>
		</table>
			</div>
		</div>
		<div className="backgroundRounded" style={{padding:"1.5em"}}>
			<div style={{background:"white", padding:"0.5em 1em"}}>
		<h2>
			Настраиваемые
		</h2>
		<table className={styles.values}>
		<thead>
		<tr><th />
			<th colSpan={2}>Название параметра</th>
			<th>Возможные значения</th>
			<th>Уровень</th>
			<th />
		</tr>
		</thead>
		<tbody>
			{
				infosetB.map((e,i,a)=><React.Fragment key={e.autokey}><tr>
				{
					!e.insert && (e.level || e.paragraph) ?
					((levels[e.level||1] = e.name), null)
					:null
				}
					<td  className={styles.arrowTd} >
						<UpDown i={i} array={a}
							up_down={up_down}
							readOnly={readOnly}
						/>
					</td>
					<td colSpan={2} style={{verticalAlign:"top",
								color:e.insert?"green":"black"
							}}>
						<ItemName
						autokey={e.autokey}
						setInfoset={setInfoset}
						label={e.name}
						readOnly={readOnly}
						level={e.level||e.paragraph}
						/>
						{
							e.insert && !readOnly &&
							<div>
								<small style={{color:"green"}}>
									в{' '}{levels[e.level]??'никуда'}
								</small>
								<TemplatePhrases readOnly={readOnly}
												 autokey={e.autokey}
												 setInfoset={setInfoset}
												 phrases={e.phrases}
								/>
							</div>
						}
						{
							!e.insert && !e.paragraph
							&& e.level
							&&
							<div>
								<ItemSubName
								autokey={e.autokey}
								setInfoset={setInfoset}
								label={e.subname}
								readOnly={readOnly}
								/>
							</div>
						}
					</td>
					{
					e.paragraph?
						<td>
							<b>Колонки раздела</b>:<br/>
							<ParagraphColumns readOnly={readOnly}
							autokey={e.autokey}
							setInfoset={setInfoset}
							columns={e.columns}
							/>
						</td>
					:
					<td style={{verticalAlign:"top"}}><table style={{borderCollapse:"collapse"}}><tbody>
					{e.domains && e.domains.map((x,i)=><tr key={i} style={{borderBottom:"0.5em solid transparent"}}>
						<td style={{verticalAlign:"top"}}><ItemDomain readOnly={readOnly}
							autokey={e.autokey} col={i}
							setInfoset={setInfoset}
							domains={e.domains}
						/></td>
						<td>{!readOnly && <button type="button" className="del small"
							onClick={()=>{
								setInfoset(e.autokey, {
									domains: Box(set_array_element(e.domains,i,undefined))
								})
							}}
							/>
						}</td>
					</tr>)}
					<tr><td/><td>{!readOnly && <button type="button" className="add small"
							onClick={()=>{
								setInfoset(e.autokey, {
									domains: Box(set_array_element(e.domains??[],null,{domain:{}}))
								})
							}}
							/>
						}</td>
					</tr>
					</tbody></table>
					</td>
					}
					<td valign="top">
						<LevelEdit autokey={e.autokey}
								level={e.level || (e.paragraph?1:0)}
								readOnly={readOnly}
								setInfoset={setInfoset}
								maxLevels={e.insert?
									(levels.length? levels.length-1:0)
									: maxLevels}
								/>
					</td>
					<td valign="top">{!readOnly &&
						<button type="button" className="del"
							onClick={async ()=>{
								await confirm('Вы действительно хотите удалить данный параметр?', true)
								setInfoset(e.autokey,undefined)
							}}
						/>
						}
					</td>
					<td valign="top">{!readOnly &&
						<PopupMenu
						trigger={<button type="button" className="add simple"
						/>}
						bindX="right"
						>
							{e.domains &&
							<ModalButton type="button" className="block"
								onClick={()=> {
									let cl = (({autokey,...e}) =>e)(e)
									prompt('Название элемента','',
										{required:true
										, inputProps: {style: {width: "80vw"}}
										})
									.then(text =>{
										setInfoset(autokey.current++,{
													...cl
													, name: text
												}, e.autokey)
									})
									.catch(()=>{})
								}}
							>Клонировать</ModalButton>}
							<ModalButton type="button" className="block"
								onClick={()=> {
									if(e.paragraph && (e.columns===undefined || e.columns.length===0)) alert("Необходимо создать колонку")
									else prompt('Название элемента','',
										{required:true
										, inputProps: {style: {width: "80vw"}}
										})									.then(text =>{
										setInfoset(autokey.current++,e.paragraph? {
											name: text
											, domains: Array(e.columns.length-1).fill({domain:{}})
										} : {
													name: text
													, domains: [{domain:{}}]
												}, e.autokey)
									})
									.catch(()=>{})
								}}
							>Добавить элемент</ModalButton>
							<ModalButton type="button" className="block"
								onClick={()=> {
									if(e.paragraph && (e.columns===undefined || e.columns.length===0)) alert("Необходимо создать колонку")
									else prompt('Название элемента','',
										{required:true
										, inputProps: {style: {width: "80vw"}}
										})
									.then(text =>{
										setInfoset(autokey.current++,e.paragraph? {
											name: text
											, domains: Array(e.columns.length-1).fill({domain:{}}), level: 2
										} : {
											name: text
											, domains: [{domain:{}}], level: 2
										}, e.autokey)
									})
									.catch(()=>{})
								}}
							>Добавить групповой элемент</ModalButton>
							<ModalButton type="button" className="block"
								onClick={()=> {
									prompt('Название раздела','',
										{required:true
										, inputProps: {style: {width: "80vw"}}
										})
									.then(text =>
										setInfoset(autokey.current++
												, {
													name: text
													, paragraph:true
													, level: 1
												}, e.autokey)
									)
									.catch(()=>{})
								}}
							>Добавить раздел</ModalButton>
							<ModalButton type="button" className="block"
								onClick={()=> {
									if(e.paragraph && (e.columns===undefined || e.columns.length===0)) alert("Необходимо создать колонку")
									else prompt('Текст на кнопке','+',
										{required:true
										, inputProps: {style: {width: "80vw"}}
										})
									.then(text =>
									setInfoset(autokey.current++,e.paragraph? {
										name: text
										, domains: Array(e.columns.length-1).fill({domain:{}}), insert:true, level: e.level
									} : {
										name: text
										, domains: [{domain:{}}], insert:true, level: e.level
									}, e.autokey)
									)
									.catch(()=>{})
								}}
							>Добавить кнопку вставки</ModalButton>
						</PopupMenu>
						}
					</td>
				</tr>
				</React.Fragment>)
			}
		</tbody>
		</table>
			</div>
</div>
		<div className="backgroundRounded">{!readOnly && <div style={{marginTop:"1.5em", padding:"0.1em 2em 2em 2em"}}>
			<h1>Добавить</h1>
		<div style={{display:"flex", flexDirection:"row"}}>
		<button type="button"  className="buttonLarge"
			onClick={(e)=> {
				prompt('Название элемента','',
					{required:true
					, inputProps: {style: {width: "80vw"}}
					})
				.then(text =>{
					setInfoset(autokey.current++,{
								name: text
								, domains: [{domain:{}}]
								, separate: true
							})
				})
				.catch(()=>{})
			}}
		><div style={{display:"block"}}>Элемент<br /><hr className="buttonLine" /><label className="buttonTextLow">для добавления простого элемента, например, дисциплины (модуля)</label></div></button>
		<button type="button" className="buttonLarge"
			onClick={()=> {
				prompt('Название элемента','',
					{required:true
					, inputProps: {style: {width: "80vw"}}
					})
				.then(text =>{
					setInfoset(autokey.current++,{
								name: text
								, domains: [{domain:{}}]
								, level: 2
							})
				})
				.catch(()=>{})
			}}
		><div style={{display:"block"}}>Групповой элемент<br /><hr className="buttonLine" /><label className="buttonTextLow">для объединения элементов в группу, например, практики, курсовые работы (проекты), факультативы и др.</label></div></button>
		<button type="button"  className="buttonLarge"
			onClick={(e)=> {
				prompt('Название раздела','',
					{required:true
					, inputProps: {style: {width: "80vw"}}
					})
				.then(text =>
					setInfoset(autokey.current++
							, {
								name: text
								, paragraph:true
								, level: 1
							})
				)
				.catch(()=>{})
			}}
		><div style={{display:"block"}}>Раздел<br /><hr className="buttonLine" /><label className="buttonTextLow">для объединения элементов в раздел, например, в дополнительные сведения</label></div></button>
		<button type="button"  className="buttonLarge"
			onClick={(e)=> {
				prompt('Текст на кнопке','+',
					{required:true
					, inputProps: {style: {width: "80vw"}}
					})
				.then(text =>
					setInfoset(autokey.current++
							, {
								name: text
								, insert:true
								, level: 1
							})
				)
				.catch(()=>{})
			}}
		><div style={{display:"block"}}>Кнопку вставки<br /><hr className="buttonLine" /><label className="buttonTextLow">для добавления элемента при заполнении документа</label></div></button>
		</div>
		</div>}  </div>
	</div>
	);
}

function TemplatePhrases ({autokey, readOnly, setInfoset, phrases}){
	const setPhrases = (phrases) =>
		setInfoset(autokey,{phrases:Box(phrases)})
	return <div className={styles.domain}>
			<PopupMenu
				trigger={
					<span>{
						<button type="button" className="buttonTextLow"
						><div style={{display:"block"}}>Справочник<br /></div></button>
					}</span>
				}
				closeBox
				readOnly={readOnly}
			>
				<div className={styles.domainChoose}>
					<EditPhrases phrases={phrases??[]}
								 setPhrases={setPhrases}
					/>
				</div>
			</PopupMenu>
		</div>
}

function EditPhrases({phrases, setPhrases}) {
	return (
		<div style={{margin:"1.3em 0 1ex 0.5em"}}>
			Перечень наименований:
			<div>
				<ArrayEditor
					value={phrases}
					onChange={e=>setPhrases(e.target.value)}

					editFunction={async (value)=>await prompt("Наименование",'',true)}

					checkUnique={
						async (value,index,array)=>{
							if(array.filter(e=>e===value).length!==1) {
								alert('уже есть')
								return false;
							}
						}
					}
					placeholder={<div>--- не заданы ---</div>}

					AddComponent={
						({value, editor, readOnly})=>
							!readOnly &&
							<button type="button" onClick={()=>editor(undefined, null, value)}
							>Добавить наименование</button>
					}
				>{({items, add})=><>
					{items}
					<div>{add}</div>
				</>
				}
				</ArrayEditor>
			</div>
		</div>
	)
}

function ItemName({label, autokey, readOnly, level, setInfoset}) {
	return <div className={styles.name} level={0}
		>
		<div onClick={ async ()=>{ if(readOnly) return;
			//console.log('edit', autokey)
			let name = await prompt('Название параметра', label,
				{required:true
				, inputProps: {style: {width: "80vw"}}
				})
			setInfoset(autokey, {name})
			//.catch(()=>{}
		}}
		style={{fontWeight:level?"bold":""}}
		>{label}</div>
	</div>
}

function ItemSubName({label, autokey, readOnly, level, setInfoset}) {
	return <div className={styles.subname} level={0}
		>
		<div onClick={ async ()=>{ if(readOnly) return;
			//console.log('edit', autokey)
			let subname = await prompt('Подзаголовок', label,
				{inputProps: {style: {width: "80vw"}}
				})
			setInfoset(autokey, {subname})
			//.catch(()=>{}
		}}
			style={{opacity:label?"1":"0.5"}}
		>{label||(readOnly?'':'----')}</div>
	</div>
}

const show_domain = (d,internal) =>
		internal === true ? 'Автоматическое значение'
		: internal === false ? 'Не используется'
		: d.strings ? d.strings.join(' | ') || '---'
		: d.range ? `${d.range.min}..${d.range.max}`
		: d.dummy !== undefined ? 'Фиксированное значение: '+(d.dummy)
		: 'Произвольный текст'

function ChoosePredef({domain, setDomain}) {
	return <>
		{predefinedDomains.map( (e,i)=>
		<div key={i}>
			<input type="radio"
				style={{position:"absolute", marginTop:"-3px"}}
				checked={!!domain.strings &&
						e.length === domain.strings.length &&
						e.every((v,i)=>v===domain.strings[i])}
				onChange={event=>setDomain({strings:e})}
			/>
			<div style={{margin:"1.3em 0 1ex 1.5em"}}>{show_domain({strings:e})}</div>
		</div>
		)
	}</>
}

function ChooseOther({domain, setDomain, hide}) {
	let [strings, setStrings] = useState(domain.strings)
	const up_down = (from,to) => {
		setStrings(swap_array_elements(from,to,strings))
	}
	return (
	<div style={{margin:"1.3em 0 1ex 1.5em"}}>
		Варинаты:
		<div>
			<ArrayEditor
				value={strings}
				onChange={e=>setStrings(e.target.value)}

				editFunction={async (value)=>
					await prompt(value??'','',true)
				}
				checkUnique={
					async (value,index,array)=>{
						if(array.filter(e=>e===value).length!==1) {
							alert('уже есть')
							return false;
						}
					}
				}
				placeholder={<div>--- не заданы ---</div>}

				AddComponent={
					({value, editor, readOnly})=>
					!readOnly &&
					<button type="button"
						onClick={()=>editor(undefined, null, value)}
					>Добавить вариант</button>
				}
			>{({items, add})=><>
				{items}
				<div>{add}</div>
				</>
			}
			</ArrayEditor>
		</div>
		<div>
			<button type="button"
				onClick={()=>{
					setDomain({
							strings
						})
					hide()
				}}
			>Сохранить</button>
		</div>
	</div>
	)
}

function ChooseRange({domain, setDomain}) {
	return (
	<div style={{margin:"1.3em 0 1ex 1.5em", padding:"1ex"}}>
		&nbsp;
		<IntInput value={domain.range?.min??1}
			onChange={(e)=>setDomain({range:
						{min: e.target.value, max: domain.range.max}})}
			maxlength={3}
			style={{width:"5em"}}
		/>
		&nbsp;..&nbsp;
		<IntInput value={domain.range?.max??100}
			onChange={(e)=>setDomain({range:
						{min: domain.range.min, max: e.target.value}})}
			maxlength={3}
			style={{width:"5em"}}
		/>
		&nbsp;
	</div>
	)
}


function ItemInternal({autokey, col, readOnly, setInfoset, internal, name}) {
	const setInternal = (internal) =>{
		setInfoset(autokey,{domains: undefined
				, internal
		})
	}

	return <div className={styles.domain}>
		<PopupMenu
			trigger={
				<span>{show_domain(null, internal)}</span>
			}
			closeBox
			readOnly={readOnly}
		>{({hide})=>
			<div className={styles.domainChoose}>
				<>
					<input type="radio"
						style={{position:"absolute", marginTop: "-5px"}}
						checked={internal === true}
						onChange={event=>setInternal(true)}
					/><div style={{margin:"1.3em 0 1ex 1.5em"}}>Автоматическое значение</div>
					{noAutoParams.includes(name) && <>
						<input type="radio"
						style={{position:"absolute", marginTop:"8px"}}
						checked={internal === false}
						onChange={event=>setInternal(false)}
						/><div style={{margin:"1.3em 0 1ex 1.5em"}}>Не используется</div>
						<hr/>
						</>
					}
				</>
			</div>
		}</PopupMenu>
		</div>
}

function ItemDomain({autokey, col, domains, readOnly, setInfoset}) {
	const setDomain = (domain) =>{
		setInfoset(autokey,{domains:
				Box(set_array_element(domains,col,
					{...domains[col], domain}
				))
				, internal: undefined
		})
	}

	let domain = domains[col].domain;
	let units = domains[col].units;

	let predefined = domain.strings
				&& predefinedDomains.find(d=> (
					//console.log('', d, domain.strings),
					d.length === domain.strings.length &&
					d.every((v,i)=>v===domain.strings[i])
					)
			)
	return <div className={styles.domain}>
		<PopupMenu
			trigger={
				<span>
				<span>{show_domain(domain)}</span>
				{domain.range && <span>{' '}{units??'-----'}</span>}
				</span>
			}
			closeBox
			readOnly={readOnly}
		>{({hide})=><div>
			<div className={styles.domainChoose}>
				<>
					<input type="radio"
						style={{position:"absolute", marginTop:"-5px"}}
						checked={!domain.strings
							&& !domain.range
							&& domain.dummy === undefined
						}
						onChange={event=>setDomain({})}
					/><div style={{margin:"1.3em 0 1ex 1.5em"}}>Произвольный текст</div>

					<ChoosePredef domain={domain} setDomain={setDomain}/>

					<input type="radio"
						style={{position:"absolute", marginTop:"8px"}}
						checked={!!domain.strings && !predefined}
						onChange={event=>setDomain({strings:[]})}
					/><div style={{margin:"1.3em 0 1ex 1.5em"}}>Иное (варинаты)</div>
					{domain.strings && !predefined?
						<ChooseOther domain={domain} setDomain={setDomain} hide={hide}/>
					: null
					}

					<input type="radio"
						style={{position:"absolute", marginTop:"8px"}}
						checked={!!domain.range}
						onChange={event=>setDomain({range:{min:1, max:100}})}
					/><div style={{margin:"1.3em 0 1ex 1.5em"}}>Иное (диапазон)</div>
					{domain.range?
						<ChooseRange domain={domain} setDomain={setDomain} hide={hide}/>
					: null
					}

					<input type="radio"
						style={{position:"absolute", marginTop:"8px"}}
						checked={domain.dummy!==undefined}
						onChange={event=>setDomain(
							event.target.checked ? {dummy:''} : {}
						)}
					/><div style={{margin:"1.3em 0 1ex 1.5em"}}>Фиксированное значение</div>
					{domain.dummy!==undefined && <div>
						<input value={domain.dummy}
							onChange={event=>setDomain({dummy: event.target.value})}
						/>
					</div>}
				</>
			</div>
			{domain.range && <hr/>}
			{domain.range && <ItemUnits autokey={autokey} col={col}
				domains={domains} setInfoset={setInfoset}
			/>}
		</div>}</PopupMenu>
		</div>
}

const predefinedUnits = [
	'баллы'
	, "часы"
	, "дни"
	, "недели"
	, "месяцы"
	, "годы"
	, "рубли"
	, "штуки"
	, "люди"
]

function ItemUnits({autokey, col, domains, setInfoset}) {
	const setUnits = (units) =>
		setInfoset(autokey,{domains:
				Box(set_array_element(domains, col,
					{ ...domains[col], units }
				))
			})

	let units = domains[col].units;

	let predef = predefinedUnits.find(e=>e === units)
	return	<>
		<div><span>{units ?? '-----'}</span></div>
		<input type="radio"
			   style={{position:"absolute", marginTop:"15px"}}
				checked={units === undefined || units === null}
				onChange={event=>setUnits(null)}
			/><div style={{margin:"1.3em 0 1ex 1.5em"}}>Нет</div>
			{
				predefinedUnits.map(e=><div key={e}>
					<input type="radio"
						style={{position:"absolute", marginTop:"-3px"}}
						checked={units===e}
						onChange={event=>setUnits(e)}
					/><div style={{margin:"1.3em 0 1ex 1.5em"}}>{e}</div>
				</div>)
			}
			<input type="radio"
				style={{position:"absolute", marginTop:"8px"}}
				checked={!predef && units !== null && units !==undefined}
				onChange={event=>setUnits('')}
			/><div style={{margin:"1.3em 0 1ex 1.5em"}}>Иное</div>
			{!predef && units !== null && units !==undefined?
				<input type="text" value={units}
					onChange={(e)=>setUnits(e.target.value.trim())}
				/>
			: null
			}
		</>
}

function ParagraphColumns({autokey, readOnly, setInfoset, columns}) {
	const setColumns = (columns) =>
		setInfoset(autokey,{columns:Box(columns)})

	return <div className={styles.domain}>
		<PopupMenu
			trigger={
				<span>{
					columns?.length? <ul>
						{columns.map((c,i)=><li key={i}>{
								c.replace(/:([0-9]+)$/,' [↔$1]')
								||'---'}</li>)}
						</ul>
					: 'без колонок'
				}</span>
			}
			closeBox
			readOnly={readOnly}
		>
			<div className={styles.domainChoose}>
				<EditColumns columns={columns??[]}
					setColumns={setColumns}
				/>
			</div>
		</PopupMenu>
		</div>
}

function EditColumns({columns, setColumns}) {
	return (
	<div style={{margin:"1.3em 0 1ex 1.5em"}}>
		Колонки:
		<div>
			<ArrayEditor
				value={columns}
				onChange={e=>setColumns(e.target.value)}

				editFunction={async (value)=>await columnProps(value??'')}
				placeholder={<div>--- не заданы ---</div>}

				AddComponent={
					({value, editor, readOnly})=>
					!readOnly &&
					<button type="button" className="modalButton" onClick={()=>editor(undefined, null, value)}
					>Добавить колонку</button>
				}
				ItemComponent={
					({value,index,array,remove,reposition,editor,readOnly})=>
					<div className={styles.listItem + " flexContainer"}>
						{reposition}
						<button type="button" className="link"
							onClick={()=>editor(value,index,array)}
						>
						{value.replace(/:([0-9]+)$/,' [↔$1]')
						|| '---'
						}
						</button>
						{remove}
					</div>
				}
			>{({items, add})=><>
				{items}
				<div>{add}</div>
				</>
			}
			</ArrayEditor>
		</div>
	</div>
	)
}

async function columnProps(v) {
	return modalFormik(
		{
			initialValues: {
				name: v.replace(/:[0-9]+$/,'')
				, width: v.match(/[0-9]+$/)?.[0] ?? ''
			}
			, validationSchema:
				yup.object({
					name: yup.string().required()
					, width: yup.number().integer().min(1).max(80)
				})
			, output: values => values.width
					?`${values.name}:${values.width}`
					:values.name
		}
		,<>	<div>
			Наименование колонки:
			<br/><Field name="name" style={{width:"30em"}} />
			</div>
			<div>
			Ширина:
			<br/><Field name="width"/>
			</div>
			<hr/>
			<button type="submit" className="modalButton">Ок</button>
		</>
		)
}

function LevelEdit({autokey, level, readOnly, maxLevels, setInfoset}) {
	return (!!level) && <span style={{whiteSpace:"nowrap"}}>
	{Array(maxLevels).fill(null).map((n,i)=>
			<input type="radio" key={i}
				checked={level === i+1}
				onChange={event=>{
					setInfoset(autokey,{level: i+1})}
				}
				disabled={readOnly}
			/>
		)
	}
	{!readOnly && !!level && <button type="button"
		style={{
			background:"transparent"
			, border: "none"
			, color: "black"
			, padding: "0 1em"
			, boxShadow: "none"
			, fontWeight: "normal"
			, margin: "auto"
			, display: "unset"
		}}
		onClick={event=>{
			setInfoset(autokey, {level: undefined})
		}}
	>⌫</button>}
	</span>
}