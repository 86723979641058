import {mainDbX} from './db'

import {seat_code} from 'azlib/components/local_storage'

import {registerSSEListenerFocused, registerSSEListener, SSEConnected} from './icc'

let iccModules = new Map()

let sent = new Map();
const initStamp = Date.now()+':';
let lastMessageSeq = 0;

export function registerThisAsICCmodule(namespace) {
	let entries = {}
	iccModules.set(namespace, entries)
	return { entryPoint: (name, callback) => {
		entries[name] = callback;
		return (user, peer, payload) =>
			new Promise((resolve,reject) => {
				const id = initStamp + ++lastMessageSeq;
				try {
					let r = mainDbX.fetch_raw(`/icc/pub/${user}/${peer}`
						, 
						JSON.stringify({ payload
						, id
						, user: window.UserInfo.personid
						, peer: seat_code()
						, event: 'peer_request'
						, namespace, name
						})
					).then(response=>{
						if(!response.ok)
							throw {code: r.status, message: r.statusText}
						sent.set(id, {resolve,reject})
						window.setTimeout(()=>{
							if(!sent.has(id)) return
							sent.delete(id)
							reject({code: 498, message: 'timeout'})
						}, 10*1000); //FIXME: config it
					})
					.catch(reject)
				} catch(e) {
					reject(e)
				}
			})
		}
		, connected: SSEConnected 
	}
}

registerSSEListenerFocused('peer_request', async ({namespace,name,user,peer,id,payload})=>{
	const entries = iccModules.get(namespace)
	if(!entries) {
		console.log(`namespace ${namespace} not found`);
		return;
	}
	if(!entries[name]) {
		console.log(`name ${name} not found in ${namespace}`);
		return;
	}
	let ok, error;
	try {
		ok = await entries[name](payload)
	} catch(e) {
		error = e
	}
	let r = await mainDbX.fetch_raw(
		`/icc/pub/${user}/${peer}`
		, 
		JSON.stringify({ ok, error
		, event: 'peer_reply'
		, id: id
		})
	)
	if(!r) throw {error: -1, message: 'no icc'}
	if(!r.ok) throw {error: r.status, message: r.statusText}
})


registerSSEListener('peer_reply', r=>{
	const id = r.id
	const op = sent.get(id)
	if(!op) return;
	sent.delete(id)
	if(r.error) op.reject(r.error)
	else op.resolve(r.ok)
})

