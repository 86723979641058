import React, {useState, useEffect, useRef} from 'react';

import {later, defer} from 'azlib/components/helpers'
import {alert} from 'azlib/components/modals'

const SCOPE = 'openid fullname birthdate snils';
const OBRN_ID = 'OBRN15';

const prod = true;
const esia_host = prod?
				'esia.gosuslugi.ru'
			: 'esia-portal1.test.gosuslugi.ru'

/*eslint no-mixed-operators: 0*/
function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

async function esia_stamp() {
	let r = await fetch('/ping')
	let dt = (new Date(r.headers.get('date')))
	return dt.toISOString()
		.replace('T',' ')
		.replace(/[.]\d+Z/,' +0000')
		.replace(/-/g,'.')
}

async function osl() {
	let state = uuidv4()
	window.sessionStorage['esia-state'] = state;
	let stamp = await esia_stamp();
	let e = await fetch('/osl?',{
		headers: { "Esia-Host": esia_host
			, "Esia-Scope": SCOPE  
			, "Esia-Stamp": stamp
			, "Esia-State": state  
		}
	})
	e = await e.arrayBuffer()
	const client_secret = base64ArrayBuffer(e)
						.replace(/=/g,'')
						.replace(/[+]/g,'-')
						.replace(/[/]/g,'_')
	return {client_secret,state,stamp}
}

async function osl_plus(url, params) {
	let {client_secret,stamp,state} = await osl()
	let fd = new URLSearchParams()
			fd.append('client_id', OBRN_ID)
			fd.append('scope', SCOPE)
			fd.append('timestamp', stamp)
			fd.append('state', state)
			fd.append('redirect_uri', window.location.origin + '/esia/ok')
			fd.append('client_secret', client_secret)
			for(let i in params)
				fd.append(i, params[i])
	return url + '?'+fd.toString().replace(/[+]/g,'%20')
}

export async function esiaOp(prod, op) {
	let url2 = await osl_plus(`https://${esia_host}/aas/oauth2/ac`
					, {response_type:'code', access_type:'online'})
	if(op) {
		var w = 800;
		var h = 600;
		//var left  = 100;
		//var top   = 100;
		var popup = window.open(url2+"&display=popup"
			, 'esia-'+op
			, "width=" + w + ",height=" + h 
			//+ ",top=" + top + ",left=" + left 
			+ ",location=1,status=0,menubar=0,resizable=0,scrollbars=0");
		window.closeUpdatePopup = () => {
			popup.close()
			window.open("/person", "_self");
		}
	} else {
		//console.log(url2, fd)
		window.open(url2, "_self");
	}
}

export async function esiaLogin(prod) {
	return await esiaOp(prod)
}

/**
 * this is empty(!!!) UI
 * maybe, messages only
 **/
export function EsiaOk() {
	let [ld, setLd] = useState(undefined)
	let blk = useRef(undefined)

	let fd = new URLSearchParams(window.location.search)

	let error_code = fd.get('error');
	let error_description = fd.get('error_description');
	if(error_code) {
		setLd({
			error: error_code
			, message: error_description
		})
	}

	let code = fd.get('code')
	let state = fd.get('state')
	//let sess_state = window.sessionStorage['esia-state']
	//TODO: check state!

	let op = window.name.match(/esia-(.*)/) 
		if(op) op = RegExp.$1
		else op = 'login' 
	//alert(op)

	useEffect(()=>{
		if(!error_code && ld === undefined && blk.current===undefined){
				setLd(null)
				blk.current = null;
				osl_plus('/login-ac',
					{grant_type: 'authorization_code', token_type: 'Bearer', code}
				).then(url=>fetch(url,{ headers: { "Esia-Host": esia_host } }))
				.then(r=>r.ok && r.status === 200 ? r.json() : null)
				.then(r=>{
					// we have access token here (or 403)
					if(!r) {
						setLd({error:'e0', message: 'Обшибка обращения к серверу доступа' })
						throw 'error';
					}
					if(r.error) {
						setLd(r)
						throw 'error';
					}
					if(op === 'update_user' || !r.login) {
						// update requested explicitly OR unknown user is here
						return fetch('/app/anonymous/esia/upd?token='
								+encodeURIComponent(r.acc)
								,{ headers: { "Esia-Host": esia_host } })
							.then(result=>result.json())
							.then(result=>{
								if(result.error) {
									setLd(result);
									throw 'error';
								}
								if(result.code === 'alien') {
									setLd({error:'e1', message: 'Пользователь не зарегистрирован' })
									throw 'error';
								}
							});
					}
					return {result:'ok'};
				})
				.then(result=>{
					blk.current=true
					setLd(true)
					switch(op) {
						case 'update_user':
							//navigate (and reload!) parent to person page
							window.opener.closeUpdatePopup()
							break;
						default:
							//login & friends
							later(2*1000)
							.then(()=>{
								window.open("/", "_self");
							})
					}
				})
		}}, [])
	return ld === undefined? <div>Подготовка входа</div>
			: ld === null ? <div>Ожидание ЕСИА</div>
			: ld === true ? <div>Выполняется вход</div>
			: <>
						<h1>Ошибка</h1>
						<p>Код: {ld.error} {ld.op??''}</p>
						<p>{ld.message}</p>
						<p>{ld.error_description}</p>
						{ld.error==='e0'&&<>
							<ol>
								<li>Если после входа в систему появляется сообщение "Ошибка обращения к серверу доступа" убедитесь, что системные часы на Вашем рабочем месте отображают правильную дату и время.</li>
								<br/>
								<li>Если Вы используете подключение по TLS, и время на Вашем рабочем месте настроено верно, для решения проблемы обратитесь в техническую поддержку ЦИТиС:
							<ul>
								<li>почта: zspd@citis.ru https://zspd.citis.ru/</li>
								<li>телефон: +7 (800) 200-65-64, +7 (495) 197-65-91</li>
							</ul>
							Если Вы входите в модуль ЕР ЦДО с рабочего места, оборудованного подключением к VipNet, и время на Вашем рабочем месте настроено верно, для решения проблемы обратитесь в техническую поддержку ЦИТиС: почта 3608vipnet@citis.ru
								</li>
							</ol>
						</>
						}
						{ld.error==='e1'&&<>
							<ol>
								<li>Если Вы являетесь ответственным от организации, и Вы не можете авторизоваться в модуле ЕР ЦДО, убедитесь, что Ваша организация подала заявку на регистрацию в модуле ЕР ЦДО и получила письмо от ercdo@citis.ru с подтверждением регистрации.
									<br/>Если же Ваша организация была зарегистрирована, и Вы верно указали СНИЛС, обратитесь в техническую поддержку ЦИТИС:
								<ul>
									<li>почта: ercdo@citis.ru</li>
								</ul></li>
								<br/>
								<li>Если Вы не являетесь ответственным от организации, и Вы не можете авторизоваться в модуле ЕР ЦДО, обратитесь к ответственному от организации и убедитесь, что Вы были приглашены в модуль как пользователь.
									<br/>Если же Вы были добавлены в модуль и верно указали СНИЛС, обратитесь в техническую поддержку ЦИТиС:
								<ul>
									<li>почта: ercdo@citis.ru</li>
								</ul>
								</li>
							</ol>
						</>
						}
						<p>
						<button type="button" onClick={esiaLogout}>повторить вход</button>
						</p>
				</> 
			;
}

export function esiaLogout() {
		try {
			//alert(1)
			//var w = 800;
			//var h = 600;
			//var left  = 100;
			//var top   = 100;
			/*
			var popup = window.open(
				'about:blank'
				, 'esia-logout'
				, "width=" + w + ",height=" + h 
				//+ ",top=" + top + ",left=" + left 
				+ ",location=1,status=0,menubar=0,resizable=0,scrollbars=0");
			*/
			//var popup = 
			window.open(`https://${esia_host}/idp/ext/Logout?client_id=${OBRN_ID}`
				, '_self'//'esia-logout'
				);
			later(2*1000)
				.then(()=>{
					window.open("/", "_self");
				})
			//await later(500);
			//alert(2)
			//popup.close()
		} catch(e) {}
		//window.open("/","_self")
}

// Converts an ArrayBuffer directly to base64, without any intermediate 'convert to string then
// use window.btoa' step. According to my tests, this appears to be a faster approach:
// http://jsperf.com/encoding-xhr-image-data/5

/*
MIT LICENSE

Copyright 2011 Jon Leighton

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/

function base64ArrayBuffer(arrayBuffer) {
  var base64    = ''
  var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

  var bytes         = new Uint8Array(arrayBuffer)
  var byteLength    = bytes.byteLength
  var byteRemainder = byteLength % 3
  var mainLength    = byteLength - byteRemainder

  var a, b, c, d
  var chunk

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048)   >> 12 // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032)     >>  6 // 4032     = (2^6 - 1) << 6
    d = chunk & 63               // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength]

    a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3)   << 4 // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '=='
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

    a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008)  >>  4 // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15)    <<  2 // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '='
  }
  
  return base64
}