import React, {useContext, useMemo} from 'react';

import {mainDbX} from 'azlib/components/db';

import {Async, AsyncOnce} from 'azlib/components/async'

import {
	ModelField
	, DbFormik, FormValues, SaveButton
} from 'azlib/components/formik-fields';

import UserInfoContext from 'UserInfo.js';

import {uinfo_changed} from 'config/common'

import {esiaOp} from 'esia/login'

import {PopupLog} from 'contingent/log'

import {XLOG} from 'azlib/components/helpers'
import {Link} from "react-router-dom";

/*
    , prefix_name VARCHAR(500)

    , last_name_lat VARCHAR(500)
    , first_name_lat VARCHAR(500)
    , middle_name_lat VARCHAR(500)
    , prefix_name_lat VARCHAR(500)

    , notes CLOB
*/

export function PersonPage({user, ...props}) {
	let uinfo = useContext(UserInfoContext);
	if(user) uinfo = user

	const readOnly = !!user;

	//console.log('U', uinfo)

	let positions = useMemo(()=>
			// here we read floated up (replicated) persons2tree!
			mainDbX.fetch_rds("persons2tree","tid{. name sname gov_regnum path} position"
				,{person: uinfo.personid})
			.then(r=>
				r.sort((a,b)=> a.tid$.path<b.tid$.path?-1:a.tid$.path>b.tid$.path?1:0 )
			)
	, [uinfo.personid])

	return (
	<DbFormik 
		store={mainDbX.store('persons', {})} 
		rowKey={[uinfo.personid]}
		onSaved={uinfo_changed}
		extra="internal"
	>
			<div
				style={{float:"right", margin:"1em 1em 0 0"}}
			>
				<PopupLog table="persons" tkey={uinfo.personid}
						transform={diff_transform} />
			</div>

		<h1>Редактирование данных пользователя {uinfo.login}
			{' '} {user?.personid}
		</h1>

		<SaveButton
			style={{
				position: "fixed"
    		, background: "#aac5f8"
    		, zIndex: 1
    		, right: "1em"
    		, padding: "1ex 2em"
    		, border: "2px solid #1f448b"
			, color: "#032a24"
    		, borderRadius: "1ex"
    		, marginTop: "-3em"
			, marginRight: "6em"
			}}
		>Сохранить</SaveButton>

		<FormValues>{row=><div className="editUser backgroundRounded">
			<div>
				<ModelField name="last_name" readOnly={!row.internal || readOnly} />
				<ModelField name="first_name" readOnly={!row.internal || readOnly} />
				<ModelField name="middle_name" readOnly={!row.internal || readOnly} />
				{!row.internal && !readOnly &&
					<div>
						<button type="button" onClick={()=>{
							esiaOp(true, 'update_user')
						}}
								style={{
									margin:"1em 0em"
								}}
						>
							Обновить из ЕСИА
						</button>
					</div>
				}</div>
			<div>
				<ModelField name="contact_tel" mask="(000) 000-00-00" readOnly={readOnly} />
				<ModelField name="contact_email" readOnly={readOnly} />
				<ModelField name="contact_info" readOnly={readOnly} />
			</div>
		</div>}</FormValues>

		<FormValues>{row=>
		<Async value={positions}>{pos=>
			<table className="simpleTable">
			<thead><tr>
			<th>Должность</th>
			<th>Наименование организации</th>
			<th>ОГРН</th>
			</tr></thead>
			<tbody>{
			(pos??[]).map(p=><tr key={p.tid}>
				<td>
				{p.position}
				</td>
				<td>
					<Link to={`/org/${p.tid}`}>{p.tid$.name} </Link>
				</td>
				<td>
					{p.tid$.gov_regnum}
				</td>
			</tr>)
			}</tbody>
			</table>
		}</Async>
		}</FormValues>


	</DbFormik>)
}

const diff_transform = {
	person: null
	, ext_filled: null
	, auth: null
}
