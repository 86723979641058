

const fio_re = [
	  /^\s*(\S+\s+)(\S)[.]\s*(\S)[.]$/ //last_name, first name letter, middle_name letter
	, /^\s*(\S+\s+)(\S)[.]$/ //last_name, first name letter
	, /^\s*(\S+\s+)(\S+\s)+(\S+\s)/ //last_name, first name exact, middle_name exact
	, /^\s*(\S+\s+)(\S+\s+)(\S+)$/ //last_name, first name exact, middle_name begin
	, /^\s*(\S+\s+)(\S+\s)$/ //last_name, first name exact
	, /^\s*(\S+\s+)(\S+)$/ //last_name, first name begin
	, /^\s*(\S+\s+)$/ //last_name exact
	, /^\s*(\S+)$/ //last_name begin
]

export function fioExtract(value, minLen) {
	if(value && value.length>=minLen)
	for(const re of fio_re) {
		if(value.match(re)) {
			let m = {
				ln: RegExp.$1.trim()
				, ln_e: RegExp.$1.trim() !== RegExp.$1
				, fn: RegExp.$2.trim()
				, fn_e: RegExp.$2.trim() !== RegExp.$2
				, mn: RegExp.$3.trim()
				, mn_e: RegExp.$3.trim() != RegExp.$3
			}
			return [ m
			, value.replace(re,'')]
		}
	}
	return [null, value]
}

export const fioCondition = gather => m => row => {
	let [ln,fn,mn] = gather(row)
	return (m.ln==='' ? true :
		m.ln_e ? ln.toUpperCase() === m.ln.toUpperCase()
		: ln.toUpperCase().startsWith(m.ln.toUpperCase())
	)
	&&
	(m.fn==='' ? true :
		m.fn_e ? fn.toUpperCase() === m.fn.toUpperCase()
		: fn.toUpperCase().startsWith(m.fn.toUpperCase())
	)
	&&
	(m.mn==='' ? true :
		m.mn_e ? mn.toUpperCase() === m.mn.toUpperCase()
		: mn.toUpperCase().startsWith(m.mn.toUpperCase())
	)
}
