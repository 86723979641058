import React from 'react'

import {ReactComponent as Img_search} from "./images/search.svg";

import css from "./search_filter.module.css"

export function SearchFilter({input, buttonProps,...props}) {
	return	<div className={css.searchFilter} {...props} >
				{input}
				<button type="button" 
					{...buttonProps}
				><Img_search alt="search"/></button>
		</div>
}
