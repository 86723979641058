import React, {useRef, useContext, useEffect} from 'react';
import {localStorageGet,localStorageSet} from './local_storage'

const SortContext = React.createContext(null)

export function loadSortSavedState(store) {
	const name = store.store
	const tid = store.Db.tid ?? '-'
	let s = JSON.parse(localStorageGet(tid+':list-sorters:'+name)||'{}')
	return {
			saveLeaf: (n,v) => {
				if(n) {
					s[n] = v
					localStorageSet(tid+':list-sorters:'+name,
						JSON.stringify(s))
				}
			}
			, leafs: () => s
		}
}


function SortCombo({slot, children, saveAs, ...props}) {
	let ctx = useContext(SortContext)
	let cur = ctx.getSortSlot(slot)

	useEffect(()=>{
		let funcs =  React.Children.map(
				children
				, child => child.props.comparator
			)
		ctx.setComparator(slot, funcs)
		ctx.setSortSlot(slot, cur)
	}, [slot, children, cur])

	return <select {...props}
				value={cur}
				onChange={e => {
					ctx.leafSaver?.saveLeaf(slot, +e.target.value)
					ctx.setSortSlot(slot, +e.target.value)
				}}
			>
			<option value={0}>Сортировка</option>
			{
				 React.Children.map(children
				 	, (e, i)=>React.cloneElement(e,{value:i+1, key:i+1, comparator:null})
				 )
			}
		   </select>
}

function SortCheck({slot, Marker, comparator, ...props}) {
	let ctx = useContext(SortContext)
	let cur = ctx.getSortSlot(slot)

	useEffect(()=>{
		ctx.setComparator(slot, comparator)
		ctx.setSortSlot(slot, cur)
	}, [slot, comparator, cur])

	return <Marker  {...props}
			direction={cur}
			onClick={e=>{
					let val = cur == 1? -1
						: cur == -1? undefined
						: 1
					ctx.leafSaver?.saveLeaf(slot, val)
					ctx.setSortSlot(slot, val)
			}}
		/>
}

function sortFunction(arr) {
	return (a,b) => {
		for(const c of arr){
			let v = c instanceof Function
					? c(a.data,b.data)
					: c.comparator(a.data,b.data) * c.direction
			if(v) return v; 
		}
		return 0;
	}
}

export default function Context({onSortChange, onSortInit, leafSaver, children}) {
	let slots = useRef(new Map(Object.entries(leafSaver.leafs())))
	let comparators = useRef(new Map())
	let lastSort = useRef(null)
	let ctx = {
		leafSaver: leafSaver
		, setComparator: (slot, funcs) => {
			if(funcs) comparators.current.set(slot, funcs)
			else comparators.current.delete(slot)
		}
		, setSortSlot: (slot, cmp) => {
			if(cmp)
				slots.current.set(slot, cmp)
			else
				slots.current.delete(slot)
			let a = Array.from(slots.current.entries())
			a =	a.sort(([a],[b]) => a>b ? 1: a<b ? -1: 0)
					.map(([slot,cmp]) => {
						if(!cmp) return null
						let funcs = comparators.current.get(slot)
						if(funcs instanceof Function)
							return {comparator: funcs, direction: cmp}
						return funcs[cmp-1]
					})
					.filter(Boolean)
			let f = a.length? sortFunction(a) : null
			if(onSortInit)
				lastSort.current = f
			else
				onSortChange(f)
		}
		, getSortSlot: slot => slots.current.get(slot)
	}
	useEffect(()=>{
		if(onSortInit) //rendered
			onSortInit(lastSort.current)
	})
	return <SortContext.Provider value={ctx}>{children}</SortContext.Provider>
}

Context.Select = SortCombo
Context.Check = SortCheck
Context.cmp = (a,b) => a>b ? 1: a<b ? -1: 0
