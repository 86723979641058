import React, {useState, useEffect} from 'react';

import {func_or_const} from './helpers'
import {Loading} from './ui_elems'

/**
 * evaluate value as a Promice
 * and when it is resolved
 * show children (resolved=>componets)
 * if it is not resolved, show fallback (or <Loading/>)
 */
export function Async({value, fallback, children}) {
  const [done,setDone] = useState(undefined)
  if(done === undefined)
	  value.then(setDone)

  return done === undefined?  (fallback ?? <Loading/>)
        : func_or_const(children,done); 
}

/**
 * same as Async by calculate value only once
 */
export function AsyncOnce({value, fallback, children}) {
  const [done,setDone] = useState(undefined)
  useEffect(() => {
  	value().then(setDone)
  }
  , [value])

  return done === undefined?  (fallback ?? <Loading/>)
        : func_or_const(children,done); 
}

